import classNames from 'classnames';
import React from 'react';
import { TNavBar, TNavBarValue } from 'types/header';
import './LibraryNav.scss';

const navBarTabs: TNavBar[] = [
  { label: 'Most Popular', value: 'popularity' },
  { label: 'Recently Used', value: 'recently' },
  { label: 'A to Z', value: 'title' },
];

interface Props {
  filterBy: TNavBarValue | string;
  handleSetFilterBy: (filter: TNavBarValue | '') => void;
  search?: (searchTerm: string) => void;
  keyword?: string;
}

export const LibraryNav: React.FC<Props> = ({ filterBy, handleSetFilterBy, keyword, search }) => {
  return (
    <nav
      className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between w-100 bg-white library__nav"
      data-cy="library__nav"
    >
      <div
        className="d-flex justify-content-center align-items-center mb-1 mb-sm-0 gap-2 gap-sm-4 library__nav__sort-by"
        data-cy="library__sort-by"
      >
        {navBarTabs.map(({ label, value }) => (
          <div
            key={value}
            className={classNames('px-2 text-center library__nav__sort-by__item cursor-pointer', {
              active: filterBy === value,
            })}
            onClick={() => handleSetFilterBy(value)}
            data-cy={value}
          >
            {label}
          </div>
        ))}
      </div>
      {search && (
        <div
          className="position-relative d-flex align-items-center px-3 py-3 py-sm-2 flex-grow-1 library__nav__search"
          data-cy="library__nav__search"
        >
          <i className="mdi mdi-24px mdi-magnify position-absolute library__nav__search__icon" />
          <input
            type="text"
            className="w-100 py-2 ps-6 pe-2 border rounded bg-transparent library__nav__search__input"
            placeholder="Search for content type..."
            onChange={e => search(e.target.value)}
            data-cy="library__search"
            value={keyword}
          />
        </div>
      )}
    </nav>
  );
};
