import apiClient from 'apis/ApiClient';
import { WorkspaceInfo } from 'types';

const endPoint = '/payment/customer';

export async function getWorkspaceBillingInformation(): Promise<WorkspaceInfo> {
  const response = await apiClient.get(`${endPoint}`);

  return response.data;
}

export async function updateWorkspaceBillingInformation(payload: WorkspaceInfo): Promise<WorkspaceInfo> {
  const response = await apiClient.put(`${endPoint}`, payload);

  return response.data;
}
