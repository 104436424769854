import React from 'react';
import { ResultContent } from 'models/Content';
import { ContentResultAnswer } from '../ContentResultAnswer';
import './ContentResultList.scss';
import classNames from 'classnames';
import { ContentResultHelper } from '../ContentResultHelper';

interface Props {
  data: ResultContent;
}

export const ContentResultList = ({ data }: Props) => {
  return (
    <div className="content-result-list d-block d-md-none">
      <div className="content-result-list__title d-none d-md-block">{data.title}</div>
      <div
        className="content-result-list__question"
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      ></div>
      {data.correctAnswers.map((answer, index) => (
        <div
          className={classNames('content-result-list__wrapper', {
            'border-bottom': index !== data.correctAnswers.length - 1,
          })}
          key={'' + index}
        >
          <div className="content-result-list__inner d-flex flex-column gap-2">
            <div className="answer-card">
              <div className="answer-card__header">Answer</div>
              <div
                className="answer-card__body"
                dangerouslySetInnerHTML={{
                  __html: data.indexes[index],
                }}
              ></div>
            </div>

            <div className="answer-card">
              <div className="answer-card__header">Learner&apos;s Answer</div>
              <div className="answer-card__body">
                {data.learnerAnswers[index] ? (
                  <ContentResultAnswer
                    answer={data.learnerAnswers[index]}
                    correct={data.correctAnswers[index] === data.learnerAnswers[index]}
                    learner={true}
                  />
                ) : null}
              </div>
            </div>

            <div className="answer-card">
              <div className="answer-card__header">Correct</div>
              <div className="answer-card__body">
                {answer ? (
                  <ContentResultAnswer
                    answer={answer}
                    correct={true}
                    learner={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ))}

      <ContentResultHelper />
    </div>
  );
};
