import { useDeepLinking } from 'hooks/lti';
import { useAppStore } from 'hooks/useAppStore';
import { H5PContent } from 'models/Content';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ContentActionStatus } from 'types';

export const useContentEditor = () => {
  const { ltiMode, contentStore } = useAppStore();
  const { mutate: submitDeepLinking } = useDeepLinking();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id: contentIdParam } = useParams();

  const backToPlayer = useMemo(() => {
    return searchParams?.get('backToPlayer') === 'true';
  }, [searchParams]);

  const saveAsync = async (action?: ContentActionStatus) => {
    contentStore.setActionStatus(action);

    // If no contentId, or editor is loaded yet, it means user goes directly to the setting/preview tab
    if (
      (!contentStore.contentId || contentStore.contentId == 'new-content') &&
      !contentStore.h5pEditorRef &&
      !contentStore.editor?.metadata?.title
    ) {
      toast('Content title is required', { type: 'error' });
      navigate(`edit?${searchParams}`, { replace: true, relative: 'route', state });
      return;
    }

    // If H5P editor is loaded, save content using H5P editor
    // which results in calling editorSaveContentCallback below
    if (contentStore.h5pEditorRef?.current) {
      return contentStore.h5pEditorRef?.current?.save();
    } else {
      // If H5P editor is not loaded, must be the case go directly to the setting/preview tab
      return saveContentSettingsAsync();
    }
  };

  const editorSaveContentCallback = async (contentId: string, requestBody: any) => {
    const content = await contentStore.saveContentAsync({ contentId, requestBody });
    saveContentSuccess(content);
    return content;
  };

  const saveContentSettingsAsync = async () => {
    const content = await contentStore.saveContentAsync({
      contentId: contentStore.contentId,
      requestBody: contentStore.editor
        ? {
            library: contentStore.editor.library,
            params: {
              metadata: contentStore.editor.metadata,
              params: contentStore.editor.params,
            },
          }
        : null,
    });
    saveContentSuccess(content);
    return content;
  };

  const saveContentSuccess = (content: H5PContent) => {
    const { id } = content;

    const message = !!contentIdParam ? 'Content has been updated.' : 'New content has been created successfully.';
    toast(message, { type: 'success' });

    searchParams.set('folderId', contentStore?.folderId || '');
    queryClient.invalidateQueries(['contents', id]);

    if (ltiMode) {
      if (contentStore.actionStatus === ContentActionStatus.SAVE_AND_INSERT) {
        submitDeepLinking(id);
      } else navigate(`../../${id}/preview?${searchParams}`, { replace: true, relative: 'path', state });

      return;
    }

    if (backToPlayer) {
      searchParams.delete('backToPlayer');
      navigate(`../play?${searchParams}`, {
        relative: 'route',
        state,
      });
    } else {
      navigate(`/contents/${id}/preview?${searchParams}`, { replace: true, relative: 'path', state });
    }

    return content;
  };

  return {
    saveAsync,
    editorSaveContentCallback,
  };
};
