import { deleteChat } from 'apis/AiChat/AiChat';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AiChatHistory, ErrorResponse } from 'types';

export const useDeleteChat = () => {
  const queryClient = useQueryClient();

  return useMutation<void, ErrorResponse, string>(deleteChat, {
    onSuccess: (_, chatId) => {
      queryClient.setQueryData<AiChatHistory[]>('chat-history', (chatsList: AiChatHistory[] = []) => {
        return chatsList.filter(chat => chat.id !== chatId);
      });

      toast.success('Chat has been deleted.');
    },
  });
};
