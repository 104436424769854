import { getUserDetail } from 'apis/User/User';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ErrorResponse } from 'types';

export const useGetUserDetail = (id: string) => {
  const navigate = useNavigate();

  return useQuery(['users', id, 'detail'], () => getUserDetail(id), {
    staleTime: 3000,
    cacheTime: 3000,
    retry: false,
    onError: (error: ErrorResponse) => {
      if (error.statusCode === 404) {
        navigate('/not-found');
      }
    },
  });
};
