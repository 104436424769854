import { getOptionLibraries } from 'apis/Options/Options';
import { useQuery } from 'react-query';
import { LibraryOption } from 'types';

export const useGetOptionLibraries = () => {
  return useQuery<LibraryOption[]>(['options', 'libraries'], getOptionLibraries, {
    cacheTime: 300000,
    staleTime: 300000,
  });
};
