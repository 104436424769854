import React from 'react';
import { AMEX, Discover, JCB, Mastercard, Unionpay, VisaIcon } from 'components';

export const PayIcon = ({ brand }: { brand: string }) => {
  switch (brand) {
    case 'visa':
      return <VisaIcon />;
    case 'mastercard':
      return <Mastercard />;
    case 'amex':
      return <AMEX />;
    case 'discover':
      return <Discover />;
    case 'unionpay':
      return <Unionpay />;
    case 'jcb':
      return <JCB />;
    default:
      return <>{brand}</>;
  }
};
