import { useAppStore } from 'hooks/useAppStore';
import { Document } from 'models/Content';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';
import { changeStatus } from 'apis/Folder/Folder';

export const useChangeStatus = (document?: Document) => {
  const { queryKey, userInfo } = useAppStore();
  const queryClient = useQueryClient();

  return useMutation(changeStatus, {
    onSuccess: (data, contentRequest) => {
      if (document) {
        queryClient.setQueryData<Document[]>(queryKey, previousData => {
          return previousData.map(doc => {
            if (doc.id === document.id) {
              return { ...doc, status: contentRequest.status, updatedAt: data.updatedAt, updatedBy: userInfo };
            }
            return doc;
          });
        });
        toast.success('Content status has been changed.');
      } else {
        queryClient.invalidateQueries({ queryKey });
      }
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
