import { useAppStore } from 'hooks/useAppStore';
import { useQueryClient } from 'react-query';
import { Connection, CreateConnection } from 'types';

export const useAddConnection = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  const queryKey = ['workspaces', currentWorkspaceId, 'connections'];
  const queryClient = useQueryClient();

  const addConnection = (connection: Partial<CreateConnection>) => {
    connection.id = Math.random().toString(36).substr(2, 9) + '_new';
    const connections = queryClient.getQueryData<Partial<Connection>[]>(queryKey) || [];
    queryClient.setQueryData<Partial<Connection>[]>(queryKey, [connection, ...connections]);
  };

  return {
    addConnection,
  };
};
