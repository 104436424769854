import { findPermissions } from 'apis/Folder/Folder';
import { useQuery } from 'react-query';
import { ContentPermissions } from 'types';

export const useGetContentPermissions = (contentId: string, onSuccess?: (data: ContentPermissions) => void) => {
  const { data: contentPermissions, isLoading } = useQuery<ContentPermissions>(
    ['contents', contentId || 'new', 'permissions'],
    async () => await findPermissions(contentId),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return {
    contentPermissions,
    isLoading,
  };
};
