import moment from 'moment';
import React from 'react';
import { ContentUserDataAttempt } from 'types/student-report';
import './ExpandedContentAttempts.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { Minus } from 'react-feather';

export const ExpandedContentAttempts = ({ attempts = [] }: { attempts: ContentUserDataAttempt[] }) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { closeModal } = useModal();

  const viewAttempt = (attempt: ContentUserDataAttempt) => {
    navigate(`/learner-reports/${attempt.user.id}/${attempt.contentId}/attempts/${attempt.id}?${searchParam}`);
    closeModal();
  };

  return (
    <div className="expanded-content-attempts">
      {attempts.map(attempt => {
        return (
          <div
            key={attempt.id}
            className="expanded-content-attempt bg-white d-flex align-items-center justify-content-between border border-neutral-200"
          >
            <div className="flex-fill d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 gap-md-5">
              <div className="attempt-time fw-medium d-flex flex-column flex-md-row">
                <span className="text-neutral-500 d-none d-md-block">Attempt Time:</span>
                <span className="ms-0 ms-md-1 gray-900">
                  {moment(attempt.finishedAt).format('DD/MM/YYYY HH:mm:ss')}
                </span>
              </div>

              <div className="w-100 w-md-auto d-flex align-items-center gap-5">
                <div className="flex-grow-1 flex-grow-md-0 fw-medium d-flex flex-column flex-md-row">
                  <span className="text-neutral-500">
                    Duration<span className="d-none d-md-inline">:</span>
                  </span>
                  <span className="ms-0 ms-md-1 gray-900">{attempt.duration}</span>
                </div>

                <div className="flex-grow-1 flex-grow-md-0 fw-medium d-flex flex-column flex-md-row">
                  <span className="text-neutral-500">
                    Total Score<span className="d-none d-md-inline">:</span>
                  </span>
                  <span className="ms-0 ms-md-1 gray-900">
                    {attempt.totalScore !== null ? `${attempt.score} (${attempt.totalScore}%)` : <Minus size={16} />}
                  </span>
                </div>
              </div>
            </div>
            <div className="me-0">
              <button
                onClick={() => viewAttempt(attempt)}
                className="btn btn-primary"
              >
                View
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
