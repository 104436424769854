import { Role } from '@spiderbox/common';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label } from 'reactstrap';
import { RoleTypeMapping, User } from 'types';
import { UserRoleBadge } from '../UserRoleBadge';
import './DropdownAssignRole.scss';

interface Props {
  user: User;
  reset?: () => void;
  onChangeValue?: (value: Role) => void;
  disabled?: boolean;
}

export const DropdownAssignRole: React.FC<Props> = ({ user, onChangeValue, disabled = false }) => {
  const userRoles = useMemo(() => user.roles, [user]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div
      id={'user-role-popover-' + user?.id}
      data-cy="add-user-role"
      onClick={e => e.stopPropagation()}
    >
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        disabled={disabled}
      >
        <DropdownToggle
          className="bg-transparent border-0 p-0 rounded-pill"
          data-cy="add-user-toggle"
        >
          <UserRoleBadge
            label={userRoles.includes(Role.ADMIN) ? RoleTypeMapping[Role.ADMIN] : RoleTypeMapping[Role.MEMBER]}
            isShowChevron={!disabled}
          />
        </DropdownToggle>

        <DropdownMenu
          className="mt-2"
          style={{ minWidth: 300 }}
          data-cy="user-role-menu"
        >
          <DropdownItem
            header
            className="px-3 py-1 text-neutral-900 fw-semibold fs-base"
          >
            Assign Role
          </DropdownItem>
          <DropdownItem divider />

          <Form
            className="p-1"
            onChange={(e: ChangeEvent<HTMLFormElement>) => onChangeValue(e.target.value)}
          >
            <div className="d-grid gap-2 px-1">
              <div
                className="d-flex border border-neutral-200 rounded p-2 dropdown-assign-role__radio"
                data-cy="user-role-member"
              >
                <FormGroup check>
                  <Input
                    id={'member' + user?.email}
                    name="role"
                    type="radio"
                    value={Role.MEMBER}
                    checked={userRoles.includes(Role.MEMBER)}
                    onChange={toggle}
                  />
                  <Label
                    check
                    for={'member' + user?.email}
                  >
                    <div className="fw-semibold text-neutral-900">Member</div>
                    <div className="fs-sm text-neutral-500 fw-normal">
                      Access to workspace, manage contents and learner reports
                    </div>
                  </Label>
                </FormGroup>
              </div>

              <div
                className="border border-neutral-200 rounded p-2 dropdown-assign-role__radio"
                data-cy="user-role-admin"
              >
                <FormGroup check>
                  <Input
                    id={'admin' + user?.email}
                    name="role"
                    type="radio"
                    value={Role.ADMIN}
                    checked={userRoles.includes(Role.ADMIN)}
                    onChange={toggle}
                  />
                  <Label
                    check
                    for={'admin' + user?.email}
                  >
                    <div className="fw-semibold text-neutral-900">Admin</div>
                    <div className="fs-sm text-neutral-500 fw-normal">
                      Manage contents, learner reports and organisation settings
                    </div>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Form>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
