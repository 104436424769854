import { findContentPermissions } from 'apis/Folder/Folder';
import { useQuery } from 'react-query';
import { ContentRole } from '@spiderbox/common';
import { ErrorResponse } from 'types';

export const useGetContentRole = (contentId: string) => {
  const { data, isLoading, error } = useQuery(
    ['contents', contentId, 'role'],
    async () => await findContentPermissions(contentId),
    {
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  if (!contentId || contentId === 'new') {
    return {
      contentRole: ContentRole.OWNER,
      permissions: ['*'],
    };
  }

  return {
    contentRole: data?.role,
    permissions: data?.permissions || [],
    isLoading,
    error: error as ErrorResponse,
  };
};
