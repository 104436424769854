import { deletePermanently } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { Document } from 'models/Content';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useDeletePermanently = () => {
  const { queryKey } = useAppStore();
  const queryClient = useQueryClient();

  return useMutation(deletePermanently, {
    onSuccess: (_, contentId) => {
      queryClient.setQueryData<Document[]>(queryKey, (contents: Document[]) =>
        contents.filter(content => content.id != contentId),
      );
      toast.success('Item has been deleted permanently.');
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
