import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from 'apis/Profile/Profile';
import PasswordField from 'components/AppForm/PasswordField';
import { changePasswordSchema } from 'libs/yup';
import { ChangePasswordRequest } from 'models/Password';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { ProfileLayout } from '../components/ProfileLayout/ProfileLayout';
import './ChangePassword.scss';
import { fetchServerError } from 'utils';

export const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ChangePasswordRequest>({
    mode: 'onChange',
    resolver: yupResolver(changePasswordSchema as any),
  });

  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } = useMutation({
    mutationFn: changePassword,
    retry: false,
    onSuccess: () => {
      toast('Your changes have been saved.', { type: 'success' });
      reset();
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  const onSubmit = async (values: ChangePasswordRequest) => {
    changePasswordMutate({ ...values });
  };

  const onError = async () => {
    toast(
      'Apologies, but there seems to be a validation error in your form submission. Please review and correct the necessary fields.',
      { type: 'warning' },
    );
  };

  return (
    <ProfileLayout
      handleClick={handleSubmit(onSubmit, onError)}
      isLoading={changePasswordLoading}
      breadcrumbActive="Reset Password"
      isDirty={isDirty}
    >
      <div className="h-100 bg-white d-flex flex-column rounded-start rounded-bottom px-4 py-3">
        <div
          className="text-medium fw-bold gray-900 mb-5"
          data-cy="title"
        >
          Reset Password
        </div>

        <div className="d-flex flex-column gap-3 gap-md-5">
          <div className="row">
            <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
              <span className="gray-900">Current Password</span>
            </label>
            <div className="col-12 col-md-8 col-xl-6 change-pw-form--input form-group mt-0">
              <PasswordField
                register={register('oldPassword')}
                id="oldPassword"
                placeholder="Input current password"
                error={!!errors.oldPassword}
                dataCy="input-old-password"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="old-password-invalid"
              >
                {errors.oldPassword?.message}
              </p>
            </div>
          </div>

          <div className="row">
            <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
              <span className="gray-900">New Password</span>
            </label>
            <div className="col-12 col-md-8 col-xl-6 change-pw-form--input form-group mt-0">
              <PasswordField
                register={register('newPassword')}
                id="newPassword"
                placeholder="Input new password"
                error={!!errors.newPassword}
                dataCy="input-new-password"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="new-password-invalid"
              >
                {errors.newPassword?.message}
              </p>
            </div>
          </div>

          <div className="row">
            <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
              <span className="gray-900">Confirm Password</span>
            </label>
            <div className="col-12 col-md-8 col-xl-6 change-pw-form--input form-group mt-0">
              <PasswordField
                register={register('passwordConfirmation')}
                id="passwordConfirmation"
                placeholder="Confirm new password"
                error={!!errors.passwordConfirmation}
                dataCy="input-confirm-password"
              />

              <p
                className="feedback-invalid mb-0"
                data-cy="confirm-password-invalid"
              >
                {errors.passwordConfirmation?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
};
