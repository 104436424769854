import useModal from 'hooks/useModal';
import React from 'react';
import { ListGroup, ListGroupItem, ModalBody, ModalHeader } from 'reactstrap';
import cx from 'classnames';

import './Publish.scss';
import { Footer } from '../Footer';
import { Icon } from '../Icon';

type Props = {
  labelCancel?: string;
  labelOK?: string;
  onCancel?: () => void;
  onOK?: () => void;
  classNameIcon?: string;
};

export const Publish = ({ labelCancel = 'Cancel', labelOK = 'OK', onOK }: Props) => {
  const { closeModal } = useModal();

  const publishList = [
    {
      type: 'unpublish',
      title: 'Unpublished',
      content: `Content can't be inserted into LMS`,
    },
    {
      type: 'publish',
      title: 'Published',
      content: `Content can be inserted into LMS`,
    },
    {
      type: 'protected',
      title: 'Protected',
      content: `Content can't be inserted into LMS`,
    },
  ];

  return (
    <>
      <ModalHeader
        toggle={closeModal}
        className="modalPublish__header"
      >
        <div className="d-flex align-items-center gap-3">
          <Icon classNameIcon="mdi-earth" />
          <div>
            <div className="modalPublish__header--title">Publishing Content</div>
            <div className="modalPublish__header--message"> Choose a status for Lorem ipsum dolor sit </div>
          </div>
        </div>
      </ModalHeader>

      <ModalBody>
        <ListGroup flush>
          {publishList.map(({ type, title, content }, i) => (
            <ListGroupItem
              className="modalPublish__item d-flex gap-3"
              key={i}
            >
              <i
                className={cx('mdi mdi-18px ms-3', {
                  'mdi-earth': type === 'publish',
                  'mdi-close-circle-outline': type === 'unpublish',
                  'mdi-lock-outline': type === 'protected',
                })}
              />
              <div>
                <div className="modalPublish__item--title">{title}</div>
                <div className="modalPublish__item--content">{content}</div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>

      <Footer
        labelCancel={labelCancel}
        labelOK={labelOK}
        onOK={onOK}
        onCancel={closeModal}
      />
    </>
  );
};
