import apiClient from 'apis/ApiClient';
import { Connection, LoginResponse, SendGrade } from 'types';

const endPoint = '/lti';

export const generateToken = async (ltiKey: string, workspaceId: string): Promise<LoginResponse> => {
  return await apiClient
    .get(`${endPoint}/token?ltiKey=${ltiKey}`, {
      headers: {
        'x-workspace-id': workspaceId,
      },
    })
    .then(response => {
      return response.data;
    });
};

export const submitDeeplinkingForm = async (
  contentId: string,
  workspaceId: string,
  ltiKey: string,
): Promise<{ form: string }> => {
  return await apiClient
    .post(`${endPoint}/deeplinking/form`, {
      contentId,
      workspaceId,
      ltiKey,
    })
    .then(response => {
      return response.data;
    });
};

export const findConnections = async (): Promise<Connection[]> => {
  return await apiClient.get(`${endPoint}/connections`).then(response => {
    return response.data;
  });
};

export const registerConnection = async (connection: Connection): Promise<Connection> => {
  return await apiClient.post(`${endPoint}/connections`, connection).then(response => {
    return response.data;
  });
};

export const deactivateConnection = async (connection: {
  platformUrl: string;
  clientId: string;
}): Promise<Connection> => {
  return await apiClient.patch(`${endPoint}/connections/deactivate`, connection).then(response => {
    return response.data;
  });
};

export const activateConnection = async (connection: {
  platformUrl: string;
  clientId: string;
}): Promise<Connection> => {
  return await apiClient.patch(`${endPoint}/connections/activate`, connection).then(response => {
    return response.data;
  });
};

export const deleteConnection = async (id: string) => {
  return await apiClient.delete(`${endPoint}/connections/${id}`).then(response => {
    return response.data;
  });
};

export const getConnectionsDomain = async () => {
  return await apiClient.get(`${endPoint}/connections/domain`).then(response => {
    return response.data;
  });
};

export const updateConnection = async (connection: Connection): Promise<Connection> => {
  return await apiClient.patch(`${endPoint}/connections/${connection.kid}`, connection).then(response => {
    return response.data;
  });
};

export const sendGrade = async (payload: SendGrade): Promise<void> => {
  return await apiClient.post(`${endPoint}/grade`, payload).then(response => {
    return response.data;
  });
};
