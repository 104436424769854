import React from 'react';
import { ReactSVG } from 'react-svg';
import ChatIcon from 'assets/images/ai-assistant/chat.svg';
import { Edit3, Trash2 } from 'react-feather';
import { AiChatHistory } from 'types';
import moment from 'moment';

type ChatCardContentProps = {
  chat: AiChatHistory;
  onEdit: () => void;
  onDelete?: () => void;
};

const ChatCardContent = ({ chat, onEdit, onDelete }: ChatCardContentProps) => {
  const handleClickEdit = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    onEdit();
  };

  const handleClickDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <>
      <ReactSVG src={ChatIcon} />
      <div className="flex-fill font-size-14 fw-normal text-neutral-700 line-clamp-2">{chat.name}</div>
      <div className="gap-4 d-flex justify-content-between align-items-center">
        <div className="font-size-14 fw-normal text-neutral-700 text-nowrap">
          {moment(chat.createdAt).format('hh:mm A')}
        </div>

        <div
          className="gap-3 d-flex"
          onClick={e => e.stopPropagation()}
        >
          <Edit3
            className="flex-shrink-0 chat-card__icon-action"
            width={20}
            height={20}
            onClick={handleClickEdit}
          />
          <Trash2
            className="flex-shrink-0 chat-card__icon-action"
            width={20}
            height={20}
            onClick={handleClickDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ChatCardContent;
