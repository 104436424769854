import { ContentSetting } from './content-setting.type';

export type ContentDetail = {
  id: string;
  library?: string;
  title: string;
  status: ContentStatus;
  user: { id: string; fullName: string; email: string };
  setting: ContentSetting;
  totalUserAttempts?: number;
};

enum ContentStatus {
  Draft = 'Draft',
  Published = 'Published',
  NotApplied = 'NotApplied',
  Inactive = 'Inactive',
}
