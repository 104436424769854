import { useContext } from 'react';

import { ModalContext } from 'contexts';

const useModal = () => {
  const { isOpen, type, openModal, closeModal, modalProps } = useContext(ModalContext);

  return { isOpen, type, openModal, closeModal, modalProps };
};

export default useModal;
