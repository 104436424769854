import { deactivateConnection } from 'apis/Lti/Lti';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Connection } from 'types';

export const useDeactivateConnection = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const queryClient = useQueryClient();
  const queryKey = ['workspaces', currentWorkspaceId, 'connections'];

  return useMutation(deactivateConnection, {
    onSuccess: (_, { platformUrl, clientId }) => {
      queryClient.setQueryData<Partial<Connection>[]>(
        queryKey,
        connections =>
          connections?.map(connection => {
            if (connection.platformUrl === platformUrl && connection.clientId === clientId) {
              return {
                ...connection,
                active: false,
              };
            }
            return connection;
          }),
      );
      toast.success('LMS connection has been deactivated.');
    },
  });
};
