import React from 'react';
import { Copy } from 'react-feather';
import { useCopyToClipboard } from 'react-use';

interface Props {
  copyText: string;
}

export const CopyClipboardBtn: React.FC<Props> = ({ copyText }) => {
  const [value, copy] = useCopyToClipboard();

  return (
    <button
      className="btn btn-outline-primary d-flex align-item-center rounded-pill"
      style={{ padding: '0px 12px', height: 'fit-content' }}
      onClick={() => copy(copyText)}
    >
      <Copy
        strokeWidth={2.6}
        size={12}
        style={{ marginTop: '3px' }}
      />
      <div className="ms-1 fs-sm">{value?.value ? 'Copied' : 'Copy'}</div>
    </button>
  );
};
