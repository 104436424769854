import MultipleChoice from 'assets/images/contents/Multiple-Choice.svg';
import SingleChoice from 'assets/images/contents/Single-Choice.svg';
import Summary from 'assets/images/contents/Summary.svg';
import TrueFalse from 'assets/images/contents/True-False.svg';
import FillInTheBlank from 'assets/images/contents/Fill-in-the-Blanks.svg';
import DragDrop from 'assets/images/contents/Drag-and-Drop.svg';
import Audio from 'assets/images/contents/Audio.svg';
import InteractiveVideo from 'assets/images/contents/Interactive-Video.svg';
import MarkWords from 'assets/images/contents/Mark-the-Words.svg';
import DragWords from 'assets/images/contents/Drag-the-Words.svg';
import Presentation from 'assets/images/contents/Presentation.svg';
import DialogCard from 'assets/images/contents/Dialog.svg';
import Accordion from 'assets/images/contents/Accordion.svg';
import Arithmetic from 'assets/images/contents/Arithmetic.svg';
import Chart from 'assets/images/contents/Chart.svg';
import Collage from 'assets/images/contents/Collage.svg';
import Column from 'assets/images/contents/Column.svg';
import Crossword from 'assets/images/contents/Crossword.svg';
import DocumentationTool from 'assets/images/contents/Documentation.svg';
import GuessAnswer from 'assets/images/contents/Guess-the-Answer.svg';
import ImageSlider from 'assets/images/contents/Image-Slider.svg';
import InteractiveBook from 'assets/images/contents/Interactive-Book.svg';
import MemoryGame from 'assets/images/contents/Memory-Game.svg';
import SortParagraph from 'assets/images/contents/Sort-the-Paragraphs.svg';
import ImageChoice from 'assets/images/contents/Image-Choice.svg';
import Quiz from 'assets/images/contents/Quiz.svg';
import ARScavenger from 'assets/images/contents/AR-Scavenger.svg';
import BranchingScenario from 'assets/images/contents/Branching-Scenario.svg';
import CornellNotes from 'assets/images/contents/Cornell-Notes.svg';
import Dictation from 'assets/images/contents/Dictation.svg';
import Flashcards from 'assets/images/contents/Flashcards.svg';
import Questionnaire from 'assets/images/contents/Questionnaire.svg';
import StructureStrip from 'assets/images/contents/Structure-Strip.svg';
import VirtualTour from 'assets/images/contents/Virtual-Tour.svg';
import AudioRecorder from 'assets/images/contents/Audio-Recorder.svg';
import DefaultCard from 'assets/images/contents/Default-Card.svg';
import ImageHotspots from 'assets/images/contents/Image-Hotspots.svg';
import { Library } from 'types';

export const EXTRA_INFO_LIBRARIES: {
  machineName: string;
  image?: string;
  description?: string;
  referenceLink?: string;
}[] = [
  {
    machineName: 'H5P.Accordion',
    image: Accordion,
    description: 'Create vertically stacked expandable items',
    referenceLink: 'https://h5p.org/accordion',
  },
  {
    machineName: 'H5P.ArithmeticQuiz',
    image: Arithmetic,
    description: 'Create time-based arithmetic quizzes',
    referenceLink: 'https://h5p.org/arithmetic-quiz',
  },

  {
    machineName: 'H5P.Chart',
    image: Chart,
    description: 'Quickly generate bar and pie charts',
    referenceLink: 'https://h5p.org/chart',
  },
  {
    machineName: 'H5P.Collage',
    image: Collage,
    description: 'Create a collage of multiple images',
    referenceLink: 'https://h5p.org/collage',
  },
  {
    machineName: 'H5P.Column',
    image: Column,
    description: 'Organize Evokio content into a column layout',
    referenceLink: 'https://h5p.org/column',
  },

  {
    machineName: 'H5P.Crossword',
    image: Crossword,
    description: 'Create a crossword puzzle',
    referenceLink: 'https://h5p.org/content-types/crossword',
  },

  {
    machineName: 'H5P.DocumentationTool',
    image: DocumentationTool,
    description: 'Create a form wizard with text export',
    referenceLink: 'https://h5p.org/documentation-tool',
  },

  {
    machineName: 'H5P.GuessTheAnswer',
    image: GuessAnswer,
    description: 'Create an image with a question and answer',
    referenceLink: 'https://h5p.org/guess-the-answer',
  },
  {
    machineName: 'H5P.ImageHotspots',
    image: ImageHotspots,
    description: 'Create an image with multiple info hotspots',
    referenceLink: 'https://h5p.org/image-hotspots',
  },
  {
    machineName: 'H5P.ImageSlider',
    image: ImageSlider,
    description: 'Easily create an Image Slider',
    referenceLink: 'https://h5p.org/image-slider',
  },
  {
    machineName: 'H5P.InteractiveBook',
    image: InteractiveBook,
    description: 'Create small courses, books and tests',
    referenceLink: 'https://h5p.org/content-types/interactive-book',
  },
  {
    machineName: 'H5P.MemoryGame',
    image: MemoryGame,
    description: 'Create the classic image pairing game',
    referenceLink: 'https://h5p.org/memory-game',
  },

  {
    machineName: 'H5P.SortParagraphs',
    image: SortParagraph,
    description: 'Create a set of paragraphs to be sorted',
    referenceLink: 'https://h5p.org/content-types/sort-the-paragraphs',
  },

  {
    machineName: 'H5P.MultiChoice',
    image: MultipleChoice,
    description: 'Create flexible multiple choice questions',
    referenceLink: 'https://h5p.org/multiplechoice',
  },
  {
    machineName: 'H5P.MultiMediaChoice',
    image: ImageChoice,
    description: 'Create a task where the alternatives are images',
  },
  {
    machineName: 'H5P.SingleChoiceSet',
    image: SingleChoice,
    description: 'Create questions with one correct answer',
    referenceLink: 'https://h5p.org/single-choice-set',
  },
  {
    machineName: 'H5P.Summary',
    image: Summary,
    description: 'Create tasks with a list of statements',
    referenceLink: 'https://h5p.org/summary',
  },
  {
    machineName: 'H5P.TrueFalse',
    image: TrueFalse,
    description: 'Create True/False questions',
    referenceLink: 'https://h5p.org/true-false',
  },
  {
    machineName: 'H5P.Blanks',
    image: FillInTheBlank,
    description: 'Create a task with missing words in a text',
    referenceLink: 'https://h5p.org/fill-in-the-blanks',
  },
  {
    machineName: 'H5P.DragQuestion',
    image: DragDrop,
    description: 'Create drag and drop tasks with images',
  },
  {
    machineName: 'H5P.Audio',
    image: Audio,
    description: 'Upload an audio recording',
    referenceLink: 'https://h5p.org/audio',
  },
  {
    machineName: 'H5P.InteractiveVideo',
    image: InteractiveVideo,
    description: 'Create video enriched with interactions',
    referenceLink: 'https://h5p.org/interactive-video',
  },
  {
    machineName: 'H5P.MarkTheWords',
    image: MarkWords,
    description: 'Create a task where users highlight words',
    referenceLink: 'https://h5p.org/mark-the-words',
  },
  {
    machineName: 'H5P.DragText',
    image: DragWords,
    description: 'Create text-based drag and drop tasks',
    referenceLink: 'https://h5p.org/drag-the-words',
  },
  {
    machineName: 'H5P.CoursePresentation',
    image: Presentation,
    description: 'Create a presentation with interactive slides',
    referenceLink: 'https://h5p.org/presentation',
  },
  {
    machineName: 'H5P.Dialogcards',
    image: DialogCard,
    description: 'Create text-based turning cards',
    referenceLink: 'https://h5p.org/dialog-cards',
  },
  {
    machineName: 'H5P.QuestionSet',
    image: Quiz,
    description: 'Create a sequence of various question types',
    referenceLink: 'https://h5p.org/question-set',
  },
  { machineName: 'H5P.TwitterUserFeed', image: DefaultCard, description: undefined },
  {
    machineName: 'H5P.Questionnaire',
    image: Questionnaire,
    description: 'Create multiple choice or open ended questions',
  },
  {
    machineName: 'H5P.AudioRecorder',
    image: AudioRecorder,
    description: 'Create audio record your voice and play back',
  },
  {
    machineName: 'H5P.StructureStrip',
    image: StructureStrip,
    description: 'Create interactive structure strips',
  },
  {
    machineName: 'H5P.ThreeImage',
    image: VirtualTour,
    description: 'Create interactive content to a Virtual Tour Scene',
  },
  {
    machineName: 'H5P.ARScavenger',
    image: ARScavenger,
    description: 'Create augmented reality',
  },
  {
    machineName: 'H5P.BranchingScenario',
    image: BranchingScenario,
    description: 'Create dilemmas and self paced learning scenarios',
  },
  {
    machineName: 'H5P.Cornell',
    image: CornellNotes,
    description: 'Create notes using the Cornell Notes system',
  },
  {
    machineName: 'H5P.Dictation',
    image: Dictation,
    description: 'Create dictation exercises',
  },
  {
    machineName: 'H5P.Flashcards',
    image: Flashcards,
    description: 'Create a single or a set of flashcard(s)',
  },
];

export const mappingLibrary = (libraries: Library[]) => {
  return libraries.map(library => {
    const element = EXTRA_INFO_LIBRARIES.find(element => element.machineName === library.metadata.machineName);

    if (!element) {
      library.image = DefaultCard;
      return library;
    }

    return {
      ...library,
      image: element.image,
      description: element.description,
      referenceLink: element.referenceLink,
    };
  });
};
