import React from 'react';
import { ModalBody } from 'reactstrap';
import { X, File } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { UploadFile } from './UploadFile';
import { useAppStore } from 'hooks/useAppStore';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useUploadContent } from 'hooks';
import './UploadContent.scss';
import classNames from 'classnames';
import { Footer } from '../Footer';

type UploadContentProps = {
  onCancel?: () => void;
  onAccept?: () => void;
};

type UploadContentFormInput = {
  file: File;
};

export const UploadContent = ({ onCancel, onAccept }: UploadContentProps) => {
  const { control, watch } = useForm<UploadContentFormInput>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const file = watch('file');
  const { ltiMode } = useAppStore();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutate, isLoading } = useUploadContent();
  const { ltiStore } = useAppStore();

  const onUseContent = () => {
    const formData = new FormData();
    formData.append('file', file);

    if (folderId) {
      formData.append('parentId', folderId);
    }

    mutate(formData, {
      onSuccess(content) {
        const baseUrl = ltiMode ? `/${ltiStore.workspaceId}/lti/launch/` : '/';
        const path = baseUrl + 'contents';

        if (folderId) {
          navigate(`${path}/${content.id}/edit?folderId=${folderId}`);
        } else {
          navigate(
            {
              pathname: `${path}/${content.id}/edit`,
              search: search,
            },
            {
              replace: true,
            },
          );
        }

        onAccept?.();
      },
    });
  };

  return (
    <div className="d-flex flex-column p-3 upload-content-wrapper gap-3">
      <ModalBody className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center w-100 gap-2">
          <div className="d-flex gray-900 fw-semibold font-size-18 overflow-hidden">Upload H5P file</div>
          <X
            onClick={() => {
              if (isLoading) {
                return;
              }
              onCancel?.();
            }}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="font-size-12 ">
            <span className="text-neutral-900 fw-medium">Drag and Drop file here or </span>
            <span
              className={classNames('text-primary fw-semibold', { 'cursor-pointer': !isLoading })}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                inputRef.current?.click();
              }}
            >
              Choose file
            </span>
          </div>
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <UploadFile
                onChange={field.onChange}
                name={field.name}
                inputRef={inputRef}
                accept=".h5p"
                isLoading={isLoading}
              />
            )}
          />
        </div>
        {!file && (
          <div>
            <div className="d-flex justify-content-end font-size-12 text-neutral-900 fw-normal">
              Maximum size: 500MB
            </div>
            <div className="d-flex justify-content-end font-size-12 text-neutral-900 fw-normal mt-2">
              Only H5P content file is allowed
            </div>
          </div>
        )}
        {file && (
          <div className="d-flex gap-2 align-items-center w-100 bg-neutral-50 rounded-3 px-3 py-2 border ">
            <File />
            <span className="text-neutral-900 font-size-12 fw-medium">{file?.name}</span>
          </div>
        )}
      </ModalBody>
      <div className="d-flex flex-fill align-items-center justify-content-end">
        <Footer
          labelOK="Use"
          labelCancel="Cancel"
          onCancel={() => onCancel?.()}
          onOK={onUseContent}
          disabledOK={!file || (!!file && isLoading)}
          disabledCancel={isLoading}
        />
      </div>
    </div>
  );
};
