import React from 'react';
import moment from 'moment';

type ChatDateProps = {
  date: string;
};

const ChatDate = ({ date }: ChatDateProps) => {
  const isToday = moment(date).isSame(moment(), 'day');

  return (
    <div className="font-size-14 fw-medium text-primary-500">
      {isToday ? 'Today' : moment(date).format('MMMM DD, YYYY')}
    </div>
  );
};

export default ChatDate;
