import { useGetOptionLibraries } from 'hooks';
import { ResultContent } from 'models/Content';
import React from 'react';
import './ContentResultTitle.scss';

type Props = {
  result: ResultContent;
  hideContentType?: boolean;
};
export const ContentResultTitle = ({ result, hideContentType }: Props) => {
  const { data: libraries } = useGetOptionLibraries();
  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };

  return (
    <>
      <div className="content-result__title mb-2 mt-3 mt-md-0 px-3 px-md-0">
        <div>
          {!hideContentType && <span className="text-neutral-500">{mappingTitle(result.contentType)}:</span>}{' '}
          {result.title || result.description}
        </div>
        <div className="mt-2 mt-md-0 mb-3 mb-md-0">
          <span className="content-result__score">Question Score:</span>
          <span>
            {result.score}/{result.maxScore}({result.totalScore}%)
          </span>
        </div>
      </div>
    </>
  );
};
