import { findAncestors } from 'apis/Folder/Folder';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Document } from 'models/Content';
import { compact } from 'lodash';
import { useAppStore } from 'hooks/useAppStore';
type Props = {
  folderId: string;
  isSearch?: boolean;
  root: {
    title: string;
    path: string;
  };
  showMyContent?: boolean;
};
export const useGetBreadcrumb = ({ folderId, root, isSearch, showMyContent = false }: Props) => {
  const { currentContentPageCrumb, ltiMode, ltiStore } = useAppStore();
  const baseUrl = ltiMode ? `/${ltiStore.workspaceId}/lti/launch` : '';

  const crumbRoot = !!folderId
    ? !showMyContent
      ? currentContentPageCrumb
      : { title: 'My Content', path: '/contents/mine' }
    : root;
  const [crumbs, setCrumbs] = useState<{ title: string; path: string; id?: string }[]>([crumbRoot]);
  const { data: folders } = useQuery<Document[]>(['folders', folderId, 'ancestors'], async () =>
    !folderId ? [] : await findAncestors(folderId),
  );

  useEffect(() => {
    const searchCrumb = {
      title: 'Search results',
      path: '',
    };
    if (isSearch && !folders?.length) {
      setCrumbs([crumbRoot, searchCrumb]);
      return;
    }

    if (folders) {
      setCrumbs(
        compact([
          crumbRoot,
          ...folders?.map(folder => ({
            id: folder.id,
            title: folder.title,
            path: `${baseUrl}/folders/${folder.id}`,
          })),
          isSearch && searchCrumb,
        ]),
      );
    }
  }, [isSearch, folders]);

  return {
    crumbs,
  };
};
