import React from 'react';
import './CreateFolder.scss';
import { TitleForm } from '../TitleForm/TitleForm';
import { useCreateFolder } from 'hooks';

export type CreateFolderModalProps = {
  onCancel: () => void;
  defaultValue?: string;
  labelOK: string;
  inputLabel?: string;
  inputPlaceHolder?: string;
  headerTitle: string;
  parentId?: string;
};
export const CreateFolder = (props: CreateFolderModalProps) => {
  const { onCancel, parentId } = props;
  const { mutateAsync, isLoading } = useCreateFolder();

  const submit = async ({ title }: { title: string }) => {
    await mutateAsync({ title, parentId: parentId });
    onCancel?.();
  };

  return (
    <TitleForm
      {...props}
      onOk={submit}
      isLoading={isLoading}
    />
  );
};
