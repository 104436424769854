import { useUpdateChat } from 'hooks/ai-chat';
import React from 'react';
import { AiChatHistory } from 'types';
import { TitleForm } from '../TitleForm/TitleForm';
import { Edit2 } from 'react-feather';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export type RenameChatModalProps = {
  chat: AiChatHistory;
  onCancel: () => void;
};
export const RenameChat = (props: RenameChatModalProps) => {
  const { chat } = props;
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useUpdateChat({
    onSuccess: (data, variables) => {
      queryClient.setQueryData<AiChatHistory[]>('chat-history', (chatsList: AiChatHistory[] = []) => {
        return chatsList.map(chat => {
          if (chat.id === variables.chatId) {
            return { ...chat, name: data.name };
          }

          return chat;
        });
      });
      toast.success('Chat name has been updated.');
    },
  });

  const updateName = async (data: { title: string }) => {
    await mutateAsync({ chatId: chat.id, name: data.title });

    // Close modal
    props.onCancel();
  };

  return (
    <TitleForm
      {...props}
      titleIcon={
        <Edit2
          size={20}
          className="text-primary"
        />
      }
      errorLabel="Chat name"
      defaultValue={chat.name}
      headerTitle="Rename chat"
      onOk={data => updateName(data)}
      isLoading={isLoading}
      labelOK="Update name"
    />
  );
};
