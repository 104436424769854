import { generateToken } from 'apis/Lti/Lti';
import { Loading, ModalManager } from 'components';
import { useAppStore } from 'hooks/useAppStore';
import React, { useEffect } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

export const LtiLayout = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const appStore = useAppStore();
  const { ltiStore } = appStore;
  const [isLoading, setIsLoading] = React.useState(false);
  const ltiKey = searchParams.get('ltik') || ltiStore.ltiKey;
  const workspaceId = params['workspaceId'] || ltiStore.workspaceId;

  const init = async () => {
    appStore.clearUser();
    ltiStore.setWorkspaceId(workspaceId);
    ltiStore.setLtiKey(ltiKey);
    const { accessToken, refreshToken, contextId } = await generateToken(ltiKey, workspaceId);
    ltiStore.setContext(contextId);
    ltiStore.setToken({ accessToken, refreshToken });
    if (!appStore.initializedLayout) {
      await appStore.initLayout();
    }
    setIsLoading(true);
  };

  useEffect(() => {
    if (ltiKey && workspaceId) {
      init().then();
    }
  }, [ltiKey, workspaceId]);

  if (!isLoading) {
    return (
      <div className="min-vh-100 d-flex justify-center align-items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="h-100 overflow-auto">
      <Outlet />
      <ModalManager />
    </div>
  );
};
