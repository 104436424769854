import { Role } from './user.enum';
import ROLES_PERMISSIONS from '../authorization/roles_permissions.json';

export const ALL_ACTIONS_ROLES_PERMISSIONS = Object.values(ROLES_PERMISSIONS)
  .flat()
  .map(value => value.action);

export const hasUserPermissionByRole = (rolesPermissions, action: string) => {
  return !!rolesPermissions && rolesPermissions[action];
};

export const getActionsUserPermissionsByRole = (roles: Role[], isOwner?: boolean) => {
  const keys = Object.keys(ROLES_PERMISSIONS);

  const actionsMap = (roles || []).map(role => {
    if (!keys.includes(role)) return {};

    return ROLES_PERMISSIONS[role].reduce((acc, { action }) => {
      return { ...acc, [action]: true };
    }, {});
  });

  const permissions = actionsMap.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  if (isOwner) {
    return {
      ...permissions,
      planBilling: true,
    };
  }

  return permissions;
};

export const hasMemberPermissions = (roles: Role[] = []) => {
  return roles.includes(Role.MEMBER) || roles.includes(Role.ADMIN);
};
