import { getConnectionsDomain } from 'apis/Lti/Lti';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useGetDomainsConnection = () => {
  return useQuery<string, ErrorResponse>('connections-domain', () => getConnectionsDomain(), {
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
    cacheTime: 300000,
    staleTime: 300000,
    retry: 1,
  });
};
