import { PageHeader } from 'components/PageHeader';
import React from 'react';
import { BillingHistory, BillingInformation, PaymentMethods } from './components';

export const BillingPage = () => {
  return (
    <>
      <PageHeader title="Billing Information" />

      <div className="d-flex flex-column p-3 p-md-4 gap-0 gap-md-4">
        <PaymentMethods />

        <BillingInformation />

        <BillingHistory />
      </div>
    </>
  );
};
