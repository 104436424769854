import { emptyTrash } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { Document } from 'models/Content';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useEmptyTrash = () => {
  const { queryKey } = useAppStore();
  const queryClient = useQueryClient();

  return useMutation(emptyTrash, {
    onSuccess: () => {
      queryClient.setQueryData<Document[]>(queryKey, []);
      toast.success('All items have been deleted permanently.');
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
