import { ContentAttemptSetting, LtiSettingOptions } from '@spiderbox/common';

export type Setting = {
  contentBehavior: ContentBehaviorSetting;
  ltiSetting: LtiSetting;
};

export type ContentBehaviorSetting = {
  contentBehaviorDisplayToolbar: string;
  contentBehaviorUsersCanDownload: ContentBehaviorOptions;
  contentBehaviorDisplayEmbedButton: ContentBehaviorOptions;
  contentBehaviorDisplayTotalAttempts: string;
};

export enum ContentBehaviorOptions {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  ONLY_FOR_EDITOR = 'ONLY_FOR_EDITOR',
  CONTROLLED_BY_EDITOR_ON_DEFAULT = 'CONTROLLED_BY_EDITOR_ON_DEFAULT',
  CONTROLLED_BY_EDITOR_OFF_DEFAULT = 'CONTROLLED_BY_EDITOR_OFF_DEFAULT',
}

export type ContentBehaviorSettingInput = {
  contentBehaviorDisplayToolbar: boolean;
  contentBehaviorUsersCanDownload: ContentBehaviorOptions;
  contentBehaviorDisplayEmbedButton: ContentBehaviorOptions;
  contentBehaviorDisplayTotalAttempts: boolean;
};

export type LtiSetting = {
  ltiContentMaxWidthDefault: string;
  ltiSendScoreToLms: LtiSettingOptions;
  ltiSendScoreFor: ContentAttemptSetting;
  ltiUserCanResumeContent: LtiSettingOptions;
};
