import React, { useState } from 'react';
import { Row } from '@tanstack/react-table';
import { downloadContentUserAttempts, findContentUserAttempts } from 'apis/ContentUserData/ContentUserData';
import { observer } from 'mobx-react-lite';
import { LearnerReportLayout } from 'pages/LearnerReport/LearnerReportLayout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ContentUserDataAttempt, LearnerReportAttemptSortBy } from 'types/student-report';
import { durationColumn, endTimeColumn, totalScoreColumn } from './components/Columns';
import { CrumbItem } from 'components/AppBreadCrumb';
import { parse } from 'qs';

export const ContentUserAttempts = observer(() => {
  const { id: contentId, userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldOrderByAsc, toggleShouldOrderByAsc] = useState<boolean>(searchParams.get('sortOrder') === 'desc');
  const lastAttemptFrom = searchParams.get('lastAttemptFrom') || '';
  const lastAttemptTo = searchParams.get('lastAttemptTo') || '';
  const platformId = searchParams.get('platform') || '';
  const sortBy = searchParams.get('sortBy');
  const sortOrder = searchParams.get('sortOrder') as 'desc' | 'asc';
  const contextId = searchParams.get('contextId') || '';
  const studentReportEmail = searchParams.get('student_report_email') || '';
  const lmsUserId = searchParams.get('lms_user_id') || '';
  const platformGuid = searchParams.get('platform_guid') || '';
  const params = {
    platformId,
    lastAttemptFrom,
    lastAttemptTo,
    sortBy,
    sortOrder,
    contextId,
    studentReportEmail,
    lmsUserId,
    platformGuid,
  };

  const sortAttempts = (sortBy: LearnerReportAttemptSortBy) => {
    toggleShouldOrderByAsc(!shouldOrderByAsc);

    setSearchParams(
      prevValue => {
        const preQueries = parse(prevValue.toString());
        return {
          ...preQueries,
          sortBy,
          sortOrder: !shouldOrderByAsc ? 'asc' : 'desc',
          timestamp: new Date().getTime().toString(),
        };
      },
      { replace: true },
    );
  };

  const navigate = useNavigate();

  const onRowClick = (row: Row<ContentUserDataAttempt>) => {
    navigate(`attempts/${row.original.id}?${searchParams}&studentReportEmail=${studentReportEmail}`, {
      replace: true,
    });
  };

  const crumbs: CrumbItem[] = [
    {
      path: `/contents/${contentId}/reports?lastAttemptFrom=${lastAttemptFrom}&lastAttemptTo=${lastAttemptTo}&platform=${platformId}`,
      title: 'All Learners',
      replace: true,
    },
    {
      path: '',
      title: searchParams.get('user'),
      unclickable: true,
    },
  ];

  const studentDetailReportColumns = [
    endTimeColumn(sortBy => sortAttempts(sortBy), sortBy as LearnerReportAttemptSortBy, sortOrder),
    durationColumn(sortBy => sortAttempts(sortBy), sortBy as LearnerReportAttemptSortBy, sortOrder),
    totalScoreColumn(sortBy => sortAttempts(sortBy), sortBy as LearnerReportAttemptSortBy, sortOrder),
  ];

  return (
    <LearnerReportLayout
      queryKey={['contents', contentId, 'users', userId, 'attempts']}
      fetchReports={() => findContentUserAttempts(contentId, userId, params)}
      downloadCsv={() => downloadContentUserAttempts(contentId, userId, params)}
      defaultColumns={studentDetailReportColumns}
      onRowClick={onRowClick}
      crumbs={crumbs}
    />
  );
});
