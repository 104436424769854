import React from 'react';
import useModal from 'hooks/useModal';
import { useRemovePendingUser, useRemoveUser, useResendInvitation } from 'hooks';
import { User } from 'types';
import { Mail, UserMinus } from 'react-feather';
import { ConfirmModalProps } from '../Confirm';
import { useMediaScreen } from 'hooks/useMediaScreen';

export const useUserModal = () => {
  const { openModal, closeModal } = useModal();
  const { mutateAsync: removeUser } = useRemoveUser();
  const { mutateAsync: removePendingUser } = useRemovePendingUser();
  const { mutateAsync: resendInvitation } = useResendInvitation();
  const { isMdDown } = useMediaScreen();

  const openModalDeleteUser = (user: User) => {
    openModal('warning', {
      title: (
        <div>
          Are you sure you want to remove <b>{`${user.fullName}`}</b> from this workspace?
        </div>
      ),
      titleIcon: (
        <UserMinus
          className="text-primary block"
          size={24}
        />
      ),
      content: "This action can't be undone. User will no longer have access to this workspace.",
      onCancel: () => {
        isMdDown ? openUserActionsModal(user) : closeModal();
      },
      onAccept: async () => {
        await removeUser(user.id);
        closeModal();
      },
      labelOK: `Yes, I'm sure`,
      labelCancel: 'No, cancel',
    } as ConfirmModalProps);
  };

  const openModalRemovePendingUser = (user: User) => {
    openModal('warning', {
      title: (
        <>
          Are you sure you want to cancel the invitation to <b style={{ wordBreak: 'break-all' }}>{user.email}</b>
        </>
      ),
      titleIcon: (
        <UserMinus
          className="text-primary block"
          size={24}
        />
      ),
      content:
        "This action can't be undone. Cancelling the invitation will prevent this user from accessing your workspace.",
      onCancel: () => {
        isMdDown ? openUserActionsModal(user) : closeModal();
      },
      onAccept: async () => {
        await removePendingUser(user.email);
        closeModal();
      },
      labelOK: `Yes, I'm sure`,
      labelCancel: 'No, cancel',
    } as ConfirmModalProps);
  };

  const openModalResendInvitation = (user: User) => {
    openModal('warning', {
      title: 'Resend invitation',
      titleIcon: (
        <Mail
          className="text-primary block"
          size={24}
        />
      ),
      content: (
        <>
          An email will be sent to{' '}
          <span
            className="fw-bold"
            style={{ wordBreak: 'break-all' }}
          >
            {user.email}
          </span>{' '}
          to remind user to sign up.
        </>
      ),
      onCancel: () => {
        isMdDown ? openUserActionsModal(user) : closeModal();
      },
      onAccept: async (done: () => void) => {
        try {
          await resendInvitation(user.email);
        } finally {
          done();
          closeModal();
        }
      },
      labelOK: 'Resend',
      labelCancel: 'Cancel',
    } as ConfirmModalProps);
  };

  const openUserActionsModal = (user: User) => {
    openModal('user-actions', {
      user,
      onCancel: () => closeModal(),
    });
  };

  return {
    openModalDeleteUser,
    openModalRemovePendingUser,
    openModalResendInvitation,
    openUserActionsModal,
  };
};
