import { ErrorEmptyBlock, Loading } from 'components';
import { PageHeader } from 'components/PageHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { Save } from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import { AdminNav } from './AdminNav';
import './AdminPage.scss';
import { observer } from 'mobx-react-lite';
import { AdminContentTypeTable } from './AdminContentTypeTable';
import { useSearchTermMasterAdmin } from 'hooks/master-admin/useSearchTermMasterAdmin';
import { useGetLibrariesMasterAdmin } from 'hooks/master-admin/useGetLibrariesMasterAdmin';
import { useUpdateAdditionalMetadata } from 'hooks/master-admin/useUpdateAdditionalMetadata';
import { findDuplicateLibsByMachineName } from 'utils/master-admin.utils';
import { useQueryClient } from 'react-query';
import { useDebounce } from 'react-use';

export const AdminPage = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState<string>('');
  const queryClient = useQueryClient();

  const searchTerm = useSearchTermMasterAdmin(searchParams, setKeyword);

  const search = (searchTerm: string) => {
    setKeyword(searchTerm);
  };

  const { data: libraries, isLoading } = useGetLibrariesMasterAdmin(searchTerm);

  const { mutateAsync, isLoading: isLoadingUpdate } = useUpdateAdditionalMetadata();

  const duplicateLibs: string[] = useMemo(() => findDuplicateLibsByMachineName(libraries), [libraries]);

  const handleUpdateAdditionalMetadata = () => {
    mutateAsync({ libraries, duplicateLibs });
  };

  useEffect(() => {
    queryClient.prefetchQuery(['master-admin-libraries']);
  }, [searchTerm]);

  useDebounce(
    () => {
      setSearchParams(prevValue => {
        const preQueries = Object.fromEntries(prevValue.entries());
        if (keyword === '') {
          delete preQueries.search;
          return { ...preQueries };
        }
        return { ...preQueries, search: keyword.trim() };
      });
    },
    500,
    [keyword],
  );

  return (
    <>
      <PageHeader
        title="Master Admin"
        rightElement={
          <div>
            <button
              className="btn btn-primary d-flex justify-center me-3"
              onClick={handleUpdateAdditionalMetadata}
            >
              <Save size={20} />
              <span className="ms-2">Save</span>
            </button>
          </div>
        }
        subHeader={
          <AdminNav
            keyword={keyword}
            search={search}
          />
        }
      />

      <div className="py-md-4 px-md-3">
        {isLoading || isLoadingUpdate ? (
          <Loading />
        ) : !(libraries || []).length ? (
          <ErrorEmptyBlock
            title="New Content at your fingertips!"
            description="Click 'New Content' on the sidebar to create content."
          />
        ) : (
          <div className="bg-white rounded-md-3">
            <div className="p-3 pb-0 px-md-4 py-md-3 content-type__header">
              <div className="text-neutral-900 fw-bold mb-2">Content Type</div>
              <div className="text-neutral-600">
                <div className="d-none d-md-inline-block">
                  Once the <span className="fw-bold">&quot;Allow&quot;</span> setting is enabled, other settings will be
                  automatically enabled as well and cannot be edited.
                </div>
                <div className="d-inline-block d-md-none">Restrict specific content types</div>
              </div>
            </div>
            <div
              className="bg-white p-3 px-md-4 py-md-3"
              data-cy="library-items"
            >
              <AdminContentTypeTable dataTable={libraries || []} />
            </div>
          </div>
        )}
      </div>
    </>
  );
});
