import React from 'react';
import useModal from 'hooks/useModal';
import { UserCheck } from 'react-feather';
import { RoleTypeMapping, User } from 'types';
import { ConfirmModalProps } from '../Confirm';
import { Role } from '@spiderbox/common';

export const useSwitchRoleModal = (user: User, switchRoleFunc: (role: Role) => void) => {
  const { openModal, closeModal } = useModal();

  const description = (role: Role) =>
    role === Role.ADMIN
      ? 'This user will be able to manage contents, learner reports and organisation settings.'
      : 'This user will be able to access to workspace, manage contents and learner reports. ';

  const openSwitchRoleModal = (role: Role) => {
    openModal('warning', {
      titleIcon: (
        <UserCheck
          size={18}
          className="text-primary"
        />
      ),
      title: (
        <>
          Are you sure to assign {RoleTypeMapping[role]} role to{' '}
          <b style={{ wordBreak: 'break-all' }}>{user?.fullName || user?.email}</b> ?
        </>
      ),
      content: <>{description(role)}</>,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onAccept: () => switchRoleFunc(role),
      onCancel: closeModal,
    } as ConfirmModalProps);
  };
  return { openSwitchRoleModal, closeModal };
};
