import React from 'react';
import { ToastContainer } from 'react-toastify';

import './ToastComponent.scss';
import { useMediaScreen } from 'hooks/useMediaScreen';

export const ToastComponent = () => {
  const { isMdDown } = useMediaScreen();
  return (
    <ToastContainer
      icon={false}
      position={isMdDown ? 'bottom-center' : 'top-right'}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
    ></ToastContainer>
  );
};
