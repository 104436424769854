class FeatureFlagService {
  private enabledFeatures: string[] = [];

  constructor() {
    this.enabledFeatures = this.getFeaturesFromUrl();
  }

  getFeaturesFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    const featuresParam = searchParams.get('features');

    return featuresParam ? featuresParam.split(',') : [];
  }

  isFeatureEnabled(feature: string) {
    return this.enabledFeatures.includes(feature);
  }
}

export default new FeatureFlagService();
