import { refresh } from 'apis/Auth/Auth';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Token } from 'types';
import { appStore } from 'stores/AppStore';

interface AppAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const onResponse = (config: AxiosResponse): AxiosResponse => {
  return config;
};

export const onResponseError = async (error: AxiosError): Promise<AxiosResponse | AxiosError> => {
  if (error.code === 'ERR_NETWORK') {
    return Promise.reject(error);
  }

  const originalRequest = error.config as AppAxiosRequestConfig;
  // Make sure the error status is 401 and it's not a token refresh request
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    // Make a request to refresh the token
    return refresh(appStore.refreshToken || appStore.ltiStore.refreshToken)
      .then(response => {
        // Update the access token
        const { accessToken, refreshToken }: Token = response.data;
        appStore.updateAccessToken(accessToken);
        appStore.updateRefreshToken(refreshToken);

        // Update the request header with the new access token
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        // Retry the original request
        return axios(originalRequest as AxiosRequestConfig);
      })
      .catch(error => {
        const { response } = error;
        if (response?.status === 400) {
          return Promise.reject(response?.data);
        }
        // Redirect or logout the user
        appStore.clearUser();
        return Promise.reject(error);
      });
  }
  return Promise.reject(error.response?.data);
};
