import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Collapse } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { ContentPermissionsInput } from 'types/content';
import { SharingView } from 'components/ModalManager/SharingView';
import { useAppStore } from 'hooks/useAppStore';
import { useSearchParams } from 'react-router-dom';
import { User } from 'types';

export const SharingSetting = observer(() => {
  const {
    contentStore,
    contentStore: { owner, contentId, permissionsInput },
    userInfo,
  } = useAppStore();

  const [searchParams] = useSearchParams();
  const folderId = searchParams.get('folderId');
  const [shareSettingCollapse, toggleShareSettingCollapse] = useState(false);

  const parentId = () => {
    if (!contentId) {
      if (!!folderId) {
        return folderId;
      }
    }
    return undefined;
  };

  const onChange = (formValues: ContentPermissionsInput) => {
    contentStore.setPermissionsInput(formValues);
  };

  return (
    <div className="row">
      <div className="col-5 d-flex">
        {shareSettingCollapse ? (
          <ChevronRight
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleShareSettingCollapse(!shareSettingCollapse)}
          />
        ) : (
          <ChevronDown
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleShareSettingCollapse(!shareSettingCollapse)}
          />
        )}

        <div className="d-flex flex-column">
          <div className="gray-900 font-size-16 fw-semibold">
            <span
              className="cursor-pointer"
              onClick={() => toggleShareSettingCollapse(!shareSettingCollapse)}
            >
              Share
            </span>
          </div>

          <Collapse isOpen={!shareSettingCollapse}>
            <div className="gray-600 mt-2">Choose who you want to share this content with.</div>
          </Collapse>
        </div>
      </div>

      <Collapse
        className="col-7"
        isOpen={!shareSettingCollapse}
      >
        <div className="w-100 w-md-75">
          <SharingView
            onChange={onChange}
            contentId={contentId}
            owner={(owner as User) || userInfo}
            parentId={parentId()}
            defaultValues={permissionsInput}
            onDirty={value => {
              contentStore.setDirty(value);
            }}
            autoFocus={false}
          />
        </div>
      </Collapse>
    </div>
  );
});
