import classNames from 'classnames';
import React from 'react';
import { getInitials } from 'utils/helpers';
import './AvatarProfile.scss';

type IProps = {
  imgSrc?: string;
  userFullName: string;
  width?: number;
  height?: number;
  avatarClassNames?: string;
  hasOutline?: boolean;
};

const AvatarProfile = ({
  imgSrc = null,
  userFullName,
  width = 40,
  height = 40,
  avatarClassNames = '',
  hasOutline = false,
}: IProps) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center text-white rounded-circle avatar-wrapper bg-primary',
        avatarClassNames,
        { 'avatar-wrapper--outline': hasOutline },
      )}
      style={{ width, height }}
      data-cy="avatar-profile"
    >
      {imgSrc ? (
        <img
          data-cy="avatar-img"
          src={imgSrc}
          alt=""
          className="w-100 h-100 rounded-circle avatar-wrapper__image bg-neutral-100"
        />
      ) : (
        <>{getInitials(userFullName)}</>
      )}
    </div>
  );
};

export default AvatarProfile;
