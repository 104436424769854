import { ContentUserData, ContentUserDataAttempt, LearnerReportQueryParams } from 'types/student-report';
import apiClient from '../ApiClient';
import moment from 'moment-timezone';
import { downloadFile } from 'utils';
import { FinishedUserDataInput } from 'types';

const endPoint = '/content-user';
const userTimezone = moment.tz.guess();
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const findContentUserReports = async (
  contentId: string,
  params: LearnerReportQueryParams,
): Promise<ContentUserData[]> => {
  const { data } = await apiClient.get(`${endPoint}/${contentId}`, { params });
  await sleep(300);
  return data;
};

export const downloadContentUserReports = async (
  contentId: string,
  params: LearnerReportQueryParams,
): Promise<void> => {
  const userTimezone = moment.tz.guess();

  const { data: fileData, headers } = await apiClient.get(`${endPoint}/${contentId}/download`, {
    params,
    headers: {
      timezone: userTimezone,
    },
  });

  const fileName = headers['content-disposition'].split('filename=')[1];

  downloadFile({ fileData, name: fileName, type: 'text/csv', extension: 'csv' });
};

export const findContentUserAttempts = async (
  contentId: string,
  userId: string,
  params: LearnerReportQueryParams,
): Promise<ContentUserDataAttempt[]> => {
  const { data } = await apiClient.get(`${endPoint}/${contentId}/${userId}`, { params });
  await sleep(300);
  return data;
};

export const downloadContentUserAttempts = async (
  contentId: string,
  userId: string = '',
  params: LearnerReportQueryParams,
): Promise<any> => {
  const { data: fileData, headers } = await apiClient.get(`${endPoint}/${contentId}/${userId}/download`, {
    params,
    headers: {
      timezone: userTimezone,
    },
  });

  const fileName = headers['content-disposition'].split('filename=')[1];

  downloadFile({ fileData, name: fileName, type: 'text/csv', extension: 'csv' });
};

export const createFinishedUserData = async (payload: FinishedUserDataInput): Promise<any> => {
  return await apiClient.post(`h5p/finishedData`, payload).then(res => res.data);
};
