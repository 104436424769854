import React, { useState } from 'react';
import { Loading, useSwitchRoleModal } from 'components';
import { useUpdateUserRole } from 'hooks/user';
import { RoleTypeMapping, User } from 'types/user';
import { DropdownAssignRole } from '../DropdownAssignRole';
import { UserRoleBadge } from '../UserRoleBadge';
import { useQueryClient } from 'react-query';
import { Role } from '@spiderbox/common';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';
import { UserPermission } from 'components/UserPermission';

interface Props {
  user: User;
  disabled?: boolean;
}

export const UserRoleCell: React.FC<Props> = ({ user, disabled = false }) => {
  const [userUpdated, setUserUpdated] = useState<User>(user);

  const { isLoading } = useUpdateUserRole();

  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateUserRole((data: User) => {
    setUserUpdated({ ...user, roles: data.roles });
  });

  const switchRole = async (value: Role) => {
    const newRoles = [value];

    await mutateAsync(
      { email: user.email, status: user.status, roles: newRoles },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          toast('User role has been updated.', { type: 'success' });
        },
        onError: (error: ErrorResponse) => {
          toast(fetchServerError(error), { type: 'error' });
        },
      },
    );
  };

  const { openSwitchRoleModal } = useSwitchRoleModal(user, switchRole);

  const labelNotPermission = userUpdated.roles?.includes(Role.ADMIN)
    ? RoleTypeMapping[Role.ADMIN]
    : RoleTypeMapping[Role.MEMBER];

  return (
    <div className="w-100 d-flex align-items-center">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="d-flex flex-fill">
          {user.isOwner ? (
            <div
              className="d-flex align-items-center"
              data-cy="user-role-cell__owner"
            >
              <div className="me-2">
                <UserRoleBadge
                  isOwner
                  label="Owner"
                />
              </div>
              <UserRoleBadge label="Admin" />
            </div>
          ) : (
            <div data-cy="user-role-cell__not-owner">
              <UserPermission
                actions={['user.manageRolePermission']}
                renderHasNotPermission={<UserRoleBadge label={labelNotPermission} />}
              >
                <DropdownAssignRole
                  user={userUpdated}
                  onChangeValue={openSwitchRoleModal}
                  disabled={disabled}
                />
              </UserPermission>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
