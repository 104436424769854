import React from 'react';
import './UserStatusBadge.scss';
import classNames from 'classnames';
import { UserStatus } from 'types/user';

type IProps = {
  status: UserStatus;
};

export const UserStatusBadge = ({ status }: IProps) => {
  return (
    <div
      className={classNames(
        'user-status-badge d-flex align-items-center justify-content-center',
        status?.toLowerCase(),
      )}
      data-cy="user-status-badge"
    >
      {status}
    </div>
  );
};
