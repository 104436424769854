import React, { ComponentProps } from 'react';
import { Spinner } from 'reactstrap';

type LoadingProps = {
  size?: string;
  color?: ComponentProps<typeof Spinner>['color'];
};

export const Loading = ({ size = 'md', color }: LoadingProps) => {
  return (
    <div
      data-cy="loading"
      className="h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <Spinner
        size={size}
        color={color}
      >
        Loading...
      </Spinner>
    </div>
  );
};
