import { observer } from 'mobx-react-lite';
import React from 'react';
import './ForgotPassword.scss';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/AppForm/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { forgotPassword } from 'apis/Auth/Password';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorResponse } from 'types';
import IconLogo from 'assets/icons/IconLogo';

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email is invalid.')
    .required('Email is required.')
    .max(255, 'Email length must be less than 255 characters.'),
});

export const ForgotPassword = observer(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<{ email: string }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema as any),
  });

  const navigate = useNavigate();

  const {
    isLoading,
    mutate,
    error: serverError,
    isSuccess,
  } = useMutation<any, ErrorResponse, string>(forgotPassword, {
    retry: false,
  });

  const onSubmit = async (value: string) => {
    mutate(value);
    reset({}, { keepValues: true });
  };

  return (
    <div className="forgot_password-wrapper vh-100 d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      {isSuccess ? (
        <div className="success-form d-flex flex-column align-items-center">
          <IconLogo isVariant={true} />

          <div className="success-title text-white text-center">An email with a password reset link has been sent.</div>

          <div className="success-description text-white text-center">
            Please check your email and follow instructions.
          </div>

          <button
            className="btn btn-submit bg-white"
            type="button"
            onClick={() => navigate('/auth/login')}
          >
            Back to Sign In
          </button>
        </div>
      ) : (
        <form
          className="forgot_password-form bg-white d-flex flex-column align-items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IconLogo />

          <div className="forgot_password-form--title text-center">Forgot Password?</div>
          <div className="forgot_password-form--description text-center">
            Enter your email address, and we’ll send you an email to reset your password.
          </div>

          <div className="form-group w-100">
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="john@example.com"
                  label="Email"
                  error={error || (serverError && !isDirty)}
                  maxLength={255}
                />
              )}
            />
            <p className="feedback-invalid">
              {errors.email?.message || (serverError && !isDirty ? serverError?.message : '')}
            </p>
          </div>

          <div className="w-100 d-flex">
            <button
              className="btn btn-primary flex-fill px-3 py-2 text-white"
              type="submit"
              disabled={isLoading}
            >
              Send
              {isLoading && (
                <span
                  className="ms-3 spinner-border spinner-border-sm text-light"
                  role="status"
                ></span>
              )}
            </button>
          </div>

          <Link
            className="text-primary-500 font-weight-medium font-size-14"
            to="/auth/login"
          >
            Back to Sign In
          </Link>
        </form>
      )}
    </div>
  );
});
