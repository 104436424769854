type DownloadFileProps = { fileData: any; name: string; type: string; extension: string };

export const downloadFile = ({ fileData, name, type, extension }: DownloadFileProps) => {
  const blob = new Blob([fileData], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.${extension}`;
  link.click();
  window.URL.revokeObjectURL(link.href);
};
