import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

export const RedirectUserDashboard = observer(() => {
  const {
    uiStore: { isNavbarCollapsed },
    userInfo,
  } = useAppStore();

  return userInfo?.isMasterAdmin ? (
    <div
      className="p-2 d-flex justify-content-start align-items-center"
      data-cy="redirect-user-dashboard"
    >
      <Link
        to="/dashboard"
        className="bg-primary d-flex justify-content-center align-items-center w-100 rounded-3 text-white py-2 px-3"
      >
        <Home size={20} />

        {!isNavbarCollapsed && <div className="ms-2 fw-medium d-none d-sm-block text-nowrap">User Dashboard</div>}
      </Link>
    </div>
  ) : (
    <></>
  );
});
