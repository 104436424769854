import { getMasterAdminLibraries } from 'apis/MasterAdmin/Library/Library';
import { useQuery, useQueryClient } from 'react-query';
import { QueryParams } from 'types/common';
import { MasterAdminLibrary, MasterAdminLibraryQuery } from 'types/master-admin-library';
import { filterLatestVersions } from 'utils/master-admin.utils';

export const useGetLibrariesMasterAdmin = (searchTerm: MasterAdminLibraryQuery) => {
  const {
    data: libraries,
    isLoading,
    ...rest
  } = useQuery<MasterAdminLibrary[]>(['master-admin-libraries'], () => fetchLibraries(searchTerm));

  if (isLoading) {
    return { data: [], isLoading };
  }

  const queryClient = useQueryClient();

  const latestVersionLibraries = filterLatestVersions(libraries);
  const masterAdminLibrariesRestricted =
    queryClient.getQueryData<MasterAdminLibrary[]>(['master-admin-libraries', 'restricted']) || [];

  if (!masterAdminLibrariesRestricted.length) {
    return { data: latestVersionLibraries, isLoading, ...rest };
  }

  const masterAdminLibrariesRestrictedIds = masterAdminLibrariesRestricted.map(item => item.id);
  const hasRestricted = latestVersionLibraries.some(item => masterAdminLibrariesRestrictedIds.includes(item.id));

  if (!hasRestricted) {
    return { data: latestVersionLibraries, isLoading, ...rest };
  }

  const newDataTable = latestVersionLibraries.map(item => {
    const restricted = masterAdminLibrariesRestricted.find(restrictedItem => restrictedItem.id === item.id);
    return restricted ? { ...item, additionalMetadata: restricted.additionalMetadata } : item;
  });

  return { data: newDataTable, isLoading, ...rest };
};

const fetchLibraries = async (queries?: QueryParams) => {
  return (await getMasterAdminLibraries({ ...queries })) || [];
};
