import React from 'react';
import { AuthLayout, VerticalLayout } from 'components';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import {
  ChangePassword,
  ContentEditorPage,
  EditProfile,
  ForgotPassword,
  LibraryPage,
  LoginPage,
  MyContentPage,
  NotFoundPage,
  ResetPasswordPage,
  SignUpPage,
  UserDetailPage,
  WelcomePage,
  WorkspaceSettingsPage,
  FolderPage,
  LtiLaunchPage,
  LtiLayout,
  ContentViewerPage,
  SubscriptionPlansPage,
  SyncSubscriptionPage,
  AdminPage,
  LearnerReportsPage,
  LearnerReportDetailPage,
  LearnerReport,
} from 'pages';
import { ShareWithMePage } from 'pages/Content/ShareWithMePage';
import { TrashPage } from 'pages/Content/TrashPage';
import { BillingPage, ContentBehavior, LtiSettings, ManageUsersPage } from 'pages/Organisation';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CheckAuth } from './checkAuth';
import { ProtectedRoute } from './protectedRoute';
import { SwitchWorkspace } from 'components/VerticalLayout/SidebarMenuBottom/components/SwitchWorkspace';
import { AllContentsPage } from 'pages/Content/AllContentsPage/AllContentsPage';
import { UserPermission } from 'components/UserPermission';
import { IntegrationsPage } from 'pages/IntegrationsPage';
import { SubscriptionPage } from 'pages/Organisation/SubscriptionPage';
import { ExpiredSubscriptionLayout } from 'components/ModalManager/ExpiredPlan';
import { EditorRouter } from 'pages/Content/ContentEditorPage/Router';
import { MasterAdminLayout } from 'components/MasterAdminLayout';
import { ManageContentTypesPage } from 'pages/AdminPage/ManageContentTypesPage';
import { ContentEmbedPage } from 'pages/Content/ContentEmbedPage';
import { SubscriptionStatus } from '@spiderbox/common';
import { LearnerAttemptPage } from 'pages/LearnerReport/LearnerAttemptPage';
import { ContentUserAttempts } from 'pages/Content/ContentUserAttempts';
import { ContentUserAttemptDetails } from 'pages/Content/ContentUserAttemptDetails';

export const Routers = observer(() => {
  const {
    authenticated,
    isHydrated,
    workspaceStore: { hasWorkspace, isWorkspaceOwner },
    subscriptionStore: { currentSubscription },
    userInfo,
  } = useAppStore();

  if (!isHydrated) {
    return null;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            isAllowed={authenticated}
            redirectPath="/auth/login"
          >
            <ExpiredSubscriptionLayout>
              <AuthLayout />
            </ExpiredSubscriptionLayout>
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={<Navigate to="/dashboard" />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              isAllowed={hasWorkspace}
              redirectPath="/welcome"
            >
              <ProtectedRoute
                isAllowed={
                  !!currentSubscription?.status &&
                  (currentSubscription?.status !== SubscriptionStatus.CANCELED || !isWorkspaceOwner)
                }
                redirectPath="/subscription-plans"
              >
                <VerticalLayout />
              </ProtectedRoute>
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={<LibraryPage />}
          />

          <Route path="/contents">
            <Route
              index
              path="all"
              element={<AllContentsPage />}
            />

            <Route
              path="mine"
              element={<MyContentPage />}
            />

            <Route
              path="shared"
              element={<ShareWithMePage />}
            />
            <Route
              path="trash"
              element={<TrashPage />}
            />
            <Route
              path="new"
              element={<ContentEditorPage />}
            >
              {EditorRouter()}
            </Route>
            <Route
              path=":id"
              element={<ContentEditorPage />}
            >
              {EditorRouter()}
            </Route>
          </Route>

          <Route
            path="/folders/:folderId"
            element={<FolderPage />}
          />

          <Route path="/organisation">
            <Route path="users">
              <Route
                index
                element={
                  <UserPermission
                    actions={['user']}
                    renderHasNotPermission={
                      <Navigate
                        to="/"
                        replace={true}
                      />
                    }
                  >
                    <ManageUsersPage />
                  </UserPermission>
                }
              />

              <Route
                path=":userId"
                element={<UserDetailPage />}
              />
            </Route>

            <Route path="settings">
              <Route
                index
                element={<Navigate to="content-behaviour" />}
              />
              <Route
                path="content-behaviour"
                element={<ContentBehavior />}
              />
              <Route
                path="lti"
                element={
                  <ProtectedRoute
                    isAllowed={!!currentSubscription?.studentReportItem}
                    redirectPath="/organisation/settings"
                  >
                    <LtiSettings />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="integrations"
              element={<IntegrationsPage />}
            />

            <Route
              path="billing"
              element={
                <UserPermission
                  actions={['planBilling']}
                  renderHasNotPermission={
                    <Navigate
                      to="/"
                      replace={true}
                    />
                  }
                >
                  <BillingPage />
                </UserPermission>
              }
            />

            <Route
              path="subscription"
              element={
                <UserPermission
                  actions={['planBilling']}
                  renderHasNotPermission={
                    <Navigate
                      to="/"
                      replace={true}
                    />
                  }
                >
                  <SubscriptionPage />
                </UserPermission>
              }
            />
          </Route>

          <Route
            path="learner-reports"
            element={
              <ProtectedRoute
                isAllowed={!!currentSubscription?.studentReportItem}
                redirectPath="../"
              >
                <UserPermission
                  actions={['report']}
                  renderHasNotPermission={
                    <Navigate
                      to="/"
                      replace={true}
                    />
                  }
                >
                  <LearnerReport />
                </UserPermission>
              </ProtectedRoute>
            }
          >
            <Route
              path=""
              element={<LearnerReportsPage />}
            />

            <Route
              path=":userId"
              element={<LearnerReportDetailPage />}
            />

            <Route
              path=":userId/:contentId/attempts/:attemptId"
              element={<LearnerAttemptPage />}
            />
          </Route>
        </Route>

        <Route
          path="welcome"
          element={
            <ProtectedRoute
              isAllowed={!hasWorkspace}
              redirectPath="/dashboard"
            >
              <WelcomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={<VerticalLayout />}
        >
          <Route
            index
            element={<Navigate to="edit" />}
          />

          <Route
            path="edit"
            element={<EditProfile />}
          />

          <Route
            path="change-password"
            element={<ChangePassword />}
          />
        </Route>

        <Route
          path="/workspace"
          element={<VerticalLayout />}
        >
          <Route
            path="settings"
            element={
              <UserPermission
                actions={['organisation']}
                renderHasNotPermission={
                  <Navigate
                    to="/"
                    replace={true}
                  />
                }
              >
                <WorkspaceSettingsPage />
              </UserPermission>
            }
          />
          <Route
            path="switch"
            element={<SwitchWorkspace />}
          />
        </Route>

        <Route
          path="/subscription-plans"
          element={
            <ProtectedRoute
              isAllowed={
                (!currentSubscription?.status || currentSubscription?.status === SubscriptionStatus.CANCELED) &&
                hasWorkspace &&
                isWorkspaceOwner
              }
              redirectPath="/dashboard"
            >
              <SubscriptionPlansPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/sync-subscription"
          element={<SyncSubscriptionPage />}
        />

        <Route
          path="admin"
          element={
            <ProtectedRoute
              isAllowed={userInfo?.isMasterAdmin}
              redirectPath="/dashboard"
            >
              <MasterAdminLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path="restricted"
            element={<AdminPage />}
          />
        </Route>

        <Route
          path="admin"
          element={
            <ProtectedRoute
              isAllowed={userInfo?.isMasterAdmin}
              redirectPath="/dashboard"
            >
              <MasterAdminLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path="manage"
            element={<ManageContentTypesPage />}
          />
        </Route>
      </Route>

      <Route
        path="auth"
        element={<CheckAuth />}
      >
        <Route
          path="login"
          element={<LoginPage />}
        />
        <Route
          path="sign-up"
          element={<SignUpPage />}
        />
        <Route
          path="forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="reset-password"
          element={<ResetPasswordPage />}
        />
        <Route
          path="invitation"
          element={<SignUpPage />}
        />
      </Route>
      <Route
        path=":workspaceId/lti"
        element={
          <ExpiredSubscriptionLayout>
            <LtiLayout />
          </ExpiredSubscriptionLayout>
        }
      >
        <Route path="launch">
          <Route
            index
            element={<LtiLaunchPage />}
          />
          <Route path="contents">
            <Route
              path=":id/play"
              element={<ContentViewerPage />}
            />
            <Route
              path=":id/reports/:userId"
              element={<ContentUserAttempts />}
            />
            <Route
              path=":id/reports/:userId/attempts/:attemptId"
              element={<ContentUserAttemptDetails />}
            />

            <Route
              path="new"
              element={<ContentEditorPage />}
            >
              {EditorRouter()}
            </Route>

            <Route
              path=":id"
              element={<ContentEditorPage />}
            >
              {EditorRouter()}
            </Route>
          </Route>
          <Route
            path="folders/:folderId"
            element={<FolderPage />}
          />
        </Route>
      </Route>
      <Route
        path="embed/:id"
        element={<ContentEmbedPage />}
      ></Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  );
});
