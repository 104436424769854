import React from 'react';
import './WelcomeSection.scss';
import { AiChatType } from 'types';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import HelpTips from '../HelpTips/HelpTips';
import BottomActions from '../BottomActions/BottomActions';
import { HelpTipsProvider } from 'components/AIOffset/components/HelpTips/HelpTipsProvider';
import { IMPORT_CREATE_TIP, BRAINSTORM_TIP } from 'constants/help-tip';
import { ReactSVG } from 'react-svg';
import importCreate from 'assets/images/ai-assistant/message-actions/import-create.svg';
import brainstorm from 'assets/images/ai-assistant/message-actions/brainstorm.svg';
interface WelcomeSectionProps {
  changeViewMode: (mode: AiChatType) => void;
}

const WelcomeSection = ({ changeViewMode }: WelcomeSectionProps) => {
  const helpTips = [IMPORT_CREATE_TIP, BRAINSTORM_TIP];
  return (
    <div className="welcome-section p-3 p-lg-4 d-flex flex-column justify-content-between h-100">
      <div className="assistant">
        <div className="py-1 d-flex align-items-center">
          <AIMascot
            className="ai-mascot ai-mascot-small"
            faceSrc={happyFace}
            isFloat={false}
          />
          <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
        </div>
        <div className="guide bg-neutral-50 font-size-14 fw-normal text-neutral-900">
          Hi there! What&apos;s your goal for today?
        </div>
      </div>
      <div className="gap-2 d-flex flex-column justify-content-center sticky-bottom">
        <HelpTipsProvider>
          <HelpTips helpTips={helpTips} />
        </HelpTipsProvider>
        <BottomActions>
          <div className="flex-row gap-3 d-flex">
            <button
              className="btn btn-primary-hover d-flex align-items-center gap-2"
              onClick={() => changeViewMode(AiChatType.IMPORT)}
            >
              <div className="flex-shrink-0 p-1 d-flex align-items-center justify-content-center">
                <ReactSVG src={importCreate} />
              </div>
              Import & Create
            </button>
            <button
              className="btn btn-primary-hover d-flex align-items-center gap-2"
              onClick={() => changeViewMode(AiChatType.BRAINSTORM)}
            >
              <div className="flex-shrink-0 p-1 d-flex align-items-center justify-content-center">
                <ReactSVG src={brainstorm} />
              </div>
              Brainstorm with Michi
            </button>
          </div>
        </BottomActions>
      </div>
    </div>
  );
};

export default WelcomeSection;
