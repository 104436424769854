import useModal from 'hooks/useModal';
import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { RedirectUserDashboard } from '../RedirectUserDashboard';
import { SidebarProfile } from 'components/VerticalLayout/SidebarMenuBottom/components/SidebarProfile';

export const SidebarMasterAdminMenuBottom = () => {
  const [open, toggleOpen] = useToggle(false);
  const sidebarMasterAdminMenuRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useModal();

  useClickAway(sidebarMasterAdminMenuRef, () => {
    if (isOpen) return;
    toggleOpen(false);
  });

  return (
    <div
      ref={sidebarMasterAdminMenuRef}
      className="position-absolute bottom-0 start-0 bg-white sidebar-master-admin-menu-bottom"
      data-cy="sidebar-master-admin-menu-bottom"
    >
      <RedirectUserDashboard />
      <SidebarProfile
        open={open}
        toggleOpen={toggleOpen}
      />
    </div>
  );
};
