import { H5PPlayerUI } from '@lumieducation/h5p-react';
import { ErrorEmptyBlock, Loading } from 'components';
import { useGetContentPreview } from 'hooks/content/useGetContentPreview';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

export const ContentPlayerTab = observer(() => {
  const { id: contentId } = useParams();

  const {
    contentStore: { editor },
  } = useAppStore();

  const { loadContentPreviewAsync, isLoading } = useGetContentPreview();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  if (!contentId && (!editor?.params || !editor?.metadata)) return <ErrorEmptyBlock />;

  return (
    <div>
      {(isLoading || !isInitialized) && <Loading />}

      <H5PPlayerUI
        contentId={contentId || 'new-content'}
        loadContentCallback={loadContentPreviewAsync}
        onInitialized={() => {
          setIsInitialized(true);
        }}
      />
    </div>
  );
});
