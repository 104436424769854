import classNames from 'classnames';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { components } from 'react-select';

export const SubscriptionMenuWorkspace = observer(({ children, options, ...props }: any) => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  const [selectedValue] = props.getValue();

  const { handleClickSwitchWorkspace, switchWorkspaces } = props.selectProps;

  if (!selectedValue) return <components.Menu {...props}>{children}</components.Menu>;
  return (
    <components.Menu {...props}>
      {options.map((option: any) => (
        <div
          key={option.id}
          className={classNames('d-flex align-items-center px-3 py-2 bg-white bg-primary-50-hover cursor-pointer', {
            'bg-primary-50': option.id === selectedValue.id ? true : false,
          })}
          onClick={() => {
            switchWorkspaces.toggleMenu();
            handleClickSwitchWorkspace({ ...option });
          }}
        >
          <AvatarProfile
            imgSrc={option.imageUrl}
            width={36}
            height={36}
            userFullName={option.name}
            hasOutline={option.id === currentWorkspaceId ? true : false}
            avatarClassNames="sidebar-profile__avatar"
          />
          <div className="ms-2 flex-fill w-1px">
            <div className="font-size-14 fw-bold text-truncate gray-900">{option.name}</div>
          </div>
        </div>
      ))}
    </components.Menu>
  );
});
