import React, { useState } from 'react';
import './AppDropDown.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import classNames from 'classnames';

type IProps = {
  items: { value: any; label: string; itemIcon?: JSX.Element }[];
  selected: any;
  disabled?: boolean;
  selectItemFunc: (item: string) => void;
  itemsDisabled?: string[];
  emptyDefault?: boolean;
  dataCy?: string;
  height?: number;
  direction?: 'up' | 'down' | 'start' | 'end';
  invalid?: boolean;
};

export const AppDropDown = ({
  items,
  selected,
  disabled = false,
  selectItemFunc,
  itemsDisabled,
  emptyDefault = false,
  dataCy,
  height,
  direction,
  invalid = false,
}: IProps) => {
  const [open, setOpen] = useState(false);

  const selectedOption = items.find(x => x.value === selected) || items[0];

  const selectedItemIcon = selectedOption?.itemIcon;

  return (
    <Dropdown
      className="w-100 app-dropdown"
      color={'bg-white'}
      isOpen={open}
      toggle={() => {
        setOpen(!open);
      }}
      data-cy={dataCy}
      direction={direction ?? 'down'}
    >
      <DropdownToggle
        className={classNames(
          'btn-neutral bg-mobile-hover w-100 app-dropdown__custom-dropdown d-flex align-items-center',
          { invalid },
        )}
        style={{ height }}
        disabled={disabled}
        data-cy={`${dataCy}-toggle`}
      >
        <div className="gray-800 flex-fill w-1px text-truncate text-start">
          {(emptyDefault && selected === '') || selected === 'NONE' ? '-' : selectedOption.label}{' '}
        </div>

        {selectedItemIcon && <div className="me-1">{selectedItemIcon}</div>}

        {open ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </DropdownToggle>

      <DropdownMenu
        className="mt-2 border w-100 app-dropdown__dropdown-menu-custom p-0"
        end={false}
      >
        {items.map(({ value, label, itemIcon = <></> }, key) => {
          return (
            <DropdownItem
              data-cy={`${dataCy}-option-${key + 1}`}
              className={classNames(
                'px-3 py-2 gray-800 text-truncate d-flex align-items-center justify-content-between',
                { 'bg-primary-50': selected === value },
              )}
              key={key}
              onClick={() => selectItemFunc(value)}
              disabled={itemsDisabled?.includes(value)}
            >
              {label}
              <div className="me-4">{itemIcon}</div>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
