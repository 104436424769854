import { updateConnection } from 'apis/Lti/Lti';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateConnection = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const queryClient = useQueryClient();
  const queryKey = ['workspaces', currentWorkspaceId, 'connections'];

  return useMutation(updateConnection, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast.success('Connection settings has been updated.');
    },
    onError: (errorResponse: ErrorResponse) => {
      toast(fetchServerError(errorResponse), { type: 'error' });
    },
  });
};
