import CONTENT_PERMISSIONS from './content_permissions.json';
import { ContentRole } from './content-role.enum';

export const hasContentPermissionByRole = (role: ContentRole, action: string) => {
  if (role === ContentRole.OWNER) {
    return true;
  }

  return CONTENT_PERMISSIONS[role]?.includes(action);
};

export const findContentPermissions = (role: ContentRole) => {
  return CONTENT_PERMISSIONS[role] || [];
};

export const hasContentPermission = (permissions: string[] = [], action: string) => {
  return permissions.includes('*') || permissions.includes(action);
};
