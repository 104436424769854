import classNames from 'classnames';
import { AppBadge } from 'components';
import { useActivateConnection, useDeactivateConnection, useDeleteConnection } from 'hooks';
import useModal from 'hooks/useModal';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Collapse } from 'reactstrap';
import { Connection, CreateConnection } from 'types';
import './ConnectionName.scss';

type Props = Partial<CreateConnection>;

export const ConnectionName: React.FC<Props> = (props: Props) => {
  const { active, platformName, createdAt, lms, platformUrl } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const createdAtDateFormat = !createdAt ? '-' : moment(createdAt).format('DD/MM/YYYY HH:mm:ss');

  const { openModal, closeModal } = useModal();

  const { mutate: deactivate } = useDeactivateConnection();
  const { mutate: activate } = useActivateConnection();
  const { mutate: deleteConnection } = useDeleteConnection();

  useEffect(() => {
    if (lms) {
      setIsOpen(true);
    }
  }, [lms]);

  const handleConnectionSettings = () => {
    openModal('add-connection-settings', { data: props });
  };

  const handleDeactivateConnection = () => {
    openModal('warning', {
      title: `Are you sure you want to ${active ? 'deactivate' : 'activate'} this LMS connection?`,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: () => {
        closeModal();
      },
      onAccept: () => {
        if (active) {
          deactivate(props as Connection);
        } else {
          activate(props as Connection);
        }
        closeModal();
      },
    });
  };

  const handleDeleteConnection = () => {
    openModal('delete', {
      title: `Are you sure you want to delete this LMS connection?`,
      content: 'You can’t undo this action.',
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: () => {
        closeModal();
      },
      onAccept: () => {
        deleteConnection(props.kid);
        closeModal();
      },
    });
  };

  return (
    <div className="mt-2 rounded-4 bg-white cursor-pointer">
      <div
        className={classNames(
          'd-flex align-item-center justify-content-between bg-white p-3 border border-neutral-200',
          { 'rounded-top-4': isOpen, 'rounded-4': !isOpen },
        )}
        onClick={toggle}
      >
        <div className="d-flex align-item-center">
          <div className="d-flex align-item-center">
            {isOpen ? (
              <ChevronDown
                size={18}
                strokeWidth={3}
                className="d-block text-neutral-900 mt-1 me-3"
              />
            ) : (
              <ChevronRight
                size={18}
                strokeWidth={3}
                className="d-block text-neutral-900 mt-1 me-3"
              />
            )}
            <div className="fs-md fw-bold text-neutral-900 me-2">{platformName}</div>
          </div>
          <div className="me-2">
            {active ? (
              <AppBadge
                type="success-outline"
                label="Enabled"
              />
            ) : (
              <AppBadge
                type="neutral-outline"
                label="Disabled"
              />
            )}
          </div>
        </div>

        <div
          className="flex-shrink-0"
          style={{
            lineHeight: 2,
          }}
        >
          {createdAtDateFormat}
        </div>
      </div>

      <Collapse
        isOpen={isOpen}
        className={classNames(
          'bg-white border border-neutral-200 border-top-0 rounded-bottom-4 p-3 connection-name__collapse',
        )}
      >
        <div className="border border-neutral-200 rounded-4 connection-name__table">
          <div className="p-3 mx-2">
            <div className="row align-items-center border border-neutral-200 border-start-0 border-end-0">
              <div className="col-3 p-3">Connection Name</div>
              <div className="col-9">{platformName}</div>
            </div>

            <div className="row align-items-center">
              <div className="col-3 p-3">LTI version</div>
              <div className="col-9">LTI v1.3</div>
            </div>
          </div>
        </div>

        <div className="d-flex align-item-center pt-3">
          <button
            className="btn btn-primary me-2"
            onClick={handleConnectionSettings}
          >
            Connection Settings
          </button>
          {platformUrl && (
            <button
              className="btn btn-outline-primary me-2"
              onClick={handleDeactivateConnection}
            >
              {active ? 'Deactivate' : 'Activate'}
            </button>
          )}
          <button
            className="btn btn-outline-danger"
            onClick={handleDeleteConnection}
          >
            Delete Connection
          </button>
        </div>
      </Collapse>
    </div>
  );
};
