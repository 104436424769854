import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { WorkspaceInfo } from 'types';
import { Setting } from 'types/setting';

export class WorkspaceStore {
  workspaces: WorkspaceInfo[] = [];
  currentWorkspaceId: string = '';
  setting: Setting = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(
      this,
      {
        name: 'WorkspaceStore',
        properties: ['currentWorkspaceId'],
        storage: window.localStorage,
        stringify: true,
        debugMode: false,
      },
      { delay: 200 },
    );
  }

  get hasWorkspace() {
    return !!this.workspaces?.length;
  }

  get currentWorkspace(): WorkspaceInfo {
    return this.workspaces?.find(x => x?.id === this.currentWorkspaceId) || null;
  }

  get workspaceSetting() {
    return this.setting;
  }

  updateWorkspaces(workspaces: WorkspaceInfo[]) {
    this.workspaces = [...workspaces];
  }

  get isWorkspaceOwner() {
    return this.currentWorkspace?.isOwner;
  }

  updateCurrentWorkspace(workspaceData: Partial<WorkspaceInfo>) {
    this.currentWorkspaceId = workspaceData?.id;
    this.workspaces = this.workspaces?.map(workspace => {
      if (workspace.id === this.currentWorkspaceId)
        return {
          ...workspace,
          ...workspaceData,
        };

      return workspace;
    });
  }

  updateSetting(setting: Setting) {
    this.setting = { ...setting };
  }

  addWorkspace(workspace: WorkspaceInfo) {
    this.workspaces = [...this.workspaces, workspace];
    this.currentWorkspaceId = workspace?.id;
  }

  clear() {
    this.workspaces = [];
    this.setting = null;
  }
}
