import { IContentMetadata } from '@lumieducation/h5p-server';
import { ContentRole, ContentSetting, MachineName } from '@spiderbox/common';
import { QueryParams } from 'types/common';

export type TContentType =
  | 'quiz'
  | 'drag-drop'
  | 'drag-words'
  | 'interactive-book'
  | 'interactive-video'
  | 'flash-card'
  | 'course-presentation'
  | 'image-hotspots'
  | 'image-slider'
  | 'mark-words'
  | 'memory-game'
  | 'multiple-choice'
  | 'accordion'
  | 'single-choice'
  | 'fill-in-the-blank'
  | 'image-choice'
  | 'true-false'
  | 'summary'
  | 'sort-paragraphs'
  | 'documentation-tool'
  | 'dialog-card'
  | 'dictation'
  | 'column'
  | 'arithmetic'
  | 'branching-scenario'
  | 'audio'
  | 'chart'
  | 'collage'
  | 'crossword'
  | 'guess-answer';

export type TContent = {
  readonly type: TContentType;
};

export type TViewMode = 'gallery' | 'list';

export enum TDocumentType {
  Content = 'Content',
  Folder = 'Folder',
}

export enum ContentStatus {
  Draft = 'Draft',
  Published = 'Published',
  NotApplied = 'NotApplied',
  Inactive = 'Inactive',
}

export type UserContentDto = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
  fullName?: string;
};

export type Document = {
  id: string;
  kind: TDocumentType;
  library?: string;
  title: string;
  status: ContentStatus;
  updatedAt?: Date;
  updatedBy?: UserContentDto;
  user?: UserContentDto;
  starred?: boolean;
  trashedAt?: Date;
  children?: Document[];
  numchild?: number;
  path?: string;
  contentRole?: ContentRole;
  hasSharing?: boolean;
};

export type Folder = {
  id: string;
  title: string;
  children?: Folder[];
  isEdited?: boolean;
  numchild?: number;
  kind?: TDocumentType;
  path?: string;
};

export type H5PContent = {
  id?: string;
  contentId: string;
  metadata: IContentMetadata;
  parameters?: ContentParameters;
  userId?: string;
  workspaceId?: string;
  setting?: ContentSetting;
  status?: ContentStatus;
};

type ContentParameters = {
  UI?: UI;
  media?: Media;
  answers?: Answer[];
  question?: string;
  behaviour?: Behaviour;
  confirmCheck?: Confirm;
  confirmRetry?: Confirm;
  overallFeedback?: OverallFeedback[];
};

type UI = {
  noInput?: string;
  a11yCheck?: string;
  a11yRetry?: string;
  tipsLabel?: string;
  shouldCheck?: string;
  wrongAnswer?: string;
  readFeedback?: string;
  tipAvailable?: string;
  correctAnswer?: string;
  scoreBarLabel?: string;
  shouldNotCheck?: string;
  tryAgainButton?: string;
  a11yShowSolution?: string;
  checkAnswerButton?: string;
  feedbackAvailable?: string;
  showSolutionButton?: string;
  submitAnswerButton?: string;
};

type Answer = {
  text?: string;
  correct?: boolean;
  tipsAndFeedback?: TipsAndFeedback;
};

type TipsAndFeedback = {
  tip?: string;
  chosenFeedback?: string;
  notChosenFeedback?: string;
};

type Behaviour = {
  type?: string;
  autoCheck?: boolean;
  enableRetry?: boolean;
  singlePoint?: boolean;
  randomAnswers?: boolean;
  passPercentage?: number;
  showScorePoints?: boolean;
  enableCheckButton?: boolean;
  confirmCheckDialog?: boolean;
  confirmRetryDialog?: boolean;
  enableSolutionsButton?: boolean;
  showSolutionsRequiresInput?: boolean;
};

type Confirm = {
  body?: string;
  header?: string;
  cancelLabel?: string;
  confirmLabel?: string;
};

type Media = {
  type?: Type;
  disableImageZooming?: boolean;
};

type Type = {
  params?: Params;
  library?: string;
  metadata?: TypeMetadata;
  subContentId?: string;
};

type TypeMetadata = {
  title?: string;
  authors?: any[];
  changes?: any[];
  license?: string;
  contentType?: string;
};

type Params = {
  decorative?: boolean;
  contentName?: string;
};

type OverallFeedback = {
  to?: number;
  from?: number;
  feedback?: string;
};

export type ContentActions = {
  icon?: JSX.Element;
  label?: string;
  action: string;
};

export type ContentQueryParams = QueryParams & {
  kind?: TDocumentType | '';
  status?: ContentStatus | '';
  libraries?: string[];
  starred?: boolean | string;
  lastAttemptFrom?: number;
  lastAttemptTo?: number;
};

export type ContentPermissions = {
  canEdit?: boolean;
  canMoveToFolder?: boolean;
  canMoveToTrash?: boolean;
  canDelete?: boolean;
};

export type ContentRenameRequest = {
  id: string;
  title: string;
};

export type contentStatusRequest = {
  id: string;
  title?: string;
  status: ContentStatus;
  parentId?: string;
};

export type ResultContent = {
  correctAnswers: string[];
  learnerAnswers: string[];
  interactionType: string;
  contentType: MachineName;
  description: string[];
  indexes: string[];
  title: string;
  helpTexts?: string[];
  score?: number;
  maxScore?: number;
  totalScore?: number;
};
