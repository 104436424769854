import React from 'react';
import { UserDetailAccordion } from 'pages/UserDetailPage/components/UserDetailAccordion';
import './UserDetailCard.scss';

export const UserDetailCard = () => {
  return (
    <div
      className="user-detail-card flex-fill d-flex flex-column bg-white mt-3"
      data-cy="user-permission-card"
    >
      <div className="border-bottom border-neutral-200 p-3 fw-bold fs-md text-neutral-900">User Permission</div>

      <div className="flex-fill d-grid gap-2 px-3 py-5">
        <UserDetailAccordion />
      </div>
    </div>
  );
};
