import { useAppStore } from 'hooks/useAppStore';
import React from 'react';
import './MichiAssistantBtn.scss';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';

export const MichiAssistantBtn = () => {
  const { uiStore } = useAppStore();

  return (
    <div
      className="d-none d-md-flex align-items-center justify-content-center cursor-pointer ai-assistant-btn"
      onClick={() => uiStore?.toggleAIOffset()}
    >
      <AIMascot
        className="ai-mascot ai-mascot-normal"
        faceSrc={happyFace}
        isFloat={false}
      />
    </div>
  );
};
