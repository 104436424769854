import React, { useState } from 'react';
import { PaymentMethodData } from 'types/payment';
import './PaymentMethodItem.scss';
import { Check, CreditCard, MoreVertical, Trash2, X } from 'react-feather';
import { PayIcon } from '../PayIcon';
import useModal from 'hooks/useModal';
import { toast } from 'react-toastify';
import { ConfirmModalProps } from 'components/ModalManager/Confirm';
import { makePaymentMethodDefault, removePaymentMethod } from 'apis/Payment/PaymentMethod';
import { useMutation, useQueryClient } from 'react-query';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

type PaymentMethodItemProps = {
  paymentMethodData: PaymentMethodData;
  selectedId?: string;
  onSelectCard?: (cardId: string) => void;
};
export const PaymentMethodItem = observer((props: PaymentMethodItemProps) => {
  const {
    workspaceStore: { isWorkspaceOwner, currentWorkspaceId },
  } = useAppStore();
  const queryClient = useQueryClient();
  const [viewOnlyMode, toggleViewOnlyMode] = useState(true);
  const { openModal, closeModal } = useModal();
  const { data, default: isDefault, id } = props?.paymentMethodData || {};
  const { mutateAsync: makeDefault } = useMutation(makePaymentMethodDefault, {
    onSuccess: () => {
      toast('Payment method has been set as default.');
    },
    onError: (error: any) => {
      toast.error(error);
    },
  });

  const { brand, expMonth, expYear, last4 } = data;

  React.useEffect(() => {
    if (props?.selectedId !== id) {
      toggleViewOnlyMode(true);
    }
  }, [props.selectedId]);

  const openRemoveCardModal = () => {
    toggleViewOnlyMode(true);
    props.onSelectCard?.(id);
    openModal('warning', {
      title: 'Are you sure you want to remove this payment method?',
      titleIcon: (
        <CreditCard
          size={24}
          className="text-primary"
        />
      ),
      content: "You can't undo this action.",
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onAccept: async (done: () => void) => {
        try {
          await removePaymentMethod(id);
          queryClient.setQueryData(['workspace', currentWorkspaceId, 'cards'], (data: PaymentMethodData[]) => {
            return data?.filter(paymentMethod => paymentMethod.id !== id);
          });
          toast('Payment method has been removed.');
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    } as ConfirmModalProps);
  };

  const onMakePaymentMethodDefault = async () => {
    await makeDefault(id);

    queryClient.setQueryData(['workspace', currentWorkspaceId, 'cards'], (data: PaymentMethodData[]) => {
      return data.map(paymentMethod => {
        return { ...paymentMethod, default: paymentMethod.id === id };
      });
    });
  };

  const renderCardActions = () => {
    if (viewOnlyMode)
      return (
        <MoreVertical
          className="cursor-pointer text-white"
          size={20}
          onClick={event => {
            event.stopPropagation();
            toggleViewOnlyMode(false);
            if (props?.selectedId !== id) {
              props.onSelectCard?.(id);
            }
          }}
        />
      );

    return (
      <div className="d-flex align-items-center gap-2">
        <div
          className="d-flex align-items-center gap-1 card-action-badge gray-900 cursor-pointer"
          onClick={event => {
            event.stopPropagation();
            toggleViewOnlyMode(true);
            onMakePaymentMethodDefault();
            props.onSelectCard?.(id);
          }}
        >
          <Check
            className="gray-900"
            size={16}
          />
          <span>Make default</span>
        </div>

        <div
          className="d-flex align-items-center gap-1 card-action-badge gray-900 cursor-pointer"
          onClick={event => {
            event.stopPropagation();
            openRemoveCardModal();
          }}
        >
          <Trash2
            className="gray-900"
            size={16}
          />
          <span>Remove</span>
        </div>

        <X
          className="text-white cursor-pointer"
          size={20}
          strokeWidth={2}
          onClick={event => {
            event.stopPropagation();
            toggleViewOnlyMode(true);
            props.onSelectCard?.(id);
          }}
        />
      </div>
    );
  };

  return (
    <div className="card-element p-4">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex align-items-center justify-content-between">
          <PayIcon brand={brand} />
          {isDefault ? (
            <div
              className="me-0 card-action-badge default"
              data-cy="card-default"
            >
              Default
            </div>
          ) : isWorkspaceOwner ? (
            renderCardActions()
          ) : (
            <></>
          )}
        </div>

        <div
          className="card-digit"
          data-cy="card-digit"
        >
          **** **** **** {last4}
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column ms-auto me-2">
            <div className="gray-300 font-size-12 fw-semibold mb-1">EXPIRY DATE</div>
            <div
              className="text-white h5 font-size-14"
              data-cy="card-expiry"
            >
              {expMonth}/{expYear}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
