import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { SubscriptionStatus } from '@spiderbox/common';
import useModal from 'hooks/useModal';
import classNames from 'classnames';

export const PastDueSubscriptionBanner = observer(({ className = '' }: { className?: string }) => {
  const {
    workspaceStore: { isWorkspaceOwner },
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  const { openModal } = useModal();
  const openRetryPaymentModal = () => {
    openModal('payment-retry', { size: 'md' });
  };

  if (currentSubscription?.status !== SubscriptionStatus.PAST_DUE) return <></>;

  return (
    <div
      className={classNames('bg-secondary-500 d-flex flex-column gap-2 px-4 py-2', className)}
      data-cy="past-due-banner"
    >
      <div className="gray-900 font-size-14 fw-medium">Payment is past due!</div>
      <div className="gray-900 font-size-14">
        {isWorkspaceOwner ? (
          <>
            Please complete payment process to continue using our services without disruptions.{' '}
            <u
              className="fw-semibold cursor-pointer"
              onClick={() => openRetryPaymentModal()}
            >
              Click here
            </u>{' '}
            to retry!
          </>
        ) : (
          <>Please contact Workspace&apos;s Owner to continue using our services without disruptions.</>
        )}
      </div>
    </div>
  );
});
