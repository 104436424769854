import apiClient from 'apis/ApiClient';
import { ResultContent } from 'models/Content';
import moment from 'moment';

import {
  ContentUserDataAttempt,
  LearnerContentReport,
  LearnerReport,
  LearnerReportQueryParams,
} from 'types/student-report';
import { downloadFile } from 'utils';

const endPoint = '/learner-reports';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const fetchLearnerReports = async (params: LearnerReportQueryParams): Promise<LearnerReport[]> => {
  const { data } = await apiClient.get(endPoint, { params });
  await sleep(300);
  return data;
};

export const downloadLearnerReports = async (params: LearnerReportQueryParams): Promise<void> => {
  const userTimezone = moment.tz.guess();

  const { data: fileData, headers } = await apiClient.get(`${endPoint}/download`, {
    params,
    headers: {
      timezone: userTimezone,
    },
  });

  const fileName = headers['content-disposition'].split('filename=')[1];

  downloadFile({ fileData, name: fileName, type: 'text/csv', extension: 'csv' });
};

export const fetchLearnerReport = async (
  params: LearnerReportQueryParams,
  userId: string,
): Promise<LearnerContentReport[]> => {
  const { data } = await apiClient.get(`${endPoint}/${userId}`, { params });
  await sleep(300);
  return data;
};

export const downloadLearnerReport = async ({
  userId,
  params,
}: {
  userId: string;
  params: LearnerReportQueryParams;
}) => {
  const userTimezone = moment.tz.guess();

  const { data: fileData, headers } = await apiClient.get(`${endPoint}/${userId}/download`, {
    params,
    headers: {
      timezone: userTimezone,
    },
  });

  const fileName = headers['content-disposition'].split('filename=')[1];

  downloadFile({ fileData, name: fileName, type: 'text/csv', extension: 'csv' });
};

export const fetchLearnerContentAttempt = async (
  userId: string,
  contentId: string,
  attemptId: string,
  { platformId, studentReportEmail, lmsUserId, platformGuid }: LearnerReportQueryParams,
): Promise<ContentUserDataAttempt> => {
  const params = { platformId, studentReportEmail, lmsUserId, platformGuid };
  const { data } = await apiClient.get(`${endPoint}/${userId}/${contentId}/attempts/${attemptId}`, {
    params,
  });

  return data;
};

export const fetchLearnerContentResult = async (attemptId: string): Promise<ResultContent[]> => {
  const { data } = await apiClient.get(`content-results/${attemptId}`);
  return data;
};
