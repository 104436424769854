import { useAppStore } from 'hooks/useAppStore';
import { useMedia } from 'react-use';

export const useMediaScreen = () => {
  const { ltiMode } = useAppStore();
  const isSmDown = useMedia('(max-width: 575.98px)') && !ltiMode;
  const isMdDown = useMedia('(max-width: 767.98px)') && !ltiMode;
  const isLgDown = useMedia('(max-width: 991.98px)');
  const isXlDown = useMedia('(max-width: 1199.98px)');
  const isXxlDown = useMedia('(max-width: 1399.98px)');

  return { isSmDown, isMdDown, isLgDown, isXlDown, isXxlDown };
};
