import { QueryClient } from 'react-query';
import {
  AIMessageActionType,
  AIMessageContentType,
  AIMessage,
  AIMessageType,
  ChatDetailResponse,
  Metadata,
} from 'types';

type Props = {
  queryClient: QueryClient;
  chatId: string;
  message: {
    id?: string;
    content: string;
    messageType: AIMessageType;
    actionType: AIMessageActionType;
    messageContentType: AIMessageContentType;
    metadata?: Metadata;
  };
};

export const addMessageToChatHistory = ({ queryClient, chatId, message }: Props) => {
  queryClient.setQueryData<ChatDetailResponse>(['chat-detail', chatId], chat => {
    const { id, content, messageType, actionType, messageContentType, metadata } = message;
    const newMessage = {
      id,
      content,
      messageType,
      actionType,
      messageContentType,
      metadata,
    } as AIMessage;

    return {
      ...chat,
      messages: [...chat.messages, newMessage],
    };
  });
};
