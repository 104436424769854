import { submitDeeplinkingForm } from 'apis/Lti/Lti';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation } from 'react-query';

export const useDeepLinking = () => {
  const {
    ltiStore: { ltiKey, workspaceId },
  } = useAppStore();

  const submitDeepLink = async (contentId: string) => {
    const { form } = await submitDeeplinkingForm(contentId, workspaceId, ltiKey);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = form;
    document.body.appendChild(tempDiv);
    (document.getElementById('ltijs_submit') as any).submit();
  };

  return useMutation(submitDeepLink);
};
