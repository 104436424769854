import { logout } from 'apis/Auth/Auth';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useLogout = () => {
  const appStore = useAppStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(logout, {
    retry: false,
    onSuccess: () => {
      appStore.clearUser();
      queryClient.clear();
      navigate('/auth/login', { replace: true });
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
