import { ChangePasswordRequest } from 'models/Password';
import apiClient from '../ApiClient';
import { EditProfileFormInput, ErrorResponse, User } from 'types';

export async function me() {
  const response = await apiClient.get('profile/me');
  return response.data;
}

export async function updateProfile(payload: EditProfileFormInput): Promise<User> {
  return await apiClient.put('profile/me', payload).then(response => {
    return response.data;
  });
}

export async function changePassword(payload: ChangePasswordRequest): Promise<any | ErrorResponse> {
  return await apiClient.put('profile/password', payload).then(response => {
    return response.data;
  });
}
