import React, { useEffect } from 'react';
import { AppDropDown } from 'components/AppDropDown';
import { useUpdateSetting } from 'hooks/setting';
import { useAppStore } from 'hooks/useAppStore';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { ContentBehaviorOptions, ContentBehaviorSetting, ContentBehaviorSettingInput } from 'types/setting';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import './ContentBehavior.scss';
import { Collapse } from 'reactstrap';
import { useUserPermission } from 'hooks/useUserPermission';

const settingOptions: { value: ContentBehaviorOptions; label: string }[] = [
  { value: ContentBehaviorOptions.NEVER, label: 'Never' },
  { value: ContentBehaviorOptions.ALWAYS, label: 'Always' },
  { value: ContentBehaviorOptions.ONLY_FOR_EDITOR, label: 'Only for authors' },
  {
    value: ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
    label: 'Controlled by author - on by default',
  },
  {
    value: ContentBehaviorOptions.CONTROLLED_BY_EDITOR_OFF_DEFAULT,
    label: 'Controlled by author - off by default',
  },
];

export const defaultContentBehaviorSetting: ContentBehaviorSetting = {
  contentBehaviorDisplayToolbar: 'true',
  contentBehaviorDisplayTotalAttempts: 'true',
  contentBehaviorDisplayEmbedButton: ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
  contentBehaviorUsersCanDownload: ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
};

export const ContentBehavior = observer(() => {
  const { workspaceStore } = useAppStore();

  const { setting } = workspaceStore;

  const { contentBehavior } = setting || {};

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ContentBehaviorSettingInput>();

  usePrompt(isDirty);

  const displayToolbarBelowSetting = watch('contentBehaviorDisplayToolbar');

  useEffect(() => {
    if (!!contentBehavior) {
      reset({
        ...contentBehavior,
        contentBehaviorDisplayToolbar: contentBehavior.contentBehaviorDisplayToolbar === 'true',
        contentBehaviorDisplayTotalAttempts: contentBehavior.contentBehaviorDisplayTotalAttempts === 'true',
      });
    }
  }, [setting]);

  const { mutate, isLoading: updateSettingLoading } = useUpdateSetting();

  const hasPermission = useUserPermission(['organisation.editContentBehavior']);

  const onSubmit = async (values: ContentBehaviorSettingInput) => {
    mutate({
      contentBehavior: {
        ...values,
        contentBehaviorDisplayToolbar: values.contentBehaviorDisplayToolbar.toString(),
        contentBehaviorDisplayTotalAttempts: values.contentBehaviorDisplayTotalAttempts.toString(),
      },
    });

    reset({}, { keepValues: true });
  };

  return (
    <SettingsLayout
      handleSubmit={handleSubmit(onSubmit)}
      isLoading={updateSettingLoading}
    >
      <div className="content-behavior-layout bg-white">
        <div className="content-behavior-title d-flex flex-column px-3 px-md-4 py-3 gap-2">
          <div className="gray-900 font-size-16 fw-semibold">Content Behaviour Settings</div>
          <div className="gray-500">Control aspects of how your content behaves</div>
        </div>

        <div className="d-flex flex-column gap-3 px-2 px-md-4 py-3">
          <div className="content-behavior-item py-3">
            <div className="d-flex justify-content-between px-3 px-md-4">
              <div
                data-cy="toolbar-below-setting-title"
                className="gray-900 fw-medium h5 font-size-14"
              >
                Toolbar Below Content
              </div>
              <div className="form-check form-switch form-switch-lg">
                <Controller
                  control={control}
                  name="contentBehaviorDisplayToolbar"
                  render={({ field }) => (
                    <input
                      {...field}
                      data-cy="toolbar-below-setting"
                      type="checkbox"
                      className="custom-switch form-check-input cursor-pointer"
                      checked={field.value}
                      onChange={field.onChange}
                      value={''}
                      disabled={!hasPermission}
                    />
                  )}
                />
              </div>
            </div>

            <div className="px-3 px-md-4 mt-2 gray-500">Controlled by author - on by default</div>

            <Collapse isOpen={displayToolbarBelowSetting}>
              <div className="mt-3">
                <div className="content-behavior-item__option px-3 px-md-4 py-3">
                  <div
                    data-cy="users-can-download-setting-title"
                    className="gray-900 fw-medium h5 font-size-14"
                  >
                    Allow users to download the content
                  </div>

                  <div className="setting-dropdown mt-3">
                    <Controller
                      control={control}
                      name="contentBehaviorUsersCanDownload"
                      render={({ field }) => (
                        <AppDropDown
                          disabled={!hasPermission}
                          dataCy="users-can-download-setting"
                          items={settingOptions}
                          selected={field.value}
                          selectItemFunc={(item: any) => field.onChange(item)}
                        ></AppDropDown>
                      )}
                    />
                  </div>
                </div>

                <div className="content-behavior-item__option px-3 px-md-4 pt-3">
                  <div
                    data-cy="display-embed-button-setting-title"
                    className="gray-900 fw-medium h5 font-size-14"
                  >
                    Display Embed button
                  </div>

                  <div className="setting-dropdown mt-3">
                    <Controller
                      control={control}
                      name="contentBehaviorDisplayEmbedButton"
                      render={({ field }) => (
                        <AppDropDown
                          disabled={!hasPermission}
                          dataCy="display-embed-button-setting"
                          items={settingOptions}
                          selected={field.value}
                          selectItemFunc={(item: any) => field.onChange(item)}
                        ></AppDropDown>
                      )}
                    />
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="content-behavior-item px-3 px-md-4 py-3">
            <div className="d-flex justify-content-between">
              <div
                data-cy="display-attempts-setting-title"
                className="gray-900 fw-medium h5 font-size-14"
              >
                Display number of attempts
              </div>
              <div className="form-check form-switch form-switch-lg">
                <Controller
                  control={control}
                  name="contentBehaviorDisplayTotalAttempts"
                  render={({ field }) => (
                    <input
                      {...field}
                      data-cy="display-attempts-setting"
                      type="checkbox"
                      className="custom-switch form-check-input cursor-pointer"
                      checked={field.value}
                      onChange={field.onChange}
                      value={''}
                      disabled={!hasPermission}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mt-2 gray-500">Display except when there are 0 attempts</div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
});
