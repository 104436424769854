import React from 'react';
import './BillingHistory.scss';
import { InvoiceTable } from './InvoiceTable';

export const BillingHistory = () => {
  return (
    <div className="billing-history-wrapper d-flex flex-column">
      <div className="gray-900 font-size-16 fw-semibold">Billing History</div>

      <InvoiceTable />
    </div>
  );
};
