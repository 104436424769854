import { moveFolder } from 'apis/Folder/Folder';
import { findFolderById } from 'components/ModalManager/FolderView/utils';
import { useAppStore } from 'hooks/useAppStore';
import { ContentStatus, Document, Folder, TDocumentType } from 'models/Content';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { FolderStore } from 'stores';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useMoveFolder = (folderStore: FolderStore) => {
  const { queryKey } = useAppStore();
  const { folderSelected, modalFolderKey } = folderStore;
  const queryClient = useQueryClient();
  const addNewFolder = () => {
    queryClient.setQueriesData<Folder[]>(modalFolderKey, previousData => {
      const folder = findFolderById(previousData, folderSelected?.id);
      const newFolder = {
        id: 'new',
        kind: TDocumentType.Folder,
        status: ContentStatus.Published,
        title: 'Untitled folder',
        isEdited: true,
      } as Folder;
      if (folder) {
        folder.children = folder.children ? [newFolder, ...folder.children] : [newFolder];
      }
      return [...previousData];
    });

    folderStore.setFolderCreating(true);
  };

  const cancelNewFolder = () => {
    queryClient.setQueriesData<Folder[]>(modalFolderKey, previousData => {
      const folder = findFolderById(previousData, folderSelected?.id);
      if (folder) {
        folder.children = folder.children.filter(folder => folder.id !== 'new');
      }
      return [...previousData];
    });

    folderStore.setFolderCreating(false);
  };

  const { mutate: moveToFolder, isLoading: moveFolderLoading } = useMutation(moveFolder, {
    onSuccess: (_, moveFolderRequest) => {
      const { id } = moveFolderRequest;
      toast(
        <div>
          Item has been moved to folder <span className="fw-bold">{folderSelected?.title || 'root'}</span>
        </div>,
        { type: 'success' },
      );
      queryClient.setQueryData<Document[]>(queryKey, (contents: Document[]) => {
        if (folderSelected?.id === 'root' && (queryKey?.[1] === 'all' || queryKey?.[1] === 'mine')) {
          return [...contents];
        }
        return contents.filter(content => content.id !== id);
      });
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  return {
    addNewFolder,
    cancelNewFolder,
    moveToFolder,
    moveFolderLoading,
  };
};
