import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AppBadge, AppPagination, ErrorEmptyBlock, SortIcon } from 'components';
import { AppTable } from 'components/AppTable';
import { useMediaScreen } from 'hooks/useMediaScreen';
import React, { useMemo, useState } from 'react';
import { ExternalLink } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Invoice } from 'types/payment';
import { InvoiceList } from '../InvoiceList';
import { getInvoices } from 'apis/Payment/Invoice';
import moment from 'moment';
import { orderBy } from 'lodash';
import { formatCurrency } from 'utils';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export const InvoiceTable = observer(() => {
  const columnHelper = createColumnHelper<Invoice>();
  const [orderByAsc, setOrderByAsc] = useState<boolean>(false);
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  const { data, isLoading } = useQuery(['workspace', currentWorkspaceId, 'invoices'], async () => await getInvoices(), {
    cacheTime: 3000000,
    staleTime: 3000000,
  });

  const defaultColumns = [
    columnHelper.display({
      id: 'invoice-info',
      header: () => <span className="ms-3">Invoice</span>,
      meta: {
        className: 'w-40',
      },
      cell: ({ row }) => {
        const invoice = row.original;
        return (
          <div className="ms-3">
            <span className="gray-900 fw-semibold">{invoice.number} </span>
            <Link
              to={invoice.hostedInvoiceUrl}
              target="_blank"
            >
              <ExternalLink
                className="ms-2 cursor-pointer gray-500"
                size={20}
              />
            </Link>
          </div>
        );
      },
    }),

    columnHelper.display({
      id: 'createdAt',
      header: () => (
        <div className="d-flex gap-1">
          <div className="align-items-center">Billing Date</div>
          <div
            className="cursor-pointer"
            onClick={() => setOrderByAsc(!orderByAsc)}
          >
            <SortIcon />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const createdAt = moment(row.original.createdAt).format('DD MMM, YYYY');
        return <span className="gray-900">{createdAt}</span>;
      },
    }),

    columnHelper.accessor('total', {
      id: 'total',
      header: 'Amount',
      meta: {
        className: 'w-25',
      },
      cell: (info: CellContext<Invoice, string>) => {
        return <span className="gray-900">{formatCurrency(info.getValue())}</span>;
      },
    }),

    columnHelper.display({
      id: 'status',
      header: 'Status',
      cell: ({ row }) => {
        return (
          <AppBadge
            type={row.original.paid ? 'success' : 'error'}
            label={row.original.paid ? 'Paid' : 'Unpaid'}
          />
        );
      },
    }),
  ];

  const columns: any = useMemo<ColumnDef<Invoice>[]>(
    () =>
      isLoading
        ? defaultColumns.map(column => {
            return {
              ...column,
              cell: () => <Skeleton />,
            };
          })
        : defaultColumns,
    [isLoading, orderByAsc],
  );

  const defaultPageSize = useMediaScreen().isMdDown ? 10 : 5;

  const invoicesData = useMemo(() => {
    return isLoading ? Array(defaultPageSize).fill({}) : orderBy(data, 'createdAt', orderByAsc ? 'asc' : 'desc');
  }, [isLoading, data, orderByAsc]);

  const table = useReactTable({
    data: invoicesData,
    columns,
    initialState: {
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (!invoicesData?.length) return <ErrorEmptyBlock />;

  return (
    <div className="h-100 w-100 d-flex flex-column justify-content-between">
      <div
        className="d-none d-md-block"
        data-cy="invoices-table"
      >
        <AppTable table={table} />
      </div>

      <div
        className="d-block d-md-none"
        data-cy="invoices-list"
      >
        <InvoiceList
          isLoading={isLoading}
          data={table.getRowModel().rows.map(x => x.original)}
        />
      </div>

      <div className="mt-4">
        <AppPagination
          totalRecords={data?.length}
          pageCount={table.getPageCount()}
          currentPageIndex={table.getState().pagination.pageIndex}
          setPageIndex={index => table.setPageIndex(index)}
          canNextPage={table.getCanNextPage()}
          canPreviousPage={table.getCanPreviousPage()}
          onNextPage={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
          onPreviousPage={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
        />
      </div>
    </div>
  );
});
