import { getUsageRecord } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, WorkspaceUsageRecord } from 'types';
import { fetchServerError } from 'utils';

export const useGetWorkspaceUsageRecord = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  return useQuery<WorkspaceUsageRecord, ErrorResponse>(
    ['workspaces', currentWorkspaceId, 'usage-record'],
    () => getUsageRecord(),
    {
      cacheTime: 300000,
      staleTime: 300000,
      onError: error => {
        toast(fetchServerError(error), { type: 'error' });
      },
    },
  );
};
