import React from 'react';
import { findAll } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { ContentListLayout } from '../components/ContentListLayout';

export const AllContentsPage = () => {
  const { setCurrentPageCrumb, ltiMode, ltiStore } = useAppStore();
  const crumbData = {
    title: 'All Content',
    path: ltiMode ? `/${ltiStore.workspaceId}/lti/launch` : '/contents/all',
  };
  setCurrentPageCrumb(crumbData);

  return (
    <ContentListLayout
      pageTitle={'All Content'}
      queryKey={['folders', 'all']}
      findContents={findAll}
      showCreateContentButton
      crumbRootData={crumbData}
    />
  );
};
