import React, { useState } from 'react';
import './DeactivateStudentReport.scss';
import { X } from 'react-feather';
import { fetchServerError, formatCurrency, studentReportServiceLines } from 'utils';
import { Footer } from '../Footer';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { SubscriptionStatus } from '@spiderbox/common';
import { useQuery } from 'react-query';
import { Invoice } from 'types/payment';
import { ErrorResponse } from 'types';
import { getInvoiceDueToday } from 'apis/Payment/Invoice';
import { toast } from 'react-toastify';
import { Loading } from 'components/Loading';

type ModalProps = {
  onCancel?: () => void;
  onAccept?: (done: () => void) => void;
};

export const DeactivateStudentReport = observer(({ onCancel, onAccept }: ModalProps) => {
  const { data: invoice, isLoading: isInvoiceLoading } = useQuery<Invoice, ErrorResponse>(
    ['billing', 'upcoming-invoice', 'due-today'],
    () => getInvoiceDueToday(),
    {
      onError: (error: ErrorResponse) => {
        toast(fetchServerError(error), { type: 'error' });
      },
    },
  );
  const [isLoading, setIsLoading] = useState(false);

  const done = () => {
    setIsLoading(false);
  };

  const accept = () => {
    setIsLoading(true);
    onAccept(done);
  };

  const {
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  return (
    <div className="deactivate-student-report-modal modal-wrapper d-flex flex-column gap-3">
      <div className="header d-flex justify-content-between align-items-center">
        <div className="text-neutral-900 fw-semibold font-size-18">Deactivate Learner Report</div>
        <X
          onClick={() => onCancel?.()}
          className="close-icon me-0 gray-400 cursor-pointer d-block"
          size={24}
        />
      </div>

      <div className="d-flex flex-column gap-3 px-4">
        {currentSubscription.status === SubscriptionStatus.ACTIVE && (
          <>
            {isInvoiceLoading ? (
              <Loading />
            ) : (
              <div className="estimated-price-today">
                <div className="text-gray-500 fw-semibold font-size-20 d-flex justify-content-center">
                  Amount due today
                </div>
                <div className="text-neutral-900 fs-4xl fw-semibold d-flex justify-content-center">
                  {formatCurrency(invoice.total)}
                </div>
              </div>
            )}
            <div className="text-error-500 fw-semibold font-size-16">
              <div>
                New billing cycle that excludes Learner Report will be applied starting today -{' '}
                {moment(new Date()).format('DD/MM/YYYY')}.
              </div>
              <div>
                Please note that you will now be charged for the prorated fee of the previous plan which included access
                to the Learner Report.
              </div>
            </div>
          </>
        )}
        <div className="d-flex flex-column">
          <div className="text-neutral-900 font-size-16">
            Disabling Learner Report will hinder you to gain valuable insights into learner performance.
          </div>

          <div className="d-flex flex-column">
            {studentReportServiceLines.map((item, index) => {
              return (
                <div
                  className="report-line"
                  key={index}
                >
                  <div className="icon">
                    <X
                      className="text-error-500"
                      size={20}
                    />
                  </div>

                  <div className="text-neutral-900 font-size-16">{item}</div>
                </div>
              );
            })}
          </div>

          <div className="text-neutral-900 font-size-16 mt-3">
            *Reports are available for content items that offer scoring capabilities
          </div>
        </div>

        <Footer
          labelCancel="Cancel"
          labelOK={currentSubscription.status === SubscriptionStatus.TRIALING ? 'Yes, Deactivate' : 'Pay & Deactivate'}
          onOK={accept}
          onCancel={() => onCancel?.()}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
});
