import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { stringify, parse } from 'qs';

type Props = {
  filters: any;
};

export const useContentSearchParams = ({ filters }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(prevValue => {
      const preQueries = parse(prevValue.toString());
      return stringify({ ...preQueries, ...filters });
    });
  }, [filters]);

  const setSearch = (search: string) => {
    setSearchParams(prevValue => {
      const preQueries = parse(prevValue.toString());
      if (!search) {
        delete preQueries.search;
        return stringify({ ...preQueries });
      }
      return stringify({ ...preQueries, search: search.trim() });
    });
  };

  const search = searchParams.get('search');

  return { searchParams, search, setSearch };
};
