import { updateWorkspaceBillingInformation } from 'apis/Payment/Customer';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, WorkspaceInfo } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateWorkspaceBillingInformation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const appStore = useAppStore();
  const {
    workspaceStore: { currentWorkspaceId },
  } = appStore;

  return useMutation<WorkspaceInfo, ErrorResponse, WorkspaceInfo>({
    mutationFn: updateWorkspaceBillingInformation,
    retry: false,
    onSuccess: () => {
      toast('Your changes have been saved.', { type: 'success' });
      queryClient.invalidateQueries(['workspace', currentWorkspaceId, 'billing-info']);
      onSuccess?.();
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
