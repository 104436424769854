import useModal from 'hooks/useModal';
import { CreateFolderModalProps } from '../CreateFolder/CreateFolder';

export const useFolderModal = () => {
  const { openModal, closeModal } = useModal();

  const openNewFolderModal = (parentId?: string) => {
    openModal('new-folder', {
      defaultValue: 'Untitled folder',
      onCancel: () => closeModal(),
      parentId,
    } as CreateFolderModalProps);
  };

  return {
    openNewFolderModal,
  };
};
