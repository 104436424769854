import axios from 'axios';
import * as _ from 'lodash';
import { appStore } from '../stores/AppStore';
import { onResponse, onResponseError } from 'libs/interceptor';
import { v4 as generateUuid } from 'uuid';

const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('deviceId') || '';
  if (!deviceId.length) {
    deviceId = generateUuid();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE || '/api',
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Device-Id': getDeviceId(),
  },
  responseType: 'json',
});

axiosInstance.interceptors.request.use(function (config) {
  const store = appStore;

  if (store?.ltiStore.accessToken && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${store.ltiStore.accessToken}`;
  }

  const { workspaceId } = store?.ltiStore;
  if (workspaceId && !config.headers['x-workspace-id']) {
    config.headers['x-workspace-id'] = workspaceId;
  }

  return config;
});

axiosInstance.interceptors.request.use(function (config) {
  const store = appStore;

  if (store?.accessToken && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${store.accessToken}`;
  }

  const { currentWorkspaceId } = store?.workspaceStore;
  if (currentWorkspaceId && !config.headers['x-workspace-id']) {
    config.headers['x-workspace-id'] = currentWorkspaceId;
  }

  return config;
});

axiosInstance.interceptors.response.use(onResponse, onResponseError);

export const baseUrl: string | undefined = process.env.REACT_APP_API_BASE;

export default _.clone(axiosInstance);
