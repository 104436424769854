import React, { useEffect, useRef } from 'react';
import './RefineModal.scss';
import { useForm } from 'react-hook-form';
import { AIMessageActionType, AIMessageContentType, AIMessageType } from 'types';
import { addMessageToChatHistory } from 'hooks/ai-chat';
import { useQueryClient } from 'react-query';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { countWords } from 'components/AIOffset/utils';
import { REFINE_CONTEXT_BADGE, SELECT_CONTENT_BADGE } from 'constants/modal-suggestions';
import ModalSuggestions from '../ModalSuggestions/ModalSuggestions';

type Props = {
  label: string;
  onClose: () => void;
  currentMessageContentType: AIMessageContentType;
  chatId: string;
  scrollBottom: () => void;
  onSubmit: ({ chatId, content, actionType }) => void;
  isLoading: boolean;
};

const LIMIT_WORD = 300;

const RefineModal = ({
  label,
  onClose,
  currentMessageContentType,
  chatId,
  scrollBottom,
  onSubmit,
  isLoading,
}: Props) => {
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const badgeLabels =
    currentMessageContentType === AIMessageContentType.Context ? REFINE_CONTEXT_BADGE : SELECT_CONTENT_BADGE;

  const { register, watch, handleSubmit, setValue } = useForm<{ content: string }>({
    defaultValues: {
      content: '',
    },
  });

  const handleActionType = (messageContentType: AIMessageContentType) => {
    if (messageContentType === AIMessageContentType.Context) {
      return AIMessageActionType.RefineContext;
    }

    return AIMessageActionType.RefineContent;
  };

  const refine = async (data: { content: string }) => {
    const actionType = handleActionType(currentMessageContentType);

    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        content: data.content,
        messageType: AIMessageType.Human,
        actionType,
        messageContentType: currentMessageContentType,
      },
    });

    onSubmit({ chatId, content: data.content, actionType });
    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const words = value.trim().split(/\s+/);

    if (words.length <= LIMIT_WORD) {
      setValue('content', value);
    } else {
      const truncatedValue = words.slice(0, LIMIT_WORD).join(' ');
      setValue('content', truncatedValue);
      e.target.value = truncatedValue;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('content');
    const combinedText = currentContent + (currentContent ? ' ' : '') + pastedText;
    const words = combinedText.trim().split(/\s+/);
    const truncatedValue = words.slice(0, LIMIT_WORD).join(' ');

    setValue('content', truncatedValue);
    (e.target as HTMLTextAreaElement).value = truncatedValue;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const content = watch('content');
    const words = content?.trim().split(/\s+/);
    if (words?.length > LIMIT_WORD && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const handleBadgeSelect = (label: string) => {
    const currentValue = watch('content') || '';
    const newValue = currentValue ? `${currentValue} ${label}` : label;

    if (countWords(newValue) <= LIMIT_WORD) {
      setValue('content', newValue);
      if (textareaRef.current) {
        textareaRef.current.value = newValue;
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isLoading) scrollBottom();
  }, [isLoading]);

  return (
    <div className="d-flex flex-column w-100 align-items-end">
      <div className="refine-modal-wrapper w-100">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="font-size-14 fw-medium text-neutral-900">{label}</div>
          <div className="font-size-12 fw-normal text-neutral-500">
            {countWords(watch('content'))}/{LIMIT_WORD}
          </div>
        </div>
        <form
          onSubmit={handleSubmit(refine)}
          className="refine-modal-section d-flex flex-column"
        >
          <div className="d-flex flex-fill align-items-center area-wrapper">
            <textarea
              {...register('content')}
              ref={textareaRef}
              className="area-input d-flex align-items-center flex-fill"
              placeholder="Need further tweaks? Simply ask Michi for assistance..."
              rows={2}
              onChange={handleTextareaChange}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
            />
          </div>

          <ModalSuggestions
            badges={badgeLabels}
            onBadgeSelect={handleBadgeSelect}
            textareaRef={textareaRef}
          />
          <div className="divider bg-neutral-200"></div>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="back-btn font-size-14 fw-medium cursor-pointer"
              onClick={onClose}
            >
              Back
            </div>
            <button
              disabled={!watch('content')}
              className="btn btn-primary submit-btn text-white rounded-circle d-flex justify-content-center align-items-center"
            >
              <ArrowRightIcon
                className="text-white"
                width={16}
                height={16}
                strokeWidth={2}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefineModal;
