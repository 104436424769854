export enum MachineName {
  H5P_Accordion = 'H5P.Accordion',
  H5P_ArithmeticQuiz = 'H5P.ArithmeticQuiz',
  H5P_Chart = 'H5P.Chart',
  H5P_Collage = 'H5P.Collage',
  H5P_Column = 'H5P.Column',
  H5P_Crossword = 'H5P.Crossword',
  H5P_DocumentationTool = 'H5P.DocumentationTool',
  H5P_GuessTheAnswer = 'H5P.GuessTheAnswer',
  H5P_ImageHotspots = 'H5P.ImageHotspots',
  H5P_ImageSlider = 'H5P.ImageSlider',
  H5P_InteractiveBook = 'H5P.InteractiveBook',
  H5P_MemoryGame = 'H5P.MemoryGame',
  H5P_SortParagraphs = 'H5P.SortParagraphs',
  H5P_MultiChoice = 'H5P.MultiChoice',
  H5P_MultiMediaChoice = 'H5P.MultiMediaChoice',
  H5P_SingleChoiceSet = 'H5P.SingleChoiceSet',
  H5P_Summary = 'H5P.Summary',
  H5P_TrueFalse = 'H5P.TrueFalse',
  H5P_Blanks = 'H5P.Blanks',
  H5P_DragQuestion = 'H5P.DragQuestion',
  H5P_Audio = 'H5P.Audio',
  H5P_InteractiveVideo = 'H5P.InteractiveVideo',
  H5P_MarkTheWords = 'H5P.MarkTheWords',
  H5P_DragText = 'H5P.DragText',
  H5P_CoursePresentation = 'H5P.CoursePresentation',
  H5P_Dialogcards = 'H5P.Dialogcards',
  H5P_QuestionSet = 'H5P.QuestionSet',
  H5P_TwitterUserFeed = 'H5P.TwitterUserFeed',
  H5P_Questionnaire = 'H5P.Questionnaire',
  H5P_AudioRecorder = 'H5P.AudioRecorder',
  H5P_StructureStrip = 'H5P.StructureStrip',
  H5P_ThreeImage = 'H5P.ThreeImage',
  H5P_ARScavenger = 'H5P.ARScavenger',
  H5P_BranchingScenario = 'H5P.BranchingScenario',
  H5P_Cornell = 'H5P.Cornell',
  H5P_Dictation = 'H5P.Dictation',
  H5P_Flashcards = 'H5P.Flashcards',
  H5P_GoalsPage = 'H5P.GoalsPage',
}
