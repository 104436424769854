import { AppBadge } from 'components';
import React from 'react';
import { ExternalLink } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Invoice } from 'types/payment';
import './InvoiceList.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatCurrency } from 'utils';

export const InvoiceList = ({ data, isLoading }: { data: Invoice[]; isLoading: boolean }) => {
  return (
    <div className="bg-white">
      {data?.map((invoice, key) => {
        return (
          <div
            className="invoice-list--item d-flex flex-column bg-white"
            key={key}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <div className="d-flex align-items-center">
                    <span
                      className="gray-900 fw-semibold"
                      data-cy="invoice-number"
                    >
                      {invoice.number}
                    </span>
                    <Link to={invoice.hostedInvoiceUrl}>
                      <ExternalLink
                        className="ms-2 cursor-pointer gray-500"
                        size={20}
                      />
                    </Link>
                  </div>
                )}

                {isLoading ? (
                  <Skeleton />
                ) : (
                  <div
                    className="mt-1 gray-900"
                    data-cy="invoice-created-date"
                  >
                    {moment(invoice.createdAt).format('DD MMM, YYYY')}
                  </div>
                )}
              </div>

              <div
                className="me-0"
                data-cy="invoice-total"
              >
                {formatCurrency(invoice.total)}
              </div>
            </div>

            <div className="mt-3 d-flex">
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <AppBadge
                    type={invoice.paid ? 'success' : 'error'}
                    label={invoice.paid ? 'Paid' : 'Unpaid'}
                    dataCy={`invoice-paid-${key}`}
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
