import React, { useMemo, useState } from 'react';
import './FolderSettings.scss';
import { ChevronDown, ChevronRight, FolderPlus } from 'react-feather';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'reactstrap';
import { FolderView } from 'components/ModalManager/FolderView';
import { useMoveFolder } from 'hooks/folder/useMoveFolder';
import { useAppStore } from 'hooks/useAppStore';
import { useGetBreadcrumb } from 'hooks';
import { AppBreadCrumb } from 'components/AppBreadCrumb';
import { useSearchParams } from 'react-router-dom';

type Props = {
  contentId: string;
  isCreateNew?: boolean;
};

export const FolderSettings = observer(({ contentId }: Props) => {
  const [displaySettingCollapse, toggleDisplaySettingCollapse] = useState(true);
  const { folderStore, contentStore } = useAppStore();
  const { folderSelected, isFolderCreating } = folderStore;
  const { addNewFolder } = useMoveFolder(folderStore);
  const crumbData = { title: 'My Content', path: '/contents/mine' };
  const [searchParams] = useSearchParams();
  const folderId = searchParams.get('folderId');

  const { crumbs } = useGetBreadcrumb({
    folderId: folderId,
    root: crumbData,
    isSearch: false,
    showMyContent: true,
  });

  const customCrumbs = useMemo(() => {
    if (crumbs?.length < 1) {
      return [];
    }

    return crumbs.map(crumb => {
      return {
        ...crumb,
        unclickable: true,
      };
    });
  }, [crumbs, folderId, contentId]);

  return (
    <div className="folder-settings bg-white d-flex flex-column gap-4">
      <div className="row folder-settings--item">
        <div className="col-5 d-flex">
          {displaySettingCollapse ? (
            <ChevronRight
              size={24}
              className="gray-900 me-2 cursor-pointer"
              onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
            />
          ) : (
            <ChevronDown
              size={24}
              className="gray-900 me-2 cursor-pointer"
              onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
            />
          )}

          <div className="d-flex flex-column">
            <div className="gray-900 font-size-16 fw-semibold">
              <span
                className="cursor-pointer"
                onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
              >
                Folders
              </span>
            </div>

            <Collapse
              isOpen={!displaySettingCollapse}
              data-cy="description"
            >
              <div className="gray-600 mt-2">Choose a folder for this content.</div>
            </Collapse>
          </div>
        </div>

        <div className="col-7">
          <div className="d-flex flex-column mb-2">
            <label className="gray-900 font-size-12 mb-0">Current folder</label>
            <AppBreadCrumb crumbs={customCrumbs} />
          </div>
          <Collapse isOpen={!displaySettingCollapse}>
            <div
              className="d-flex flex-column gap-3 w-75"
              data-cy="folder-view"
            >
              <FolderView
                folderStore={folderStore}
                onSelectFolder={folder => contentStore.setFolderId(folder?.id)}
                selectedFolderId={contentStore.folderId || 'root'}
              />
              <div className="d-flex flex-fill align-items-center justify-content-between">
                <button
                  disabled={isFolderCreating || !folderSelected?.id}
                  className="d-flex btn align-items-center justify-content-center move-folder-wrapper__icon p-2 border rounded-circle"
                  data-cy="add-new-icon"
                  onClick={() => addNewFolder()}
                >
                  <FolderPlus
                    size={20}
                    className="primary-300"
                    strokeWidth={1.5}
                  />
                </button>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
});
