import { useContentEditor } from 'hooks/content/useContentEditor';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Globe } from 'react-feather';
import { ContentActionStatus } from 'types';

export const SaveAndInsertButton = observer(() => {
  const { contentStore } = useAppStore();
  const { isSaving } = contentStore;
  const { saveAsync } = useContentEditor();

  return (
    <button
      className="btn btn-primary d-flex justify-center"
      data-cy="insert-button"
      onClick={() => saveAsync(ContentActionStatus.SAVE_AND_INSERT)}
      disabled={isSaving}
    >
      <Globe size={20} />
      <span className="ms-2">Save & Insert</span>
    </button>
  );
});
