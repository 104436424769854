import { analyzeContent } from 'apis/AiChat/AiChat';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AnalyzeContentInputForm } from 'types';

export const useAnalyzeContent = () => {
  const queryKeyClient = useQueryClient();
  return useMutation(
    ({ chatId, payload }: { chatId: string; payload: AnalyzeContentInputForm }) => analyzeContent(chatId, payload),
    {
      onSuccess: () => {
        queryKeyClient.invalidateQueries(['chat-history']);
      },
      onError: () => {
        toast("Oops! We couldn't process your input. Please try making it shorter or simpler.", { type: 'error' });
      },
    },
  );
};
