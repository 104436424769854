import { makeAutoObservable } from 'mobx';

export class LtiStore {
  accessToken: string;
  refreshToken: string;
  workspaceId: string;
  ltiKey: string;
  contextId: any;

  constructor() {
    makeAutoObservable(this);
  }

  setToken({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  setContext(contextId: string) {
    this.contextId = contextId;
  }

  setWorkspaceId(value: string) {
    this.workspaceId = value;
  }

  setLtiKey(value: string) {
    this.ltiKey = value;
  }
}
