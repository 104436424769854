import { ContentAttemptSetting, LtiSettingOptions } from '@spiderbox/common';
import { useAppStore } from 'hooks/useAppStore';
import { ContentLtiSettingInput } from 'pages/Content/components/ContentSettings/LtiSettings';

export const useGetContentDefaultLtiSetting = () => {
  const { workspaceStore } = useAppStore();
  const { setting } = workspaceStore;

  const { ltiSetting } = setting || {};

  const { ltiContentMaxWidthDefault, ltiSendScoreToLms, ltiSendScoreFor, ltiUserCanResumeContent } = ltiSetting || {};

  let ltiSendScoreOnSetting: string = ltiSendScoreToLms;

  if (ltiSendScoreToLms === LtiSettingOptions.PER_CONTENT_ON_DEFAULT) {
    ltiSendScoreOnSetting = ltiSendScoreFor;
  }

  if (ltiSendScoreToLms === LtiSettingOptions.PER_CONTENT_OFF_DEFAULT) {
    ltiSendScoreOnSetting = ContentAttemptSetting.DO_NOT_SEND;
  }

  const defaultSetting: ContentLtiSettingInput = {
    ltiMaxWidth: ltiContentMaxWidthDefault,
    ltiSendScoreOn: ltiSendScoreOnSetting,
    ltiCanResume: [LtiSettingOptions.ALWAYS, LtiSettingOptions.PER_CONTENT_ON_DEFAULT].includes(
      ltiUserCanResumeContent,
    ),
  };

  return defaultSetting;
};
