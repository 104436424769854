import { ContentSetting } from '@spiderbox/common';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Collapse } from 'reactstrap';
import { ContentBehaviorOptions } from 'types/setting';

type Props = {
  defaultValues?: ContentSetting;
};

export const DisplaySetting = observer(({ defaultValues }: Props) => {
  const { control, watch } = useForm<ContentSetting>({
    defaultValues: defaultValues,
  });

  const [displaySettingCollapse, toggleDisplaySettingCollapse] = useState(false);
  const {
    workspaceStore: {
      workspaceSetting: { contentBehavior },
    },
    contentStore,
  } = useAppStore();

  watch(values => {
    contentStore.setSettings(values);
  });

  const displayToolbarBelowSetting = watch?.('showToolbarBelow');

  const shouldDisplayAllowUsersDownloadSetting = useMemo(() => {
    if (!contentBehavior) return true;

    return [
      ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
      ContentBehaviorOptions.CONTROLLED_BY_EDITOR_OFF_DEFAULT,
    ].includes(contentBehavior?.contentBehaviorUsersCanDownload);
  }, [contentBehavior]);

  const shouldDisplayEmbedButtonSetting = useMemo(() => {
    if (!contentBehavior) return true;

    return [
      ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
      ContentBehaviorOptions.CONTROLLED_BY_EDITOR_OFF_DEFAULT,
    ].includes(contentBehavior?.contentBehaviorDisplayEmbedButton);
  }, [contentBehavior]);

  return (
    <div className="row">
      <div className="col-5 d-flex">
        {displaySettingCollapse ? (
          <ChevronRight
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
          />
        ) : (
          <ChevronDown
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
          />
        )}

        <div className="d-flex flex-column">
          <div className="gray-900 font-size-16 fw-semibold">
            <span
              className="cursor-pointer"
              onClick={() => toggleDisplaySettingCollapse(!displaySettingCollapse)}
            >
              Display
            </span>
          </div>

          <Collapse isOpen={!displaySettingCollapse}>
            <div className="gray-600 mt-2">Choose which function others will see below the content.</div>
          </Collapse>
        </div>
      </div>

      <Collapse
        className="col-7 cursor-pointer"
        isOpen={!displaySettingCollapse}
      >
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center">
            <div
              className="form-check form-switch form-switch-lg"
              onClick={event => event.stopPropagation()}
            >
              <Controller
                control={control}
                name="showToolbarBelow"
                render={({ field }) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="custom-switch form-check-input cursor-pointer"
                    checked={field.value}
                    onChange={field.onChange}
                    value={''}
                    data-cy="show-toolbar-setting"
                  />
                )}
              />
            </div>

            <div className="gray-900 h5 font-size-14 mb-0 ms-3">Toolbar below content</div>
          </div>

          <Collapse isOpen={displayToolbarBelowSetting}>
            <div className="ms-2 d-flex flex-column gap-3">
              {shouldDisplayAllowUsersDownloadSetting && (
                <Controller
                  control={control}
                  name="showDownloadButton"
                  render={({ field }) => (
                    <label
                      className="d-flex align-items-center cursor-pointer form-check checkbox-lg gray-500 mb-0"
                      onClick={event => event.stopPropagation()}
                    >
                      <input
                        {...field}
                        type="checkbox"
                        value={'true'}
                        checked={field.value}
                        onChange={field.onChange}
                        className="form-check-input cursor-pointer primary-500"
                        data-cy="show-download-btn-setting"
                      />
                      <div className="gray-900 h5 font-size-14 mt-1 mb-0 ms-3">Allow users to download the content</div>
                    </label>
                  )}
                />
              )}

              <Controller
                control={control}
                name="showCopyButton"
                render={({ field }) => (
                  <label
                    className="d-flex align-items-center cursor-pointer form-check checkbox-lg gray-500 mb-0"
                    onClick={event => event.stopPropagation()}
                  >
                    <input
                      {...field}
                      type="checkbox"
                      value={'true'}
                      checked={field.value}
                      onChange={field.onChange}
                      className="form-check-input cursor-pointer primary-500"
                      data-cy="show-copy-btn-setting"
                    />
                    <div className="gray-900 h5 font-size-14 mt-1 mb-0 ms-3">Allow to copy content</div>
                  </label>
                )}
              />

              {shouldDisplayEmbedButtonSetting && (
                <Controller
                  control={control}
                  name="showEmbedButton"
                  render={({ field }) => (
                    <label
                      className="d-flex align-items-center cursor-pointer form-check checkbox-lg gray-500 mb-0"
                      onClick={event => event.stopPropagation()}
                    >
                      <input
                        {...field}
                        type="checkbox"
                        value={'true'}
                        checked={field.value}
                        onChange={field.onChange}
                        className="form-check-input cursor-pointer primary-500"
                        data-cy="show-embed-btn-setting"
                      />
                      <div className="gray-900 h5 font-size-14 mt-1 mb-0 ms-3">Embed URL button</div>
                    </label>
                  )}
                />
              )}
            </div>
          </Collapse>
        </div>
      </Collapse>
    </div>
  );
});
