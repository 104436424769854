import { yupResolver } from '@hookform/resolvers/yup';
import TextField from 'components/AppForm/TextField';
import useModal from 'hooks/useModal';
import { useCreateWorkspace } from 'hooks/workspace';
import React from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Footer } from '../Footer';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'hooks/useAppStore';

interface Props {
  onCancel?: () => void;
}

const schemaNewWorkspace = yup.object().shape({
  name: yup.string().trim().required('Workspace name is required!'),
});

export const ModalCreateWorkspace: React.FC<Props> = ({ onCancel }) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    subscriptionStore: { updateCurrentSubscription },
  } = useAppStore();

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaNewWorkspace),
    mode: 'onChange',
  });

  const { isLoading, mutate } = useCreateWorkspace(() => {
    queryClient.invalidateQueries();
    closeModal();
    updateCurrentSubscription(null);
    navigate('/subscription-plans', { replace: true });
  });

  const onSubmit = handleSubmit((data: { name: string }) => {
    if (errors?.name) return;
    mutate(data.name);
  });

  return (
    <div
      className="d-flex flex-column p-3 contents-filter-modal modal-wrapper"
      data-cy="modal-create-workspace"
    >
      <ModalBody className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div
            className="gray-900 fw-semibold font-size-18"
            data-cy="header-title"
          >
            New workspace
          </div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon me-0 gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="form-group mt-3">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="Workspace name"
                label="Workspace name"
                error={error}
                dataCy="workspace-name"
                isRequired={true}
              />
            )}
          />

          <p
            className="feedback-invalid mb-0"
            data-cy="name-invalid"
          >
            {errors?.name && errors?.name?.message}
          </p>
        </div>
      </ModalBody>

      <Footer
        labelOK="Create workspace"
        onOK={() => {
          onSubmit();
        }}
        isLoading={isLoading}
        labelCancel="Cancel"
        onCancel={() => {
          clearErrors();
          onCancel?.();
        }}
      />
    </div>
  );
};
