import apiClient from 'apis/ApiClient';
import { Invoice } from 'types/payment';

const endPoint = '/payment/invoices';

export async function getInvoices(): Promise<Invoice[]> {
  const response = await apiClient.get(`${endPoint}`);

  return response.data;
}

export async function getInvoiceDueToday(): Promise<Invoice> {
  const response = await apiClient.get(`${endPoint}/due-today`);

  return response.data;
}
