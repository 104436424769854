import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';
import { Document } from 'models/Content';
import { addStar, removeStar } from 'apis/Folder/Folder';

export const useAddOrRemoveStarContent = () => {
  const { queryKey } = useAppStore();
  const queryClient = useQueryClient();

  const addOrRemoveStarContent = async (content: Document) => {
    if (content?.trashedAt) {
      return;
    }

    if (content?.starred) {
      return await removeStar(content.id);
    } else {
      return await addStar(content.id);
    }
  };

  return useMutation(addOrRemoveStarContent, {
    retry: false,
    onSuccess: (_, contentRequest) => {
      if (contentRequest?.trashedAt) {
        return;
      }
      queryClient.setQueryData<Document[]>(queryKey, previousData => {
        return previousData?.map(doc => {
          if (doc.id === contentRequest.id) {
            doc.starred = !contentRequest?.starred;
          }
          return doc;
        });
      });
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
