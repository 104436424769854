import React from 'react';

export const XCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM17.414 14.586C17.0368 14.2217 16.5316 14.0201 16.0072 14.0247C15.4828 14.0292 14.9812 14.2395 14.6104 14.6104C14.2395 14.9812 14.0292 15.4828 14.0247 16.0072C14.0201 16.5316 14.2217 17.0368 14.586 17.414L17.172 20L14.586 22.586C14.395 22.7705 14.2426 22.9912 14.1378 23.2352C14.033 23.4792 13.9778 23.7416 13.9755 24.0072C13.9732 24.2728 14.0238 24.5361 14.1244 24.7819C14.2249 25.0277 14.3734 25.251 14.5612 25.4388C14.749 25.6266 14.9723 25.7751 15.2181 25.8756C15.4639 25.9762 15.7272 26.0268 15.9928 26.0245C16.2584 26.0222 16.5208 25.967 16.7648 25.8622C17.0088 25.7574 17.2295 25.605 17.414 25.414L20 22.828L22.586 25.414C22.9632 25.7783 23.4684 25.9799 23.9928 25.9753C24.5172 25.9708 25.0188 25.7605 25.3896 25.3896C25.7605 25.0188 25.9708 24.5172 25.9753 23.9928C25.9799 23.4684 25.7783 22.9632 25.414 22.586L22.828 20L25.414 17.414C25.7783 17.0368 25.9799 16.5316 25.9753 16.0072C25.9708 15.4828 25.7605 14.9812 25.3896 14.6104C25.0188 14.2395 24.5172 14.0292 23.9928 14.0247C23.4684 14.0201 22.9632 14.2217 22.586 14.586L20 17.172L17.414 14.586Z"
        fill="#CBD5E1"
      />
    </svg>
  );
};
