import React from 'react';
import { Button, ModalFooter } from 'reactstrap';

type Props = {
  labelCancel?: string;
  labelOK?: string;
  onOK?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  disabledOK?: boolean;
  disabledCancel?: boolean;
};

export const Footer = ({ labelCancel, labelOK, onOK, onCancel, isLoading, disabledOK, disabledCancel }: Props) => {
  return (
    <ModalFooter
      className="d-flex align-items-center justify-content-end border-0 p-0"
      data-cy="modal-footer"
    >
      <Button
        data-bs-dismiss="modal"
        className="me-2"
        outline
        color="primary"
        onClick={onCancel}
        data-cy="btn-cancel"
        type="button"
        disabled={disabledCancel}
      >
        {labelCancel}
      </Button>
      <Button
        className="btn-ok"
        color="primary"
        onClick={onOK}
        data-cy="btn-ok"
        disabled={isLoading || disabledOK}
        type="button"
      >
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {labelOK}
      </Button>
    </ModalFooter>
  );
};
