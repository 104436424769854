import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isVariant?: boolean;
}

const IconLogo: React.FC<Props> = ({ isVariant = false, ...props }) => (
  <div
    className="w-fit h-fit"
    data-cy="icon-logo"
  >
    {isVariant ? (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.2379 35.9079C13.1145 37.909 8.49114 36.5217 5.88245 34.0153C8.90035 37.0332 13.9336 39.8465 19.9489 39.8465C26.1894 39.8465 30.8952 37.0332 32.7367 35.1918C32.7367 35.1918 30.2814 34.5268 28.0819 33.4527C25.9282 32.4008 24.7553 33.9485 24.7075 34.0133C24.7531 33.9504 25.7994 32.4749 24.3479 31.3043C22.8645 30.1081 22.5065 29.3094 22.5065 29.3094C22.1484 30.9974 20.7162 34.2199 17.2379 35.9079Z"
          fill="#94A3B8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0946 16.9819C4.14322 16.9819 -0.306905 27.7073 5.89938 33.9136C-1.5175 26.4967 -1.41521 15.8573 3.29069 9.10543C4.57467 7.2632 4.70588 6.13788 3.98977 5.21717C3.35925 4.4065 2.41411 4.10314 1.60199 3.84247C0.723106 3.56038 0 3.32829 0 2.55732C-2.31461e-06 0.357812 12.6343 -0.000244141 19.9489 -0.000244141C27.9239 -0.000244141 35.9079 5.21717 35.9079 13.5036C35.9079 18.3118 31.6624 20.6136 28.9003 20.6136C26.9686 20.6136 24.9765 19.8968 22.7622 19.1001C19.9925 18.1035 16.8753 16.9819 13.0946 16.9819ZM11.202 11.202C12.3273 8.23523 15.1917 5.16617 20.2045 5.16617C24.0408 5.16617 26.7518 7.41681 26.7518 9.7186C26.7518 11.0485 25.2173 12.8388 23.6316 12.8388C22.4611 12.8388 21.4766 12.3504 20.3919 11.8123C18.337 10.7928 15.9225 9.59497 11.202 11.202Z"
          fill="#F1F5F9"
        />
      </svg>
    ) : (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.2383 35.9079C13.1149 37.909 8.49151 36.5217 5.88281 34.0153C8.90072 37.0332 13.934 39.8465 19.9493 39.8465C26.1897 39.8465 30.8956 37.0332 32.737 35.1918C32.737 35.1918 30.2818 34.5268 28.0823 33.4527C25.9285 32.4008 24.7557 33.9485 24.7079 34.0133C24.7535 33.9504 25.7998 32.4749 24.3483 31.3043C22.8649 30.1081 22.5069 29.3094 22.5069 29.3094C22.1488 30.9974 20.7166 34.2199 17.2383 35.9079Z"
          fill="#173EAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0946 16.9819C4.14322 16.9819 -0.306905 27.7074 5.89938 33.9137C-1.5175 26.4968 -1.41521 15.8574 3.29069 9.10549C4.57467 7.26326 4.70588 6.13794 3.98977 5.21723C3.35925 4.40656 2.41411 4.1032 1.60199 3.84254C0.723106 3.56044 0 3.32835 0 2.55738C-2.31461e-06 0.357873 12.6343 -0.000183105 19.9489 -0.000183105C27.9239 -0.000183105 35.9079 5.21723 35.9079 13.5037C35.9079 18.3119 31.6624 20.6136 28.9003 20.6136C26.9686 20.6136 24.9765 19.8968 22.7622 19.1001C19.9925 18.1035 16.8753 16.9819 13.0946 16.9819ZM11.202 11.202C12.3273 8.23529 15.1917 5.16624 20.2045 5.16624C24.0408 5.16624 26.7518 7.41688 26.7518 9.71867C26.7518 11.0486 25.2173 12.8389 23.6316 12.8389C22.4611 12.8389 21.4766 12.3505 20.3919 11.8124C18.337 10.7929 15.9225 9.59503 11.202 11.202Z"
          fill="#6183E4"
        />
      </svg>
    )}
  </div>
);

export default IconLogo;
