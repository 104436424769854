import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar, ChevronLeft, ChevronRight, X } from 'react-feather';
import './DateRangePicker.scss';
import classNames from 'classnames';

type DateRangePickerProps = {
  fromDate?: Date;
  toDate?: Date;
  onDatesChange: (startDate: number, endDate: number) => void;
  dataCy?: string;
  type?: 'button' | 'input';
};

export const DateRangePicker = ({
  fromDate,
  toDate,
  onDatesChange,
  dataCy = '',
  type = 'button',
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    onDatesChange(null, null);
  };

  // eslint-disable-next-line react/display-name
  const FilterDateButton: React.FC = forwardRef<any, any>(({ value, onClick }, ref) => (
    <button
      className="btn btn-outline-primary d-flex align-items-center"
      onClick={onClick}
      ref={ref}
    >
      <Calendar
        size={20}
        className="primary-500 me-2"
      />

      <span>{value || 'All time'}</span>

      {startDate && endDate && (
        <X
          size={20}
          className="primary-200 ms-2"
          onClick={event => {
            event.stopPropagation();
            clearDates();
          }}
        />
      )}
    </button>
  ));

  // eslint-disable-next-line react/display-name
  const FilterDateControl: React.FC = forwardRef<any, any>(({ value, onClick }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center border border-neutral-200 rounded-3 shadow cursor-pointer"
      style={{ padding: '8px 12px' }}
      onClick={onClick}
      ref={ref}
    >
      <div className="d-flex align-items-center">
        <Calendar
          size={20}
          className="me-2 text-neutral-500"
        />

        <span className={classNames('fw-medium font-size-14', value ? 'gray-900' : 'text-neutral-500')}>
          {value || 'From date - to date'}
        </span>
      </div>

      {startDate && endDate && (
        <X
          size={20}
          className="text-neutral-600 me-0"
          onClick={event => {
            event.stopPropagation();
            clearDates();
          }}
        />
      )}
    </div>
  ));

  const onChange = (dates: Date[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formatStartDate = moment(start).startOf('day').unix();
      const formatEndDate = moment(end).endOf('day').unix();

      onDatesChange(formatStartDate, formatEndDate);
    }
  };

  return (
    <div
      className="custom-date-picker"
      data-cy={dataCy}
    >
      <DatePicker
        dateFormat="dd/MM/YYYY"
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        selectsRange
        useWeekdaysShort
        popperPlacement="bottom-start"
        customInput={type === 'button' ? <FilterDateButton /> : <FilterDateControl />}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div className="d-flex justify-content-between align-items-center bg-white p-2">
            <ChevronLeft
              strokeWidth={1.5}
              size={20}
              className="gray-900 cursor-pointer"
              onClick={decreaseMonth}
            />
            <span className="font-size-14 gray-900 fw-semibold">{moment(monthDate).format('MMM YYYY')}</span>
            <ChevronRight
              strokeWidth={1.5}
              size={20}
              className="gray-900 cursor-pointer"
              onClick={increaseMonth}
            />
          </div>
        )}
      />
    </div>
  );
};
