import { uploadMedia } from 'apis/Media/Media';
import { Loading } from 'components/Loading';
import { Media } from 'models/Media';
import React, { useEffect, useState } from 'react';
import { UploadCloud } from 'react-feather';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useMutation } from 'react-query';
import { ErrorResponse } from 'types';
import './DragDropUpload.scss';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import classNames from 'classnames';

type IProps = {
  imageUrl?: string;
  onUploadSuccess: (data: Media) => void;
  onUploadError?: (error: ErrorResponse) => void;
  userFullName: string;
  setUploadLoading?: (isLoading: boolean) => void;
  isPublic: boolean;
  hasEditPermission?: boolean;
};

export const DragDropUpload = ({
  imageUrl,
  onUploadSuccess,
  onUploadError,
  userFullName,
  setUploadLoading,
  isPublic,
  hasEditPermission = true,
}: IProps) => {
  const [imageList, setImageList] = useState<ImageListType>([
    {
      dataURL: imageUrl,
    },
  ]);

  const { mutate: uploadMutate, isLoading: isUploadingProfileImage } = useMutation<Media, ErrorResponse, FormData>({
    mutationFn: uploadMedia,
    retry: false,
    onSuccess: data => {
      onUploadSuccess(data);
      setImageList([{ dataURL: data.url }]);
    },
    onError: (error: ErrorResponse) => {
      onUploadError?.(error);
    },
  });

  const uploadImage = (value: ImageListType) => {
    const formData = new FormData();
    const file = value[0].file;
    formData.append('file', file);
    formData.append('isPublic', isPublic.toString());

    uploadMutate(formData);
  };

  useEffect(() => {
    setUploadLoading?.(isUploadingProfileImage);
  }, [isUploadingProfileImage]);

  return (
    <ImageUploading
      value={imageList}
      onChange={uploadImage}
      acceptType={['svg', 'png', 'jpg']}
    >
      {({ imageList, dragProps, onImageUpload, errors }) => (
        <div
          className="col-12 col-xl-6 col-md-8 my-md-0 form-group drag-drop-upload cursor-default"
          onClick={e => {
            if (!hasEditPermission) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <div className="d-flex flex-column flex-md-row align-items-center gap-4">
            <AvatarProfile
              avatarClassNames="fw-md avatar-settings"
              width={80}
              height={80}
              imgSrc={imageList[0]?.dataURL}
              userFullName={userFullName}
            />

            <div
              className={classNames(
                'drag-drop-zone d-flex flex-fill flex-column align-items-center gap-2 p-3 rounded-start rounded-bottom',
                { 'cursor-pointer': hasEditPermission },
                { 'cursor-default': !hasEditPermission },
              )}
              data-cy="drag-drop-zone"
              {...dragProps}
              onClick={() => {
                if (!hasEditPermission) return;
                onImageUpload();
              }}
            >
              <div className="drag-drop-icon d-flex justify-content-center align-items-center rounded-circle p-2 gap-1">
                {isUploadingProfileImage ? (
                  <Loading />
                ) : (
                  <UploadCloud
                    size={32}
                    className="primary-500"
                  />
                )}
              </div>

              <div className="text-center drag-drop-describe">
                <span className="fw-bold">Click to upload</span> or drag and drop
              </div>
              <div className="text-center drag-drop-describe">SVG, PNG or JPG (max. 400x400px)</div>
            </div>
          </div>
          {errors?.acceptType && (
            <p className="feedback-invalid mb-0">
              The image file is invalid or the image type is not allowed. Allow types: SVG, PNG or JPG (max. 400x400px)
            </p>
          )}
        </div>
      )}
    </ImageUploading>
  );
};
