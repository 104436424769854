import React from 'react';
import { findSharedWithMe } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { ContentListLayout } from '../components/ContentListLayout';

export const ShareWithMePage = () => {
  const { setCurrentPageCrumb } = useAppStore();
  const crumbData = { title: 'Shared with me', path: '/contents/shared' };
  setCurrentPageCrumb(crumbData);

  return (
    <ContentListLayout
      pageTitle="Shared with me"
      queryKey={['folders', 'shared']}
      findContents={findSharedWithMe}
      crumbRootData={crumbData}
    />
  );
};
