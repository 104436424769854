import React from 'react';
import { ContentRole, hasContentPermission, hasContentPermissionByRole } from '@spiderbox/common';

type Props = {
  permissions?: string[];
  role?: ContentRole;
  action: string;
  children: JSX.Element;
};
export const ContentPermission = ({ permissions, role, action, children }: Props) => {
  const hasPermission = permissions
    ? hasContentPermission(permissions, action)
    : role
      ? hasContentPermissionByRole(role, action)
      : false;

  return hasPermission ? children : <></>;
};
