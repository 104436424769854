import { ContentRole } from '@spiderbox/common';
import { updatePermissions } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ContentPermissions, ErrorResponse } from 'types';
import { ContentPermissionsInput } from 'types/content';
import { fetchServerError } from 'utils';
import { Document } from 'models/Content';

type SubmitContentPermissions = {
  value: ContentPermissionsInput;
  contentId: string;
  workspaceId: string;
  shouldSendInvitation?: boolean;
};

export const mapToContentPermissions = (value: ContentPermissionsInput): ContentPermissions => {
  const newUserRoles = !value.newUserRoles?.role
    ? []
    : value.newUserRoles?.users.map(option => ({
        userId: option.value,
        user: option.user,
        role: value.newUserRoles.role,
      }));
  const existUserRoles =
    value.existingUserRoles
      ?.filter(userRole => userRole?.role !== ContentRole.OWNER)
      ?.map(userRole => ({
        userId: userRole?.userId,
        user: userRole?.user,
        role: userRole?.role || ContentRole.NONE,
      })) || [];
  return {
    contentId: value.contentId,
    userRoles: [...newUserRoles, ...existUserRoles],
    workspaceRole: {
      role: value.workspaceRole || ContentRole.NONE,
      workspaceId: value.workspaceId,
    },
  };
};

export const mapToContentPermissionsInput = (
  permissions: ContentPermissions,
  workspaceId?: string,
): ContentPermissionsInput => {
  return {
    contentId: permissions?.contentId,
    newUserRoles: {
      users: [],
      role: ContentRole.VIEWER,
    },
    existingUserRoles:
      permissions?.userRoles?.map(userRole => ({
        userId: userRole.userId,
        user: userRole.user,
        role: userRole.role,
      })) || [],
    workspaceRole: permissions?.workspaceRole?.role ?? ContentRole.NONE,
    workspaceId: permissions?.workspaceRole?.workspaceId ?? workspaceId,
  };
};

export const useUpdatePermissions = () => {
  const { queryKey, userInfo } = useAppStore();
  const queryClient = useQueryClient();

  const submit = async (payload: SubmitContentPermissions) => {
    const { value } = payload;
    const body = mapToContentPermissions(value);
    return await updatePermissions(body);
  };

  const mutation = useMutation<void, ErrorResponse, SubmitContentPermissions>(submit, {
    onSuccess: (_, payload) => {
      const { shouldSendInvitation, value } = payload;
      const message = shouldSendInvitation
        ? 'Item has been shared successfully.'
        : 'Access permission has been updated.';
      toast(message, { type: 'success' });

      const body = mapToContentPermissions(value);
      const userContentRole = body.userRoles?.find(userRole => userRole.userId === userInfo.id)?.role;

      const hasSharing =
        body.workspaceRole.role !== ContentRole.NONE || body.userRoles?.some(role => role.role !== ContentRole.NONE);

      const updateContentRole = body.userRoles?.some(
        role => role.role !== ContentRole.NONE && role.userId === userInfo.id,
      )
        ? userContentRole
        : body.workspaceRole.role;

      queryClient.setQueriesData<Document[]>(queryKey, prev => {
        const doc = prev?.find(doc => doc.id === body.contentId);
        if (doc) {
          doc.hasSharing = hasSharing;
          doc.contentRole = userInfo.id === doc.user.id ? ContentRole.OWNER : updateContentRole;
        }
        return [...prev];
      });

      if (body.workspaceRole.role === ContentRole.NONE && userContentRole === ContentRole.NONE) {
        queryClient.invalidateQueries({ queryKey });
      }

      queryClient.invalidateQueries(['contents', body.contentId, 'role']);
    },
    onError: error => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  return {
    ...mutation,
  };
};
