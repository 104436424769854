import { Setting } from 'types/setting';
import apiClient from '../ApiClient';

export async function getSetting(): Promise<Setting> {
  const response = await apiClient.get('setting');
  return response.data;
}

export async function updateSetting(payload: Setting): Promise<Setting> {
  const response = await apiClient.patch('setting', { ...payload });
  return response.data;
}
