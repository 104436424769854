/* eslint-disable */
import React, { useRef } from 'react';
import { THelpTip } from 'models/HelpTip';
import Slider, { Settings } from 'react-slick';
import HelpTipIndicator from './HelpTipIndicator';
import HelpTipItem from './HelpTipItem';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './HelpTips.scss';

type HelpTipsProps = {
  helpTips: THelpTip[];
};

const settings: Settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  waitForAnimate: false,
};

const HelpTips = ({ helpTips }: HelpTipsProps) => {
  const sliderRef = useRef<Slider>(null);

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <>
      <HelpTipIndicator />

      <Slider
        ref={sliderRef}
        {...settings}
      >
        {helpTips.map(tip => (
          <HelpTipItem
            onNext={handleNext}
            onPrev={handlePrev}
            key={tip.type}
            helpTip={tip}
          />
        ))}
      </Slider>
    </>
  );
};

export default HelpTips;
