import { yupResolver } from '@hookform/resolvers/yup';
import { inviteUsers } from 'apis/Workspace/Workspace';
import TextField from 'components/AppForm/TextField';
import React, { useCallback } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { ModalBody } from 'reactstrap';
import { ErrorResponse, RoleTypeMapping } from 'types';
import { fetchServerError } from 'utils';
import * as yup from 'yup';
import { Footer } from '../Footer';
import './ModalInviteUsers.scss';
import { ERSColor, RSClearIndicator, RSDropdownIndicator, RSMultiValueRemove, RSOption } from './common/RSInviteUsers';
import { Role } from '@spiderbox/common';
import { EMAIL_REGEXP } from 'constants/regexp';

interface Props {
  onCancel?: () => void;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Email is invalid')
    .matches(EMAIL_REGEXP, 'Email is invalid'),
  roles: yup.object().test('is-empty', 'User must have at least one role', value => {
    return Object.keys(value).length > 0;
  }),
});

const components = {
  IndicatorSeparator: null,
  ClearIndicator: RSClearIndicator,
  DropdownIndicator: RSDropdownIndicator,
  MultiValueRemove: RSMultiValueRemove,
  Option: RSOption,
};

export const ModalInviteUsers: React.FC<Props> = ({ onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      roles: {},
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const queryClient = useQueryClient();

  const { mutate, reset, isLoading } = useMutation(inviteUsers, {
    retry: false,
    onSuccess: () => {
      toast('Invitation has been sent!', { type: 'success' });
      onCancel?.();
      reset();
      queryClient.invalidateQueries();
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  const onSubmit = useCallback(data => {
    if (errors?.email || errors?.roles) return;

    const { email, roles } = data;

    mutate({
      email: email,
      roles: [roles.value],
    });
  }, []);

  const onError = () => {
    toast(
      'Apologies, but there seems to be a validation error in your form submission. Please review and correct the necessary fields.',
      { type: 'warning' },
    );
  };

  return (
    <form className="d-flex flex-column p-3 modal-invite-user modal-wrapper">
      <ModalBody className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div
            className="gray-900 fw-semibold font-size-18"
            data-cy="header-title"
          >
            Add more user
          </div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon me-0 gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="form-group mt-0 input-field">
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Invite by email"
                placeholder="Input email(s)"
                error={error}
                dataCy="label-email"
              />
            )}
          />

          <p
            className="feedback-invalid"
            data-cy="feedback-invalid-email"
          >
            {errors?.email && errors?.email?.message}
          </p>
        </div>

        <div className="form-group mt-0">
          <label
            className="gray-900"
            data-cy="label-roles"
          >
            Select more role(s)
          </label>
          <Controller
            name="roles"
            control={control}
            render={({ field: { onChange, ref }, formState: { errors } }) => (
              <ReactSelect
                id="invite-users-roles"
                ref={ref}
                onChange={onChange}
                menuPlacement="auto"
                placeholder="Add role(s)"
                components={components}
                name="roles"
                options={[
                  { label: RoleTypeMapping[Role.MEMBER], value: Role.MEMBER },
                  { label: RoleTypeMapping[Role.ADMIN], value: Role.ADMIN },
                ]}
                classNamePrefix="select"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: errors?.roles ? 'red' : state.isFocused ? ERSColor.PRIMARY : ERSColor.NEUTRAL_200,
                    boxShadow: errors?.roles ? '0 0 0 4px #fee2e2' : 'none',
                    '&:hover': {
                      borderColor: errors?.roles ? 'red' : state.isFocused ? ERSColor.PRIMARY : ERSColor.NEUTRAL_200,
                    },
                    height: 40,
                  }),
                  multiValue: base => ({
                    ...base,
                    padding: '4px 8px',
                    borderRadius: 32,
                    border: `1px solid ${ERSColor.PRIMARY_100}`,
                    background: ERSColor.PRIMARY_50,
                    color: ERSColor.PRIMARY,
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    fontSize: 14,
                    padding: 0,
                    color: ERSColor.PRIMARY,
                  }),
                  multiValueRemove: base => ({
                    ...base,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: ERSColor.PRIMARY_600,
                    },
                  }),
                  placeholder: baseStyles => ({
                    ...baseStyles,
                    color: ERSColor.NEUTRAL_400,
                  }),
                  menuList: base => ({
                    ...base,
                    cursor: 'pointer',
                    color: ERSColor.NEUTRAL_900,
                    fontWeight: 500,
                    padding: 12,
                  }),
                  singleValue: base => ({
                    ...base,
                    fontWeight: 500,
                  }),
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary: ERSColor.PRIMARY,
                    primary25: ERSColor.PRIMARY_50,
                  },
                })}
              />
            )}
          />

          <p
            className="feedback-invalid"
            data-cy="feedback-invalid-roles"
          >
            {errors?.roles && errors?.roles?.message}
          </p>
        </div>

        <Footer
          labelOK="Send Invitation"
          onOK={handleSubmit(onSubmit, onError)}
          isLoading={isLoading}
          labelCancel="Cancel"
          onCancel={() => onCancel?.()}
        />
      </ModalBody>
    </form>
  );
};
