import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import './ContentResultHelper.scss';

export const ContentResultHelper = () => {
  return (
    <div className="content-result-helper d-flex flex-column flex-md-row mt-md-3 gap-2">
      <div className="helper">
        <div className="helper-icon incorrect-answer">
          <XMarkIcon />
        </div>
        <span>Learner&apos;s incorrect answer</span>
      </div>

      <div className="helper ms-md-4">
        <div className="helper-icon learner-correct-answer">
          <CheckIcon />
        </div>
        <span>Learner&apos;s correct answer</span>
      </div>

      <div className="helper ms-md-4">
        <div className="helper-icon correct-answer">
          <CheckIcon />
        </div>
        <span>Correct answer</span>
      </div>
    </div>
  );
};
