import React, { useState } from 'react';
import { ModalBody } from 'reactstrap';
import { Footer } from '../Footer';
import { X } from 'react-feather';
import './Confirm.scss';

export type ConfirmModalProps = {
  title?: string | JSX.Element;
  titleIcon?: JSX.Element;
  labelCancel?: string;
  labelOK?: string;
  onCancel?: () => void;
  onAccept?: (done?: () => void) => void;
  classNameIcon?: string;
  content?: string | JSX.Element;
  hasFooter?: boolean;
};

export const Confirm = ({
  labelCancel = 'Cancel',
  labelOK = 'OK',
  onAccept,
  onCancel,
  title = '',
  content = '',
  titleIcon = null,
  hasFooter = true,
}: ConfirmModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const done = () => {
    setIsLoading(false);
  };

  const accept = () => {
    setIsLoading(true);
    onAccept?.(done);
  };

  return (
    <div
      className="d-flex flex-column p-4 confirm-modal__wrapper"
      data-cy="confirm-modal"
    >
      <ModalBody className="d-flex flex-column gap-3">
        <div className="position-relative d-flex justify-content-between w-100">
          {titleIcon && (
            <div
              className="d-flex align-items-center justify-content-center p-2 rounded-circle h-48px w-48px bg-primary-50"
              data-cy="title-icon"
            >
              {titleIcon}
            </div>
          )}
          <X
            onClick={() => onCancel?.()}
            className="position-absolute top-0 end-0 gray-400 cursor-pointer d-block close-icon"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="confirm-modal__body">
          <div
            className="gray-900 font-weight-medium font-size-18"
            data-cy="title"
          >
            {title}
          </div>
          <div
            className="gray-500 mt-2"
            data-cy="content"
          >
            {content}
          </div>
        </div>
      </ModalBody>

      {hasFooter && (
        <Footer
          labelCancel={labelCancel}
          labelOK={labelOK}
          onOK={accept}
          onCancel={() => onCancel?.()}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
