import { activateConnection } from 'apis/Lti/Lti';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Connection } from 'types';

export const useActivateConnection = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const queryClient = useQueryClient();
  const queryKey = ['workspaces', currentWorkspaceId, 'connections'];
  return useMutation(activateConnection, {
    onSuccess: (_, { platformUrl, clientId }) => {
      queryClient.setQueryData<Partial<Connection>[]>(
        queryKey,
        connections =>
          connections?.map(connection => {
            if (connection.platformUrl === platformUrl && connection.clientId === clientId) {
              return {
                ...connection,
                active: true,
              };
            }
            return connection;
          }),
      );
      toast.success('LMS connection has been activated.');
    },
  });
};
