import { updateSetting } from 'apis/Setting/Setting';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { Setting } from 'types/setting';
import { fetchServerError } from 'utils';

export const useUpdateSetting = () => {
  const { workspaceStore } = useAppStore();
  return useMutation<Setting, ErrorResponse, Partial<Setting>>(updateSetting, {
    retry: false,
    onSuccess: data => {
      workspaceStore.updateSetting(data);
      toast('Your changes have been saved.');
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
