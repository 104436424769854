import React from 'react';
import './ContentSettings.scss';
import { DisplaySetting } from './DisplaySetting';
import { SharingSetting } from './SharingSetting';
import { FolderSettings } from './FolderSettings';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { ContentPermission, Loading } from 'components';
import { useGetContentRole } from 'hooks';
import { useParams } from 'react-router-dom';
import { ContentLtiSettingInput, ContentLtiSettings } from './LtiSettings';
import { useGetContentDefaultLtiSetting } from 'hooks/setting';
import { LtiSettingOptions } from '@spiderbox/common';

export const ContentSettings = observer(() => {
  const { id: contentId } = useParams();
  const {
    workspaceStore,
    contentStore: { settings, isContentLoaded },
    contentStore,
    subscriptionStore: { currentSubscription },
  } = useAppStore();
  const { workspaceSetting } = workspaceStore;
  const { contentBehavior, ltiSetting } = workspaceSetting || {};
  const { contentRole } = useGetContentRole(contentId);
  const defaultLtiSetting = useGetContentDefaultLtiSetting();

  const contentLtiSetting: ContentLtiSettingInput = {
    ltiMaxWidth: !!contentId ? settings?.ltiMaxWidth : defaultLtiSetting?.ltiMaxWidth,
    ltiSendScoreOn: !!contentId ? settings?.ltiSendScoreOn : defaultLtiSetting?.ltiSendScoreOn,
    ltiCanResume: !!contentId ? settings?.ltiCanResume : defaultLtiSetting?.ltiCanResume,
  };

  const shouldShowDisplaySetting = () => {
    if (!contentBehavior) return true;
    return contentBehavior.contentBehaviorDisplayToolbar === 'true';
  };

  const shouldShowLtiSetting = () => {
    if (!ltiSetting) return true;

    return [LtiSettingOptions.PER_CONTENT_ON_DEFAULT, LtiSettingOptions.PER_CONTENT_OFF_DEFAULT].includes(
      ltiSetting.ltiSendScoreToLms,
    );
  };

  if (!isContentLoaded) return <Loading />;

  return (
    <div className="content-settings d-flex flex-column gap-4">
      {shouldShowDisplaySetting() && (
        <div className="content-settings--item">
          <DisplaySetting defaultValues={settings} />
        </div>
      )}
      <div className="content-settings--item">
        <SharingSetting />
      </div>
      <ContentPermission
        role={contentRole}
        action="content.moveToFolder"
      >
        <div className="content-settings--item">
          <FolderSettings contentId={contentId} />
        </div>
      </ContentPermission>
      {shouldShowLtiSetting() && currentSubscription?.studentReportItem && (
        <div className="content-settings--item">
          <ContentLtiSettings
            defaultValues={contentLtiSetting}
            onChange={ltiSettings => contentStore.setSettings(ltiSettings)}
          />
        </div>
      )}
    </div>
  );
});
