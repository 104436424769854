import { ContentDetail } from '@spiderbox/common';
import { getContent } from 'apis/Content/Content';
import { useQuery } from 'react-query';

export const useGetContent = (contentId: string) => {
  return useQuery<ContentDetail>(['contents', contentId], () => getContent(contentId), {
    cacheTime: 300000,
    staleTime: 300000,
  });
};
