import React from 'react';
import useModal from 'hooks/useModal';
import { NewContentModalProps } from '../NewContent';
import { Trash2 } from 'react-feather';
import { ConfirmModalProps } from '../Confirm';
import { useDeletePermanently } from 'hooks';
import classNames from 'classnames';
import { useAppStore } from 'hooks/useAppStore';

export const useContentModal = () => {
  const { ltiMode } = useAppStore();
  const { openModal, closeModal } = useModal();
  const { mutate: deletePermanently } = useDeletePermanently();

  const openNewContentModal = () => {
    openModal('new-content', {
      size: 'xl',
      className: classNames('modal-fullscreen-lg-down custom-new-content-modal', { 'modal-fullscreen': ltiMode }),
      scrollable: true,
      onCancel: closeModal,
    } as NewContentModalProps);
  };

  const openPermanentlyDeleteContentModal = (contentId: string) => {
    openModal('warning', {
      title: 'Permanently delete?',
      content: "This item will be permanently deleted and you won't be able to restore it.",
      titleIcon: (
        <Trash2
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: 'Delete',
      onCancel: closeModal,
      onAccept: () => {
        deletePermanently(contentId);
        closeModal();
      },
    } as ConfirmModalProps);
  };

  return {
    openNewContentModal,
    openPermanentlyDeleteContentModal,
  };
};
