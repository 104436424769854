import React from 'react';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { UncontrolledTooltip } from 'reactstrap';

interface Props {
  expanded: boolean;
  onToggle: (value: boolean) => void;
}

const ExpandCollapseButton = ({ expanded, onToggle }: Props) => {
  return expanded ? (
    <>
      <UncontrolledTooltip
        placement="bottom"
        target="collapse-tooltip"
      >
        Make chat smaller
      </UncontrolledTooltip>
      <ArrowsPointingInIcon
        className="cursor-pointer tooltip-icon d-none d-lg-block"
        strokeWidth={2}
        onClick={() => onToggle(false)}
        id="collapse-tooltip"
      />
    </>
  ) : (
    <>
      <ArrowsPointingOutIcon
        className="cursor-pointer tooltip-icon d-none d-lg-block"
        strokeWidth={2}
        onClick={() => onToggle(true)}
        id="expand-tooltip"
      />
      <UncontrolledTooltip
        placement="bottom"
        target="expand-tooltip"
      >
        Make chat larger
      </UncontrolledTooltip>
    </>
  );
};

export default ExpandCollapseButton;
