import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';
import React from 'react';

export const UserProfileCurrent = () => {
  const { userInfo } = useAppStore();

  const { email, fullName, profileImageUrl } = userInfo || {};

  return (
    <div
      className="d-flex justify-content-start align-items-center p-3 sidebar-profile__profile"
      data-cy="sidebar-profile__profile"
    >
      <div className="d-flex align-items-center justify-content-start w-100">
        <AvatarProfile
          imgSrc={profileImageUrl}
          width={36}
          height={36}
          userFullName={fullName}
          avatarClassNames="sidebar-profile__avatar"
        />
        <div className="ms-1 flex-fill w-1px">
          <div
            className="sidebar-profile__profile__user-name text-truncate"
            data-cy="sidebar-profile__profile__user-name"
          >
            {fullName}
          </div>
          <div
            className="sidebar-profile__profile__email text-truncate"
            data-cy="sidebar-profile__profile__email"
          >
            {email}
          </div>
        </div>
      </div>
    </div>
  );
};
