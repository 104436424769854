import { ContentRole, ContentSetting } from '@spiderbox/common';
import { User } from './user';
import { ContentPermissions } from 'types';
import { ContentQueryParams } from 'models/Content';
import { ContentParameters, IContentMetadata } from '@lumieducation/h5p-server';

export type ContentFormInput = {
  owner?: User;
  setting: ContentSetting;
  permissions: ContentPermissions;
  shouldDirty?: boolean;
  ContentFormInputDirtyFields?: Partial<{
    owner: boolean;
    setting: boolean;
    permissions: boolean;
  }>;
};

export type ContentPermissionsInput = {
  contentId?: string;
  newUserRoles: {
    users: { label: string; value: string; user: User }[];
    role: ContentRole;
  };
  existingUserRoles: { userId: string; role: ContentRole; user?: User; _destroy?: boolean }[];
  workspaceRole: ContentRole;
  workspaceId: string;
};

export enum ContentActionStatus {
  DRAFT = 'Draft',
  PUBLISH = 'Publish',
  SAVE = 'Save',
  SAVE_AND_INSERT = 'Save And Insert',
}

export type ContentFilterModalProps = {
  value?: ContentQueryParams;
  onCancel?: () => void;
  onAccept?: (queries: ContentQueryParams) => void;
  onClear?: () => void;
  canFilterByStatus?: boolean;
  canFilterByType?: boolean;
  canFilterByStarred?: boolean;
  canFilterByAttemptTime?: boolean;
};

export type ContentPlay = {
  contentId: string;
  contextId?: string;
  asUserId?: string;
  readOnlyState?: boolean;
  library?: string;
  parameters?: ContentParameters;
  metadata?: IContentMetadata;
  settings?: ContentSetting;
};

export type FinishedUserDataInput = {
  score: number;
  maxScore: number;
  openedTimestamp: number;
  finishedTimestamp: number;
  contentId: string;
  userId: string;
  contextId?: string;
  data?: ContentUserDataEvent[];
  lastPercentageScore?: number;
  totalAttempts?: number;
};

export type ContentUserDataEvent = {
  actor: any;
  verb: any;
  object: {
    id: string;
    objectType: string;
    definition: any;
  };
  context: any;
  result: any;
};
