import { updateUserRoles } from 'apis/User/User';
import useModal from 'hooks/useModal';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, User } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateUserRole = (onSuccess?: (data: User) => void) => {
  const { closeModal } = useModal();

  return useMutation(updateUserRoles, {
    retry: false,
    onSuccess: (data: User) => {
      onSuccess(data);
    },

    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
    onSettled: () => closeModal(),
  });
};
