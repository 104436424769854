import { H5PEditorUI } from '@lumieducation/h5p-react';
import { PageHeader } from 'components/PageHeader';
import { useContentEditor } from 'hooks/content/useContentEditor';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const ManageContentTypesPage = observer(() => {
  const { contentStore } = useAppStore();
  const { editorSaveContentCallback } = useContentEditor();

  return (
    <>
      <PageHeader title="Master Admin" />
      <div className="py-md-4 px-md-3">
        <H5PEditorUI
          contentId="new-content"
          loadContentCallback={(contentId: string) => contentStore.loadEditorAsync(contentId, null)}
          saveContentCallback={async (contentId, requestBody) => {
            return await editorSaveContentCallback(contentId, requestBody);
          }}
          htmlCustomClass="h5p-editor__h5p-hub-show"
        />
      </div>
    </>
  );
});
