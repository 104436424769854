import React, { useEffect } from 'react';
import './ResetPassword.scss';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { ErrorResponse } from 'types';
import { resetPassword } from 'apis/Auth/Password';
import PasswordField from 'components/AppForm/PasswordField';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { fetchServerError } from 'utils';
import IconLogo from 'assets/icons/IconLogo';

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required.')
    .minNumbers(1, 'Password must have at least 8 characters including a number.')
    .min(8, 'Password must have at least 8 characters including a number.'),
  passwordConfirmation: yup
    .string()
    .required('Confirmation password is required.')
    .oneOf([yup.ref('password'), null], 'Confirmation password does not match.'),
});

export const ResetPasswordPage = observer(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ password: string; passwordConfirmation: string }>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema as any),
  });

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!searchParams.get('id')?.length || !searchParams.get('token')?.length) {
      navigate('/auth/login', { replace: true });
    }
  }, [searchParams.get('id'), searchParams.get('token')]);

  const { isLoading, mutate } = useMutation(resetPassword, {
    retry: false,
    onSuccess: () => {
      toast('Password has been changed successfully.', { type: 'success' });
      navigate('/auth/login', { replace: true });
    },
    onError: (errorResponse: ErrorResponse) => {
      toast(fetchServerError(errorResponse), { type: 'error' });
    },
  });

  const onSubmit = async ({ password }) => {
    const params = {
      userId: searchParams.get('id'),
      token: searchParams.get('token'),
      password,
    };
    mutate(params);
    reset({}, { keepValues: true });
  };

  return (
    <div className="password-wrapper vh-100 d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="password-form bg-white d-flex flex-column align-items-center"
      >
        <IconLogo />

        <div className="password-form--title text-center">Change Password</div>

        <div className="form-group w-100 mt-0">
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                placeholder="Input password"
                label="Password"
                error={error}
              />
            )}
          />

          {errors.password?.message && <p className="feedback-invalid mb-0">{errors.password?.message}</p>}
          {!errors.password?.message && (
            <p className="password-guide mt-2 mb-0">Password must have at least 8 characters including a number.</p>
          )}
        </div>

        <div className="form-group w-100 mt-0">
          <Controller
            control={control}
            name="passwordConfirmation"
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                placeholder="Confirm password"
                label="Confirm Password"
                error={error}
              />
            )}
          />

          <p className="feedback-invalid mb-0">{errors.passwordConfirmation?.message}</p>
        </div>

        <div className="w-100 d-flex">
          <button
            className="btn btn-primary flex-fill px-3 py-2 text-white"
            type="submit"
            disabled={isLoading}
          >
            Change Password
            {isLoading && (
              <span
                className="ms-3 spinner-border spinner-border-sm text-light"
                role="status"
              ></span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
});
