import React from 'react';
import './AIMascot.scss';
import { ReactSVG } from 'react-svg';
import bodyMascot from 'assets/images/ai-assistant/cloud-body-evokio.svg';

interface Props {
  className: string;
  faceSrc: string;
  runningSrc?: string;
  isFloat: boolean;
}

const AIMascot = ({ className, faceSrc, runningSrc, isFloat }: Props) => {
  return (
    <div className={`${className} ai-float`}>
      <div className={`ai-body ${isFloat ? 'float' : ''}`}>
        <ReactSVG src={bodyMascot} />
      </div>
      <div className={`ai-face ${isFloat ? 'float' : ''}`}>
        <ReactSVG src={faceSrc} />
      </div>
      {runningSrc && (
        <div>
          <ReactSVG src={runningSrc} />
        </div>
      )}
    </div>
  );
};

export default AIMascot;
