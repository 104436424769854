import React, { useEffect } from 'react';
import { ROLES_PERMISSIONS, Role } from '@spiderbox/common';
import { useGetFetchQuery } from 'hooks/useGetFetchQuery';
import { DropdownAssignRole } from 'pages/Organisation/ManageUsersPage/components/DropdownAssignRole';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { User } from 'types';
import './UserDetailInfo.scss';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';

export const UserDetailInfo = observer(() => {
  const { userId: id } = useParams<{ userId?: string }>();
  const { userInfo } = useAppStore();

  const [userDetail, setUserDetail] = useState<User>(useGetFetchQuery(['users', id, 'detail']) as User);

  const { fullName, roles } = userDetail;

  const { setValue, reset } = useFormContext();

  const onChangeValue = (role: Role) => {
    setUserDetail((prevState: User) => ({ ...prevState, roles: [role] }));

    setValue('roles', role, { shouldDirty: true });

    if (role === Role.ADMIN) {
      ROLES_PERMISSIONS[role].forEach(permission => {
        if (permission.group) {
          setValue(`${permission.action}.all`, true, { shouldDirty: true });
        } else {
          setValue(permission.action, true, { shouldDirty: true });
        }
      });
    }

    if (role === Role.MEMBER) {
      reset({ roles: role }, { keepDirty: true });
    }
  };

  useEffect(() => {
    setValue('roles', roles.includes(Role.ADMIN) ? Role.ADMIN : Role.MEMBER, { shouldDirty: false });
  }, []);

  return (
    <div className="user-detail-info bg-white mt-3 p-3 text-neutral-900">
      <div
        className="mb-2 font-size-24 text-neutral-900 fw-bolder text-capitalize"
        data-cy="user-permission-info__full-name"
      >
        {fullName}
      </div>
      <div className="d-grid">
        <div className="row">
          <div className="col-3 fw-medium">{'Role(s)'}</div>
          <div className="col-9 ps-0 ps-md-5 d-flex justify-content-end justify-content-md-start ">
            {!!roles.length && (
              <DropdownAssignRole
                user={userDetail as User}
                onChangeValue={onChangeValue}
                disabled={id === userInfo?.id}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
