import { makeAutoObservable } from 'mobx';
import { Folder, TDocumentType } from 'models/Content';

export const rootFolder = {
  id: 'root',
  children: [],
  title: 'My Content',
  kind: TDocumentType.Folder,
} as Folder;

export class FolderStore {
  modalFolderKey: any[] = [];
  isFolderCreating: boolean = false;
  folderSelected: Folder = null;

  constructor() {
    makeAutoObservable(this);
  }

  get folderSelectedId() {
    return this.folderSelected?.id;
  }

  setFolderCreating(value: boolean) {
    this.isFolderCreating = value;
  }
  selectFolder(folder: Folder) {
    this.folderSelected = { ...folder };
  }
  setModalFolderKey(value: any[]) {
    this.modalFolderKey = value;
  }
}
