import React from 'react';
import cx from 'classnames';

import './Icon.scss';

type Props = {
  classNameIcon: string;
};

export const Icon = ({ classNameIcon }: Props) => {
  return (
    <span className="appModalIcon d-flex justify-content-center align-items-center">
      <i
        className={cx('mdi mdi-24px', {
          [classNameIcon]: !!classNameIcon,
        })}
      />
    </span>
  );
};
