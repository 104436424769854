import { updateUserDetail } from 'apis/User/User';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateUserDetail = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateUserDetail, {
    onSuccess: data => {
      queryClient.setQueryData(['users', id, 'detail'], data);
      toast.success('Update user permissions successfully');
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
