import React from 'react';
import { findFolderDetail } from 'apis/Folder/Folder';
import classNames from 'classnames';
import { Folder, TDocumentType } from 'models/Content';
import { ContentTypeIcon } from 'pages/Content/components/ContentTypeIcon';
import { useState, useEffect, MouseEventHandler } from 'react';
import { NodeRendererProps } from 'react-arborist';
import Skeleton from 'react-loading-skeleton';
import { useQueryClient } from 'react-query';
import { findFolderById } from './utils';
import { ChevronDown, ChevronRight, X } from 'react-feather';
import MyContentIcon from 'assets/images/MyContentIcon.svg';
import { toast } from 'react-toastify';

const ToggleButton = ({
  isLeaf,
  isOpen,
  onClick,
  isDisabled,
}: {
  isLeaf: boolean;
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}) => {
  if (!isLeaf) {
    return isOpen ? (
      <button
        onClick={onClick}
        type="button"
        title="Expand"
        className="btn btn-link p-0"
      >
        <ChevronDown
          size={20}
          className={classNames('me-0', isDisabled ? 'gray-300' : 'gray-600')}
        />
      </button>
    ) : (
      <button
        onClick={onClick}
        type="button"
        title="Expand"
        className="btn btn-link p-0"
      >
        <ChevronRight
          size={20}
          className={classNames('me-0', isDisabled ? 'gray-300' : 'gray-600')}
        />
      </button>
    );
  }

  return <></>;
};

type Props = {
  onCreate: (title: string, parentId: string) => void;
  isFolderCreating: boolean;
  createFolderLoading: boolean;
  search: string;
  queryKey: any;
  folderSelected: Folder;
  content: Folder;
  parentFolderId: string;
  cancelNewFolder: () => void;
  isDisable: (data?: Folder) => boolean;
};
export const FolderTreeItem = (props: NodeRendererProps<Folder> & Props) => {
  const {
    node,
    tree,
    style,
    onCreate,
    isFolderCreating,
    search,
    queryKey,
    folderSelected,
    createFolderLoading,
    cancelNewFolder,
    isDisable,
  } = props;

  const { title, children, isEdited } = node.data;
  const { isOpen, isSelected } = node;
  const isLeaf = !!search || (!children?.length && !node.data.numchild);
  const [value, setValue] = useState(title);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (node?.data?.id === 'root' && !node?.isOpen) {
      node?.open();
    }

    if (isFolderCreating && node?.data?.id === folderSelected?.id) {
      node?.open();
    }
  }, [folderSelected, isFolderCreating, node]);

  const getListFolderChildren = async () => {
    const previousData = queryClient.getQueryData<Folder[]>(queryKey);
    const folder = findFolderById(previousData, node?.data.id);
    if (folder?.children?.length) {
      return;
    }
    const listFolder = await findFolderDetail({}, node?.data.id);
    if (!listFolder) {
      return;
    }

    queryClient.setQueriesData<Folder[]>(queryKey, previousData => {
      if (folder) {
        folder.children = listFolder;
      }
      return [...previousData];
    });
  };

  const clickItem = async () => {
    if (isDisable(node?.data) || isFolderCreating) {
      return;
    }
    if (!isEdited && !isLeaf) {
      if (node?.data.id !== 'root') {
        await getListFolderChildren();
        tree.toggle(node?.data.id);
      }
    }
  };

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}
      className={classNames(
        'w-100 d-flex justify-content-between align-items-center  h-40px folder-tree-item',
        isSelected && !isDisable(node?.data) ? 'bg-primary-50' : 'bg-mobile-hover',
        !isDisable(node?.data) ? 'cursor-pointer bg-primary-50-hover ' : 'cursor-default',
        isEdited ? 'edit' : '',
      )}
      style={style}
      onClick={clickItem}
      data-cy="folder-tree-item"
    >
      <div className="d-flex flex-fill align-items-center justify-content-between px-3 py-2">
        <div className="d-flex w-100 align-items-center">
          {node?.data.id === 'root' ? (
            <img src={MyContentIcon} />
          ) : (
            <ContentTypeIcon
              contentType={node?.data.kind}
              className={node?.data.kind === TDocumentType.Content || isDisable(node?.data) ? 'gray-300' : 'gray-600'}
            />
          )}

          {isEdited ? (
            createFolderLoading ? (
              <Skeleton />
            ) : (
              <>
                <input
                  className="d-flex ms-2 h-100 px-2 flex-fill w-1px text-truncate border-0"
                  autoFocus
                  onFocus={event => event.target.select()}
                  value={value}
                  onChange={event => {
                    setValue(event.target.value);
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      if (!event.currentTarget.value?.length) {
                        toast.error('Folder name is required.');
                        return;
                      }
                      onCreate(event.currentTarget.value, folderSelected?.id);
                    }
                  }}
                />
                <X
                  width={20}
                  height={20}
                  className="ms-2 cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    cancelNewFolder();
                  }}
                />
              </>
            )
          ) : (
            <div
              className={classNames(
                'flex-fill w-1px text-truncate ms-2',
                isDisable(node?.data) ? ' gray-300' : ' gray-600',
              )}
            >
              {title}
            </div>
          )}
        </div>
        <ToggleButton
          isLeaf={isLeaf}
          isOpen={isOpen}
          isDisabled={isDisable(node?.data)}
        />
      </div>
    </div>
  );
};
