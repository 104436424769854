import { updateProfile } from 'apis/Profile/Profile';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { appStore } from 'stores/AppStore';
import { EditProfileFormInput, ErrorResponse, User } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateProfile = (setError?: UseFormSetError<User>) => {
  const queryClient = useQueryClient();

  return useMutation<User, ErrorResponse, EditProfileFormInput>({
    mutationFn: updateProfile,
    retry: false,
    onSuccess: (data: any) => {
      appStore.updateUserInfo(data);
      queryClient.invalidateQueries('userInfo');
      toast('Your changes have been saved.', { type: 'success' });
    },
    onError: (error: ErrorResponse) => {
      Object.entries(error.errors).forEach(([key, value]) => {
        setError(key as any, { type: 'serverError', message: value[0] });
      });
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
