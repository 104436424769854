import useModal from 'hooks/useModal';
import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmToLeave: () => Promise<boolean>, when = true) {
  const { navigator } = useContext(NavigationContext);
  const { closeModal } = useModal();

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = async (...args: Parameters<typeof push>) => {
      const result = await confirmToLeave();

      if (result !== false) {
        push(...args);
      }

      closeModal();
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmToLeave, when]);
}

export function usePrompt(when = true, title?: string, content?: string) {
  const { openModal } = useModal();

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return '';
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  function confirmToLeave(): Promise<boolean> {
    return new Promise(resolve => {
      openModal('warning', {
        title: title || 'Are you sure you want to leave without saving?',
        content: content || 'All changes will not be saved.',
        labelOK: "Yes, I'm sure",
        labelCancel: 'No, cancel',
        onAccept: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }

  useConfirmExit(confirmToLeave, when);
}
