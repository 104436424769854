import React, { useMemo } from 'react';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { Trash2 } from 'react-feather';
import { observer } from 'mobx-react-lite';
import { useDeleteChat, useFetchChatHistory } from 'hooks/ai-chat';
import { AiChatHistory, AiChatType } from 'types';
import useModal from 'hooks/useModal';
import ChatCard from './ChatCard';
import ChatDate from './ChatDate';
import { groupChatsByDate } from 'components/AIOffset/utils';
import _isEmpty from 'lodash/isEmpty';
import EmptyChat from './EmptyChat';

import './ChatHistory.scss';

interface Props {
  isWelcome: boolean;
  onChatSelect: (chatId: string) => void;
  selectedChatId: string;
  changeViewMode?: (mode: AiChatType) => void;
  setSelectedChatId: (chatId: string) => void;
}

const ChatHistory = observer((props: Props) => {
  const { onChatSelect, setSelectedChatId, selectedChatId } = props;
  const { data: chatHistory } = useFetchChatHistory();
  const { openModal, closeModal } = useModal();
  const { mutateAsync: deleteChat } = useDeleteChat();

  const groupedChats = useMemo(() => {
    return groupChatsByDate(chatHistory);
  }, [chatHistory]);

  const openDeleteChatModal = (chat: AiChatHistory) => {
    openModal('warning', {
      title: 'Are you sure you want to delete this chat?',
      content: "This chat will be deleted and won't be able to retrieve.",
      titleIcon: (
        <Trash2
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: closeModal,
      onAccept: async (done: () => void) => {
        await deleteChat(chat.id);
        done();
        closeModal();
        if (selectedChatId === chat.id) {
          setSelectedChatId(null);
        }
      },
    });
  };

  if (_isEmpty(groupedChats)) {
    return <EmptyChat />;
  }

  return (
    <div className="sidebar-history d-flex flex-column justify-content-between h-100">
      <div
        className="px-4 py-3 h-100 d-flex flex-column"
        style={{ overflowY: 'auto' }}
      >
        {Object.keys(groupedChats).length ? (
          <>
            {Object.keys(groupedChats).map(groupChat => (
              <div
                key={groupChat}
                className="mb-3"
              >
                <ChatDate date={groupChat} />
                <div className="mt-2 cursor-pointer flex-fill d-flex flex-column">
                  {groupedChats[groupChat].map((chat, index) => (
                    <ChatCard
                      key={index}
                      chat={chat}
                      onDelete={() => openDeleteChatModal(chat)}
                      onSelect={() => onChatSelect(chat.id)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="px-3 mt-5 d-flex flex-column">
              <ChatBubbleOvalLeftEllipsisIcon
                className="flex-shrink-0 mb-3 text-primary-500"
                strokeWidth={2}
                height={52}
                width={52}
              />
              <div className="font-size-14">
                <div className="kick-off fw-semibold">Kick off your first chat now!</div>
                <div className="fw-normal text-neutral-400">
                  Your conversation history will be saved and displayed here for you to revisit anytime.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default ChatHistory;
