import React from 'react';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SwitchWorkspace = observer(() => {
  const queryClient = useQueryClient();
  const appStore = useAppStore();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    appStore?.workspaceStore.updateCurrentWorkspace(state?.data);
    queryClient.invalidateQueries();
    appStore?.setInitializedLayout(false);

    toast(`Your current workspace: ${state?.data.name}`, { type: 'success' });
  }, []);

  return (
    <Navigate
      to="/"
      replace={true}
    />
  );
});
