import React from 'react';

const Icon404: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="475"
    height="201"
    viewBox="0 0 475 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.810547 147.258V147.758H1.31055H68.418V170.5V171H68.918H101.252H101.752V170.5V147.758H118H118.5V147.258V120.598V120.098H118H101.752V30.5V30H101.252H82.6113H82.1113H69.6016H69.1016H58.6641H58.3892L58.2419 30.2321L0.888388 120.603L0.810547 120.726V120.871V147.258ZM68.7835 66.8203H69.1016V120.098H35.4434V119.648L68.7835 66.8203Z"
      fill="#1D4ED8"
      stroke="#3460DC"
    />
    <mask
      id="mask0_863_11429"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="140"
      y="2"
      width="196"
      height="197"
    >
      <ellipse
        cx="238"
        cy="100.5"
        rx="97.5124"
        ry="97.9952"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_863_11429)">
      <ellipse
        className="sun-light"
        cx="237.999"
        cy="100.5"
        rx="37.8109"
        ry="37.9981"
        fill="#FBE7A3"
      />
      <ellipse
        cx="237.999"
        cy="100.5"
        rx="37.8109"
        ry="37.9981"
        fill="#F7CE46"
      />
      <path
        className="wave wave-light"
        d="M109.642 111.34L132.628 99.4567C138.341 96.5031 145.122 96.5031 150.835 99.4567L164.717 106.634C170.43 109.587 177.211 109.587 182.924 106.634L196.807 99.4567C202.52 96.5031 209.301 96.5031 215.014 99.4567L228.896 106.634C234.609 109.587 241.39 109.587 247.103 106.634L260.986 99.4567C266.699 96.5031 273.48 96.5031 279.193 99.4567L293.075 106.634C298.789 109.587 305.569 109.587 311.283 106.634L325.165 99.4567C330.878 96.5031 337.659 96.5031 343.372 99.4567L357.254 106.634C362.968 109.587 369.748 109.587 375.462 106.634L389.344 99.4567C395.057 96.5031 401.838 96.5031 407.551 99.4567L421.434 106.634C427.147 109.587 433.927 109.587 439.641 106.634L453.523 99.4567C459.236 96.5031 466.017 96.5031 471.73 99.4567L485.613 106.634C491.326 109.587 498.107 109.587 503.82 106.634L517.702 99.4567C523.415 96.5031 530.196 96.5031 535.909 99.4567L549.792 106.634C555.505 109.587 562.286 109.587 567.999 106.634L581.881 99.4567C587.594 96.5031 594.375 96.5031 600.089 99.4567L613.971 106.634C619.684 109.587 626.465 109.587 632.178 106.634L646.06 99.4567C651.774 96.5031 658.554 96.5031 664.268 99.4567L678.15 106.634C683.863 109.587 690.644 109.587 696.357 106.634L710.24 99.4567C715.953 96.5031 722.733 96.5031 728.447 99.4567L742.329 106.634C748.042 109.587 754.823 109.587 760.536 106.634L774.419 99.4567C780.132 96.5031 786.913 96.5031 792.626 99.4567L806.508 106.634C812.221 109.587 819.002 109.587 824.715 106.634L838.598 99.4567C844.311 96.5031 851.092 96.5031 856.805 99.4567L879.791 111.34V153.747H109.642V111.34Z"
        fill="#BBCAF3"
      />
      <path
        className="wave wave-mid"
        d="M-373.443 136.339L-350.457 124.455C-344.744 121.502 -337.963 121.502 -332.25 124.455L-318.368 131.632C-312.655 134.586 -305.874 134.586 -300.161 131.632L-286.278 124.455C-280.565 121.502 -273.784 121.502 -268.071 124.455L-254.189 131.632C-248.476 134.586 -241.695 134.586 -235.982 131.632L-222.099 124.455C-216.386 121.502 -209.605 121.502 -203.892 124.455L-190.01 131.632C-184.296 134.586 -177.516 134.586 -171.802 131.632L-157.92 124.455C-152.207 121.502 -145.426 121.502 -139.713 124.455L-125.831 131.632C-120.117 134.586 -113.337 134.586 -107.623 131.632L-93.741 124.455C-88.0278 121.502 -81.247 121.502 -75.5338 124.455L-61.6515 131.632C-55.9382 134.586 -49.1575 134.586 -43.4442 131.632L-29.5619 124.455C-23.8487 121.502 -17.0679 121.502 -11.3547 124.455L2.52765 131.632C8.24088 134.586 15.0216 134.586 20.7349 131.632L34.6172 124.455C40.3304 121.502 47.1112 121.502 52.8244 124.455L66.7068 131.632C72.42 134.586 79.2007 134.586 84.914 131.632L98.7963 124.455C104.51 121.502 111.29 121.502 117.004 124.455L130.886 131.632C136.599 134.586 143.38 134.586 149.093 131.632L162.975 124.455C168.689 121.502 175.469 121.502 181.183 124.455L195.065 131.632C200.778 134.586 207.559 134.586 213.272 131.632L227.155 124.455C232.868 121.502 239.648 121.502 245.362 124.455L259.244 131.632C264.957 134.586 271.738 134.586 277.451 131.632L291.334 124.455C297.047 121.502 303.828 121.502 309.541 124.455L323.423 131.632C329.136 134.586 335.917 134.586 341.63 131.632L355.513 124.455C361.226 121.502 368.007 121.502 373.72 124.455L396.706 136.339V178.746H-373.443V136.339Z"
        fill="#6183E4"
      />
      <path
        className="wave wave-dark"
        d="M109.642 161.338L132.628 149.454C138.341 146.501 145.122 146.501 150.835 149.454L164.717 156.631C170.43 159.585 177.211 159.585 182.924 156.631L196.807 149.454C202.52 146.501 209.301 146.501 215.014 149.454L228.896 156.631C234.609 159.585 241.39 159.585 247.103 156.631L260.986 149.454C266.699 146.501 273.48 146.501 279.193 149.454L293.075 156.631C298.789 159.585 305.569 159.585 311.283 156.631L325.165 149.454C330.878 146.501 337.659 146.501 343.372 149.454L357.254 156.631C362.968 159.585 369.748 159.585 375.462 156.631L389.344 149.454C395.057 146.501 401.838 146.501 407.551 149.454L421.434 156.631C427.147 159.585 433.927 159.585 439.641 156.631L453.523 149.454C459.236 146.501 466.017 146.501 471.73 149.454L485.613 156.631C491.326 159.585 498.107 159.585 503.82 156.631L517.702 149.454C523.415 146.501 530.196 146.501 535.909 149.454L549.792 156.631C555.505 159.585 562.286 159.585 567.999 156.631L581.881 149.454C587.594 146.501 594.375 146.501 600.089 149.454L613.971 156.631C619.684 159.585 626.465 159.585 632.178 156.631L646.06 149.454C651.774 146.501 658.554 146.501 664.268 149.454L678.15 156.631C683.863 159.585 690.644 159.585 696.357 156.631L710.24 149.454C715.953 146.501 722.733 146.501 728.447 149.454L742.329 156.631C748.042 159.585 754.823 159.585 760.536 156.631L774.419 149.454C780.132 146.501 786.913 146.501 792.626 149.454L806.508 156.631C812.221 159.585 819.002 159.585 824.715 156.631L838.598 149.454C844.311 146.501 851.092 146.501 856.805 149.454L879.791 161.338V203.745H109.642V161.338Z"
        fill="#1D4ED8"
      />
    </g>
    <path
      d="M330.512 100.5C330.512 151.883 289.07 193.495 238 193.495C186.93 193.495 145.487 151.883 145.487 100.5C145.487 49.1169 186.93 7.50482 238 7.50482C289.07 7.50482 330.512 49.1169 330.512 100.5Z"
      stroke="#1D4ED8"
      strokeWidth="10"
    />
    <path
      d="M358 147.258V120.871L415.354 30.5H438.801V66.3203H425.197L391.633 119.504V120.598H474.689V147.258H358ZM425.607 170.5V139.191L426.291 127.639V30.5H457.941V170.5H425.607Z"
      fill="#1D4ED8"
    />
  </svg>
);

export default Icon404;
