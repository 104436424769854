import React from 'react';
import classNames from 'classnames';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { Settings } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { useUserPermission } from 'hooks/useUserPermission';
import { UserPermission } from 'components/UserPermission';

interface Props {
  toggleOpen: () => void;
}

export const WorkspaceCurrent: React.FC<Props> = observer(({ toggleOpen }) => {
  const navigate = useNavigate();

  const hasPermission = useUserPermission(['organisation']);

  const {
    workspaceStore: { currentWorkspace },
  } = useAppStore();

  const handleNavigateToWorkspacePageSettings = () => {
    if (!hasPermission) return;
    navigate('/workspace/settings', { replace: true });
    toggleOpen();
  };

  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-between p-3 border-bottom sidebar-profile__popover__workspaces',
        {
          'sidebar-profile__popover__workspaces-has-permission': hasPermission,
        },
      )}
      onClick={handleNavigateToWorkspacePageSettings}
      data-cy="sidebar-profile__popover__workspaces"
    >
      <div className="d-flex flex-fill text-truncate w-1px justify-content-start align-items-center">
        <div
          className="d-block"
          id="current-workspace"
          data-cy="sidebar-profile__popover__workspaces__current-workspace"
        >
          <AvatarProfile
            imgSrc={currentWorkspace?.imageUrl}
            width={36}
            height={36}
            userFullName={currentWorkspace?.name}
            avatarClassNames="sidebar-profile__avatar"
          />

          <UncontrolledTooltip
            placement="top"
            target="current-workspace"
          >
            {currentWorkspace?.name}
          </UncontrolledTooltip>
        </div>

        <div className="ms-1 pe-3 fw-bold text-truncate">{currentWorkspace?.name}</div>
      </div>

      <UserPermission actions={['organisation']}>
        <div
          className="sidebar-profile__popover__workspaces__settings-icon"
          data-cy="sidebar-profile__popover__workspaces__settings-icon"
        >
          <Settings size={18} />
        </div>
      </UserPermission>
    </div>
  );
});
