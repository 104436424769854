import {
  AiChatHistory,
  AiChatQuery,
  AiChatType,
  AIMessage,
  AnalyzeContentInputForm,
  ChatDetailResponse,
  CreateAIMessagePayload,
  UploadFileResponse,
} from 'types';
import apiClient from '../ApiClient';

const endPoint = '/ai/chats';

export const fetchChatHistory = async (params: AiChatQuery): Promise<AiChatHistory[]> => {
  const { data } = await apiClient.get(endPoint, { params });

  return data;
};

export const fetchChatDetail = async (chatId: string, params: AiChatQuery): Promise<ChatDetailResponse> => {
  const { data } = await apiClient.get(`${endPoint}/${chatId}/messages`, { params });

  return data;
};

export const createChat = async ({
  chatType,
  name,
}: {
  chatType: AiChatType;
  name?: string;
}): Promise<AiChatHistory> => {
  const { data } = await apiClient.post(endPoint, { name, chatType });

  return data;
};

export const updateChat = async ({ chatId, name }: { chatId: string; name: string }): Promise<AiChatHistory> => {
  const { data } = await apiClient.put(`${endPoint}/${chatId}`, { name });

  return data;
};

export const deleteChat = async (chatId: string): Promise<void> => {
  const { data } = await apiClient.delete(`${endPoint}/${chatId}`);

  return data;
};

export const analyzeContent = async (chatId: string, payload: AnalyzeContentInputForm): Promise<AiChatHistory> => {
  const { data } = await apiClient.post(`${endPoint}/${chatId}/analyze-content`, payload);

  return data;
};

export const uploadFiles = async (chatId: string, files: File[]): Promise<UploadFileResponse[]> => {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file));

  const { data } = await apiClient.post(`${endPoint}/${chatId}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const createMessage = async (chatId: string, payload: CreateAIMessagePayload): Promise<AIMessage> => {
  const { data } = await apiClient.post<AIMessage>(`${endPoint}/${chatId}/messages`, payload);

  return data;
};

export const applyContent = async (chatId: string, messageId: string): Promise<AIMessage> => {
  const { data } = await apiClient.post(`${endPoint}/${chatId}/apply-content/${messageId}`);

  return data;
};

export const regenerateMessage = async ({
  chatId,
  messageId,
}: {
  chatId: string;
  messageId: string;
}): Promise<AIMessage> => {
  const { data } = await apiClient.post(`${endPoint}/${chatId}/messages/re-generate/${messageId}`);

  return data;
};
