import React from 'react';

export const CheckCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM27.414 17.414C27.7783 17.0368 27.9799 16.5316 27.9753 16.0072C27.9708 15.4828 27.7605 14.9812 27.3896 14.6104C27.0188 14.2395 26.5172 14.0292 25.9928 14.0247C25.4684 14.0201 24.9632 14.2217 24.586 14.586L18 21.172L15.414 18.586C15.0368 18.2217 14.5316 18.0201 14.0072 18.0247C13.4828 18.0292 12.9812 18.2395 12.6104 18.6104C12.2395 18.9812 12.0292 19.4828 12.0247 20.0072C12.0201 20.5316 12.2217 21.0368 12.586 21.414L16.586 25.414C16.9611 25.7889 17.4697 25.9996 18 25.9996C18.5303 25.9996 19.0389 25.7889 19.414 25.414L27.414 17.414Z"
        fill="#1D4ED8"
      />
    </svg>
  );
};
