import React from 'react';
import { ChevronDown, ChevronUp, X } from 'react-feather';
import { Input, ModalBody } from 'reactstrap';
import { Footer } from '../Footer';
import { AppDropDown } from 'components/AppDropDown';
import { ContentFilterModalProps } from 'types';
import { MultiSelect } from 'react-multi-select-component';
import { TSelectOption } from 'models/Option';
import './ContentAdvanceFilter.scss';
import { MultiSelectCheckbox } from './MultiSelectCheckbox/MultiSelectCheckbox';
import { ContentStatus, TDocumentType } from 'models/Content';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { useContentFilterForm } from 'hooks/content/useContentFilterForm';
import { useGetOptionLibraries } from 'hooks';
import { DateRangePicker } from 'components/DateRangePicker';

const contentTypeOptions: TSelectOption[] = [
  { value: '', label: 'All Type' },
  { value: TDocumentType.Folder, label: 'Folder' },
  { value: TDocumentType.Content, label: 'Content' },
];

const contentStatusOptions: TSelectOption[] = [
  { value: '', label: 'All Status' },
  { value: ContentStatus.Published, label: 'Published' },
  { value: ContentStatus.Draft, label: 'Draft' },
  { value: ContentStatus.Inactive, label: 'Inactive' },
];

const ContentAdvanceFilter = ({
  value,
  onClear,
  onCancel,
  onAccept,
  canFilterByStarred = true,
  canFilterByStatus = true,
  canFilterByType = true,
  canFilterByAttemptTime = false,
}: ContentFilterModalProps) => {
  const { data: allContentTypes, isLoading } = useGetOptionLibraries();

  const {
    control,
    onApplyFilter,
    clearAll,
    selectTypeFilter,
    statusWatch,
    kindWatch,
    librariesWatch,
    libraryOptions,
    removeLibraryItem,
    valueContainer,
    setValue,
  } = useContentFilterForm(value, allContentTypes, onAccept, onClear);

  return (
    <div className="d-flex flex-column p-3 contents-filter-modal modal-wrapper">
      <ModalBody className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="gray-900 fw-semibold font-size-18">Filter</div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon me-0 gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>
        {canFilterByType && (
          <div className="mt-2">
            <label className="gray-900 font-size-12">Type</label>
            <Controller
              name="kind"
              control={control}
              render={fields => (
                <AppDropDown
                  dataCy="type-dropdown"
                  items={contentTypeOptions}
                  selected={fields.field.value}
                  disabled={!!statusWatch || librariesWatch?.length !== 0}
                  selectItemFunc={item => {
                    fields.field.onChange(item as TDocumentType);
                    selectTypeFilter(item);
                  }}
                />
              )}
            />
          </div>
        )}
        {canFilterByStatus && (
          <div className="">
            <label className="gray-900 font-size-12">Status</label>
            <Controller
              name="status"
              control={control}
              render={fields => (
                <AppDropDown
                  dataCy="status-dropdown"
                  items={contentStatusOptions}
                  selected={fields.field.value}
                  disabled={kindWatch === TDocumentType.Folder}
                  selectItemFunc={item => fields.field.onChange(item as ContentStatus)}
                />
              )}
            />
          </div>
        )}
        <div className="">
          <label className="gray-900 font-size-12">Content Type</label>
          <Controller
            name="libraries"
            control={control}
            render={fields => (
              <MultiSelect
                options={libraryOptions}
                isLoading={isLoading}
                hasSelectAll={true}
                closeOnChangedValue={false}
                disableSearch={false}
                value={fields.field.value}
                disabled={kindWatch === TDocumentType.Folder}
                onChange={value => fields.field.onChange(value)}
                labelledBy="All Content"
                className={classNames('custom-libraries-select', kindWatch === TDocumentType.Folder && 'disabled')}
                filterOptions={(options, filter) => {
                  return options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase().trim()));
                }}
                overrideStrings={{
                  allItemsAreSelected: 'All Content Type',
                  selectAll: 'All Content Type',
                  selectAllFiltered: 'All Content Type (Filtered)',
                  selectSomeItems: 'All Content Type',
                }}
                ItemRenderer={MultiSelectCheckbox}
                valueRenderer={(selected, options) => valueContainer(selected || [], options?.length)}
                ClearIcon={<X size={20} />}
                ClearSelectedIcon={
                  <X
                    size={20}
                    className="me-1"
                  />
                }
                ArrowRenderer={({ expanded }) => {
                  return expanded ? <ChevronUp /> : <ChevronDown />;
                }}
              />
            )}
          />

          <div className="selected-libraries-wrapper d-flex align-items-center my-2 gap-2 flex-wrap">
            {kindWatch !== TDocumentType.Folder &&
              librariesWatch?.length > 0 &&
              librariesWatch?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="selected-libraries-item bg-primary-50 primary-600 font-size-12"
                  >
                    {item.label}
                    <X
                      size={16}
                      className="cursor-pointer primary-600 ms-2"
                      onClick={() => removeLibraryItem(item)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {canFilterByStarred && (
          <div className="mb-2">
            <label
              data-cy="starred-checkbox"
              className="gray-900 font-size-14 mb-0 cursor-pointer"
            >
              <Controller
                name="starred"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    className="cursor-pointer me-2 p-2"
                    type="checkbox"
                    checked={value}
                    onChange={() => onChange(!value)}
                  />
                )}
              />

              <span
                className="d-inline-block"
                style={{ padding: 2 }}
              >
                Starred
              </span>
            </label>
          </div>
        )}

        {canFilterByAttemptTime && (
          <div className="d-block d-md-none form-group mt-0 mb-2">
            <label className="gray-900 font-size-12">Filter by date</label>
            <DateRangePicker
              fromDate={value.lastAttemptFrom ? new Date(value.lastAttemptFrom * 1000) : null}
              toDate={value.lastAttemptTo ? new Date(value.lastAttemptTo * 1000) : null}
              onDatesChange={(startDate, endDate) => {
                setValue('lastAttemptFrom', startDate);
                setValue('lastAttemptTo', endDate);
              }}
              type="input"
            />
          </div>
        )}
      </ModalBody>

      <Footer
        labelCancel="Clear All"
        labelOK="Apply"
        onOK={onApplyFilter}
        onCancel={() => clearAll()}
      />
    </div>
  );
};

export default ContentAdvanceFilter;
