import { TDocumentType } from 'models/Content';
import React from 'react';
import { FileText, Folder } from 'react-feather';

type IProps = {
  contentType: TDocumentType;
  className?: string;
};

export const ContentTypeIcon = ({ contentType, className }: IProps) => {
  return contentType === TDocumentType.Content ? (
    <FileText
      size={20}
      className={className ?? 'gray-500'}
    />
  ) : (
    <Folder
      size={20}
      className={className ?? 'gray-500'}
    />
  );
};
