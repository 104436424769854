import { SetupIntent } from '@stripe/stripe-js';
import { createSetupIntent, getAllPaymentMethods, getLatestPaymentIntent } from 'apis/Payment/PaymentMethod';
import { AppDropDown } from 'components/AppDropDown';
import { useAppStore } from 'hooks/useAppStore';
import useModal from 'hooks/useModal';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button } from 'reactstrap';
import './PaymentRetry.scss';
import { PayIcon } from 'pages/Organisation/BillingPage/components/PaymentMethods/PayIcon';
import { useLogout } from 'hooks';
import { LogOut } from 'react-feather';
import { PaymentMethodData } from 'types/payment';
import { useStripe } from '@stripe/react-stripe-js';
import { syncCurrentSubscription } from 'apis/Payment/Subscription';
import { SubscriptionStatus } from '@spiderbox/common';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading } from 'components/Loading';
import { useClickAway } from 'react-use';

export const PaymentRetry = observer(({ defaultPaymentMethodId }: { defaultPaymentMethodId?: string }) => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    workspaceStore: { currentWorkspaceId },
    subscriptionStore: { updateCurrentSubscription },
    ltiMode,
  } = useAppStore();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>(defaultPaymentMethodId);
  const { data: paymentMethods = [], isLoading } = useQuery(
    ['workspace', currentWorkspaceId, 'cards'],
    async () => await getAllPaymentMethods(),
    {
      onSuccess: data => {
        setSelectedPaymentMethodId(selectedPaymentMethodId ? selectedPaymentMethodId : data[0].id);
      },
    },
  );
  const { mutateAsync: setupIntentPayment, isLoading: setupIntentLoading } =
    useMutation<SetupIntent>(createSetupIntent);

  const { mutate: logout } = useLogout();
  const stripe = useStripe();
  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [invalidCardErr, setInvalidCardErr] = useState<string>(null);
  const modalRef = useRef(null);
  useClickAway(modalRef, () => closeModal());

  const paymentMethodOptions = useMemo(() => {
    return paymentMethods.map(payment => ({
      value: payment.id,
      label: '**** **** **** ' + payment.data.last4,
      itemIcon: <PayIcon brand={payment.data.brand} />,
    }));
  }, [paymentMethods]);

  const openAddPaymentMethodModal = async () => {
    const paymentIntent = await setupIntentPayment();

    openModal('add-payment-method', {
      onAccept: (paymentMethod: PaymentMethodData) => {
        openModal('payment-retry', { defaultPaymentMethodId: paymentMethod.id });
        queryClient.setQueriesData(['workspace', currentWorkspaceId, 'cards'], (data: PaymentMethodData[]) => {
          return [paymentMethod, ...data];
        });
        setSelectedPaymentMethodId(paymentMethod.id);
      },
      onCancel: () => openModal('payment-retry'),
      paymentIntent,
    });
  };

  const retryPayment = async () => {
    setRetryLoading(true);
    const latestPaymentIntent = await getLatestPaymentIntent();
    const result = await stripe.confirmCardPayment(latestPaymentIntent.clientSecret, {
      payment_method: selectedPaymentMethodId,
    });
    // Handle result.error or result.paymentIntent
    if (result.error) {
      setInvalidCardErr(result.error?.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        const subscription = await syncCurrentSubscription();
        if (subscription.status === SubscriptionStatus.ACTIVE) {
          queryClient.invalidateQueries(['workspace', currentWorkspaceId, 'invoices']);
          updateCurrentSubscription(subscription);
          closeModal();
          if (!ltiMode) navigate('/dashboard');
          toast('Congratulations! Your plan is activated and ready to use.');
        }
      }
    }
    setRetryLoading(false);
  };

  return (
    <div
      ref={modalRef}
      className="payment-retry-modal p-3 d-flex flex-column"
    >
      <div className="gray-900 fw-bold text-center">Your plan has expired</div>

      <div className="text-neutral-500 font-size-13 text-center">
        <div>Please update your payment details to reactivate it.</div>
        <div className="mt-1">
          Payment will process instantly once you click on <span className="fw-medium">Retry.</span>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="d-flex flex-column gray-900 font-size-13">
          <div className="font-size-13 text-neutral-400">Card Number</div>
          <div className="mt-2">
            <AppDropDown
              dataCy="payment-methods"
              items={paymentMethodOptions}
              selected={selectedPaymentMethodId}
              selectItemFunc={(value: string) => setSelectedPaymentMethodId(value)}
              invalid={!!invalidCardErr}
            />
          </div>

          {invalidCardErr && (
            <div className="d-flex justify-content-end mt-2 invalid-card-error font-size-14">{invalidCardErr}</div>
          )}

          <div className="mt-3 d-flex align-items-center">
            <Button
              className="me-2 w-50"
              outline
              color="primary"
              disabled={setupIntentLoading || retryLoading}
              onClick={() => openAddPaymentMethodModal()}
              data-cy="new-card-btn"
            >
              New card
            </Button>

            {selectedPaymentMethodId && (
              <Button
                className="w-50"
                color="primary"
                disabled={setupIntentLoading || retryLoading}
                onClick={() => {
                  retryPayment();
                }}
                data-cy="retry-btn"
              >
                Retry
                {retryLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
            )}
          </div>

          {!ltiMode && (
            <div className="d-flex justify-content-end mt-3">
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={() => logout(false)}
                data-cy="log-out-btn"
              >
                <span className="gray-900">Log Out</span>

                <LogOut
                  className="ms-2"
                  size={20}
                  strokeWidth={2}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
