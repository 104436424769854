import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Collapse } from 'reactstrap';
import './ContentLtiSettings.scss';
import { AppDropDown } from 'components/AppDropDown';
import { ContentAttemptSetting } from '@spiderbox/common';
import { observer } from 'mobx-react-lite';

const sendScoreForOptions: { value: ContentAttemptSetting; label: string }[] = [
  { value: ContentAttemptSetting.FIRST_ATTEMPT, label: 'First attempt' },
  { value: ContentAttemptSetting.LAST_ATTEMPT, label: 'Last attempt' },
  { value: ContentAttemptSetting.BEST_ATTEMPT, label: 'Best attempt' },
  { value: ContentAttemptSetting.DO_NOT_SEND, label: 'Do not send score' },
];

type Props = {
  defaultValues?: ContentLtiSettingInput;
  onChange?: (formValues: ContentLtiSettingInput) => void;
};

export type ContentLtiSettingInput = {
  ltiMaxWidth?: string;
  ltiSendScoreOn?: string;
  ltiCanResume?: boolean;
};

export const ContentLtiSettings = observer(({ defaultValues, onChange }: Props) => {
  const [ltiSettingCollapse, toggleLtiSettingCollapse] = useState(false);

  const { control, watch } = useForm<ContentLtiSettingInput>({ defaultValues });

  watch(values => {
    onChange?.(values);
  });

  return (
    <div className="row content-lti-settings">
      <div className="col-5 d-flex">
        {ltiSettingCollapse ? (
          <ChevronRight
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleLtiSettingCollapse(!ltiSettingCollapse)}
          />
        ) : (
          <ChevronDown
            size={24}
            className="gray-900 me-2 cursor-pointer"
            onClick={() => toggleLtiSettingCollapse(!ltiSettingCollapse)}
          />
        )}

        <div className="d-flex flex-column">
          <div className="gray-900 font-size-16 fw-semibold">
            <span
              className="cursor-pointer"
              onClick={() => toggleLtiSettingCollapse(!ltiSettingCollapse)}
            >
              LTI Settings
            </span>
          </div>

          <Collapse isOpen={!ltiSettingCollapse}>
            <div className="gray-600 mt-2">Set up interoperability for this content.</div>
          </Collapse>
        </div>
      </div>

      <Collapse
        className="col-7 cursor-pointer"
        isOpen={!ltiSettingCollapse}
      >
        <div className="d-flex flex-column gap-4 w-100 w-md-75">
          <div className="d-flex flex-column gap-2">
            <div className="gray-900 fw-medium">Select when to send score to LMS:</div>
            <div>
              <Controller
                control={control}
                name="ltiSendScoreOn"
                render={({ field }) => (
                  <AppDropDown
                    items={sendScoreForOptions}
                    selected={field.value}
                    selectItemFunc={(item: any) => field.onChange(item)}
                  />
                )}
              />
            </div>
            <div className="gray-600">
              <span className="fw-semibold">Guiding text:</span> If you choose to send a score we will store grades in
              any LMS where the content is embedded via LTI. Any grades already sent to the LMS will not be resubmitted
              when this setting changes.
            </div>

            <div className="gray-600">
              <span className="fw-semibold">Important:</span> It is possible for computer savvy learners to cheat in
              Evokio and always get full score. Evokio should not be used for exams.
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
});
