import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TitleForm } from '../TitleForm/TitleForm';
import { rename } from 'apis/Folder/Folder';
import { toast } from 'react-toastify';
import { Document } from 'models/Content';
import { fetchServerError } from 'utils';
import { ErrorResponse } from 'types';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';

export type RenameModalProps = {
  onOk?: (value?: any) => void;
  onCancel: () => void;
  defaultValue?: string;
  labelOK: string;
  inputLabel?: string;
  inputPlaceHolder?: string;
  headerTitle: string;
  currentId?: string;
};
export const Rename = observer((props: RenameModalProps) => {
  const { queryKey, userInfo } = useAppStore();

  const { currentId, onOk } = props;
  const queryClient = useQueryClient();
  const { mutate, reset } = useMutation(rename, {
    retry: false,
    onSuccess: (data, { title }) => {
      toast('Title has been updated.', { type: 'success' });
      queryClient.setQueryData<Document[]>(queryKey, previousData => {
        return previousData.map(doc => {
          if (doc.id === currentId) {
            return { ...doc, title: title, updatedAt: data.updatedAt, updatedBy: userInfo };
          }
          return doc;
        });
      });
      onOk?.();
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  const submit = ({ title }: { title: string }) => {
    mutate({ id: currentId, title });
    reset();
  };

  return (
    <TitleForm
      {...props}
      onOk={submit}
      inputLabel="Title"
    />
  );
});
