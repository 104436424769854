import React from 'react';
import classNames from 'classnames';
import { MenuItem } from '../MenuItem';
import { UserPermission } from 'components/UserPermission';
import { SubMenuType } from 'types/sidebar';

export interface SubMenuProps {
  subMenu: SubMenuType[];
  isShowing: boolean;
  depth: number;
}

export const SubMenu = ({ subMenu, isShowing, depth }: SubMenuProps) => {
  return (
    <ul className={classNames('sub-menu mm-collapse', { 'mm-show': isShowing })}>
      {subMenu.map(({ title, link, actions }: SubMenuType) => {
        return (
          <UserPermission
            actions={actions}
            key={link}
          >
            <MenuItem
              to={link}
              title={title}
              depth={depth}
              className="px-0 py-0 mt-1"
            />
          </UserPermission>
        );
      })}
    </ul>
  );
};
