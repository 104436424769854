import React from 'react';
import Icon404 from 'assets/icons/Icon404';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './NotFoundPage.scss';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ltiKey = searchParams.get('ltik');
  const workspaceId = searchParams.get('workspaceId');

  const path = ltiKey ? `/${workspaceId}/lti/launch?ltik=${ltiKey}` : '/dashboard';
  const titleButton = ltiKey ? 'Go Back' : 'Back to Dashboard';

  return (
    <div className="bg-white vw-100 vh-100 d-flex flex-column align-items-center not-found-page">
      <div className="container py-16 m-auto text-center d-flex flex-column align-items-center">
        <h2>Looks like you got lost</h2>
        <Icon404 />
        <p>The page you are trying to access does not exist. Please go back and try again</p>
        <button
          className="btn btn-outline-primary"
          onClick={() => navigate(path)}
        >
          {titleButton}
        </button>
      </div>
    </div>
  );
};
