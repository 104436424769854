import { useAppStore } from 'hooks/useAppStore';
import { ContentBehaviorOptions } from 'types/setting';

export const useGetContentDefaultSetting = () => {
  const { workspaceStore } = useAppStore();
  const { setting } = workspaceStore;

  const { contentBehavior } = setting || {};

  const {
    contentBehaviorDisplayEmbedButton,
    contentBehaviorDisplayToolbar,
    contentBehaviorDisplayTotalAttempts,
    contentBehaviorUsersCanDownload,
  } = contentBehavior || {};

  const defaultSetting = {
    showEmbedButton: [ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT, ContentBehaviorOptions.ALWAYS].includes(
      contentBehaviorDisplayEmbedButton,
    ),
    showToolbarBelow: contentBehaviorDisplayToolbar === 'true',
    showTotalAttempts: contentBehaviorDisplayTotalAttempts === 'true',
    showDownloadButton: [
      ContentBehaviorOptions.CONTROLLED_BY_EDITOR_ON_DEFAULT,
      ContentBehaviorOptions.ALWAYS,
    ].includes(contentBehaviorUsersCanDownload),
    showCopyButton: true,
    showEditorName: false,
  };

  return defaultSetting;
};
