import { ErrorResponse } from 'types';
import apiClient from '../ApiClient';
import { ResetPasswordRequest } from 'models/Password';

export async function forgotPassword(payload: string): Promise<any | ErrorResponse> {
  return await apiClient.post('auth/forgot-password', payload).then(response => {
    return response.data;
  });
}

export async function resetPassword(payload: ResetPasswordRequest): Promise<any | ErrorResponse> {
  return await apiClient.post('auth/reset-password', payload).then(response => {
    return response.data;
  });
}
