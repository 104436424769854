import React, { useMemo } from 'react';
import { useAppStore } from 'hooks/useAppStore';
import './Header.scss';
import { ChevronLeft, X } from 'react-feather';
import { ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { AiChatType } from 'types';
import { ExpandCollapseButton } from '../ExpandCollapseButton';

interface Props {
  onClose: () => void;
  isWelcome: boolean;
  expanded: boolean;
  toggleExpand: (value: boolean) => void;
  displayWelcomeScreen: () => void;
  changeViewMode: (mode: AiChatType) => void;
  viewMode: AiChatType;
  previousViewMode: AiChatType;
}

const Header = ({
  onClose,
  isWelcome,
  expanded,
  toggleExpand,
  displayWelcomeScreen,
  changeViewMode,
  viewMode,
  previousViewMode,
}: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();

  const isHistoryView = useMemo(() => viewMode === AiChatType.HISTORY, [viewMode]);

  const handleClickBackToChat = () => {
    if (previousViewMode === AiChatType.HISTORY) {
      changeViewMode(null);
    } else {
      changeViewMode(previousViewMode);
    }
  };

  if (isHistoryView) {
    return (
      <div className="d-flex justify-content-between align-items-center flex-fill">
        <div className="gap-3 d-flex align-items-center flex-fill">
          <ChevronLeft
            className="flex-shrink-0 cursor-pointer gray-400"
            width={24}
            height={24}
            onClick={handleClickBackToChat}
          />
          <div className="font-size-20 text-neutral-400 font-weight-medium">Back to chat</div>
        </div>

        <div className="gap-3 d-flex align-items-start header-assistant__action-icon">
          <ExpandCollapseButton
            expanded={expanded}
            onToggle={toggleExpand}
          />
          <div className="divider" />
          <X
            className="cursor-pointer close-icon gray-400"
            strokeWidth={2}
            size={20}
            onClick={onClose}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="gap-4 d-flex align-items-center header-assistant">
        <div className="d-flex align-items-center">
          <AIMascot
            faceSrc={happyFace}
            className={isWelcome ? 'ai-mascot' : 'ai-mascot ai-mascot-large'}
            isFloat={false}
          />
        </div>

        <div className="d-flex flex-column">
          {isWelcome ? (
            <>
              <div className="header-assistant__welcome">Hello, {fullName}</div>
              <div className="text-neutral-400">How can I help you?</div>
            </>
          ) : (
            <div className="header-assistant__welcome">Michi Assistant</div>
          )}
        </div>
      </div>
      <div className="gap-3 d-flex align-items-start header-assistant__action-icon">
        <PencilSquareIcon
          className="cursor-pointer tooltip-icon d-none d-lg-block"
          strokeWidth={2}
          id="edit-tooltip"
          onClick={displayWelcomeScreen}
        />
        <UncontrolledTooltip
          placement="bottom"
          target="edit-tooltip"
        >
          Start a new chat
        </UncontrolledTooltip>
        <ClockIcon
          className="cursor-pointer tooltip-icon d-none d-lg-block"
          strokeWidth={2}
          id="clock-tooltip"
          onClick={() => changeViewMode(AiChatType.HISTORY)}
        />
        <UncontrolledTooltip
          placement="bottom"
          target="clock-tooltip"
        >
          View chat history
        </UncontrolledTooltip>
        <ExpandCollapseButton
          expanded={expanded}
          onToggle={toggleExpand}
        />
        <div className="divider"></div>
        <X
          className="cursor-pointer close-icon gray-400"
          strokeWidth={2}
          size={20}
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default Header;
