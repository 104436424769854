import React from 'react';
import { formInputsSchema } from 'libs/yup';
import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorResponse, SignUpFormInput } from 'types';
import TextField from 'components/AppForm/TextField';
import PasswordField from 'components/AppForm/PasswordField';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import leftImage from 'assets/images/auth/sign-up-logo.svg';
import { appStore } from 'stores/AppStore';
import './SignUp.scss';
import { observer } from 'mobx-react-lite';
import { useMutation } from 'react-query';
import { acceptInvitation, signUp } from 'apis/Auth/Auth';
import { toast } from 'react-toastify';
import { LoginResponse } from 'types';
import { Helmet } from 'react-helmet-async';
import { fetchControlError, fetchServerError } from 'utils';
import IconLogo from 'assets/icons/IconLogo';

export const SignUpPage = observer(() => {
  const {
    setError,
    control,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<SignUpFormInput>({
    resolver: yupResolver(formInputsSchema as any),
  });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('token');

  const [pwdWatch, pwdConfirmWatch] = useWatch({
    control,
    name: ['password', 'passwordConfirmation'],
  });

  useEffect(() => {
    if (pwdWatch === pwdConfirmWatch && errors?.passwordConfirmation?.type === 'oneOf') {
      clearErrors('passwordConfirmation');
    }
  }, [pwdWatch, pwdConfirmWatch, errors]);

  useEffect(() => {
    if (
      pwdWatch !== pwdConfirmWatch &&
      errors?.passwordConfirmation?.type !== 'oneOf' &&
      dirtyFields?.passwordConfirmation
    ) {
      setError('passwordConfirmation', {
        type: 'oneOf',
        message: 'Confirmation Password does not match.',
      });
    }
  }, [pwdWatch, pwdConfirmWatch, errors]);

  const registerAccount = invitationToken ? acceptInvitation : signUp;

  const {
    isLoading,
    mutate,
    error: serverError,
  } = useMutation(registerAccount, {
    retry: false,
    onSuccess: (data: LoginResponse) => {
      appStore.updateAccessToken(data.accessToken);
      appStore.updateRefreshToken(data.refreshToken);

      const successPath = invitationToken ? '/dashboard' : '/welcome';
      navigate(successPath, { replace: true });
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });

  const onSubmit = async (values: SignUpFormInput) => {
    mutate({ ...values, invitationToken });

    reset({}, { keepValues: true });
  };

  const getFieldErrorMessage = (key: string): string => {
    return fetchControlError(errors, serverError, key, dirtyFields[key]);
  };

  return (
    <PerfectScrollbar options={{ suppressScrollX: true, useBothWheelAxes: false }}>
      <div className="sign-up-wrapper">
        <div className="sign-up--tab bg-primary-400">
          <div className="sign-up--left">
            <div>
              <IconLogo
                isVariant={true}
                className="mb-3"
              />

              <div className="sign-up--left__title primary-50">Create, share, and re-use interactive HTML5 content</div>
            </div>
            <img
              className="sign-up--left__img"
              src={leftImage}
            />
          </div>
        </div>
        <div className="sign-up--tab sign-up--bg-color">
          <div className="sign-up--right d-flex flex-column justify-content-center">
            <div className="sign-up--right__title gray-900">Sign up to Evokio</div>
            <form
              className="mt-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Helmet>
                <title>Sign Up</title>
              </Helmet>
              <div className="d-flex gap-4 w-100">
                <div className="w-50 form-group">
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        placeholder="Input first name"
                        label="First Name"
                        error={error}
                        dataCy="first-name-field"
                      />
                    )}
                  />

                  <p
                    className="feedback-invalid"
                    data-cy="invalid-first-name"
                  >
                    {errors.firstName?.message}
                  </p>
                </div>
                <div className="w-50 form-group">
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        placeholder="Input last name"
                        label="Last Name"
                        error={error}
                        dataCy="last-name-field"
                      />
                    )}
                  />

                  <p
                    className="feedback-invalid"
                    data-cy="invalid-last-name"
                  >
                    {errors.lastName?.message}
                  </p>
                </div>
              </div>

              <div className="form-group">
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      placeholder="john@example.com"
                      label="Email"
                      error={error || (serverError?.errors?.email && !dirtyFields.email)}
                      dataCy="email-field"
                      maxLength={255}
                    />
                  )}
                />

                <p
                  className="feedback-invalid"
                  data-cy="invalid-email"
                >
                  {getFieldErrorMessage('email')}
                </p>
              </div>

              <div className="form-group pt-2">
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <PasswordField
                      {...field}
                      placeholder="Input password"
                      label="Password"
                      error={error}
                      dataCy="password-field"
                    />
                  )}
                />

                {errors.password?.message && (
                  <p
                    className="feedback-invalid"
                    data-cy="invalid-password"
                  >
                    {errors.password?.message}
                  </p>
                )}
                {!errors.password?.message && (
                  <p className="password-guide mt-2 mb-0">
                    Password must have at least 8 characters including a number.
                  </p>
                )}
              </div>

              <div className="form-group pt-2">
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <PasswordField
                      {...field}
                      placeholder="Confirm password"
                      label="Confirm Password"
                      error={error}
                      dataCy="confirm-password-field"
                    />
                  )}
                />

                <p className="feedback-invalid">{errors.passwordConfirmation?.message}</p>
              </div>

              <div className="form-group mt-3">
                <Controller
                  name="agreement"
                  control={control}
                  render={({ field }) => (
                    <label className="d-flex cursor-pointer form-check checkbox-lg gray-500 mb-0">
                      <input
                        type="checkbox"
                        {...field}
                        value={'true'}
                        checked={field.value}
                        className="form-check-input cursor-pointer primary-500"
                        data-cy="agreement-field"
                      />
                      <div className="ms-2 font-size-14">
                        Creating an account means you accept Sign up to Evokio&apos;s Terms of Service and Privacy
                        Policy.
                      </div>
                    </label>
                  )}
                />
                <p
                  className="feedback-invalid"
                  data-cy="invalid-agreement"
                >
                  {errors.agreement?.message}
                </p>
              </div>
              <div className="d-flex mt-4">
                <button
                  className="btn btn-primary w-100 px-3 py-2 text-white"
                  type="submit"
                  disabled={isLoading}
                  data-cy="btn-submit"
                >
                  Create Account
                  {isLoading && (
                    <span
                      className="ms-3 spinner-border spinner-border-sm text-light"
                      role="status"
                    ></span>
                  )}
                </button>
              </div>

              <div className="divider my-4"></div>

              <div className="d-flex justify-content-center align-items-center gray-900">
                Already a member?{' '}
                <Link
                  className="primary-500 ms-1"
                  to="/auth/login"
                >
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  );
});
