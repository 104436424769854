import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export const CheckAuth = observer(() => {
  const appStore = useAppStore();
  const location = useLocation();

  if (appStore.ltiMode) {
    return (
      <Navigate
        to={`/${appStore.ltiStore.workspaceId}/lti/launch`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (appStore.authenticated) {
    return (
      <Navigate
        to="/"
        state={{ from: location }}
        replace
      />
    );
  }
  return <Outlet />;
});
