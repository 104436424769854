import React, { useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
import './SearchBar.scss';
import { useDebounce } from 'react-use';

const SearchBar = ({
  placeholder = '',
  search,
  value,
  timeDelay,
}: {
  placeholder?: string;
  search?: (value: string) => void;
  value?: string;
  timeDelay?: number;
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(!value ? '' : value);
  }, [value]);

  useDebounce(
    () => {
      search?.(inputValue);
    },
    timeDelay ?? 300,
    [inputValue],
  );

  return (
    <div
      className="d-flex align-items-center pe-3 app-custom-search search-wrapper"
      data-cy="search-field"
    >
      <div className="ps-3">
        <Search
          size={20}
          className="gray-400"
        />
      </div>

      <input
        data-cy="search-input"
        className="search-input d-flex align-items-center h-100 px-2 flex-fill w-1px text-truncate"
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
      />

      {value && (
        <X
          data-cy="clear-icon"
          size={20}
          className="search-icon cursor-pointer"
          onClick={() => setInputValue('')}
        />
      )}
    </div>
  );
};

export default React.memo(SearchBar);
