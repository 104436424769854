import { EMAIL_REGEXP } from 'constants/regexp';

import yupPassword from 'yup-password';
yupPassword(yup);

import * as yup from 'yup';

export const formInputsSchema = yup.object({
  firstName: yup.string().trim().required('First Name is required.'),
  lastName: yup.string().trim().required('Last Name is required.'),
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .matches(EMAIL_REGEXP, 'Email is invalid.')
    .max(255, 'Email length must be less than 255 characters.'),
  password: yup
    .string()
    .required('Password is required.')
    .minNumbers(1, 'Password must have at least 8 characters including a number.')
    .min(8, 'Password must have at least 8 characters including a number.'),
  passwordConfirmation: yup
    .string()
    .required('Confirmation password is required.')
    .oneOf([yup.ref('password'), null], 'Confirmation password does not match.'),
  agreement: yup
    .bool()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Current password is required.')
    .minNumbers(1, 'Current password must have at least 8 characters including a number.')
    .min(8, 'Current password must have at least 8 characters including a number.'),
  newPassword: yup
    .string()
    .required('New password is required.')
    .minNumbers(1, 'New password must have at least 8 characters including a number.')
    .min(8, 'New password must have at least 8 characters including a number.'),
  passwordConfirmation: yup
    .string()
    .required('Confirmation password is required.')
    .oneOf([yup.ref('newPassword'), null], 'Confirmation password does not match.'),
});
