import { AppDropDown } from 'components/AppDropDown';
import { PageHeader } from 'components/PageHeader';
import SearchBar from 'components/SearchBar/SearchBar';
import { TSelectOption } from 'models/Option';
import { parse, stringify } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { UserPlus } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { UsersTable } from './components';
import { useQuery, useQueryClient } from 'react-query';
import { UserSortType, User } from 'types/user';
import { fetchUsers } from 'apis/User/User';
import useModal from 'hooks/useModal';
import { UserPermission } from 'components/UserPermission';

const userStatusOptions: TSelectOption[] = [
  { value: '', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
];

export const ManageUsersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldOrderByAsc, toggleShouldOrderByAsc] = useState<boolean>(searchParams.get('sortOrder') === 'desc');
  const [filters, setFilters] = useState({});
  const queryClient = useQueryClient();

  const { control, watch } = useForm({
    defaultValues: {
      search: searchParams.get('search') || '',
      status: searchParams.get('status') || '',
    },
  });

  const search = watch('search');

  const searchTerm = useMemo(() => {
    const search = parse(searchParams.toString());
    queryClient.setQueryData(['users', 'searchTerm'], search);
    return search;
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(prevValue => {
      const preQueries = parse(prevValue.toString());
      if (search === '') {
        delete preQueries.search;
        return stringify({ ...preQueries, ...filters });
      }
      return stringify({ ...preQueries, ...filters, search: search.trim() });
    });
  }, [search, filters]);

  const { data: users, isFetching } = useQuery<User[]>(
    ['users', 'all', searchTerm],
    async () => await fetchUsers(searchTerm),
    {
      cacheTime: 0,
      staleTime: 300000,
    },
  );

  const orderBy = (filter: UserSortType) => {
    toggleShouldOrderByAsc(!shouldOrderByAsc);

    setSearchParams(prevValue => {
      const preQueries = parse(prevValue.toString());
      return { ...preQueries, sortBy: filter, sortOrder: shouldOrderByAsc ? 'asc' : 'desc' };
    });
  };

  const renderSubHeader = () => {
    return (
      <div className="p-3 px-md-4 pt-md-4 bg-neutral-50 sub-header">
        <div className="d-flex align-items-center">
          <div className="pe-4">
            <Controller
              name="status"
              control={control}
              render={fields => {
                return (
                  <AppDropDown
                    items={userStatusOptions}
                    selected={fields.field.value}
                    selectItemFunc={item => {
                      fields.field.onChange(item);
                      setFilters({ ...filters, status: item });
                    }}
                    dataCy="status-filter"
                  />
                );
              }}
            />
          </div>

          <div className="flex-fill">
            <Controller
              name="search"
              control={control}
              render={fields => {
                return (
                  <SearchBar
                    value={fields.field.value}
                    search={fields.field.onChange}
                    placeholder="Search for name or email..."
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const { openModal, closeModal } = useModal();

  const openInviteUsersModal = () => {
    openModal('invite-users', {
      onCancel: closeModal,
    });
  };

  return (
    <>
      <PageHeader
        title="Manage Users"
        rightElement={
          <UserPermission actions={['user.invite']}>
            <div className="d-flex align-items-center">
              <Button
                className="d-flex align-items-center"
                color="primary"
                data-cy="btn-invite-users"
                onClick={openInviteUsersModal}
              >
                <UserPlus
                  className="d-none d-sm-block me-2"
                  strokeWidth={1.5}
                  size={20}
                  type="outline"
                  data-cy="invite-users-icon"
                />
                <span>Invite Users</span>
              </Button>
            </div>
          </UserPermission>
        }
        subHeader={renderSubHeader()}
      />

      <div className="flex-fill d-flex flex-column p-3 p-md-4">
        <div className="flex-fill">
          <UsersTable
            data={users}
            isLoading={isFetching}
            orderBy={orderBy}
            searchTerm={searchTerm}
          />
        </div>
      </div>
    </>
  );
};
