import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { ContentUserDataReports } from '../ContentUserDataReports';
import { ContentUserAttemptDetails } from '../ContentUserAttemptDetails';
import { ContentUserAttempts } from '../ContentUserAttempts';
import { ContentEditorTab } from '../components/ContentEditorTab';
import { ContentPlayerTab } from '../components/ContentPlayerTab';
import { ContentSettings } from '../components/ContentSettings';
import { ProtectedRoute } from 'routes/protectedRoute';
import { useAppStore } from 'hooks/useAppStore';
import { ContentUserReportPage } from '../components/ContentUserReportPage';
import { UserPermission } from 'components/UserPermission';

export const EditorRouter = () => {
  const { search, state } = useLocation();
  const {
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  return (
    <>
      <Route
        index
        element={
          <Navigate
            to={{ pathname: 'edit', search }}
            state={state}
          />
        }
      />

      <Route
        path="edit"
        element={<ContentEditorTab />}
      />

      <Route
        path="settings"
        element={<ContentSettings />}
      />

      <Route
        path="preview"
        element={<ContentPlayerTab />}
      />

      <Route
        path="reports"
        element={
          <ProtectedRoute
            isAllowed={!!currentSubscription?.studentReportItem}
            redirectPath="../"
          >
            <UserPermission
              actions={['report']}
              renderHasNotPermission={
                <Navigate
                  to="../"
                  replace={true}
                />
              }
            >
              <ContentUserReportPage />
            </UserPermission>
          </ProtectedRoute>
        }
      >
        <Route
          path=""
          element={<ContentUserDataReports />}
        />

        <Route
          path=":userId"
          element={<ContentUserAttempts />}
        />

        <Route
          path=":userId/attempts/:attemptId"
          element={<ContentUserAttemptDetails />}
        />
      </Route>
    </>
  );
};
