import React, { useEffect, useMemo, useRef, useState } from 'react';
import './NewContent.scss';
import { ArrowLeft, Grid, List, X } from 'react-feather';
import { TViewMode } from 'models/Content';
import { Library, LibraryQuery } from 'types';
import { getLibraries } from 'apis/Library/Library';
import { mappingLibrary } from 'helpers/libraries';
import { useQuery } from 'react-query';
import { TNavBarValue } from 'types/header';
import { useDebounce } from 'react-use';
import { ErrorEmptyBlock } from 'components/ErrorEmptyBlock';
import { LibraryGallery } from 'pages/LibraryPage/components/LibraryGallery';
import LibraryList from 'pages/LibraryPage/components/LibraryList/LibraryList';
import { LibraryNav } from 'pages/LibraryPage/components/LibraryNav';
import { Loading } from 'components/Loading';
import SearchBar from 'components/SearchBar/SearchBar';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import useModal from 'hooks/useModal';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export type NewContentModalProps = {
  title?: string;
  onCancel?: () => void;
};

export const NewContent = observer(({ title = 'New Content', onCancel }: NewContentModalProps) => {
  const [viewMode, setViewMode] = useState<TViewMode>('gallery');
  const [queryParams, setQueryParams] = useState<LibraryQuery>({ orderBy: 'popularity' });
  const headerRef = useRef<HTMLDivElement>(null);
  const { closeModal, openModal } = useModal();
  const { control, watch } = useForm({
    defaultValues: {
      search: '',
    },
  });
  const { ltiMode } = useAppStore();

  const search = watch('search');

  const scrollToTop = () => {
    headerRef?.current?.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const searchTerm = useMemo(() => {
    scrollToTop();
    return queryParams;
  }, [queryParams]);

  const fetchLibraries = async (queries: LibraryQuery) => {
    queries.orderBy = queries.orderBy || 'popularity';
    const data = (await getLibraries({ ...queries })) || [];
    return mappingLibrary(data);
  };

  const { data: libraries, isLoading } = useQuery<Library[]>(
    ['libraries', searchTerm],
    () => fetchLibraries(searchTerm),
    { cacheTime: 0, staleTime: 300000, keepPreviousData: false },
  );

  const handleSetFilterBy = (filter: TNavBarValue) => {
    setQueryParams({ ...queryParams, orderBy: filter });
  };

  useDebounce(
    () => {
      const params = { ...queryParams, search: search.trim() };

      if (params.search === '') delete params.search;

      setQueryParams(params);
    },
    500,
    [search],
  );

  useEffect(() => {
    scrollToTop();
  }, [viewMode]);

  const renderContent = () => {
    if (!libraries.length)
      return (
        <ErrorEmptyBlock
          title="New Content at your fingertips!"
          description="Click 'New Content' on the sidebar to create content."
        />
      );

    return viewMode === 'gallery' ? <LibraryGallery libraries={libraries} /> : <LibraryList libraries={libraries} />;
  };

  const openModalUpload = () => {
    openModal('upload-content', {
      onAccept: () => {
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  return (
    <div
      className="h-100 new-content-modal modal-wrapper bg-neutral-50"
      ref={headerRef}
    >
      <div className="new-content-modal__header sticky-top pt-4">
        <div className="header-top d-flex justify-content-between align-items-center px-4 pb-4 w-100">
          <div className="d-flex align-items-center">
            {ltiMode && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-link back-button"
                >
                  <ArrowLeft
                    size={20}
                    className="me-1 align-top"
                  />
                  Back
                </button>
                <div className="page-header__divider me-3"></div>
              </>
            )}
            <div
              className="gray-900 fw-semibold font-size-18"
              data-cy="modal-title"
            >
              {title}
            </div>
          </div>
          {!ltiMode && (
            <X
              onClick={() => onCancel?.()}
              className="close-icon gray-400 cursor-pointer d-block"
              size={24}
              data-cy="close-icon"
            />
          )}
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <LibraryNav
            filterBy={searchTerm.orderBy}
            handleSetFilterBy={handleSetFilterBy}
          />
          <div className="d-none d-sm-block mx-4">
            <button
              className="btn btn-outline-primary"
              onClick={openModalUpload}
            >
              Upload
            </button>
          </div>
        </div>

        <div className="p-4 bg-neutral-50">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <Controller
                name="search"
                control={control}
                render={fields => {
                  return (
                    <SearchBar
                      value={fields.field.value}
                      search={fields.field.onChange}
                      placeholder="Search for content type..."
                    />
                  );
                }}
              />
            </div>

            <div className="ms-3 d-flex">
              <div
                className={classNames('btn-view-mode gallery', { active: viewMode === 'gallery' })}
                data-cy="btn-gallery-mode"
                onClick={() => setViewMode('gallery')}
              >
                <Grid
                  size={20}
                  className={classNames(viewMode === 'gallery' ? 'text-white' : 'primary-500')}
                />
              </div>
              <div
                className={classNames('btn-view-mode list', { active: viewMode === 'list' })}
                data-cy="btn-list-mode"
                onClick={() => setViewMode('list')}
              >
                <List
                  size={20}
                  className={classNames(viewMode === 'list' ? 'text-white' : 'primary-500')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 pt-2 pb-4">{isLoading ? <Loading /> : renderContent()}</div>
    </div>
  );
});
