export enum ContentLibrary {
  H5P_DRAG_TEXT = 'H5P.DragText',
  H5P_TRUE_FALSE = 'H5P.TrueFalse',
  H5P_BLANKS = 'H5P.Blanks',
  H5P_MARK_THE_WORDS = 'H5P.MarkTheWords',
  H5P_MULTIPLE_CHOICE = 'H5P.MultiChoice',
  H5P_CROSSWORD = 'H5P.Crossword',
  H5P_QUESTION_SET = 'H5P.QuestionSet',
  H5P_DIALOG_CARDS = 'H5P.Dialogcards',
}
