import React from 'react';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { AppBadge } from 'components/AppBadge';

type ModalSuggestionsProps = {
  badges: string[];
  onBadgeSelect: (label: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
};

const ModalSuggestions = ({ badges, onBadgeSelect, textareaRef }: ModalSuggestionsProps) => {
  const handleBadgeClick = (label: string) => {
    if (textareaRef?.current) {
      const event = {
        target: textareaRef?.current,
        preventDefault: () => {},
      } as React.ChangeEvent<HTMLTextAreaElement>;

      onBadgeSelect(label, event);
    }
  };

  return (
    <div className="gap-1 d-flex flex-column">
      <div className="d-flex align-items-center">
        <AIMascot
          className="ai-mascot ai-mascot-small"
          faceSrc={happyFace}
          isFloat={false}
        />
        <div className="font-size-12 fw-semibold text-primary-400 ms-2">You may want to add...</div>
      </div>
      <div className="overflow-auto gap-2 pb-1 d-flex">
        {badges.map((label, index) => (
          <AppBadge
            key={index}
            type="ai-hint-text-hover"
            label={label}
            onClick={() => handleBadgeClick(label)}
            nowrap
          />
        ))}
      </div>
    </div>
  );
};

export default ModalSuggestions;
