import { createFinishedUserData } from 'apis/ContentUserData/ContentUserData';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { ContentUserDataEvent, FinishedUserDataInput } from 'types';

export const useContentUserData = () => {
  const [finishedUserData, setFinishedUserData] = useState<FinishedUserDataInput>(null);
  const { mutateAsync: setFinishedAsync, isLoading: isSetFinishedLoading } = useMutation(
    async ({ event, userId, contextId }: { event: ContentUserDataEvent | any; userId: string; contextId: string }) => {
      const payload = {
        contentId: event.getVerifiedStatementValue([
          'object',
          'definition',
          'extensions',
          'http://h5p.org/x-api/h5p-local-content-id',
        ]),
        score: event.getScore(),
        maxScore: event.getMaxScore(),
        userId,
        contextId,
      } as FinishedUserDataInput;

      const validScore = typeof payload.score === 'number' || (payload.score as any) instanceof Number;
      if (!validScore) return;

      const H5P = getH5P(payload.contentId);

      if (!H5P) return;

      const openedTimestamp = H5P.opened[payload.contentId];
      if (!openedTimestamp) return;

      const toUnix = function (date) {
        return Math.round(date.getTime() / 1000);
      };

      payload.openedTimestamp = toUnix(openedTimestamp);
      payload.finishedTimestamp = toUnix(new Date());

      const instances = getInstanceByContentId(payload.contentId);

      const xAPIData =
        instances && instances.getXAPIData
          ? instances.getXAPIData()
          : {
              statement: event.data.statement,
            };

      const supportedInteractions = ['choice', 'true-false', 'fill-in', 'sequencing', 'matching'];

      const statements = [
        ...(xAPIData.children
          ?.map(child => {
            return [...(child.children?.map(item => item.statement) || []), child.statement];
          })
          .flat() || []),
        xAPIData.statement,
      ].filter(item => supportedInteractions.includes(item.object.definition.interactionType));

      payload.data = mergeObjects(statements);

      const res = await createFinishedUserData(payload);
      setFinishedUserData({ ...res });
    },
  );

  const getH5P = (contentId: string) => {
    let element = document.getElementById(`h5p-iframe-${contentId}`);

    if (!element) {
      element = document.querySelector('.h5p-iframe');
    }

    if (element) {
      const contextWindow = 'contentWindow' in element ? element.contentWindow : (global as any).window;

      const H5P = (contextWindow.H5P = contextWindow.H5P || {});
      return H5P;
    }

    return undefined;
  };

  const findInstance = (instances, contentId) => {
    if (0 !== instances && 0 !== contentId)
      for (let n = 0; n < instances.length; n++) if (instances[n].contentId === contentId) return instances[n];
  };

  const getInstanceByContentId = (contentId: any) => {
    let iframe,
      instance = null;
    const H5P = getH5P(contentId);

    if (contentId) {
      if (!(instance = findInstance(H5P.instances, contentId))) {
        iframe = document.getElementsByClassName('h5p-iframe');
        for (
          let o = 0;
          o < iframe.length && !(instance = findInstance(iframe[o].contentWindow.H5P.instances, contentId));
          o++
        );
      }
    } else
      (instance = H5P.instances[0]) ||
        (instance = (iframe = document.getElementsByClassName('h5p-iframe')[0] as any).contentWindow.H5P.instances[0]);
    return instance;
  };

  const mergeObjects = (data: ContentUserDataEvent[]) => {
    const merged: { [key: string]: ContentUserDataEvent } = {};

    data.forEach(entry => {
      const objId = entry.object.id;
      if (entry.context)
        if (!merged[objId]) {
          merged[objId] = { ...entry };
        } else {
          const entryDescription = entry?.object?.definition.description;
          if (entryDescription) {
            if (!merged[objId].object?.definition?.description) {
              merged[objId].object.definition.description = entryDescription;
            } else {
              merged[objId].object.definition.description['en-US'] += `[,]${entryDescription['en-US']}`;
            }
          }

          if (entry?.result && entry?.result?.response) {
            if (!merged[objId].result) {
              merged[objId].result = { response: entry.result.response };
            } else {
              merged[objId].result.response += `[,]${entry.result.response}`;
            }
          }
        }
    });

    return Object.values(merged);
  };

  return {
    finishedUserData,
    setFinishedAsync,
    isSetFinishedLoading,
  };
};
