import { getOptionConnections } from 'apis/Options/Options';
import { useAppStore } from 'hooks/useAppStore';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Connection, ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useGetOptionsConnections = ({ cacheTime = 0 }: { cacheTime?: number }) => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  const queryKey = ['workspaces', currentWorkspaceId, 'connections', 'options'];

  return useQuery<Connection[], ErrorResponse>(queryKey, () => getOptionConnections(), {
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
    cacheTime,
    staleTime: Infinity,
    retry: 1,
  });
};
