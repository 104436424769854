import React, { useState } from 'react';
import classNames from 'classnames';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import './PlanOptions.scss';
import { CheckCircleIcon } from 'assets/icons/CheckCircleIcon';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { XCircleIcon } from 'assets/icons/XCircleIcon';
import { Check } from 'react-feather';
import { useGetPlans, useLogout, useSubscriptionCheckout } from 'hooks';
import { Loading, useSubscriptionModal } from 'components';
import { studentReportServiceLines } from 'utils';
import { SubscriptionPlan, SubscriptionStatus } from '@spiderbox/common';

export const PlanOptions = observer(({ onboarding = false }: { onboarding?: boolean }) => {
  const {
    subscriptionStore: { plans, currentSubscription },
    workspaceStore: { isWorkspaceOwner },
  } = useAppStore();

  const { isLoading: isPlansLoading } = useGetPlans();

  const [enableStudentReport, setEnableStudentReport] = useState<boolean>(!!currentSubscription?.studentReportItem);

  const { mutate: logout } = useLogout();

  const { mutate: checkout, isLoading: isCheckoutLoading } = useSubscriptionCheckout();

  const { openCancelPlanModal, openDeactivateStudentReportModal, openActivateStudentReportModal } =
    useSubscriptionModal();

  return isPlansLoading ? (
    <Loading />
  ) : (
    <div className={classNames('plan-options-layout d-flex flex-column', { onboarding })}>
      <div className="plan-options-card">
        <div className="plan-options-card--icon">
          <CheckCircleIcon />
        </div>
        <div className="plan-options-card--info">
          <div className="text-neutral-900 fw-bolder fs-4">Standard Plan</div>
          <div className="text-neutral-600 font-size-16">Price based on monthly users (minimum 3 users)</div>
        </div>
        <div className="plan-options-card--price">
          <div className="gray-900 fs-4xl fw-semibold">${plans[SubscriptionPlan.STANDARD_USER]?.price * 3}</div>
          <div className="text-neutral-600 font-size-16">${plans[SubscriptionPlan.STANDARD_USER]?.price} per user</div>
        </div>
      </div>

      <div className="plan-options-card mt-3">
        <div className="plan-options-card--icon">{enableStudentReport ? <CheckCircleIcon /> : <XCircleIcon />}</div>
        <div className="plan-options-card--info">
          {onboarding ? (
            <>
              <div className="text-neutral-900 font-size-16 fw-semibold mb-3">Optional features</div>
              <div className="d-flex">
                <label className="d-flex cursor-pointer form-check checkbox-lg gray-500 mb-0">
                  <input
                    type="checkbox"
                    checked={enableStudentReport}
                    onChange={event => setEnableStudentReport(event.target.checked)}
                    className="form-check-input cursor-pointer primary-500 me-3 mt-2"
                    data-cy="student-report-checkbox"
                  />
                  <div>
                    <div className="text-neutral-900 fw-bolder fs-4">Learner Report</div>
                    <div className="text-neutral-600 font-size-16">Price based on monthly active learners</div>
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              <div className="text-neutral-900 fw-bolder fs-4">Learner Report</div>
              <div className="text-neutral-600 font-size-16">Price based on monthly active learners</div>
            </>
          )}

          <div className="mt-3">
            <div className="gray-900 font-size-16">
              Learner answers are stored and presented in reports when they submit Evokio questions through LTI. This
              feature enables you to gain valuable learner performance information:
            </div>

            <div className="d-flex flex-column">
              {studentReportServiceLines.map((item, index) => {
                return (
                  <div
                    className="plan-options-card--info__report-line"
                    key={index}
                  >
                    <div className="icon">
                      <Check
                        className={enableStudentReport ? 'text-primary' : 'text-neutral-300'}
                        size={20}
                      />
                    </div>

                    <div className="text-neutral-900 font-size-16">{item}</div>
                  </div>
                );
              })}
            </div>

            <div className="text-neutral-900 font-size-16 mt-3">
              *Reports are available for content items that offer scoring capabilities.
            </div>
          </div>
        </div>
        <div className="plan-options-card--price">
          <div className="gray-900 fs-4xl fw-semibold">${plans[SubscriptionPlan.STUDENT_REPORT]?.price}</div>
          <div className="text-neutral-600 font-size-16">per active learner</div>
          {!onboarding && (
            <div
              className="mt-3 plan-options-card__report-btn"
              data-cy="activate-report-btn"
            >
              {enableStudentReport ? (
                <button
                  id="deactivate-student-report"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    openDeactivateStudentReportModal(() => setEnableStudentReport(false));
                  }}
                  disabled={currentSubscription?.status === SubscriptionStatus.PAST_DUE}
                  data-cy="deactivate-student-report"
                >
                  Deactivate Report
                </button>
              ) : (
                <button
                  id="activate-student-report"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    openActivateStudentReportModal(() => setEnableStudentReport(true));
                  }}
                  disabled={currentSubscription?.status === SubscriptionStatus.PAST_DUE}
                  data-cy="activate-student-report"
                >
                  Add Now
                </button>
              )}

              {currentSubscription?.status === SubscriptionStatus.PAST_DUE && (
                <UncontrolledTooltip
                  placement="bottom"
                  container="body"
                  target={enableStudentReport ? 'deactivate-student-report' : 'activate-student-report'}
                >
                  Due to an unpaid invoice, this action is temporarily disabled. Please settle the outstanding payment
                  to regain access.
                </UncontrolledTooltip>
              )}
            </div>
          )}
        </div>
      </div>

      {onboarding ? (
        <div className="plan-options-footer d-flex justify-content-between flex-column flex-lg-row">
          <Button
            outline
            color="primary"
            onClick={() => logout(false)}
          >
            Log Out
          </Button>

          <Button
            color="primary"
            onClick={() => checkout(enableStudentReport)}
            disabled={isCheckoutLoading}
          >
            {isCheckoutLoading && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Continue
          </Button>
        </div>
      ) : (
        isWorkspaceOwner &&
        [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(currentSubscription?.status) && (
          <div className="mt-4">
            <button
              className={classNames(
                'btn',
                currentSubscription.cancelAtPeriodEnd ? 'btn-primary' : 'btn-outline-primary',
              )}
              onClick={() => openCancelPlanModal(currentSubscription.cancelAtPeriodEnd)}
              data-cy="cancel-plan-btn"
            >
              {currentSubscription.cancelAtPeriodEnd ? 'Resume' : 'Cancel'} Plan
            </button>
          </div>
        )
      )}
    </div>
  );
});
