import React from 'react';
import './AllContentsList.scss';
import { ContentTypeIcon } from 'pages/Content/components/ContentTypeIcon';
import { MoreVertical, Star } from 'react-feather';
import { Document, TDocumentType } from 'models/Content';
import classNames from 'classnames';
import { ContentStatusIcon } from 'pages/Content/components/ContentStatusIcon';
import useModal from 'hooks/useModal';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { Library } from 'types';
import { getLibraries } from 'apis/Library/Library';
import { useAddOrRemoveStarContent } from 'hooks';

type IProps = {
  data: Document[];
  isLoading: boolean;
  showIconStatusMobile?: boolean;
  onRowClick: (data: Document) => void;
};

export const AllContentsList = ({ data, isLoading, showIconStatusMobile = true, onRowClick }: IProps) => {
  const { openModal, closeModal } = useModal();

  const openContentActionsModal = (content: Document) => {
    openModal('content-actions', {
      content,
      onCancel: () => closeModal(),
    });
  };

  const { data: libraries } = useQuery<Library[]>(['libraries'], () => getLibraries({ orderBy: 'title' }), {
    cacheTime: 300000,
    staleTime: 300000,
  });

  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };
  const { mutate: addOrRemoveStarContent } = useAddOrRemoveStarContent();

  const onStarredContent = (content: Document) => {
    addOrRemoveStarContent(content);
  };

  return (
    <div
      className="contents-page px-0"
      data-cy="list-content"
    >
      {data?.map((row, key) => {
        return (
          <div
            key={key}
            className="card-content__item p-3 gap-3 bg-white bg-neutral-100-hover bg-mobile-hover"
            data-cy="content-item"
          >
            <div className="flex-fill d-flex align-items-center justify-content-between">
              <div className="flex-fill d-flex align-items-center gap-2">
                {!isLoading && (
                  <>
                    <Star
                      className={classNames('me-2 cursor-pointer', row?.starred ? 'text-secondary-500' : 'gray-500')}
                      fill={row?.starred ? '#F7CE46' : 'none'}
                      size={20}
                      onClick={() => onStarredContent(row)}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => onRowClick(row)}
                    >
                      <ContentTypeIcon contentType={row?.kind} />
                    </button>
                  </>
                )}

                <div
                  className="flex-fill w-1px px-2"
                  onClick={() => onRowClick(row)}
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <div
                      className="gray-700 fw-semibold text-truncate title-content"
                      data-cy="content-name"
                    >
                      {row?.title}
                    </div>
                  )}

                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    row?.library && <div className="title-original text-truncate">{mappingTitle(row.library)}</div>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center gap-2 ">
                {!isLoading && (
                  <>
                    {showIconStatusMobile && (
                      <ContentStatusIcon contentStatus={row.kind === TDocumentType.Folder ? '' : row?.status} />
                    )}
                    <MoreVertical
                      size={20}
                      className="cursor-pointer gray-500 ms-1 me-2"
                      onClick={() => openContentActionsModal(row)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
