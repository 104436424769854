import { fetchAllPlans } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { Plan } from 'types/subscriptions';
import { fetchServerError } from 'utils';

export const useGetPlans = () => {
  const appStore = useAppStore();
  const {
    subscriptionStore: { setPlans },
  } = appStore;

  return useQuery<Plan[], ErrorResponse>(['subscription', 'plans'], () => fetchAllPlans(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: data => {
      setPlans(data);
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
