import React from 'react';
import './AdminNav.scss';
import { Search, X } from 'react-feather';

interface Props {
  search?: (searchTerm: string) => void;
  keyword?: string;
}

export const AdminNav: React.FC<Props> = ({ keyword, search }) => {
  return (
    <nav
      className="d-flex flex-column-reverse flex-sm-row align-items-start align-items-sm-center justify-content-between w-100 bg-white admin__nav"
      data-cy="admin__nav"
    >
      <div
        className="d-flex justify-content-center align-items-center mb-1 mb-sm-0 gap-2 gap-sm-4 admin__nav__sort-by"
        data-cy="admin__sort-by"
      >
        <div
          className="px-2 text-center admin__nav__sort-by__item cursor-pointer active"
          data-cy="recently-added"
        >
          Recently Added
        </div>
      </div>
      {search && (
        <div
          className="position-relative d-flex align-items-center px-3 py-3 py-sm-2 flex-grow-1 admin__nav__search"
          data-cy="admin__nav__search"
        >
          <Search
            size={18}
            className="position-absolute admin__nav__search__icon"
          />
          <input
            type="text"
            className="w-100 py-2 ps-6 pe-2 border rounded bg-transparent admin__nav__search__input"
            placeholder="Search for content type..."
            onChange={e => search(e.target.value)}
            data-cy="admin__search"
            value={keyword}
          />
          {keyword && (
            <X
              className="position-absolute admin__nav__search__x__icon cursor-pointer"
              size={18}
              onClick={() => search('')}
            />
          )}
        </div>
      )}
    </nav>
  );
};
