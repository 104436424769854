import { ContentType } from '@spiderbox/common';
import { createMessage } from 'apis/AiChat/AiChat';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AIMessageActionType } from 'types';
import { addMessageToChatHistory } from './addMessageToChatHistory';

type CreateMessagePayload = {
  chatId: string;
  content: string;
  actionType: AIMessageActionType;
  contentType?: ContentType;
};

export const useCreateMessage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CreateMessagePayload) => {
      const { chatId, content, actionType, contentType } = payload;

      return createMessage(chatId, { content, actionType, contentType });
    },
    {
      onSuccess: data => {
        addMessageToChatHistory({
          queryClient,
          chatId: data.aiChatId,
          message: data,
        });
      },
      onError: () => {
        toast('Oops! We encountered an issue processing your input. Please review and try again.', { type: 'error' });
      },
    },
  );
};
