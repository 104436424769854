import React, { useEffect, useMemo, useRef } from 'react';
import './FolderView.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import { Tree } from 'react-arborist';
import { useQuery, useQueryClient } from 'react-query';
import { Document, Folder as FolderType, TDocumentType } from 'models/Content';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { findMine } from 'apis/Folder/Folder';
import { useCreateFolder } from 'hooks';
import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import { FolderTreeItem } from './FolderTreeItem';
import { findFolderById, mapFolderTypes } from './utils';
import { FolderStore, rootFolder } from 'stores';
import { useMoveFolder } from 'hooks/folder/useMoveFolder';
import { ContentFormInput } from 'types';

type Props = {
  content?: Document;
  folderStore: FolderStore;
  parentFolderId?: string;
  onSelectFolder?: (folder: FolderType) => void;
  selectedFolderId?: string;
};

export const FolderView = observer(
  ({ content, folderStore, parentFolderId, onSelectFolder, selectedFolderId }: Props) => {
    const queryClient = useQueryClient();
    const { isFolderCreating, folderSelected, modalFolderKey } = folderStore;
    const { cancelNewFolder } = useMoveFolder(folderStore);
    const { control, watch } = useForm({
      defaultValues: {
        search: '',
      },
    });
    const treeRef = useRef(null);

    const { setValue } = useFormContext<ContentFormInput>() || {};

    const search = watch('search');
    const searchTerm = useMemo(() => {
      const searchKey = { search: search.trim() };
      folderStore.setModalFolderKey(['modal', 'folders', searchKey]);
      return searchKey;
    }, [search]);

    const { data: folders = [], isLoading } = useQuery<FolderType[]>(
      modalFolderKey,
      async () =>
        await findMine(searchTerm).then(documents => {
          return [
            {
              ...rootFolder,
              children: mapFolderTypes(documents?.filter(doc => doc.kind !== TDocumentType.Content) || []),
            } as FolderType,
          ];
        }),
      {
        staleTime: 300000,
      },
    );

    const { mutate: createFolderMutate, isLoading: createFolderLoading } = useCreateFolder();

    const isDisable = (data?: FolderType) => {
      return (
        data?.kind === TDocumentType.Content ||
        parentFolderId === data?.id ||
        (content && (content?.id === data?.id || data?.path?.startsWith(content?.path, 0)))
      );
    };

    const createFolder = (title: string, parentId: string) => {
      createFolderMutate(
        { title, parentId },
        {
          onSuccess: folder => {
            queryClient.setQueryData<FolderType[]>(['modal', 'folders', searchTerm], previousData => {
              const newFolder = findFolderById(previousData, 'new');
              if (newFolder) {
                newFolder.id = folder.id;
                newFolder.title = folder.title;
                newFolder.children = [];
                newFolder.isEdited = false;
              }
              return [...previousData];
            });
          },
          onSettled: () => {
            folderStore.setFolderCreating(false);
          },
        },
      );
    };

    useEffect(() => {
      if (folderStore?.isFolderCreating) {
        treeRef?.current?.scrollTo('new', 'smart');
      }
    }, [folderStore?.isFolderCreating]);

    return (
      <div>
        <div className="mb-3">
          <label
            className="gray-900 font-size-12"
            data-cy="search-title"
          >
            Search
          </label>

          <Controller
            name="search"
            control={control}
            render={fields => {
              return (
                <SearchBar
                  value={fields.field.value}
                  search={fields.field.onChange}
                  placeholder="Search by folder"
                  timeDelay={500}
                />
              );
            }}
          />
        </div>
        <div className="w-100">
          <label
            className="gray-900 font-size-12"
            data-cy="existing-folders"
          >
            Existing folder(s)
          </label>
          <div
            className="w-100 d-flex flex-column move-folder-wrapper__existing gap-1 py-2"
            data-cy="list-folders"
          >
            {((!!folders?.length && !search) || (search && !!folders?.[0]?.children?.length)) && !isLoading && (
              <Tree
                ref={treeRef}
                data={folders}
                rowHeight={40}
                indent={24}
                width={'100%'}
                height={200}
                openByDefault={false}
                selection={selectedFolderId}
                onActivate={({ data }) => {
                  if (data.id === 'new' || isFolderCreating) {
                    return;
                  }
                  if (data.kind === TDocumentType.Content || isDisable(data)) {
                    onSelectFolder?.(data);
                    folderStore.selectFolder(null);
                    return;
                  }
                  onSelectFolder?.(data);
                  folderStore.selectFolder(data);
                  setValue?.('shouldDirty', true);
                }}
              >
                {props => (
                  <FolderTreeItem
                    isFolderCreating={isFolderCreating}
                    queryKey={modalFolderKey}
                    folderSelected={folderSelected}
                    createFolderLoading={createFolderLoading}
                    search={search}
                    onCreate={createFolder}
                    parentFolderId={parentFolderId}
                    content={content}
                    cancelNewFolder={cancelNewFolder}
                    isDisable={isDisable}
                    {...props}
                  />
                )}
              </Tree>
            )}
            {!folders?.[0]?.children?.length && !isLoading && search && (
              <div
                className="d-flex justify-content-center align-items-center gray-600"
                style={{ height: 200 }}
              >
                No options
              </div>
            )}
            {isLoading ? (
              <div style={{ height: 200 }}>
                <Skeleton
                  count={3}
                  height={40}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  },
);
