import React, { useState } from 'react';
import './BillingInformation.scss';
import TextField from 'components/AppForm/TextField';
import { BillingAddressForm } from './BillingAddressForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { useUpdateWorkspaceBillingInformation } from 'hooks';
import classNames from 'classnames';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { useGetWorkspaceBillingInformation } from 'hooks/payment/useGetWorkspaceBillingInformation';
import { Loading } from 'components';

export const BillingInformation = observer(() => {
  const {
    workspaceStore: { currentWorkspace, isWorkspaceOwner },
  } = useAppStore();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const { data: workspace, isLoading: getWorkspaceLoading } = useGetWorkspaceBillingInformation();

  const { mutate, isLoading } = useUpdateWorkspaceBillingInformation(() => setIsFormDirty(false));

  const [workspaceAddressValue, setWorkspaceAddressValue] = useState(null);

  usePrompt(isWorkspaceOwner ? isFormDirty : false);

  const onUpdateWorkspace = () => {
    mutate({ id: currentWorkspace.id, customerId: currentWorkspace?.customerId, ...workspaceAddressValue });
  };

  return (
    <div className="billing-information-wrapper d-flex flex-column mb-3 mb-md-0">
      <div className="gray-900 font-size-16 fw-semibold">Billing Information</div>

      {getWorkspaceLoading ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <label className="d-block col-12 col-md-4 mb-md-0">
              <div className="gray-900 h5 font-size-14">Email Address</div>

              <div className="gray-500 font-size-12 fw-normal">Invoices will be sent to this email address.</div>
            </label>
            <div className="col-12 col-md-8 col-xl-6">
              <div className="form-group mt-0 input-field">
                <TextField
                  disabled
                  defaultValue={workspace?.owner?.email}
                  dataCy="billing-email"
                  type="email"
                  id="email"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <label className="d-block col-12 col-md-4 mb-md-0">
              <div className="gray-900 h5 font-size-14">Billing</div>

              <div className="gray-500 font-size-12 fw-normal">Don&apos;t worry, we won&apos;t send anything here.</div>
            </label>
            <div className="col-12 col-md-8 col-xl-6">
              <BillingAddressForm
                workspace={workspace}
                onFormChange={value => {
                  setWorkspaceAddressValue(value);
                  setIsFormDirty(true);
                }}
              />

              {isWorkspaceOwner && (
                <div
                  className={classNames(
                    'billing-information__buttons d-flex justify-content-end align-items-center w-100 w-md-auto mt-2',
                    { display: !!workspaceAddressValue },
                  )}
                >
                  <button
                    onClick={onUpdateWorkspace}
                    className="btn btn-primary btn-save w-100 w-md-auto"
                    type="submit"
                    disabled={isLoading}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});
