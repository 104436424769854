import { deactivateStudentReport } from 'apis/Payment/Subscription';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useDeactivateStudentReport = () => {
  return useMutation(deactivateStudentReport, {
    onSuccess: () => {
      toast('Learner Report has been deactivated.');
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
