import { AppDropDown } from 'components/AppDropDown';
import { User } from 'types';
import { ContentRole } from '@spiderbox/common';
import React from 'react';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';

type Props = { role: ContentRole; user?: User; onChange?: (value: any) => void };

export const UserAccess = ({ role, user, onChange }: Props) => {
  const { userInfo } = useAppStore();

  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex align-items-center gap-2 overflow-hidden">
        <AvatarProfile
          imgSrc={user?.profileImageUrl}
          width={32}
          height={32}
          userFullName={user?.fullName}
          avatarClassNames="flex-shrink-0"
        />
        <div className="d-flex flex-column overflow-hidden">
          <div
            className="user-name text-truncate pe-1"
            data-cy="user-name"
          >
            {user?.fullName}
            <span>{user?.id === userInfo?.id ? ' (you)' : ''}</span>
          </div>
          <div
            className="user-email fw-normal text-truncate pe-1"
            data-cy="user-email"
          >
            {user?.email}
          </div>
        </div>
      </div>
      {role === ContentRole.OWNER ? (
        <div
          className="bg-neutral-50 fw-medium p-2 rounded h-100 font-size-12 gray-500"
          data-cy="user-permission"
        >
          Owner
        </div>
      ) : (
        <>
          <div
            className=""
            data-cy="dropdown-permission"
          >
            <AppDropDown
              direction="up"
              items={
                role !== ContentRole.NONE
                  ? [
                      { value: ContentRole.VIEWER, label: 'Viewer' },
                      { value: ContentRole.EDITOR, label: 'Editor' },
                      { value: ContentRole.NONE, label: 'Remove access' },
                    ]
                  : [
                      { value: ContentRole.VIEWER, label: 'Viewer' },
                      { value: ContentRole.EDITOR, label: 'Editor' },
                    ]
              }
              selected={role}
              selectItemFunc={(item: ContentRole) => {
                onChange({
                  userId: user.id,
                  role: item,
                });
              }}
              emptyDefault={true}
              height={32}
            />
          </div>
        </>
      )}
    </div>
  );
};
