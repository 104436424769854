import { EMAIL_REGEXP, PHONE_REGEXP } from 'constants/regexp';

import yupPassword from 'yup-password';
yupPassword(yup);

import * as yup from 'yup';

export const profileSchema = yup.object().shape({
  firstName: yup.string().trim().required('First Name is required.'),
  lastName: yup.string().trim().required('Last Name is required.'),
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .matches(EMAIL_REGEXP, 'Email is invalid.')
    .max(255, 'Email length must be less than 255 characters.'),
  phoneNumber: yup
    .string()
    .matches(PHONE_REGEXP, 'Phone number is invalid')
    .nullable()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value)),
});
