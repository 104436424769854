import { downloadLearnerReport, downloadLearnerReports } from 'apis/LearnerReport/LearnerReport';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useDownloadLearnerReports = () => {
  return useMutation({
    mutationFn: downloadLearnerReports,
    onError: (error: ErrorResponse) => {
      toast.warning(fetchServerError(error));
    },
  });
};

export const useDownloadLearnerReport = () => {
  return useMutation({
    mutationFn: downloadLearnerReport,
    onError: (error: ErrorResponse) => {
      toast.warning(fetchServerError(error));
    },
  });
};
