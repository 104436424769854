import React, { useEffect, useMemo } from 'react';
import './SharingView.scss';
import { AppDropDown } from 'components/AppDropDown';
import { UserAccess } from './UserAccess';
import Select, { ControlProps, InputProps, OptionProps, components } from 'react-select';
import { Search } from 'react-feather';
import { useQuery } from 'react-query';
import { ContentFormInput, ContentPermissionsInput, User } from 'types';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { ContentRole } from '@spiderbox/common';
import { useGetContentPermissions } from 'hooks/content/useGetContentPermissions';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { Loading } from 'components/Loading';
import { fetchOptionUsers } from 'apis/Options/Options';
import { mapToContentPermissionsInput } from 'hooks';

type Props = {
  contentId?: string;
  owner?: User;
  onChange: (value: ContentPermissionsInput) => void;
  onDirty?: (value: boolean) => void;
  onShouldSendInvitation?: (value: boolean) => void;
  parentId?: string;
  defaultValues?: ContentPermissionsInput;
  autoFocus?: boolean;
};

export const SharingView = observer((props: Props) => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const { contentId, owner, onChange, onDirty, onShouldSendInvitation, parentId, defaultValues } = props;
  const autoFocus = props.autoFocus ?? true;
  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<ContentPermissionsInput>({
    defaultValues: defaultValues ?? {
      contentId: contentId,
      newUserRoles: {
        users: [],
        role: ContentRole.VIEWER,
      },
      existingUserRoles: [],
      workspaceRole: ContentRole.NONE,
      workspaceId: currentWorkspaceId,
    },
  });

  const { setValue: setContentValue } = useFormContext<ContentFormInput>() || {};

  const { contentPermissions, isLoading } = useGetContentPermissions(parentId ?? contentId);

  const { fields: existUsersField } = useFieldArray({ control, name: 'existingUserRoles' });

  useEffect(() => {
    if (contentPermissions) {
      const contentPermissionInput = mapToContentPermissionsInput(contentPermissions, currentWorkspaceId);
      defaultValues && (contentPermissionInput.newUserRoles = defaultValues.newUserRoles);
      reset(contentPermissionInput);
    }
  }, [contentPermissions]);

  const {
    workspaceStore: { currentWorkspace },
  } = useAppStore();

  const { userRoles = [] } = contentPermissions || {};

  const newUserRoles = watch('newUserRoles');
  const workspaceRole = watch('workspaceRole');

  const { data: dataUsers = [], isLoading: isUserLoading } = useQuery<User[]>(
    ['contents', contentId, 'users'],
    async () => await fetchOptionUsers({ status: 'Active' }),
    {
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  watch(value => {
    return onChange(value as ContentPermissionsInput);
  });

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const previousWorkspaceRole = contentPermissions?.workspaceRole?.role || ContentRole.NONE;
    const hasNewUserRoles = !!newUserRoles?.users?.length;
    const isPreviousRoleNone = previousWorkspaceRole === ContentRole.NONE;
    const hasRoleChanged = workspaceRole !== previousWorkspaceRole;

    onShouldSendInvitation?.(hasNewUserRoles || (!hasNewUserRoles && isPreviousRoleNone && hasRoleChanged));
  }, [newUserRoles?.users?.length, contentPermissions, workspaceRole]);

  const users = useMemo(() => {
    return dataUsers
      .filter(user => user?.id !== owner?.id && existUsersField.findIndex(x => x.userId === user.id) < 0)
      .map(user => ({
        label: user.fullName,
        value: user.id,
        user,
      }));
  }, [dataUsers, userRoles, existUsersField]);

  const filterOptions = (candidate: { value: string; label: string; data: any }, input: string) => {
    const searchTerm = input.toLowerCase();
    const { email } = candidate.data.user;
    const { label } = candidate;
    return label?.toLowerCase().includes(searchTerm.trim()) || email?.toLowerCase().includes(searchTerm.trim());
  };

  if (isLoading || isUserLoading) {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: 300 }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <div className="shareWith__body--search-user">
        <label className="gray-900 font-size-12">Search specific user(s)</label>
        <div className="w-100 d-flex flex-row gap-2 align-items-center">
          <Controller
            name="newUserRoles.users"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                options={users}
                onChange={newValue => {
                  setContentValue?.('shouldDirty', true);
                  setContentValue?.('ContentFormInputDirtyFields.permissions', true);
                  field.onChange(newValue);
                }}
                value={field.value}
                classNamePrefix="select"
                className="flex-fill w-1px"
                placeholder="Search people and groups"
                filterOption={filterOptions}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                isClearable
                isSearchable
                components={{
                  Control: ({ children, ...props }: ControlProps) => (
                    <components.Control
                      {...props}
                      className="px-2"
                    >
                      <Search size={20} /> {children}
                    </components.Control>
                  ),
                  Option: (props: OptionProps) => {
                    const user = (props.data as any).user;

                    return (
                      <components.Option {...props}>
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <AvatarProfile
                              imgSrc={user?.profileImageUrl}
                              width={32}
                              height={32}
                              userFullName={props.label}
                            />
                          </div>
                          <div className="d-flex flex-column w-100 overflow-hidden">
                            <div className="fw-semibold text-truncate">{props.label}</div>
                            <div className="text-truncate">{user?.email}</div>
                          </div>
                        </div>
                      </components.Option>
                    );
                  },
                  Input: (props: InputProps) => {
                    return (
                      <components.Input
                        {...props}
                        autoFocus={autoFocus}
                        data-cy="new-users-select"
                      />
                    );
                  },
                }}
              />
            )}
          />
          {newUserRoles.users.length !== 0 && (
            <div className="flex-shrink-1">
              <Controller
                name="newUserRoles.role"
                control={control}
                render={({ field }) => (
                  <AppDropDown
                    items={[
                      { value: ContentRole.VIEWER, label: 'Viewer' },
                      { value: ContentRole.EDITOR, label: 'Editor' },
                    ]}
                    selected={field.value}
                    selectItemFunc={(value: ContentRole) => field.onChange(value)}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>

      <div className="shareWith__body--people-access">
        <label className="gray-900 font-size-12">People with access</label>
        <div
          className="w-100 user rounded list-user"
          data-cy="list-user"
        >
          <UserAccess
            user={owner}
            role={ContentRole.OWNER}
          />
          {existUsersField?.map((userRole, index) => (
            <Controller
              key={userRole.id}
              name={`existingUserRoles.${index}`}
              control={control}
              render={({ field }) => (
                <UserAccess
                  key={userRole.id}
                  onChange={value => {
                    setContentValue?.('shouldDirty', true);
                    setContentValue?.('ContentFormInputDirtyFields.permissions', true);
                    field.onChange(value);
                  }}
                  user={userRole.user}
                  role={field.value?.role}
                />
              )}
            />
          ))}
        </div>
      </div>

      <div className="shareWith__body--general-access">
        <label className="gray-900 font-size-12">General access</label>
        <div className="d-flex justify-content-between border rounded">
          <div className="flex-fill d-flex flex-column text-truncate">
            <div className="mb-0 workplace gray-700 text-truncate pe-2">{currentWorkspace?.name}</div>
            <div className="mb-0 desc font-size-12">Everyone in this workplace can access</div>
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            data-cy="general_access"
          >
            <Controller
              name="workspaceRole"
              control={control}
              render={({ field }) => (
                <AppDropDown
                  dataCy="dropdown__general-access"
                  items={
                    field.value !== ContentRole.NONE
                      ? [
                          { value: ContentRole.VIEWER, label: 'Viewer' },
                          { value: ContentRole.EDITOR, label: 'Editor' },
                          { value: ContentRole.NONE, label: 'Remove access' },
                        ]
                      : [
                          { value: ContentRole.VIEWER, label: 'Viewer' },
                          { value: ContentRole.EDITOR, label: 'Editor' },
                        ]
                  }
                  selected={field.value}
                  selectItemFunc={(item: ContentRole) => {
                    field.onChange(item);
                    setContentValue?.('shouldDirty', true);
                    setContentValue?.('ContentFormInputDirtyFields.permissions', true);
                    onDirty?.(true);
                  }}
                  emptyDefault={true}
                  height={32}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
