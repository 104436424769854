import React, { useEffect } from 'react';
import { findFolderDetail } from 'apis/Folder/Folder';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { ContentListLayout } from '../components/ContentListLayout';
import { ContentPermission } from 'components';
import { useGetContentRole } from 'hooks';
import { ContentRole } from '@spiderbox/common';
import { toast } from 'react-toastify';
import { appStore } from 'stores';

export const FolderPage = observer(() => {
  const { currentContentPageCrumb } = useAppStore();
  const { folderId } = useParams();
  const { contentRole, error } = useGetContentRole(folderId);
  const navigate = useNavigate();

  const path = appStore.ltiMode
    ? `/not-found?workspaceId=${appStore.ltiStore.workspaceId}&ltik=${appStore.ltiStore.ltiKey}`
    : '/not-found';

  useEffect(() => {
    if (contentRole === ContentRole.NONE) {
      navigate('/dashboard');
      toast('You do not have permission to access this content.', { type: 'error' });
    }

    if (error?.statusCode === 404) {
      navigate(path);
    }
  }, [contentRole, error]);

  return (
    <ContentPermission
      role={contentRole}
      action="content"
    >
      <ContentListLayout
        pageTitle={currentContentPageCrumb.title}
        queryKey={['folders', folderId]}
        hideOwnerColumn
        findContents={findFolderDetail}
        folderId={folderId}
        showCreateContentButton
      />
    </ContentPermission>
  );
});
