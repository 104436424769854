import React from 'react';
import { ReactSVG } from 'react-svg';
import EmptyChatIcon from 'assets/images/ai-assistant/empty-chat.svg';
import './EmptyChat.scss';

const EmptyChat = () => {
  return (
    <div className="p-4 sidebar-history h-100 d-flex flex-column align-items-center">
      <div className="mt-4">
        <ReactSVG src={EmptyChatIcon} />
      </div>
      <div className="mt-4 text-center kick-off-chat font-size-14 font-weight-bold">Kick off your first chat now!</div>
      <div className="text-center font-size-14 text-neutral-400 font-weight-normal">
        Your conversation history will be saved and displayed here for you to revisit anytime.
      </div>
    </div>
  );
};

export default EmptyChat;
