import { Document, ContentQueryParams, ContentRenameRequest, contentStatusRequest } from 'models/Content';
import { CreateFolderRequest, Folder } from 'models/Folder';
import apiClient from '../ApiClient';
import qs from 'qs';
import { ContentPermissions, ContentRolePermission, moveFolderRequest } from 'types';

const baseEndpoint = '/folders';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const createFolder = async (payload: CreateFolderRequest): Promise<Folder> => {
  payload.parentId = payload.parentId !== 'root' ? payload.parentId : undefined;
  return await apiClient
    .post(`${baseEndpoint}`, { title: payload.title, parentId: payload.parentId })
    .then(response => response.data);
};

export const findAll = async (params: ContentQueryParams): Promise<Document[]> => {
  return findFolderChildren(`${baseEndpoint}/all`, params);
};

export const findMine = async (params: ContentQueryParams): Promise<Document[]> => {
  return findFolderChildren(`${baseEndpoint}/mine`, params);
};

export const findSharedWithMe = async (params: ContentQueryParams): Promise<Document[]> => {
  return findFolderChildren(`${baseEndpoint}/shared-with-me`, params);
};

export const findTrash = async (params: ContentQueryParams): Promise<Document[]> => {
  return await findFolderChildren(`${baseEndpoint}/trash`, params);
};

export const findFolderDetail = async (params: ContentQueryParams, id: string): Promise<Document[]> => {
  return findFolderChildren(`${baseEndpoint}/${id}/children`, params);
};

export const rename = async (payload: ContentRenameRequest): Promise<any> => {
  return await apiClient
    .patch(`${baseEndpoint}/${payload.id}`, { title: payload.title })
    .then(response => response.data);
};

const findFolderChildren = async (url: string, params: ContentQueryParams): Promise<Document[]> => {
  const { data } = await apiClient.get(url, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { encodeValuesOnly: true, arrayFormat: 'indices' });
    },
  });
  await sleep(300);
  return data;
};

export const findPermissions = async (contentId: string): Promise<ContentPermissions> => {
  if (!contentId) {
    return Promise.resolve(null);
  }

  return await apiClient.get(`${baseEndpoint}/${contentId}/permissions`).then(response => response.data);
};

export const updatePermissions = async (payload: ContentPermissions): Promise<any> => {
  return await apiClient.post(`${baseEndpoint}/${payload.contentId}/permissions`, payload);
};

export const addStar = async (contentId: string): Promise<any> => {
  return await apiClient.post(`${baseEndpoint}/${contentId}/star`);
};

export const removeStar = async (contentId: string): Promise<any> => {
  return await apiClient.delete(`${baseEndpoint}/${contentId}/star`);
};

export const moveToTrash = async (contentId: string): Promise<any> => {
  return await apiClient.put(`${baseEndpoint}/${contentId}/trash`);
};

export const changeStatus = async (payload: contentStatusRequest) => {
  return await apiClient
    .patch(`${baseEndpoint}/${payload.id}`, { status: payload.status })
    .then(response => response.data);
};

export const duplicate = async (contentId: string) => {
  return await apiClient.put(`${baseEndpoint}/${contentId}/duplicate`).then(response => response.data);
};

export const restore = async (contentId: string) => {
  return await apiClient.put(`${baseEndpoint}/${contentId}/restore`).then(response => response.data);
};

export const deletePermanently = async (contentId: string) => {
  return await apiClient.delete(`${baseEndpoint}/${contentId}`);
};

export const moveFolder = async (payload: moveFolderRequest) => {
  payload.targetId = payload.targetId || 'root';
  return await apiClient.put(`${baseEndpoint}/${payload.id}/move`, { targetId: payload.targetId });
};

export const emptyTrash = async () => {
  return await apiClient.delete(`${baseEndpoint}/trash`);
};

export const findAncestors = async (folderId: string) => {
  return await apiClient.get(`${baseEndpoint}/${folderId}/ancestors`).then(response => response.data);
};

export const findContentPermissions = async (contentId: string): Promise<ContentRolePermission> => {
  if (!contentId) {
    return Promise.resolve(null);
  }
  return await apiClient.get(`${baseEndpoint}/${contentId}/permissions/mine`).then(response => response.data);
};
