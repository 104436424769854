import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { ArrowDown, ArrowUp, Minus } from 'react-feather';
import { ContentUserDataAttempt, LearnerReportAttemptSortBy } from 'types/student-report';
import { SortIcon } from 'components';
import classNames from 'classnames';

const columnHelper = createColumnHelper<ContentUserDataAttempt>();

export const endTimeColumn = (
  sortAttempts: (sortBy: LearnerReportAttemptSortBy) => void,
  sortByValue: LearnerReportAttemptSortBy,
  sortOrder: 'asc' | 'desc' | '',
) => {
  return columnHelper.display({
    id: 'finishedAt',
    meta: {
      className: 'w-40',
    },
    header: () => (
      <div
        className={classNames(
          'd-flex justify-content-start align-items-center ps-2 gap-1',
          sortByValue === 'finishedTimestamp' ? 'text-primary' : 'gray-900',
        )}
      >
        End time
        <span
          className="cursor-pointer"
          onClick={() => sortAttempts('finishedTimestamp')}
        >
          {sortByValue !== 'finishedTimestamp' ? (
            <SortIcon stroke={'var(--bs-gray-900)'} />
          ) : sortOrder === 'asc' ? (
            <ArrowUp size={20} />
          ) : (
            <ArrowDown size={20} />
          )}
        </span>
      </div>
    ),
    size: 75,
    enableResizing: false,
    cell: ({ row }) => <div className="ps-2">{moment(row.original.finishedAt).format('DD/MM/YYYY HH:mm:ss')} </div>,
  });
};

export const durationColumn = (
  sortAttempts: (sortBy: LearnerReportAttemptSortBy) => void,
  sortByValue: LearnerReportAttemptSortBy,
  sortOrder: 'asc' | 'desc' | '',
) => {
  return columnHelper.display({
    id: 'duration',
    header: () => (
      <div
        className={classNames(
          'd-flex justify-content-start align-items-center ps-2 gap-1',
          sortByValue === 'completionTime' ? 'text-primary' : 'gray-900',
        )}
      >
        Duration
        <span
          className="cursor-pointer"
          onClick={() => sortAttempts('completionTime')}
        >
          {sortByValue !== 'completionTime' ? (
            <SortIcon stroke={'var(--bs-gray-900)'} />
          ) : sortOrder === 'asc' ? (
            <ArrowUp size={20} />
          ) : (
            <ArrowDown size={20} />
          )}
        </span>
      </div>
    ),
    size: 75,
    enableResizing: false,
    cell: ({ row }) => <div className="ps-2">{`${row.original.duration}`}</div>,
  });
};

const createScoreColumn = (
  id: string,
  percentId: string,
  headerText: string,
  sortAttempts: (sortBy: LearnerReportAttemptSortBy) => void,
  sortByValue: LearnerReportAttemptSortBy,
  sortOrder: 'asc' | 'desc' | '',
) => {
  return columnHelper.display({
    id,
    header: () => (
      <div
        className={classNames(
          'd-flex justify-content-start align-items-center ps-2 gap-1',
          sortByValue === 'score' ? 'text-primary' : 'gray-900',
        )}
      >
        {headerText}
        <span
          className="cursor-pointer"
          onClick={() => sortAttempts('score')}
        >
          {sortByValue !== 'score' ? (
            <SortIcon stroke={'var(--bs-gray-900)'} />
          ) : sortOrder === 'asc' ? (
            <ArrowUp size={20} />
          ) : (
            <ArrowDown size={20} />
          )}
        </span>
      </div>
    ),
    size: 75,
    enableResizing: false,
    cell: ({ row }) => (
      <div className="d-flex align-items-center ps-2">
        {row.original[percentId] !== null ? `${row.original[id]} (${row.original[percentId]}%)` : <Minus size={16} />}
      </div>
    ),
  });
};

export const totalScoreColumn = (
  sortAttempts: (sortBy: LearnerReportAttemptSortBy) => void,
  sortByValue: LearnerReportAttemptSortBy,
  sortOrder: 'asc' | 'desc' | '',
) => {
  return createScoreColumn('score', 'totalScore', 'Total Score', sortAttempts, sortByValue, sortOrder);
};
