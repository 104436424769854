import React from 'react';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import { ContentStatus, Document, TDocumentType, UserContentDto } from 'models/Content';
import { ContentTypeIcon } from '../ContentTypeIcon';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import moment from 'moment';
import { ContentStatusIcon } from '../ContentStatusIcon';
import ContentActionsPopup from '../ContentActionsPopup/ContentActionsPopup';
import { TitleCell, TitleCellProps } from './TitleCell';
import { RefreshCw, Trash2 } from 'react-feather';
import classNames from 'classnames';
import './Columns.scss';
import { useDeepLinking } from 'hooks';
import { appStore } from 'stores';

const columnHelper = createColumnHelper<Document>();

export const typeColumn = columnHelper.accessor('kind', {
  id: 'type',
  header: () => <div className="d-flex justify-content-center align-items-center">Type</div>,
  size: 75,
  enableResizing: false,
  cell: (info: CellContext<Document, TDocumentType>) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <ContentTypeIcon contentType={info.getValue()} />
      </div>
    );
  },
});

export const ownerColumn = columnHelper.accessor('user', {
  id: 'owner',
  header: 'Owner',
  size: appStore.ltiMode ? 110 : 175,
  enableResizing: false,
  cell: (info: CellContext<Document, UserContentDto>) => {
    const owner = info.getValue();

    return (
      <div className="d-flex align-items-center">
        <AvatarProfile
          imgSrc={owner?.profileImageUrl}
          width={32}
          height={32}
          userFullName={owner.fullName || owner.firstName + ' ' + owner.lastName}
        />
        <div className="ms-2 gray-700 flex-fill w-1px text-truncate owner-name">
          {owner.fullName || owner.firstName + ' ' + owner.lastName}
        </div>
      </div>
    );
  },
});

export const lastModifiedColumn = columnHelper.accessor('updatedAt', {
  id: 'last_modified',
  header: 'Last Modified',
  size: 175,
  enableResizing: false,
  cell: ({ row }) => {
    const updatedBy = row.original.updatedBy;
    const updatedAt = moment(new Date(row.original.updatedAt)).format('DD MMM, YYYY');
    return (
      <div className="gray-500 last-modified d-flex align-items-center">
        <div className="flex-fill w-1px">
          <div className="gray-900 last-modified__at"> {updatedAt}</div>
          <div className="last-modified__by text-truncate">{updatedBy?.fullName}</div>
        </div>
      </div>
    );
  },
});

export const statusColumn = columnHelper.accessor('status', {
  id: 'status',
  header: 'Status',
  size: appStore.ltiMode ? 55 : 75,
  enableResizing: false,
  cell: (info: CellContext<Document, ContentStatus>) => {
    return (
      <div className="ps-2">
        <ContentStatusIcon contentStatus={info.row.original.kind === TDocumentType.Folder ? '' : info.getValue()} />
      </div>
    );
  },
});

export const actionColumn = () => {
  return columnHelper.display({
    id: 'actions',
    size: appStore.ltiMode ? 40 : 60,
    enableResizing: false,
    cell: ({ row }) => {
      return <ContentActionsPopup content={row.original} />;
    },
  });
};

export const titleColumn = ({ allowEditMode, className, onRenameContent }: TitleCellProps & { className?: string }) => {
  return columnHelper.display({
    id: 'title',
    header: 'Title',
    meta: {
      className: classNames(className ?? ''),
    },
    cell: (props: CellContext<Document, Document>) => (
      <TitleCell
        {...props}
        allowEditMode={allowEditMode}
        onRenameContent={onRenameContent}
      />
    ),
  });
};

export const removedAtColumn = columnHelper.accessor('trashedAt', {
  id: 'trashedAt',
  header: 'Trash Date',
  size: 175,
  enableResizing: false,
  cell: (info: CellContext<Document, Date>) => {
    const removedAt = moment(info.getValue()).format('DD MMM, YYYY');
    return <div className="gray-900 font-weight-medium font-size-14">{removedAt}</div>;
  },
});

export const restoreColumn = ({ onRestore }: { onRestore: (contentId: string) => void }) => {
  return columnHelper.display({
    id: 'restore',
    header: () => <div className="d-flex justify-content-center align-items-center">Restore</div>,
    size: 100,
    enableResizing: false,
    cell: ({ row }) => {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <RefreshCw
            size={20}
            className="gray-500 cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              onRestore(row.original.id);
            }}
          />
        </div>
      );
    },
  });
};

export const permanentlyDeleteColumn = ({ onDelete }: { onDelete: (contentId: string) => void }) => {
  return columnHelper.display({
    id: 'permanentlyDelete',
    size: 60,
    enableResizing: false,
    cell: ({ row }) => {
      return (
        <Trash2
          size={20}
          className="gray-500 cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            onDelete(row.original.id);
          }}
        />
      );
    },
  });
};

export const insertContentColumn = () => {
  const { mutate, isLoading } = useDeepLinking();

  return columnHelper.display({
    id: 'insert',
    size: 60,
    enableResizing: false,
    cell: ({ row }) => {
      if (row.original.kind !== TDocumentType.Content) {
        return <></>;
      }

      return (
        <div className="d-flex justify-content-center align-items-center">
          <button
            disabled={isLoading}
            onClick={e => {
              e.stopPropagation();
              mutate(row.original.id);
            }}
            className="btn btn-outline-primary"
          >
            Insert
          </button>
        </div>
      );
    },
  });
};
