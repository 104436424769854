import { AppDropDown } from 'components/AppDropDown';
import React, { useMemo } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody } from 'reactstrap';
import { Footer } from '../Footer';
import { useGetOptionsConnections } from 'hooks';
import { TSelectOption } from 'models/Option';
import { DateRangePicker } from 'components/DateRangePicker';

export type ReportFilterModalProps = {
  value?: { platformId?: string; lastAttemptFrom?: number; lastAttemptTo?: number };
  onCancel?: () => void;
  onAccept?: (queries: { platformId?: string; lastAttemptFrom?: number; lastAttemptTo?: number }) => void;
  onClear?: () => void;
};

export const ReportFilter = ({ value, onClear, onCancel, onAccept }: ReportFilterModalProps) => {
  const { data } = useGetOptionsConnections({ cacheTime: 300000 });

  const platformOptions = useMemo(() => {
    let options: TSelectOption[] = [{ value: '', label: 'All Connections' }];

    if (data?.length) {
      options = [...options, ...data.map(tool => ({ value: tool.kid, label: tool.platformName }))];
    }

    return options;
  }, [data]);

  const { control, getValues, reset } = useForm<{
    platformId: string;
    lastAttemptFrom?: number;
    lastAttemptTo?: number;
  }>({
    defaultValues: {
      platformId: value.platformId || platformOptions[0].value,
      lastAttemptFrom: value.lastAttemptFrom || null,
      lastAttemptTo: value.lastAttemptTo || null,
    },
  });

  const onApplyFilter = () => {
    const data = getValues();

    onAccept?.(data);
  };

  const clearAll = () => {
    reset({
      platformId: '',
      lastAttemptFrom: null,
      lastAttemptTo: null,
    });
    onClear?.();
  };

  return (
    <div className="d-flex flex-column p-3 modal-wrapper">
      <ModalBody className="d-flex flex-column gap-3 mb-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="gray-900 fw-semibold font-size-18">Filter</div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="form-group mt-2 font-size-12">
          <label className="gray-900 font-size-12">LMS Connection</label>
          <Controller
            name="platformId"
            control={control}
            render={fields => (
              <AppDropDown
                items={platformOptions}
                selected={fields.field.value}
                selectItemFunc={item => fields.field.onChange(item)}
              />
            )}
          />
        </div>

        <div className="d-block d-md-none form-group mt-2 font-size-12">
          <label className="gray-900 font-size-12">Filter by date</label>
          <DateRangePicker
            fromDate={getValues().lastAttemptFrom ? new Date(getValues().lastAttemptFrom * 1000) : null}
            toDate={getValues().lastAttemptTo ? new Date(getValues().lastAttemptTo * 1000) : null}
            onDatesChange={(startDate, endDate) =>
              reset({ platformId: getValues().platformId, lastAttemptFrom: startDate, lastAttemptTo: endDate })
            }
            type="input"
          />
        </div>
      </ModalBody>

      <Footer
        labelCancel="Clear"
        labelOK="Apply"
        onOK={onApplyFilter}
        onCancel={() => clearAll()}
      />
    </div>
  );
};
