export const formatFileType = (mimeType: string): string => {
  const mimeTypeMap: { [key: string]: string } = {
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
    'application/msword': 'DOC',
    'application/vnd.ms-powerpoint': 'PPT',
    'text/plain': 'TXT',
  };

  return mimeTypeMap[mimeType];
};
