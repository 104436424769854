import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { useGetUserDetail, useUpdateUserDetail } from 'hooks';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { ArrowLeft, Save } from 'react-feather';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { flattenPermissions } from 'utils/user_detail.utils';
import { UserDetailCard } from './mains/UserDetailCard';
import { UserDetailInfo } from './mains/UserDetailInfo';
import './UserDetailPage.scss';
import { Loading } from 'components';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export const UserDetailPage = observer(() => {
  const { userId: id } = useParams<{ userId?: string }>();
  const navigate = useNavigate();
  const { userInfo } = useAppStore();

  const { isLoading } = useGetUserDetail(id);

  const methods = useForm();

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const { mutate, isLoading: isLoadingUpdate } = useUpdateUserDetail(id);

  const onSubmit = data => {
    const { roles, ...rest } = data;

    mutate(
      { userId: id, rolesPermissions: flattenPermissions(rest), roles: [roles] },
      {
        onSuccess: () => {
          reset({}, { keepValues: true });
        },
      },
    );
  };

  usePrompt(isDirty);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PageHeader
          title="User Permissions"
          backTo="/organisation/users"
          hasBackButton
          rightElement={
            id !== userInfo?.id ? (
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary d-flex align-items-center"
                  type="submit"
                  data-cy="btn-save-changes"
                  disabled={isLoadingUpdate}
                >
                  <Save
                    className="d-none d-md-block me-2"
                    color="white"
                    strokeWidth={1.5}
                    height={20}
                    width={20}
                  />
                  Save
                </button>
              </div>
            ) : (
              <></>
            )
          }
        />
        <div className="d-md-none d-flex align-items-center font-size-14 mt-3 px-2 gap-3">
          <button
            onClick={() => {
              navigate('/organisation/users');
            }}
            type="button"
            className="btn border-0 fw-medium gray-500 p-0"
          >
            <ArrowLeft
              size={20}
              className="me-1 align-top"
            />
            Back
          </button>
        </div>

        <div className="d-flex flex-column p-2 p-md-4 pt-0 pt-md-0">
          <div className="flex-fill d-flex flex-column">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <UserDetailInfo />
                <UserDetailCard />
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
});
