import React, { ReactElement } from 'react';
import { Modal } from 'reactstrap';

import useModal from 'hooks/useModal';
import { ModalType } from 'contexts/Modal';

import './ModalManager.scss';
import { Confirm, ConfirmModalProps } from './Confirm';
import { Publish } from './Publish';
import { AlertTriangle, Trash2 } from 'react-feather';
import { useMediaScreen } from 'hooks/useMediaScreen';
import ContentAdvanceFilter from './ContentAdvanceFilter/ContentAdvanceFilter';
import classNames from 'classnames';
import { ContentActions } from './ContentActions/ContentActions';
import { ModalInviteUsers } from './ModalInviteUsers';
import { ModalCreateWorkspace } from './ModalCreateWorkspace/ModalCreateWorkspace';
import { CreateFolder } from './CreateFolder/CreateFolder';
import { Rename } from './Rename/Rename';
import { NewContent } from './NewContent';
import { UserActions } from './UserActions';
import { ShareWith } from './ShareWith';
import { MoveFolder } from './MoveFolder';
import { AddLMSConnection } from './AddLMSConnection';
import { AddPaymentMethod } from './AddPaymentMethod';
import { UploadContent } from './UploadContent';
import { ExpiredPlan } from './ExpiredPlan';
import { PaymentRetry } from './PaymentRetry';
import { AddConnectionSettings } from './AddConnectionSettings';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DeactivateStudentReport } from './DeactivateStudentReport';
import { ReportFilter } from './ReportFilter';
import { ContentAttempts } from './ContentAttempts';
import { RenameChat } from './RenameChat';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const ModalManager = () => {
  const { isOpen, type, modalProps } = useModal();
  const { isMdDown } = useMediaScreen();

  const mappingModalType = new Map<ModalType, ReactElement>();
  mappingModalType.set('move-to-trash', <Confirm {...modalProps} />);
  mappingModalType.set(
    'delete',
    <Confirm
      titleIcon={
        <Trash2
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      }
      {...modalProps}
    />,
  );
  mappingModalType.set('restore', <Confirm {...modalProps} />);
  mappingModalType.set(
    'warning',
    <Confirm
      {...(modalProps as ConfirmModalProps)}
      titleIcon={
        modalProps?.titleIcon || (
          <AlertTriangle
            strokeWidth={2}
            className="text-primary block"
            size={24}
          />
        )
      }
    />,
  );
  mappingModalType.set(
    'move-folder',
    <MoveFolder
      labelOK="Move here"
      title="Move to another folder"
      {...modalProps}
    />,
  );
  mappingModalType.set(
    'new-folder',
    <CreateFolder
      type="folder"
      headerTitle="New folder"
      inputLabel="Folder name"
      inputPlaceHolder="Input folder name"
      labelOK="Create folder"
      {...modalProps}
    />,
  );
  mappingModalType.set(
    'rename-content',
    <Rename
      type="content"
      headerTitle="Rename"
      inputPlaceHolder="Input title"
      labelOK="Rename"
      {...modalProps}
    />,
  );
  mappingModalType.set(
    'publish',
    <Publish
      labelOK="Update"
      {...modalProps}
    />,
  );
  mappingModalType.set('share-with', <ShareWith {...modalProps} />);
  mappingModalType.set('duplicate-content', <Confirm {...modalProps} />);
  mappingModalType.set('content-filter', <ContentAdvanceFilter {...modalProps} />);
  mappingModalType.set('content-actions', <ContentActions {...modalProps} />);
  mappingModalType.set('invite-users', <ModalInviteUsers {...modalProps} />);
  mappingModalType.set('create-workspace', <ModalCreateWorkspace {...modalProps} />);
  mappingModalType.set('new-content', <NewContent {...modalProps} />);
  mappingModalType.set('user-actions', <UserActions {...modalProps} />);
  mappingModalType.set('add-lms-connection', <AddLMSConnection {...modalProps} />);
  mappingModalType.set('add-payment-method', <AddPaymentMethod {...modalProps} />);
  mappingModalType.set('upload-content', <UploadContent {...modalProps} />);
  mappingModalType.set('expired-plan', <ExpiredPlan {...modalProps} />);
  mappingModalType.set(
    'payment-retry',
    <Elements stripe={stripePromise}>
      <PaymentRetry {...modalProps} />
    </Elements>,
  );
  mappingModalType.set('add-connection-settings', <AddConnectionSettings {...modalProps} />);
  mappingModalType.set('deactivate-student-report', <DeactivateStudentReport {...modalProps} />);
  mappingModalType.set('report-filter', <ReportFilter {...modalProps} />);
  mappingModalType.set('content-attempts', <ContentAttempts {...modalProps} />);
  mappingModalType.set(
    'rename-chat',
    <RenameChat
      headerTitle="Rename"
      inputPlaceHolder="Input chat name"
      labelOK="Rename"
      {...modalProps}
    />,
  );

  const RenderedModal = mappingModalType.get(type);

  return (
    <Modal
      className={classNames(
        { 'align-items-end mw-100 h-100 m-0': isMdDown && !modalProps?.hiddenModalClassName },
        modalProps?.className,
      )}
      isOpen={isOpen}
      centered
      fade={true}
      size={modalProps.size || 'md'}
      scrollable={modalProps?.scrollable}
    >
      {RenderedModal}
    </Modal>
  );
};
