import apiClient from 'apis/ApiClient';
import { UserDetail, UpdateUserRolesParams, User, UserQueryParams, UserRole } from 'types';

const endPoint = '/users';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const fetchUsers = async (params: UserQueryParams): Promise<User[]> => {
  const { data } = await apiClient.get(endPoint, { params });
  await sleep(300);
  return data;
};

export const updateUserRoles = async (params: UpdateUserRolesParams): Promise<User> => {
  const { email, status, roles } = params;

  return await apiClient
    .patch(`${endPoint}/roles`, { roles: roles?.map(x => x.toUpperCase())?.sort(), email, status })
    .then(response => {
      return response.data;
    });
};

export const getUserDetail = async (userId: string): Promise<UserDetail> => {
  return await apiClient.get(`${endPoint}/${userId}`).then(response => {
    return response.data;
  });
};

export const updateUserDetail = async (params: {
  userId: string;
  roles: UserRole;
  rolesPermissions: { [key: string]: boolean };
}): Promise<UserDetail> => {
  const { userId, rolesPermissions, roles } = params;

  return await apiClient.patch(`${endPoint}/${userId}`, { rolesPermissions, roles }).then(response => {
    return response.data;
  });
};
