import { Media } from 'models/Media';
import apiClient from '../ApiClient';

export async function uploadMedia(payload: FormData): Promise<Media> {
  return await apiClient
    .post('media', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      return response.data;
    });
}
