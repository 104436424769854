import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { SidebarProvider, ModalProvider, Compose } from 'contexts';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import { ToastComponent } from './components/ToastComponent';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import 'bootstrap/dist/js/bootstrap.bundle';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID || 'dummy');
const queryClient = new QueryClient();

const app = (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Compose components={[ModalProvider, SidebarProvider]}>
        <HelmetProvider>
          <Helmet
            defaultTitle="Evokio"
            titleTemplate={`%s | ${process.env.REACT_APP_APP_NAME}`}
          />
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
          <ToastComponent />
        </HelmetProvider>
      </Compose>
    </BrowserRouter>
    <ReactQueryDevtools position="bottom-right" />
  </QueryClientProvider>
);

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
