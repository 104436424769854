import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Folder, MoreVertical } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import rootFolder from 'assets/images/root-folder.svg';

export type CrumbItem = { title: string; path: string; unclickable?: boolean; replace?: boolean };

type Props = {
  crumbs?: CrumbItem[];
  displayFull?: boolean;
};

export const AppBreadCrumb = ({ crumbs = [], displayFull = false }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const displayedCrumbs = useMemo(() => {
    const longCrumbs = crumbs ? [...crumbs] : [];
    if (longCrumbs.length > 3 && !displayFull) {
      return longCrumbs.slice(longCrumbs.length - 2, longCrumbs.length);
    }
    return longCrumbs;
  }, [crumbs]);

  const dropdownCrumbs = useMemo(() => {
    const dropdown = crumbs ? [...crumbs] : [];
    if (dropdown.length > 3 && !displayFull) {
      return dropdown.slice(0, dropdown.length - 2);
    }
    return [];
  }, [crumbs]);

  if (!crumbs?.length) return <></>;

  return (
    <nav
      data-cy="breadcrumb-wrapper"
      aria-label="breadcrumb"
    >
      <ol className="breadcrumb">
        {dropdownCrumbs.length > 0 && (
          <li className="breadcrumb-item">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              direction="down"
            >
              <DropdownToggle className="bg-light rounded-circle h-100 w-100 d-flex justify-content-center align-items-center p-1">
                <MoreVertical
                  size={20}
                  className="cursor-pointer gray-500"
                />
              </DropdownToggle>
              <DropdownMenu>
                {dropdownCrumbs.map((crumb, index) => {
                  if (index === 0) {
                    return (
                      <DropdownItem
                        key={crumb.path}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={rootFolder}
                          alt=""
                          className="me-2"
                        />
                        <NavLink
                          className="fw-semibold"
                          to={crumb.path}
                          onClick={e => {
                            if (crumb.unclickable) {
                              e.preventDefault();
                            }
                          }}
                          replace={crumb.replace}
                        >
                          {crumb.title}
                        </NavLink>
                      </DropdownItem>
                    );
                  }

                  return (
                    <DropdownItem
                      key={crumb.path}
                      className="d-flex align-items-center"
                      id={`dropdown-crumb-` + index}
                    >
                      <Folder
                        size={20}
                        className="me-2 gray-400"
                      />
                      <NavLink
                        className="fw-semibold text-truncate"
                        style={{ maxWidth: 200 }}
                        to={crumb.path}
                        onClick={e => {
                          if (crumb.unclickable) {
                            e.preventDefault();
                          }
                        }}
                        replace={crumb.replace}
                      >
                        {crumb.title}
                        <UncontrolledTooltip
                          placement="bottom"
                          className="d-none d-sm-block"
                          container="body"
                          target={`dropdown-crumb-` + index}
                        >
                          {crumb.title}
                        </UncontrolledTooltip>
                      </NavLink>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </li>
        )}
        {displayedCrumbs.map((crumb, key) => {
          return (
            <li
              key={key}
              className={classNames('breadcrumb-item', {
                active: key === displayedCrumbs.length - 1,
              })}
              id={`crumb-title-` + key}
            >
              <NavLink
                data-cy={`breadcrumb-item-${crumb.title}`}
                to={crumb.path}
                className="fw-semibold text-truncate"
                style={{ maxWidth: 200 }}
                onClick={e => {
                  if (crumb.unclickable) {
                    e.preventDefault();
                  }
                }}
                replace={crumb.replace}
              >
                {crumb.title}

                <UncontrolledTooltip
                  placement="bottom"
                  className="d-none d-sm-block"
                  container="body"
                  target={`crumb-title-` + key}
                >
                  {crumb.title}
                </UncontrolledTooltip>
              </NavLink>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
