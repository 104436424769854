import { useMutation, useQueryClient } from 'react-query';
import { addMessageToChatHistory } from './addMessageToChatHistory';
import { toast } from 'react-toastify';
import { regenerateMessage } from 'apis/AiChat/AiChat';

export const useRegenerateMessage = () => {
  const queryClient = useQueryClient();

  return useMutation(regenerateMessage, {
    onSuccess: data => {
      addMessageToChatHistory({
        queryClient,
        chatId: data.aiChatId,
        message: data,
      });
    },
    onError: () => {
      toast("Oops! We couldn't process your input. Please try making it shorter or simpler.", { type: 'error' });
    },
  });
};
