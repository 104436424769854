import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import IconLogo from './IconLogo';
import MainLogo from './MainLogo';

const EvokioLogo = observer(() => {
  const { uiStore } = useAppStore();
  const { isNavbarCollapsed } = uiStore;

  return isNavbarCollapsed ? <IconLogo /> : <MainLogo />;
});

export default EvokioLogo;
