import { ErrorResponse, WorkspaceInfo } from 'types';
import apiClient from '../ApiClient';

export async function createWorkspace(payload: string): Promise<WorkspaceInfo | ErrorResponse> {
  return await apiClient.post('workspaces', { name: payload }).then(response => {
    return response.data;
  });
}

export async function getWorkspaces(): Promise<WorkspaceInfo[]> {
  return await apiClient.get('workspaces').then(response => {
    return response.data;
  });
}

export async function updateWorkspace(payload: WorkspaceInfo): Promise<WorkspaceInfo> {
  return await apiClient.patch(`workspaces/${payload.id}`, { ...payload }).then(response => {
    return response.data;
  });
}

export const inviteUsers = async (payload: { email: string; roles: string[] }) => {
  const { email, roles } = payload;

  return await apiClient.post('workspaces/invitation', { email, roles }).then(response => {
    return response.data;
  });
};

export const resendInvitation = async (email: string) => {
  return await apiClient.post('workspaces/invitation/resend', { email });
};

export const removeUser = async (userId: string) => {
  return await apiClient.delete(`workspaces/members/${userId}`);
};

export const removePendingUser = async (email: string) => {
  return await apiClient.delete('workspaces/invitation', { data: { email } });
};
