import React, { Fragment, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import classNames from 'classnames';
import './PasswordField.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  error?: FieldError | boolean;
  label?: string;
  register?: UseFormRegisterReturn<any>;
  dataCy?: string;
}

// eslint-disable-next-line react/display-name
const PasswordField: React.FC<Props> = forwardRef(
  ({ placeholder = '', error, maxLength, label, register, dataCy = 'password-field', ...props }: Props, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const classesInput = classNames('form-control password gray-900 bg-white', {
      invalid: error,
    });

    return (
      <Fragment>
        {label && <label className="mb-2 d-block gray-900">{label}</label>}
        <div className="d-flex align-items-center position-relative w-100">
          <input
            ref={ref}
            type={showPassword ? 'text' : 'password'}
            className={classesInput}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={maxLength && maxLength + 1}
            {...register}
            {...props}
            data-cy={dataCy}
          />

          <div
            className="addon-icon d-flex align-items-center pe-3"
            data-cy="toggle-icon"
          >
            {!showPassword ? (
              <div
                className="cursor-pointer"
                onClick={() => setShowPassword(true)}
              >
                <EyeOff
                  size={20}
                  className="gray-900"
                />
              </div>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => setShowPassword(false)}
              >
                <Eye
                  size={20}
                  className="gray-900"
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  },
);

export default PasswordField;
