import { H5PPlayerUI } from '@lumieducation/h5p-react';
import { IPlayerModel } from '@lumieducation/h5p-server';
import { getEmbed } from 'apis/Content/Content';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ContentEmbedPage = () => {
  const { id: contentId } = useParams();

  const loadContentPlayAsync = async (contentId: string): Promise<IPlayerModel> => {
    return await getEmbed(contentId);
  };

  return (
    <H5PPlayerUI
      contentId={contentId}
      loadContentCallback={loadContentPlayAsync}
    />
  );
};
