import { resendInvitation } from 'apis/Workspace/Workspace';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useResendInvitation = () => {
  return useMutation(resendInvitation, {
    retry: false,
    onSuccess: () => {
      toast('Invitation has been resent!', { type: 'success' });
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
