import { ContentRole } from '@spiderbox/common';
import { createFolder, findPermissions } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { Document } from 'models/Content';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useCreateFolder = () => {
  const queryClient = useQueryClient();
  const { queryKey } = useAppStore();

  return useMutation(createFolder, {
    retry: false,
    onSuccess: async (data, payload) => {
      toast('Folder has been created.', { type: 'success' });
      const { parentId } = payload;

      if (!parentId) {
        queryClient.invalidateQueries(queryKey);
        return;
      }

      if (queryKey.includes(parentId)) {
        const documents = queryClient.getQueryData<Document[]>(queryKey);
        const contentPermission = await findPermissions(data.id);

        if (contentPermission) {
          const { workspaceRole, userRoles } = contentPermission;
          const hasSharing =
            (!!workspaceRole?.role && workspaceRole?.role !== ContentRole.NONE) ||
            (!!userRoles && userRoles?.some(role => role.role !== ContentRole.NONE));

          queryClient.setQueryData(queryKey, [
            { ...data, contentRole: ContentRole.OWNER, hasSharing },
            ...(documents || []),
          ]);
        }
      }
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
