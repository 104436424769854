import React from 'react';
import './SubHeader.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import { MultipleSelectActions } from '../MultipleSelectActions';
import { ArrowLeft, Filter } from 'react-feather';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';

type Props = {
  filterCount: number;
  crumbs?: { title: string; path: string }[];
  crumbTitle?: string;
  search?: string;
  onSearch?: (value: string) => void;
  onOpenFilterModal?: () => void;
};
export const SubHeader = observer(
  ({ filterCount = 0, crumbs, crumbTitle, search, onSearch, onOpenFilterModal }: Props) => {
    const navigate = useNavigate();

    const { ltiMode } = useAppStore();

    const handleBack = () => {
      if (crumbs?.length > 2 || !(crumbs[crumbs.length - 1]?.title === 'Search results')) {
        navigate(crumbs[crumbs.length - 2].path);
      } else {
        navigate(crumbs[0]?.path);
      }
    };

    return (
      <div className="p-3 px-md-4 py-md-4 bg-neutral-50">
        <div className="row content-actions">
          <div
            data-cy="content-actions"
            className={classNames('d-none align-items-center', {
              ' d-sm-flex col-sm-6': ltiMode,
              ' d-md-flex col-md-6': !ltiMode,
            })}
          >
            <MultipleSelectActions
              crumbs={crumbs}
              crumbTitle={crumbTitle}
            />
          </div>
          {crumbs?.length > 1 && (
            <div
              className={classNames('d-flex align-items-center font-size-16 mb-3 gap-3', {
                'd-sm-none': ltiMode,
                'd-md-none': !ltiMode,
              })}
            >
              <button
                onClick={handleBack}
                type="button"
                className="btn border-0 fw-semibold gray-600 p-0"
              >
                <ArrowLeft
                  size={20}
                  className="align-top"
                />
              </button>
              <div className="text-truncate gray-900 fw-bold">{crumbs?.[crumbs?.length - 1].title}</div>
            </div>
          )}
          <div
            className={classNames(' d-flex align-items-center', {
              'col-sm-6': ltiMode,
              'col-md-6': !ltiMode,
            })}
          >
            <div className="flex-fill pe-4">
              <SearchBar
                value={search}
                search={value => onSearch && onSearch(value)}
                placeholder="Search for folder or content name..."
              />
            </div>

            <button
              data-cy="filter-button"
              className={classNames('btn btn-outline-primary d-flex align-items-center', {
                'bg-primary-50': filterCount > 0,
              })}
              onClick={() => onOpenFilterModal && onOpenFilterModal()}
            >
              {filterCount === 0 ? (
                <Filter
                  size={20}
                  className="primary-500 me-2"
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle p-1 bg-primary text-white font-size-12 h5 me-2 mb-0"
                  style={{ width: 20, height: 20 }}
                >
                  {filterCount}
                </div>
              )}

              <span>Filters</span>
            </button>
          </div>
        </div>
      </div>
    );
  },
);
