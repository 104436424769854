import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import './Login.scss';
import leftImage from 'assets/images/auth/left-image-sign-in.svg';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/AppForm/TextField';
import PasswordField from 'components/AppForm/PasswordField';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorResponse, SignInFormInput, LoginResponse } from 'types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import yupPassword from 'yup-password';
import { appStore } from 'stores/AppStore';
import { useMutation } from 'react-query';
import { login } from 'apis/Auth/Auth';
import { Helmet } from 'react-helmet-async';
import IconLogo from 'assets/icons/IconLogo';

yupPassword(yup);

const LoginSchema: yup.ObjectSchema<any> = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email is invalid.')
    .required('Email is required.')
    .max(255, 'Email length must be less than 255 characters.'),
  password: yup.string().required('Password is required.'),
});

export const LoginPage = observer(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<SignInFormInput>({
    reValidateMode: 'onChange',
    resolver: yupResolver<SignInFormInput>(LoginSchema),
  });

  const navigate = useNavigate();

  const { isLoading, mutate, error } = useMutation<LoginResponse, ErrorResponse, SignInFormInput>(login, {
    retry: false,
    onSuccess: (data: LoginResponse) => {
      appStore.updateAccessToken(data.accessToken);
      appStore.updateRefreshToken(data.refreshToken);
      navigate('/');
    },
  });

  const onSubmit = async (value: SignInFormInput) => {
    mutate(value);
    reset({}, { keepValues: true });
  };

  return (
    <PerfectScrollbar options={{ suppressScrollX: true, useBothWheelAxes: false }}>
      <div className="sign-in-wrapper">
        <div className="sign-in--tab bg-image bg-primary-400">
          <div className="sign-in--left">
            <div>
              <IconLogo
                isVariant={true}
                className="mb-3"
              />

              <div className="sign-in--left__title primary-50">
                Create, share, and re-use interactive HTML5 content{' '}
              </div>
            </div>
            <img
              className="sign-in--left__img"
              src={leftImage}
            />
          </div>
        </div>
        <div className="sign-in--tab sign-in--bg-color">
          <div className="sign-in--right d-flex flex-column justify-content-center">
            <p className="sign-in--right__title gray-900">Sign In</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Helmet>
                <title>Sign In</title>
              </Helmet>
              <div className="form-group">
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      placeholder="john@example.com"
                      label="Email"
                      error={error}
                      maxLength={255}
                      dataCy="email-field"
                    />
                  )}
                />
                <p className="feedback-invalid">{errors.email?.message}</p>
              </div>
              <div className="form-group">
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <PasswordField
                      {...field}
                      placeholder="Input password"
                      label="Password"
                      error={error}
                    />
                  )}
                />
                <p className="feedback-invalid">{errors.password?.message}</p>
              </div>
              <div className="form-group sign-up-link">
                <Link to="/auth/forgot-password">Forgot password?</Link>
              </div>
              <div className="d-flex mt-4">
                <button
                  className="btn btn-primary w-100 px-3 py-2 text-white"
                  type="submit"
                  disabled={isLoading}
                >
                  Sign In
                  {isLoading && (
                    <span
                      className="ms-3 spinner-border spinner-border-sm text-light"
                      role="status"
                    ></span>
                  )}
                </button>
              </div>
              <div className="form-group">
                <p className="feedback-invalid">{error?.message && !isDirty ? error?.message : ''}</p>
              </div>
              <div className="divider my-4"></div>
              <div className="d-flex justify-content-center align-items-center gray-900 sign-up-link">
                Don&apos;t have an account?{' '}
                <Link
                  className="text-primary-500 ms-1"
                  to="/auth/sign-up"
                >
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  );
});
