import { PageHeader } from 'components/PageHeader';
import React from 'react';
import { Save } from 'react-feather';
import { NavLink } from 'react-router-dom';
import './SettingsLayout.scss';
import { UserPermission } from 'components/UserPermission';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

type IProps = {
  handleSubmit?: () => void;
  isLoading?: boolean;
  children: JSX.Element;
};

export const SettingsLayout = observer(({ handleSubmit, isLoading = false, children }: IProps) => {
  const {
    subscriptionStore: { currentSubscription },
  } = useAppStore();
  return (
    <form
      onSubmit={handleSubmit}
      className="d-flex flex-column settings-layout vh-100"
    >
      <PageHeader
        title="Settings"
        rightElement={
          <UserPermission actions={['organisation.editContentBehavior']}>
            <button
              className="btn btn-primary p-2 d-flex align-items-center"
              type="submit"
              disabled={isLoading}
              data-cy="btn-save-changes"
            >
              <Save
                className="d-none d-md-block me-2"
                color="white"
                strokeWidth={2}
                height={20}
                width={20}
              />
              Save
            </button>
          </UserPermission>
        }
        subHeader={
          <div className="header-routing gap-4 d-flex bg-white">
            <NavLink
              to="../content-behaviour"
              className={({ isActive }) => {
                return `text-medium cursor-pointer header-routing--title ${isActive ? 'active' : ''}`;
              }}
              data-cy="tab-content-behavior"
            >
              Content Behaviour
            </NavLink>
            {currentSubscription.studentReportItem && (
              <NavLink
                to="../lti"
                className={({ isActive }) => {
                  return `text-medium cursor-pointer header-routing--title ${isActive ? 'active' : ''}`;
                }}
                data-cy="tab-lti-setting"
              >
                LTI Settings
              </NavLink>
            )}
          </div>
        }
      />
      <div className="settings-wrapper flex-fill d-flex flex-column">
        <div className="flex-fill p-0 p-md-4">{children}</div>
      </div>
    </form>
  );
});
