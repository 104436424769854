import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from 'components/AppForm/TextField';
import useModal from 'hooks/useModal';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import * as yup from 'yup';
import { ERSColor } from '../ModalInviteUsers/common/RSInviteUsers';
import { useAddConnection } from 'hooks/lti/useAddConnection';
import { CreateConnection } from 'types';

export const AddLMSConnection = () => {
  const optionsLMS = [
    { value: 'nimbu', label: 'Nimbu' },
    { value: 'moodle', label: 'Moodle' },
    { value: 'brightspace', label: 'BrightSpace' },
    { value: 'other', label: 'Other' },
  ];

  const { closeModal } = useModal();
  const { addConnection } = useAddConnection();

  const schema = yup
    .object()
    .shape({
      lms: yup.object().required('LMS is required.'),
      platformName: yup.string().trim().required('Connection name is required.'),
    })
    .required();

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Partial<CreateConnection>>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const watchLMS = watch('lms');

  const onSubmit = handleSubmit((data: Partial<CreateConnection>) => {
    addConnection(data);
    closeModal();
    reset({});
  });

  useEffect(() => {
    if (watchLMS?.value) {
      reset(
        {
          lms: watchLMS,
          ltiVersion: 'LTI v1.3',
          platformName: '',
        },
        {
          keepValues: false,
        },
      );
    }
  }, [watchLMS?.value]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div
          className="d-flex flex-column p-3 modal-wrapper"
          data-cy="add-lms-connection"
        >
          <div className="d-flex justify-content-between align-item-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                className="gray-900 fw-semibold font-size-18"
                data-cy="header-title"
              >
                New Connection
              </div>
              <X
                onClick={() => {
                  closeModal();
                  reset({});
                }}
                className="close-icon me-0 gray-400 cursor-pointer d-block"
                size={24}
                data-cy="close-icon"
              />
            </div>
          </div>

          <div className="form-group mt-3">
            <label className="text-neutral-900">Select your LMS</label>
            <Controller
              control={control}
              name="lms"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  isSearchable={false}
                  placeholder="-"
                  options={optionsLMS}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  classNamePrefix="select"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: errors?.lms ? 'red' : state.isFocused ? ERSColor.PRIMARY : ERSColor.NEUTRAL_200,
                      boxShadow: errors?.lms ? '0 0 0 4px #fee2e2' : 'none',
                      '&:hover': {
                        borderColor: errors?.lms ? 'red' : state.isFocused ? ERSColor.PRIMARY : ERSColor.NEUTRAL_200,
                      },
                      height: 40,
                    }),
                  }}
                />
              )}
            />

            {errors?.lms && <p className="feedback-invalid">{errors?.lms?.message as string}</p>}
          </div>

          {watchLMS && (
            <>
              <div className="form-group mt-3">
                <TextField
                  register={register('platformName')}
                  label="Connection name"
                  style={{
                    height: 40,
                  }}
                  dataCy="connection-name"
                />
                {errors?.platformName && <p className="feedback-invalid">{errors?.platformName?.message as string}</p>}
              </div>

              <div className="form-group mt-3">
                <TextField
                  register={register('ltiVersion')}
                  label="LTI version"
                  style={{
                    height: 40,
                    backgroundColor: 'white',
                  }}
                  value={'LTI v1.3'}
                  dataCy="lti-version"
                  disabled
                />
              </div>
            </>
          )}

          <button
            className="ms-auto mt-3 w-fit btn btn-primary"
            type="submit"
          >
            Add
          </button>
        </div>
      </form>
    </>
  );
};
