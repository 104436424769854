import React, { useEffect, useMemo } from 'react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { downloadContentUserReports, findContentUserReports } from 'apis/ContentUserData/ContentUserData';
import { LearnerReportLayout } from 'pages/LearnerReport/LearnerReportLayout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ContentUserData } from 'types/student-report';
import {
  attemptsColumn,
  bestScoreColumn,
  firstScoreColumn,
  lastScoreColumn,
  userColumn,
} from './components/Columns/Columns';
import Skeleton from 'react-loading-skeleton';
import { useGetOptionsConnections } from 'hooks';
import { parse } from 'qs';

export const ContentUserDataReports = () => {
  const { id: contentId } = useParams();
  const navigate = useNavigate();
  const [searchParam, setSearchParams] = useSearchParams();
  const columnHelper = createColumnHelper<ContentUserData>();
  const { data, isLoading } = useGetOptionsConnections({ cacheTime: 0 });

  const connectionsData = useMemo(() => {
    if (!data?.length) return {};

    return data.reduce((current, { kid, platformName }) => {
      return { ...current, [kid]: platformName };
    }, {});
  }, [data]);

  useEffect(() => {
    setSearchParams(
      prevValue => {
        const preQueries = parse(prevValue.toString());
        return { ...preQueries, user: '', platformName: '' };
      },
      { replace: true },
    );
  }, []);

  const onRowClick = (row: Row<ContentUserData>) => {
    const report = row.original;
    const lastAttemptFrom = searchParam.get('lastAttemptFrom') || '';
    const lastAttemptTo = searchParam.get('lastAttemptTo') || '';
    const platformId = report.platformId;

    navigate(
      `${report.userId}?lastAttemptFrom=${lastAttemptFrom}&lastAttemptTo=${lastAttemptTo}&platform=${platformId}&user=${report.user.fullName}&platformName=${connectionsData[platformId]}`,
      { replace: true },
    );
  };

  const lmsConnectionColumn = columnHelper.display({
    id: 'content-reports-lms-connection',
    header: 'LMS Connection',
    meta: {
      className: 'w-25',
    },
    cell: ({ row }) => {
      const platformId = row.original.platformId;

      return isLoading ? (
        <Skeleton />
      ) : (
        <div className="d-flex pe-2">
          <div className="gray-900 flex-fill w-1px text-truncate">{connectionsData[platformId]}</div>
        </div>
      );
    },
  });

  const studentReportColumns = [
    userColumn,
    lmsConnectionColumn,
    attemptsColumn,
    firstScoreColumn,
    lastScoreColumn,
    bestScoreColumn,
  ];

  return (
    <LearnerReportLayout
      queryKey={['contents', contentId, 'reports']}
      fetchReports={searchTerm => findContentUserReports(contentId, searchTerm)}
      downloadCsv={searchTerm => downloadContentUserReports(contentId, searchTerm)}
      defaultColumns={studentReportColumns}
      searchInputPlaceHolder="Search by name or email..."
      canFilterByPlatform={true}
      onRowClick={onRowClick}
      crumbs={[{ path: '', title: 'All Learners', unclickable: true }]}
      isLoading={isLoading}
    />
  );
};
