import React, { useMemo, useState } from 'react';
import { UserDetailRow } from '../UserDetailRow';
import { ChevronRight } from 'react-feather';
import classNames from 'classnames';
import { useGetFetchQuery } from 'hooks/useGetFetchQuery';
import { useParams } from 'react-router-dom';
import { UserDetail } from 'types';
import { convertRolePermission } from 'utils/user_detail.utils';
import { Role } from '@spiderbox/common';
import './UserDetailAccordion.scss';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

export const UserDetailAccordion = observer(() => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { userId: id } = useParams<{ userId?: string }>();

  const { userInfo } = useAppStore();

  const { watch } = useFormContext();

  const { rolesPermissions, roles } = useGetFetchQuery(['users', id, 'detail']) as UserDetail;

  const hasAdminPermission = useMemo(() => {
    return watch('roles') ? watch('roles') === Role.ADMIN : roles?.includes(Role.ADMIN);
  }, [watch('roles'), roles]);

  const rolesPermissionsMapping = useMemo(
    () => convertRolePermission(rolesPermissions, hasAdminPermission ? Role.ADMIN : Role.MEMBER),
    [rolesPermissions, watch('roles'), roles],
  );

  return (
    <div
      className="user-detail accordion"
      style={{ zIndex: 1 }}
      id={`accordionPanelsStayOpen-admin`}
      data-cy="user-permission-accordion"
    >
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id={`panelsStayOpen-headingOne-admin`}
        >
          <button
            className="accordion-button collapsed fs-md fw-medium text-neutral-900 bg-white shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-admin`}
            aria-expanded={isOpen}
            aria-controls={`panelsStayOpen-admin`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ChevronRight
              className={classNames('accordion-button__icon', { 'accordion-button__icon--open': isOpen })}
              data-cy="user-permission-accordion__icon"
            />
            <span
              className="d-inline-block ms-2"
              data-cy="user-permission-accordion__title"
            >
              {hasAdminPermission ? 'Admin' : 'Member'}
            </span>
          </button>
        </h2>
        <div
          id={`panelsStayOpen-admin`}
          className="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div
            className="accordion-body d-grid px-3 pt-0 pb-3"
            data-cy="roles-permission-mapping"
          >
            {rolesPermissionsMapping?.map(row => (
              <UserDetailRow
                key={row.action}
                {...row}
                disabled={id === userInfo?.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
