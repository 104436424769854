import React from 'react';
import { WorkspaceProfileBtn } from '../WorkspaceProfileBtn';
import { WorkspacesList } from '../WorkspacesList';
import { WorkspaceCurrent } from '../WorkspaceCurrent';
import { UserProfileCurrent } from '../UserProfileCurrent';
import { WorkspaceMenu } from '../WorkspaceMenu';
import './SidebarProfile.scss';

interface Props {
  open: boolean;
  toggleOpen: () => void;
}

export const SidebarProfile = ({ open, toggleOpen }: Props) => {
  return (
    <div className="position-relative border-top bg-white sidebar-profile__btn-wrapper">
      <WorkspaceProfileBtn
        open={open}
        toggleOpen={toggleOpen}
      />
      {open && (
        <div
          className="position-absolute d-flex rounded-4 shadow-sm sidebar-profile__popover"
          data-cy="sidebar-profile__popover"
        >
          <WorkspacesList toggleOpen={toggleOpen} />

          <div className="border-start sidebar-profile__popover__workspaces-profile">
            <WorkspaceCurrent toggleOpen={toggleOpen} />
            <UserProfileCurrent />
            <WorkspaceMenu toggleOpen={toggleOpen} />
          </div>
        </div>
      )}
    </div>
  );
};
