import React, { useState } from 'react';
import { StripeAddressElementOptions, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements, AddressElement } from '@stripe/react-stripe-js';
import { WorkspaceInfo } from 'types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {
  betas: ['address_element_beta_1'],
});

export const BillingAddressForm = ({
  workspace,
  onFormChange,
}: {
  workspace: WorkspaceInfo;
  onFormChange: (value: any) => void;
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const options: StripeAddressElementOptions = {
    mode: 'billing',
    defaultValues: {
      name: workspace.billingName || '',
      address: {
        line1: workspace.line1 || '',
        line2: workspace.line2 || '',
        city: workspace.city || '',
        state: workspace.state || '',
        postal_code: workspace.postal_code || '',
        country: workspace.country || 'AU',
      },
    },
    fields: {
      phone: 'never',
    },
  };

  const elementOptions: StripeElementsOptions = {
    appearance: {
      theme: 'stripe',
      variables: {
        fontSizeBase: '14px',
      },
    },
  };

  return (
    <Elements
      stripe={stripePromise}
      options={elementOptions}
    >
      <AddressElement
        options={options}
        onFocus={() => setIsTouched(true)}
        onChange={event => isTouched && onFormChange({ ...event.value.address, billingName: event.value.name })}
      />
    </Elements>
  );
};
