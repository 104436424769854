import useModal from 'hooks/useModal';
import { ContentQueryParams } from 'models/Content';
import { parse } from 'qs';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

export const useFilterModal = () => {
  const { openModal, closeModal } = useModal();
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();

  useUpdateEffect(() => {
    const param = parse(searchParams.toString());
    delete param?.search;
    setFilters(param);
  }, [searchParams]);

  const openFilterModal = (searchTerm: any) => {
    openModal('content-filter', {
      value: searchTerm,
      className: 'align-items-center pb-3',
      onAccept: (query: ContentQueryParams) => {
        setFilters({ ...query, timestamp: new Date().getTime() });
        closeModal();
      },
      onClear: () => {
        setFilters({
          status: '',
          kind: '',
          libraries: [],
          starred: false,
        });
      },
      onCancel: () => closeModal(),
    });
  };

  return {
    filters,
    openFilterModal,
  };
};
