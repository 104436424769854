import React from 'react';
import { ContentResultHelper } from '../ContentResultHelper';
import { ResultContent } from 'models/Content';
import classNames from 'classnames';
import './ContentMarkTheWords.scss';

type Props = {
  result: ResultContent;
};
export const ContentMarkTheWords = ({ result }: Props) => {
  return (
    <>
      <div className="w-100 content-result-table">
        {!!result.title && (
          <div
            className="content-result-table__question px-3 px-md-0"
            dangerouslySetInnerHTML={{
              __html: result.description,
            }}
          ></div>
        )}
        <div className="w-100 d-flex flex-wrap lh-lg mb-3 px-3 px-md-0">
          {result.indexes.map((indexItem: string, index: number) => (
            <span
              className="inline-flex"
              key={index}
            >
              {result.learnerAnswers[index] ? (
                <span
                  className={classNames('answer me-1', {
                    'incorrect-answer': result.learnerAnswers[index] !== result.correctAnswers[index],
                    'learner-correct-answer': result.learnerAnswers[index] === result.correctAnswers[index],
                  })}
                >
                  {result.learnerAnswers[index]}
                </span>
              ) : (
                <>
                  {result.correctAnswers[index] ? (
                    <span className={classNames('answer me-1 correct-answer')}>{result.correctAnswers[index]}</span>
                  ) : (
                    <span className="me-1">{indexItem}</span>
                  )}
                </>
              )}
            </span>
          ))}
        </div>
        <ContentResultHelper />
      </div>
    </>
  );
};
