import { Folder, Document } from 'models/Content';

export const mapFolderTypes = (documents: Document[]): Folder[] => {
  return documents.map(
    document =>
      ({
        id: document.id,
        title: document.title,
        children: mapFolderTypes(document?.children || []),
        kind: document.kind,
        isEdited: document.id === 'new',
        numchild: document.numchild ?? 0,
        path: document?.path,
      }) as Folder,
  );
};

export const findFolderById = (folders: Folder[], id: string): Folder => {
  const result = folders?.find(folder => folder.id === id);
  if (!result) {
    const allChildFolders = folders.map(folder => folder.children || []).flat();
    if (!allChildFolders.length) {
      return null;
    }
    return findFolderById(allChildFolders, id);
  }
  return result;
};
