const FILE_SIZE = 1024;

export const formatFileSize = (fileSize: number) => {
  let formattedSize: string;

  switch (true) {
    case fileSize < FILE_SIZE:
      formattedSize = `${FILE_SIZE} Bytes`;
      break;
    case fileSize < FILE_SIZE * FILE_SIZE:
      formattedSize = `${(fileSize / FILE_SIZE).toFixed(2)} KB`;
      break;
    default:
      formattedSize = `${(fileSize / (FILE_SIZE * FILE_SIZE)).toFixed(2)} MB`;
      break;
  }

  return formattedSize;
};
