import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { AppBadge } from 'components';
import moment from 'moment';
import { Minus } from 'react-feather';
import { ContentUserData } from 'types/student-report';

const columnHelper = createColumnHelper<ContentUserData>();

export const userColumn = columnHelper.display({
  id: 'user',
  header: () => <div className="w-fit d-flex justify-content-start align-items-center ps-2">User</div>,
  meta: {
    className: 'w-25',
  },
  cell: ({ row }) => (
    <div className="d-flex align-items-center px-2">
      <div className="flex-fill w-1px d-flex flex-column">
        <div className="text-neutral-700 fw-semibold text-truncate">{row.original.user.fullName}</div>

        <div className="text-neutral-600 text-truncate">{row.original.user.email}</div>
      </div>
    </div>
  ),
});

export const attemptsColumn = columnHelper.display({
  id: 'attempts',
  header: () => <div className="d-flex justify-content-start align-items-center ps-2">Attempts</div>,
  meta: {
    className: 'w-25',
  },
  enableResizing: false,
  cell: ({ row }) => {
    const compareDate = moment(row.original.firstAttemptAt).isSame(row.original.lastAttemptAt);

    const convertFirstTime = moment(row.original.firstAttemptAt).format('DD/MM/YYYY HH:mm:ss');

    const convertLastTime = moment(row.original.lastAttemptAt).format('DD/MM/YYYY HH:mm:ss');

    return (
      <div className="d-flex align-items-center ps-2 text-neutral-600">
        <div
          className="pe-2"
          style={{ width: 20 }}
        >
          {row.original.totalAttempt}
        </div>
        <div className="border-start border-neutral-400 ps-2">
          <div>First: {convertFirstTime}</div>
          {!compareDate && <div>Last: {convertLastTime}</div>}
        </div>
      </div>
    );
  },
});

const createScoreColumn = (scoreId: string, scorePercentId: string, headerText: string, best?: boolean) =>
  columnHelper.display({
    id: scoreId,
    header: () => <div className="d-flex justify-content-start align-items-center pe-2">{headerText}</div>,
    size: 130,
    enableResizing: false,
    cell: ({ row }) => (
      <div className="d-flex align-items-center pe-2">
        {row.original[scorePercentId] !== null ? (
          <AppBadge
            type={best ? 'primary-light' : 'neutral-outline'}
            label={
              <span className={classNames('text-nowrap', { 'text-neutral-700': !best })}>
                {row.original[scoreId]} <span className="ms-1 fw-normal">({row.original[scorePercentId]}%)</span>
              </span>
            }
          />
        ) : (
          <Minus size={16} />
        )}
      </div>
    ),
  });

export const firstScoreColumn = createScoreColumn('firstAttemptScore', 'firstAttemptPercent', 'First Score');
export const lastScoreColumn = createScoreColumn('lastAttemptScore', 'lastAttemptPercent', 'Last Score');
export const bestScoreColumn = createScoreColumn('bestScore', 'bestScorePercent', 'Best Score', true);
