import React from 'react';
import './UploadFile.scss';
import { Upload } from 'react-feather';
import classNames from 'classnames';
import { Loading } from 'components/Loading';
import { toast } from 'react-toastify';

type Props = {
  inputRef: React.MutableRefObject<HTMLInputElement>;
  onChange: (file: File) => void;
  name: string;
  accept: string;
  isLoading?: boolean;
};

export const UploadFile = (props: Props) => {
  const { inputRef, onChange, name, accept, isLoading = false } = props;
  const [isDragActive, setDragActive] = React.useState<boolean>(false);

  const onReadFileUpload = (file: File) => {
    onChange(file);
  };

  const handleChange = () => {
    const files = inputRef.current?.files as unknown as File[] | undefined;
    if (files && files[0]) {
      onReadFileUpload(files[0]);
    }
  };

  const handleDrop = (event: any) => {
    setDragActive(false);
    const files = event?.dataTransfer?.files as File[] | undefined;
    if (files && files[0]) {
      if (!files[0].name.includes(accept)) {
        toast.error('Sorry, this file type is not supported.');
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      onReadFileUpload(files[0]);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrag = (event: any) => {
    if (!isDragActive) {
      setDragActive(true);
    }
    event.preventDefault();
  };

  const handleDragEnd = (event: any) => {
    setDragActive(false);
    event.preventDefault();
  };

  const UploadBody = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center p-4">
        <div className="d-flex align-items-center justify-center">
          {isLoading ? (
            <Loading />
          ) : (
            <Upload
              size={24}
              className={classNames('icon-upload')}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div
      className={classNames('d-flex justify-content-center align-items-center w-100 dropzone-wrapper', {
        disable: isLoading,
      })}
      onDragOver={handleDrag}
      onDragLeave={handleDragEnd}
      onDrop={handleDrop}
    >
      <label
        htmlFor="dropzone-file"
        className={classNames(
          'drag-drop-section d-flex flex-column justify-content-center align-items-center w-100 mb-0 cursor-pointer rounded-3',
          {
            'drag-active': isDragActive,
          },
        )}
      >
        <UploadBody />
        <input
          ref={inputRef}
          id="dropzone-file"
          name={name}
          type="file"
          className="d-none"
          onChange={handleChange}
          accept={accept}
        />
      </label>
    </div>
  );
};
