import classNames from 'classnames';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarMenu } from './SidebarMenu';
import { ModalManager } from 'components/ModalManager';
import AIOffset from 'components/AIOffset/AIOffset';

export const VerticalLayout: React.FC = observer(() => {
  const { uiStore } = useAppStore();
  const { isNavbarCollapsed, toggleScrollToTop } = uiStore;
  const pageRef = useRef(null);

  useEffect(() => {
    pageRef?.current?.scroll({ top: 0, behavior: 'smooth' });
  }, [toggleScrollToTop]);

  return (
    <div className={classNames('h-100', { 'vertical-collapsed': isNavbarCollapsed })}>
      <SidebarMenu />
      <div
        ref={pageRef}
        className="main-content h-100 overflow-auto"
        data-cy="main-content"
      >
        <div className="page-content">
          <Outlet />
        </div>
      </div>
      <ModalManager />

      <AIOffset></AIOffset>
    </div>
  );
});
