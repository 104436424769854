import { Row, Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';
import './AppTable.scss';

type IProps<T extends object> = {
  table?: Table<T>;
  onRowClick?: (row: Row<T>) => void;
  trClassName?: (row: Row<T>) => string;
};

export const AppTable = <T extends object>({ table, onRowClick, trClassName }: IProps<T>) => {
  return (
    <table className="app-custom-table table-wrapper w-100">
      <thead className="bg-neutral-100">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const meta = header.column.columnDef.meta as any;
              const width = header.getSize() !== 150 ? header.getSize() : undefined;
              return (
                <th
                  key={header.id}
                  className={classNames('px-1 py-3 gray-900 fw-semibold', meta?.className)}
                  // Width for header only when have size props
                  style={{ width, minWidth: width }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="table-body">
        {table.getRowModel().rows.map(row => {
          return (
            <React.Fragment key={row.id}>
              <tr
                className={classNames(
                  'row-wrapper',
                  { 'cursor-pointer': !!onRowClick },
                  { selected: row.getIsSelected() },
                  trClassName?.(row),
                  { 'bg-neutral-50 border-bottom-0': row.getIsExpanded() },
                )}
                onClick={() => onRowClick?.(row)}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    height={60}
                    key={cell.id}
                    className="px-1 py-2"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>

              {row.getIsExpanded() ? (
                <tr className="expanded-row bg-neutral-50">
                  <td colSpan={table.getAllLeafColumns().length}>
                    {(row.original as any)?.customExpandedRowComponent}
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
