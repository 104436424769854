import { removeUser } from 'apis/Workspace/Workspace';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, User } from 'types';
import { fetchServerError } from 'utils';

export const useRemoveUser = () => {
  const queryClient = useQueryClient();

  return useMutation(removeUser, {
    onSuccess: (_, userId) => {
      const searchTerm = queryClient.getQueryData(['users', 'searchTerm']);
      queryClient.setQueryData(['users', 'all', searchTerm], (users: User[]) => {
        return users.filter(user => user.id != userId);
      });
      toast('User has been removed.', { type: 'success' });
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
