import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import './ProfileLayout.scss';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { PageHeader } from 'components/PageHeader';
import { Save } from 'react-feather';

interface Props {
  handleClick: () => void;
  isLoading: boolean;
  breadcrumbActive: string;
  children: JSX.Element;
  isDirty?: boolean;
}

export const ProfileLayout: React.FC<Props> = ({ handleClick, isLoading, children, isDirty }) => {
  usePrompt(isDirty);

  return (
    <form
      className="d-flex flex-column profile-layout"
      onSubmit={handleClick}
    >
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <PageHeader
        title="My Profile"
        rightElement={
          <button
            className="btn btn-primary p-2 d-flex align-items-center gap-1"
            type="submit"
            disabled={isLoading}
            data-cy="btn-save-changes"
          >
            <Save
              className="d-none d-md-block"
              color="white"
              strokeWidth={2}
              height={20}
              width={20}
            />
            Save Changes
          </button>
        }
        subHeader={
          <div className="header-routing gap-4 d-flex bg-white">
            <NavLink
              to="../edit"
              className={({ isActive }) => {
                return `text-medium cursor-pointer header-routing--title ${isActive ? 'active' : ''}`;
              }}
              data-cy="tab-edit"
            >
              Edit Profile
            </NavLink>

            <NavLink
              to="../change-password"
              className={({ isActive }) => {
                return `text-medium cursor-pointer header-routing--title ${isActive ? 'active' : ''}`;
              }}
              data-cy="tab-reset-pwd"
            >
              Reset Password
            </NavLink>
          </div>
        }
      />
      <div className="profile-wrapper flex-fill d-flex flex-column">
        <div className="flex-fill p-0 p-md-4">{children}</div>
      </div>
    </form>
  );
};
