import { getWorkspaceBillingInformation } from 'apis/Payment/Customer';
import { useAppStore } from 'hooks/useAppStore';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, WorkspaceInfo } from 'types';
import { fetchServerError } from 'utils';

export const useGetWorkspaceBillingInformation = () => {
  const appStore = useAppStore();
  const {
    workspaceStore: { currentWorkspaceId },
  } = appStore;

  return useQuery<WorkspaceInfo, ErrorResponse>(
    ['workspace', currentWorkspaceId, 'billing-info'],
    () => getWorkspaceBillingInformation(),
    {
      cacheTime: 300000,
      staleTime: 300000,
      onError: (error: ErrorResponse) => {
        toast(fetchServerError(error), { type: 'error' });
      },
    },
  );
};
