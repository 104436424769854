import { fetchChatHistory } from 'apis/AiChat/AiChat';
import { useQuery } from 'react-query';
import { AiChatHistory } from 'types';

export const useFetchChatHistory = () => {
  return useQuery<AiChatHistory[]>(['chat-history'], () => fetchChatHistory({ limit: 50, offset: 0 }), {
    cacheTime: 300000,
    staleTime: 300000,
  });
};
