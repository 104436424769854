import React from 'react';
import { Option } from 'react-multi-select-component';
import { Input } from 'reactstrap';

interface IProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: () => void;
}

export const MultiSelectCheckbox = ({ checked, option, onClick, disabled }: IProps) => {
  return (
    <>
      <Input
        className="cursor-pointer p-2"
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className="ms-3 mt-1 gray-900 fw-normal">{option.label}</span>
    </>
  );
};
