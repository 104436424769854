import React from 'react';
import { SubscriptionStatus } from '@spiderbox/common';
import IconLogo from 'assets/icons/IconLogo';
import trialLogo from 'assets/images/subscription-trial.svg';
import classNames from 'classnames';
import { Loading } from 'components';
import { SubscriptionSelectWorkspace } from 'components/ModalManager/ExpiredPlan/SubscriptionSelectWorkspace';
import { useLogout, useSubscriptionCheckout } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { PlanOptions } from 'pages/Organisation/SubscriptionPage/components/PlanOptions';
import { LogOut } from 'react-feather';
import { ReactSVG } from 'react-svg';
import './SubscriptionPlansPage.scss';

export const SubscriptionPlansPage = observer(() => {
  const {
    workspaceStore: { workspaces },
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  const { mutate: logout } = useLogout();
  const { isLoading } = useSubscriptionCheckout();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="subscription-plans-page overflow-auto">
        <div className="subscription-plans-page__logo">
          <IconLogo isVariant={true} />
        </div>

        {workspaces?.length > 1 && currentSubscription?.status === SubscriptionStatus.CANCELED && (
          <div
            className="ms-auto d-block d-lg-none workspace-selections d-flex flex-column mb-3"
            data-cy="workspaces-dropdown-mobile"
          >
            <div className="text-white font-size-16 fw-medium my-2">Switch Workspace</div>

            <SubscriptionSelectWorkspace />
          </div>
        )}

        <div className="subscription-plans-body d-flex flex-column justify-content-start justify-content-lg-center">
          <div className="subscription-plans-card bg-white d-flex flex-column gap-3">
            {!currentSubscription && (
              <ReactSVG
                className="trial-logo"
                data-cy="trial-logo"
                src={trialLogo}
              />
            )}

            <div className="subscription-plans-title d-flex px-4 px-lg-0 pb-4 pb-lg-0">
              <div
                className={classNames(
                  'd-flex flex-column align-items-start gap-1 pe-2',
                  currentSubscription?.status === SubscriptionStatus.CANCELED
                    ? 'align-items-start'
                    : 'align-items-lg-center',
                )}
                style={{ flex: '70%' }}
              >
                {!currentSubscription ? (
                  <>
                    <div className="text-neutral-900 plan">Monthly Plan</div>
                    <div className="text-neutral-600 font-size-16 fst-italic mt-1 d-none d-lg-block">
                      *Prices are GST exclusive
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-neutral-900 plan">Subscription has expired!</div>
                    <div className="text-neutral-600 font-size-16 fst-italic d-none d-lg-block">
                      *Prices are GST exclusive
                    </div>
                    <div
                      className="text-error-600 font-size-16"
                      data-cy="canceled-subscription-message"
                    >
                      We noticed that your trial has ended or your subscription has been cancelled, please renew your
                      subscription to continue enjoying our service.
                    </div>
                  </>
                )}
              </div>

              {currentSubscription?.status === SubscriptionStatus.CANCELED && (
                <>
                  {workspaces?.length > 1 && (
                    <div
                      className="workspace-selections d-none d-lg-flex flex-column"
                      data-cy="workspaces-dropdown-desktop"
                    >
                      <div className="text-neutral-700 font-size-16 fw-medium mb-2">Switch Workspace</div>

                      <SubscriptionSelectWorkspace />
                    </div>
                  )}

                  <div className="d-block d-lg-none">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => logout(false)}
                    >
                      Log Out{' '}
                      <LogOut
                        className="ms-2 text-primary"
                        size={20}
                        strokeWidth={2}
                      />
                    </span>
                  </div>
                </>
              )}
            </div>

            <div>
              <PlanOptions onboarding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
