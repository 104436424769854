import React from 'react';
import illustration from 'assets/images/contents/Illustration.svg';

export const NotSupported = () => {
  return (
    <div className="d-flex flex-column align-items-center px-3 py-2 p-md-0">
      <img src={illustration} />
      <div className="mt-3 primary-500 fw-medium text-center text-md-start">
        Report rendering is not supported for this content type
      </div>
    </div>
  );
};
