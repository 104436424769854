import { deleteConnection } from 'apis/Lti/Lti';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export const useDeleteConnection = () => {
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const queryClient = useQueryClient();
  const queryKey = ['workspaces', currentWorkspaceId, 'connections'];

  return useMutation(deleteConnection, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast.success('The connection has been deleted.');
    },
  });
};
