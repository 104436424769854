import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from 'components/AppForm/TextField';
import { DragDropUpload } from 'components/DragDropUpload';
import { useUpdateProfile } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import { profileSchema } from 'libs/yup';
import { observer } from 'mobx-react-lite';
import { Media } from 'models/Media';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EditProfileFormInput, ErrorResponse } from 'types';
import { fetchServerError } from 'utils';
import { ProfileLayout } from '../components/ProfileLayout/ProfileLayout';
import './EditProfile.scss';
import { Role } from '@spiderbox/common';

export const EditProfile = observer(() => {
  const appStore = useAppStore();
  const [uploadFileLoading, setUploadFileLoading] = useState<boolean>(false);
  const { userInfo } = appStore;
  const { firstName, lastName, phoneNumber, email, profileImageUrl, fullName, isOwner, roles } = userInfo || {};

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
    setError,
  } = useForm<EditProfileFormInput>({
    mode: 'onChange',
    resolver: yupResolver(profileSchema as any),
    defaultValues: {
      firstName,
      lastName,
      phoneNumber,
      email,
      profileImageUrl,
    },
  });

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useUpdateProfile(setError);

  const onSubmit = async (values: EditProfileFormInput) => {
    const params = Object.assign({}, values);

    const isEmailChanged = appStore.userInfo?.email?.toLowerCase() !== params.email.toLowerCase();
    if (!isEmailChanged) delete params.email;

    updateProfileMutate(params);

    reset({}, { keepValues: true });
  };

  const onError = async () => {
    toast(
      'Apologies, but there seems to be a validation error in your form submission. Please review and correct the necessary fields.',
      { type: 'warning' },
    );
  };

  const onUploadImgSuccess = (data: Media) => {
    setValue('profileImageUrl', data.url);
    setValue('profileImageId', data.id, { shouldDirty: true, shouldTouch: true });
  };

  const onUploadImgError = (error: ErrorResponse) => {
    toast(fetchServerError(error), { type: 'error' });
  };

  return (
    <ProfileLayout
      handleClick={handleSubmit(onSubmit, onError)}
      isLoading={updateProfileLoading || uploadFileLoading}
      breadcrumbActive="Edit Profile"
      isDirty={isDirty}
    >
      <div className="h-100 d-flex flex-column bg-white px-4 py-3 rounded-start rounded-bottom gap-5 edit-profile-wrapper">
        <header
          className="text-medium fw-bold gray-900"
          data-cy="title"
        >
          User Information
        </header>
        <div className="row">
          <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
            <span
              className="gray-900"
              data-cy="label-profile-picture"
            >
              Profile Picture
            </span>
          </label>
          <DragDropUpload
            imageUrl={profileImageUrl}
            setUploadLoading={loading => setUploadFileLoading(loading)}
            onUploadSuccess={data => onUploadImgSuccess(data)}
            onUploadError={error => onUploadImgError(error)}
            userFullName={fullName}
            isPublic
          />
        </div>

        <div className="row">
          <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
            <span
              className="gray-900"
              data-cy="label-role"
            >
              Role
            </span>
          </label>
          <div
            className="col-12 col-md-8 my-md-0 gap-2"
            data-cy="roles-title"
          >
            <div className="d-flex gap-2">
              {isOwner && (
                <span className="font-weight-medium px-2 py-1 rounded-pill cursor-default role-title">Owner</span>
              )}
              <span className="font-weight-medium px-2 py-1 rounded-pill cursor-default role-title">
                {roles?.includes(Role.ADMIN) ? 'Admin' : 'Member'}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
            <span
              className="gray-900"
              data-cy="label-name"
            >
              Name*
            </span>
          </label>
          <div className="col-12 col-md-8 col-xl-6">
            <div className="form-group mt-0 input-field">
              <TextField
                register={register('firstName')}
                id="firstName"
                placeholder="First name"
                error={!!errors.firstName}
                dataCy="input-first-name"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="first-name-invalid"
              >
                {errors.firstName?.message}
              </p>
            </div>
            <div className="form-group input-field">
              <TextField
                register={register('lastName')}
                id="lastName"
                placeholder="Last name"
                error={!!errors.lastName}
                dataCy="input-last-name"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="last-name-invalid"
              >
                {errors.lastName?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
            <span
              className="gray-900"
              data-cy="label-email"
            >
              Email*
            </span>
          </label>
          <div className="col-12 col-md-8 col-xl-6">
            <div className="form-group mt-0 input-field">
              <TextField
                type="email"
                register={register('email')}
                id="email"
                placeholder="Email"
                error={!!errors.email}
                dataCy="input-email"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="email-invalid"
              >
                {errors?.email?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
            <span
              className="gray-900"
              data-cy="label-phone"
            >
              Phone
            </span>
          </label>
          <div className="col-12 col-md-8 col-xl-6">
            <div className="form-group mt-0 input-field">
              <TextField
                id="phoneNumber"
                placeholder="Phone number"
                error={!!errors.phoneNumber}
                register={register('phoneNumber')}
                dataCy="input-phone"
              />
              <p
                className="feedback-invalid mb-0"
                data-cy="phone-invalid"
              >
                {errors.phoneNumber?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
});
