import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RolePermissionMapping } from 'types';
import './UserDetailRow.scss';

export const UserDetailRow: React.FC<RolePermissionMapping> = ({
  label,
  helper,
  active,
  action,
  group,
  disabled = false,
}) => {
  const { control, watch, setValue } = useFormContext();

  const nameControl = useMemo(() => {
    return group ? `${action}.all` : action;
  }, [action]);

  const toggleAll = group => {
    const groupName = action.split('.')[0];
    const watchGroupValue = watch(groupName);
    const watchChildrenValue = Object.keys(watchGroupValue).filter(item => item !== 'all');

    // Toggle parent and set all children to value like parent
    if (group) {
      watchChildrenValue.forEach(action => {
        setValue(`${groupName}.${action}`, watchGroupValue.all);
      });

      return;
    }

    // All children are false then not effect parent value
    const allChildUnselected = watchChildrenValue.every(value => watchGroupValue[value] === false);

    if (allChildUnselected) {
      return;
    }

    // At least one children are true then set parent is true
    const someChildSelected = watchChildrenValue.some(value => watchGroupValue[value] === true);

    if (someChildSelected) {
      setValue(`${groupName}.all`, true);
      return;
    }
  };

  return (
    <div
      className={classNames('d-grid align-items-center rounded-3 px-3 py-2 user-permission-row', {
        'bg-primary-50 mt-3': group,
      })}
      data-cy="user-permission-row"
    >
      <div className="row justify-content-between align-items-center text-neutral-900">
        <div
          className="col-3 fw-medium d-sm-block d-none"
          data-cy="user-permission-row__helper"
        >
          {helper}
        </div>
        <div className="col-12 col-sm-9">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-none flex-fill d-sm-block"
              data-cy="user-permission-row__label"
            >
              {label}
            </div>

            <div
              className="d-sm-none d-block flex-fill"
              data-cy="user-permission-row__helper__mobile"
            >
              <div className="fw-medium mb-2">{helper}</div>

              <div>{label}</div>
            </div>

            <div className="form-check form-switch form-switch-lg">
              <Controller
                name={nameControl}
                control={control}
                defaultValue={active}
                render={({ field }) => {
                  return (
                    <input
                      name={nameControl}
                      className="custom-switch form-check-input cursor-pointer"
                      type="checkbox"
                      checked={field.value}
                      value={field.value}
                      onChange={e => {
                        field.onChange(e.target.checked);
                        toggleAll(group);
                      }}
                      ref={field.ref}
                      data-cy="user-permission-row__input"
                      disabled={disabled}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
