import React from 'react';
import './ErrorEmptyBlock.scss';
import noData from 'assets/images/no-data.svg';

type IProps = {
  src?: string;
  title?: string;
  description?: string;
  btnLabel?: string;
};

export const ErrorEmptyBlock = ({
  src = noData,
  title = 'No data to display',
  description = 'Please try again.',
  btnLabel = '',
}: IProps) => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        data-cy="empty-block"
      >
        <div className="block-wrapper d-flex flex-column align-items-center p-4 gap-4">
          <img
            src={src}
            data-cy="block-image"
          />
          <div
            className="block-wrapper--title text-center primary-500"
            data-cy="block-title"
          >
            {title}
          </div>
          {description && (
            <div
              className="block-wrapper--description text-center gray-900"
              data-cy="block-description"
            >
              {description}
            </div>
          )}
          {btnLabel && (
            <button
              className="btn btn-primary flex-fill px-3 py-2 text-white"
              type="submit"
              data-cy="block-btn"
            >
              {btnLabel}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
