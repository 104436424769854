import React from 'react';
import './LibraryList.scss';
import { LibraryListItem } from './LibraryListItem';
import { Library } from 'types';

type Props = {
  libraries: Library[];
};

const LibraryList = ({ libraries }: Props) => {
  return (
    <div
      className="libraryList h-100"
      data-cy="libraryList"
    >
      <div
        className="libraryList__list px-0"
        data-cy="libraryList-list"
      >
        <div className="libraryList__list--layout gap-2">
          {libraries.map((library, index) => (
            <LibraryListItem
              library={library}
              key={library.id}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LibraryList;
