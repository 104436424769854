import classNames from 'classnames';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChevronDown } from 'react-feather';

interface Props {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

export const WorkspaceProfileBtn: React.FC<Props> = observer(({ open, toggleOpen }) => {
  const {
    userInfo,
    workspaceStore: { currentWorkspace },
  } = useAppStore();

  const { imageUrl } = currentWorkspace || {};

  const { profileImageUrl, fullName } = userInfo || {};

  return (
    <div
      className={classNames('d-flex align-items-center justify-content-between w-100 sidebar-profile__btn-inner', {
        'sidebar-profile__btn-inner--open': open,
      })}
      onClick={() => toggleOpen(!open)}
      data-cy="sidebar-profile__btn-inner"
    >
      <div className="d-flex w-100 sidebar-profile__workspace-me">
        <div
          className="d-block"
          data-cy="sidebar-profile__workspace"
        >
          <AvatarProfile
            imgSrc={imageUrl}
            width={36}
            height={36}
            userFullName={currentWorkspace?.name}
            avatarClassNames="sidebar-profile__workspace"
          />
        </div>

        <div
          className="d-block"
          data-cy="sidebar-profile__me"
        >
          <AvatarProfile
            width={36}
            height={36}
            userFullName={fullName}
            imgSrc={profileImageUrl}
            avatarClassNames="sidebar-profile__me"
          />
        </div>
      </div>
      <div
        className={classNames('sidebar-profile__chevron', { 'sidebar-profile__chevron--open': open })}
        data-cy="chevron-up-sidebar-profile"
      >
        <ChevronDown />
      </div>
    </div>
  );
});
