import { Loading } from 'components';
import { useGetConnections } from 'hooks/lti/useGetConnections';
import { ConnectionName } from 'pages/IntegrationsPage/components/ConnectionName';
import React, { Fragment, useMemo } from 'react';

export const ListConnectionName = () => {
  const { data, isLoading } = useGetConnections({});

  const connections = useMemo(() => data, [data]);

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ minHeight: 300 }}
        >
          <Loading />
        </div>
      ) : (
        connections?.map(connection => (
          <ConnectionName
            key={connection.id}
            {...connection}
          />
        ))
      )}
    </>
  );
};
