import React from 'react';
import { NotSupported } from '../../NotSupported';
import { ContentResultTable } from '../ContentResultTable/ContentResultTable';
import { ContentSupportedList, ContentSetSupportedList, MachineName } from '@spiderbox/common';
import { Loading } from '../../../../../../components/Loading/Loading';
import { useQuery } from 'react-query';
import { fetchLearnerContentResult } from '../../../../../../apis/LearnerReport/LearnerReport';
import { ContentDocumentationTool } from '../ContentDocumentationTool';
import classNames from 'classnames';
import { ContentResultTitle } from '../ContentResultTitle';
import { ContentMarkTheWords } from '../ContentMarkTheWords';
import { ResultContent } from 'models/Content';

type Props = {
  attemptId: string;
  library: MachineName;
};
export const ContentResultContainer = ({ attemptId, library }: Props) => {
  const { data, isLoading } = useQuery(['content-results', attemptId], () => fetchLearnerContentResult(attemptId));

  if (isLoading || !data) {
    return <Loading />;
  }

  const RenderContentSupportedList = ({ library, result }: { library: MachineName; result: ResultContent }) => {
    const contentType = ContentSetSupportedList.includes(library) ? result.contentType : library;
    return (
      <RenderResultContent
        contentType={contentType}
        result={result}
      />
    );
  };

  const RenderResultContent = ({ contentType, result }: { contentType: MachineName; result: ResultContent }) => {
    switch (contentType) {
      case MachineName.H5P_MarkTheWords:
        return <ContentMarkTheWords result={result} />;
      default:
        return <ContentResultTable result={result} />;
    }
  };

  if (ContentSupportedList.includes(library) || ContentSetSupportedList.includes(library)) {
    return (
      <div className={classNames('p-0 p-md-3', { 'bg-neutral-100': data.length > 1 })}>
        {data.map((result, index) => (
          <div
            key={index}
            className={classNames(
              { 'border bg-white border-neutral-200 rounded-3 p-3': data.length > 1 },
              { 'mb-3': index !== data.length - 1 },
            )}
          >
            <ContentResultTitle
              result={result}
              hideContentType={data.length <= 1}
            />
            <RenderContentSupportedList
              library={library}
              result={result}
            />
          </div>
        ))}
      </div>
    );
  }

  if (library === MachineName.H5P_DocumentationTool) {
    return (
      <div className={classNames('p-0 p-md-3', { 'bg-neutral-100': data.length > 1 })}>
        <ContentDocumentationTool results={data} />
      </div>
    );
  }

  return <NotSupported />;
};
