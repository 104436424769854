export enum LtiSettingOptions {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  PER_CONTENT_ON_DEFAULT = 'PER_CONTENT_ON_DEFAULT',
  PER_CONTENT_OFF_DEFAULT = 'PER_CONTENT_OFF_DEFAULT',
}

export enum ContentAttemptSetting {
  FIRST_ATTEMPT = 'FIRST_ATTEMPT',
  LAST_ATTEMPT = 'LAST_ATTEMPT',
  BEST_ATTEMPT = 'BEST_ATTEMPT',
  DO_NOT_SEND = 'DO_NOT_SEND',
}
