import { useQuery } from 'react-query';

import { fetchChatDetail } from 'apis/AiChat/AiChat';
import { ChatDetailResponse } from 'types';

export const useFetchChatDetail = (chatId: string) => {
  const queryKey = ['chat-detail', chatId];

  return useQuery<ChatDetailResponse>(queryKey, () => fetchChatDetail(chatId, { limit: 50, offset: 0 }), {
    cacheTime: 1,
    staleTime: 300000,
  });
};
