import React, { createContext, useContext, useEffect, useMemo, type PropsWithChildren } from 'react';
import { useToggle } from 'react-use';

type HelpTipsContextType = {
  defaultOpen?: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
};

const HelpTipsContext = createContext<HelpTipsContextType | undefined>(undefined);

const HelpTipsProvider = ({ children, defaultOpen = true }: PropsWithChildren<{ defaultOpen?: boolean }>) => {
  const [isOpen, toggleOpen] = useToggle(defaultOpen);

  const value = useMemo(() => ({ isOpen, toggleOpen }), [isOpen]);

  useEffect(() => {
    toggleOpen(defaultOpen);
  }, [defaultOpen]);

  return <HelpTipsContext.Provider value={{ ...value }}>{children}</HelpTipsContext.Provider>;
};

const useHelpTipsContext = () => {
  const context = useContext(HelpTipsContext);
  if (context === undefined) {
    throw new Error('useHelpTipsContext must be used within a HelpTipsProvider');
  }
  return context;
};

export { HelpTipsContext, HelpTipsProvider, useHelpTipsContext };
