import React from 'react';
import './ContentAttempts.scss';
import { X } from 'react-feather';
import { ContentUserDataAttempt } from 'types/student-report';
import { ExpandedContentAttempts } from 'pages/LearnerReport/components';

export const ContentAttempts = ({
  title,
  library,
  attempts,
  onCancel,
}: {
  title: string;
  library: string;
  attempts: ContentUserDataAttempt[];
  onCancel: () => void;
}) => {
  return (
    <div className="h-100 content-attempts-modal modal-wrapper px-4">
      <div className="content-attempts-modal__header sticky-top pt-4">
        <div className="header-top d-flex justify-content-between pb-3 w-100">
          <div className="d-flex flex-column w-100 pe-4">
            <div className="text-neutral-700 font-size-18 fw-semibold content-title">{title}</div>

            <div className="text-neutral-700">{library}</div>
          </div>

          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block flex-fill"
            size={24}
            data-cy="close-icon"
          />
        </div>
      </div>

      <ExpandedContentAttempts attempts={attempts} />
    </div>
  );
};
