import React from 'react';
import { observer } from 'mobx-react-lite';
import { components } from 'react-select';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { ChevronDown, ChevronUp } from 'react-feather';

export const SubscriptionControlWorkspace = observer(({ children, ...props }: any) => {
  const [selectedValue] = props.getValue();

  const { selectProps } = props;

  const {
    switchWorkspaces: { toggleMenu },
  } = selectProps;

  if (!selectedValue) return <components.Control {...props}>{children}</components.Control>;

  return (
    <components.Control {...props}>
      <div
        className="d-flex align-items-center justify-content-between px-3 py-2 w-100 rounded-3 bg-white cursor-pointer"
        onClick={toggleMenu}
        onTouchStart={toggleMenu}
      >
        <AvatarProfile
          imgSrc={selectedValue.imageUrl}
          width={36}
          height={36}
          userFullName={selectedValue.name}
          avatarClassNames="sidebar-profile__avatar"
        />
        <div className="ms-2 flex-fill w-1px">
          <div className="font-size-14 fw-bold text-truncate gray-900">{selectedValue.name}</div>
        </div>

        <div className="cursor-pointer">
          {props?.menuIsOpen ? (
            <ChevronUp
              size={20}
              className="d-block m-auto"
            />
          ) : (
            <ChevronDown
              size={20}
              className="d-block m-auto"
            />
          )}
        </div>
      </div>
    </components.Control>
  );
});
