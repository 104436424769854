import React from 'react';
import { sidebarMenuAdmin } from 'common/data/ts/sidebar';
import { UserPermission } from 'components/UserPermission';
import { MenuItem } from 'components/VerticalLayout/MenuItem';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SidebarMasterAdminMenuBottom } from '../SidebarMasterAdminMenuBottom';
import EvokioLogo from 'assets/icons/EvokioLogo';

export const SidebarMasterAdmin = observer(() => (
  <div
    className="sidebar-menu"
    data-cy="sidebar-menu"
  >
    <div
      data-simplebar="true"
      className="h-100"
    >
      <div
        data-simplebar="init"
        className="h-100"
      >
        <div className="simplebar-wrapper m-0">
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className="simplebar-content-wrapper mm-show">
                <div className="simplebar-content mm-show mm-active">
                  <div className="navbar-brand-box border-bottom border-gray-100 py-3 sticky-top bg-white">
                    <Link to="/admin">
                      <EvokioLogo />
                    </Link>
                  </div>

                  <div
                    id="sidebar-menu"
                    className="mm-active"
                    data-cy="sidebar-menu"
                  >
                    <ul
                      className="metismenu list-unstyled mm-show mm-active"
                      id="side-menu"
                    >
                      {(sidebarMenuAdmin || []).map(({ link, icon, title, subMenu, actions }) => {
                        return (
                          <UserPermission
                            key={title}
                            actions={actions}
                          >
                            <MenuItem
                              to={link}
                              title={title}
                              subMenu={subMenu}
                              icon={icon}
                            ></MenuItem>
                          </UserPermission>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="simplebar-placeholder">
            <SidebarMasterAdminMenuBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
));
