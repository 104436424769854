import React from 'react';
import './ContentResultTable.scss';
import { ResultContent } from 'models/Content';
import { ContentResultAnswer } from '../ContentResultAnswer';
import { ContentResultHelper } from '../ContentResultHelper';
import { ContentResultList } from '../ContentResultList';
import isEqual from 'lodash/isEqual';
import { sortBy } from 'lodash';

type Props = {
  result: ResultContent;
};

export const ContentResultTable = ({ result }: Props) => {
  return (
    <>
      <div className="w-100 content-result-table d-none d-md-block">
        {!!result.title && (
          <div
            className="content-result-table__question"
            dangerouslySetInnerHTML={{
              __html: result.description,
            }}
          ></div>
        )}
        <table className="w-100">
          <thead>
            <tr>
              <th>Answer</th>
              <th>Learner&apos;s Answer</th>
              <th>Correct</th>
            </tr>
          </thead>
          <tbody>
            {result.indexes.map((_, index) => (
              <tr key={index}>
                <td
                  dangerouslySetInnerHTML={{
                    __html: result.indexes[index],
                  }}
                ></td>
                <td>
                  {result.learnerAnswers[index] ? (
                    <ContentResultAnswer
                      answer={result.learnerAnswers[index]}
                      correct={
                        Array.isArray(result.correctAnswers[index])
                          ? result.correctAnswers[index].includes(result.learnerAnswers[index]) ||
                            isEqual(sortBy(result.correctAnswers[index]), sortBy(result.learnerAnswers[index]))
                          : result.correctAnswers[index] === result.learnerAnswers[index]
                      }
                      learner={true}
                      choice={result.interactionType === 'choice'}
                    />
                  ) : null}
                </td>
                <td>
                  {result.correctAnswers[index] ? (
                    <ContentResultAnswer
                      answer={result.correctAnswers[index]}
                      correct={true}
                      learner={false}
                      choice={result.interactionType === 'choice'}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ContentResultHelper />
      </div>

      <ContentResultList data={result} />
    </>
  );
};
