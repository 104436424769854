import React, { useState } from 'react';
import Tree from 'rc-tree';

import './Folder.scss';

// eslint-disable-next-line
const renderTitle = (title: string) => () => {
  return (
    <div className="d-inline-flex align-items-center">
      <i className="mdi mdi-folder-outline size-22 gray-400" />
      <span className="ms-2 size-14 f-weight-400 gray-900">{title}</span>
    </div>
  );
};

const treeData = [
  {
    key: 0,
    title: renderTitle('parent 1'),
    children: [
      {
        key: 1,
        title: renderTitle('parent 1-1'),
        children: [
          {
            key: 3,
            title: renderTitle('parent 1-1-1'),
          },
          {
            key: 4,
            title: renderTitle('parent 1-1-2'),
          },
        ],
      },
    ],
  },
];

export const Folder = () => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [gData, setGData] = useState(treeData);

  const handleDragEnter = ({ expandedKeys }) => {
    setExpandedKeys(expandedKeys);
  };

  const handleDrop = (info: any) => {
    const dropKey = info.node.key;
    const dropPos = info.node.pos.split('-');
    const dragKey = info.dragNode.key;
    const dropPosition = info.dropPosition - +dropPos[dropPos.length - 1];

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...gData];

    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap
      let ar: any;
      let i: any;
      loop(data, dropKey, (_, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setGData(data);
  };

  const handleExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const switchIcon = (obj: any) => {
    if (!obj.expanded) {
      return <i className="mdi mdi-chevron-right mdi-18px gray-400" />;
    }
    return <i className="mdi mdi-chevron-down mdi-18px gray-400" />;
  };

  return (
    <div className="p-3 folder">
      <Tree
        className="folder__tree"
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
        autoExpandParent={autoExpandParent}
        draggable
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        treeData={gData}
        showLine={false}
        switcherIcon={switchIcon}
        showIcon={false}
      />
    </div>
  );
};
