import React from 'react';
import { CopyClipboardBtn } from 'components/CopyClipboardBtn';
import useModal from 'hooks/useModal';
import { Link } from 'react-feather';
import { useGetDomainsConnection } from 'hooks/lti/useGetDomainsConnection';

export const ConnectLMS = () => {
  const { openModal } = useModal();

  const { data: domain } = useGetDomainsConnection();

  const handleAddLMSConnection = () => {
    openModal('add-lms-connection');
  };

  return (
    <div className="bg-white rounded-4 border border-neutral-200">
      <div className="p-3 pb-0 border-bottom border-neutral-200">
        <p className="fs-md fw-bold text-neutral-900 mb-1">Connect LMS</p>
        <p className="text-neutral-500">
          When your LMS supports external learning tools via LTI you can create a connection with Evokio to make it
          simple and easy to create and insert content that supports returning grades to your LMS.
        </p>
      </div>
      <div className="p-3">
        <div className="rounded-3 border border-neutral-200 p-3">
          <p className="mb-1 fw-bold text-primary">Important Info</p>
          <p className="text-neutral-500">
            When registering Evokio as an external tool inside your LMS you have to remember
          </p>

          <p className="mb-1 fw-bold text-neutral-900">For LTI v1.3</p>
          <ul>
            <li className="mt-1 text-neutral-600">
              <div className="d-flex align-item-center">
                <div className="m-auto ms-0 me-2">
                  Use the following LTI Advantage URL: <span className="fw-semibold">{domain}/lti/register</span>
                </div>
                <CopyClipboardBtn copyText={`${domain}/lti/register`} />
              </div>
            </li>
            <li className="mt-1 text-neutral-600">
              <div className="d-flex align-item-center">
                <div className="m-auto ms-0 me-2">
                  Use the following Login URL: <span className="fw-semibold">{domain}/lti/login</span>
                </div>
                <CopyClipboardBtn copyText={`${domain}/lti/login`} />
              </div>
            </li>
            <li className="mt-1 text-neutral-600">
              <div className="d-flex align-item-start">
                <div className="ms-0 me-2">Use the following Redirect URL:</div>
                <div className="position-relative">
                  <div className="d-flex align-items-center">
                    <span className="me-2 fw-semibold">{domain}/lti/</span>
                    <CopyClipboardBtn copyText={`${domain}/lti/`} />
                  </div>
                </div>
              </div>
            </li>
            <li className="mt-1 text-neutral-600">
              <div className="d-flex align-items-center">
                <div className="ms-0 me-2">Use the following Public key:</div>
                <div className="d-flex align-items-center">
                  <span className="me-2 fw-semibold">{domain + '/lti/keyset'}</span>
                  <CopyClipboardBtn copyText={domain + '/lti/keyset'} />
                </div>
              </div>
            </li>
            <li className="mt-1 text-neutral-600">
              Once you{`'`}ve made a LMS connection, add the extra data from your platform to the connection
            </li>
          </ul>
        </div>

        <button
          className="btn btn-primary d-flex align-item-center mt-3"
          onClick={handleAddLMSConnection}
        >
          <Link
            strokeWidth={2.6}
            size={16}
            className="me-2"
            style={{ marginTop: '3px' }}
          />
          <div>Add LMS Connection</div>
        </button>
      </div>
    </div>
  );
};
