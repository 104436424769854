export interface SubscriptionInfo {
  processorPlan: string;
  status: SubscriptionStatus;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
  standardUserItem?: string;
  studentReportItem?: string;
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  TRIALING = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
}

export enum SubscriptionPlan {
  STANDARD_USER = 'STANDARD_USER',
  STUDENT_REPORT = 'STUDENT_REPORT',
}

export enum BaseSubscriptionUsageRecord {
  STANDARD_USER = 3,
  STUDENT_REPORT = 100,
}
