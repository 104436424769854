import { moveToTrash } from 'apis/Folder/Folder';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';
import { Document } from 'models/Content';
import { useAppStore } from 'hooks/useAppStore';

export const useMoveToTrash = () => {
  const queryClient = useQueryClient();
  const { queryKey } = useAppStore();

  return useMutation(moveToTrash, {
    onSuccess: (_, contentId) => {
      queryClient.setQueryData(queryKey, (documents: Document[]) => {
        return documents.filter(document => document.id != contentId);
      });
      toast('Item has been moved to Trash.', { type: 'success' });
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
