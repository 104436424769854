import React, { createContext, ReactElement, useCallback, useState } from 'react';

// create context for useSidebar
type SidebarContextType = {
  isCollapsed: boolean;
  setCollapsed: () => void;
  setExpanded: () => void;
  toggle: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({
  isCollapsed: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCollapsed: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setExpanded: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggle: () => {},
});

// create provider for useSidebar
export const SidebarProvider = ({ children }: { children: ReactElement }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggle = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const setCollapsed = () => {
    setIsCollapsed(true);
  };

  const setExpanded = () => {
    setIsCollapsed(false);
  };

  return (
    <SidebarContext.Provider value={{ isCollapsed, setCollapsed, setExpanded, toggle }}>
      {children}
    </SidebarContext.Provider>
  );
};
