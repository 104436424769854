import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class UIStore {
  isNavbarCollapsed: boolean;
  toggleScrollToTop?: boolean;
  isAIOffsetOpen?: boolean = false;

  constructor() {
    this.isNavbarCollapsed = false;
    this.toggleScrollToTop = false;
    this.isAIOffsetOpen = false;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'UiStore',
      properties: ['isNavbarCollapsed'],
      storage: window.localStorage,
      stringify: true,
      debugMode: false,
    });
  }

  collapseNav() {
    this.isNavbarCollapsed = true;
  }

  expandNav() {
    this.isNavbarCollapsed = false;
  }

  toggleNav() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  clear() {
    this.isNavbarCollapsed = false;
    this.isAIOffsetOpen = false;
  }

  scrollToTop() {
    this.toggleScrollToTop = !this.toggleScrollToTop;
  }

  toggleAIOffset() {
    this.isAIOffsetOpen = !this.isAIOffsetOpen;
  }
}
