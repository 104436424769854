import React from 'react';
import './ExpiredPlan.scss';
import { Link } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { useAppStore } from 'hooks/useAppStore';
import PastDue from 'assets/images/past-due.svg';
import { SubscriptionSelectWorkspace } from './SubscriptionSelectWorkspace';
import { useLogout } from 'hooks';
import { observer } from 'mobx-react-lite';

export const ExpiredPlan = observer(() => {
  const { ltiMode } = useAppStore();

  const { mutate } = useLogout();

  const handleLogout = () => {
    mutate(false);
  };

  return (
    <div className="expired-plan-modal modal-wrapper">
      <div className="d-flex align-items-center justify-content-between px-4 py-3">
        <div className="font-size-18 fw-bold text-neutral-900">Subscription has expired!</div>
        {!ltiMode && (
          <Link
            className="d-flex align-items-center pointer-cursor"
            to={null}
            onClick={handleLogout}
          >
            <span className="text-nowrap">Log Out</span>
            <LogOut
              className="ms-2"
              size={20}
              strokeWidth={2}
            />
          </Link>
        )}
      </div>
      <div className="expired-plan-modal--divider mb-3"></div>
      <div className="d-flex flex-column flex-lg-row pb-3 px-3 px-lg-4">
        <div className="expired-plan-modal--left col-12 col-lg-5">
          <div className="d-flex flex-column me-0 me-lg-4">
            <div
              className="font-size-14 fw-medium text-neutral-900 mb-3 mb-lg-5"
              data-cy="subscription-message"
            >
              {ltiMode && <span>⏱️ </span>}
              Please contact Workspace&apos;s Owner to continue using our services without disruptions.
            </div>
            {!ltiMode && (
              <>
                <div className="font-size-14 fw-medium text-neutral-900">Switch Workspace</div>
                <div
                  className="mt-2"
                  data-cy="switch-workspace-dropdown"
                >
                  <SubscriptionSelectWorkspace />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-12 col-lg-7">
          <div
            className="d-flex justify-content-center align-items-center ms-4 py-5"
            data-cy="no-subscription-plan"
          >
            <img
              src={PastDue}
              alt="Past Due"
            />
          </div>
        </div>
      </div>
    </div>
  );
});
