import React from 'react';
import ReactPaginate from 'react-paginate';
import { Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather';
import './AppPagination.scss';
import Skeleton from 'react-loading-skeleton';
import { appStore } from 'stores';

type Props = {
  totalRecords?: number;
  pageCount?: number;
  onClick?: (page: any) => void;
  table?: Table<any>;
  currentPageIndex?: number;
  setPageIndex?: (index: number) => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  isLoading?: boolean;
};

export const AppPagination = ({
  totalRecords,
  pageCount,
  currentPageIndex,
  setPageIndex,
  onNextPage,
  onPreviousPage,
  canNextPage,
  canPreviousPage,
  isLoading,
}: Props) => {
  return (
    <>
      <div
        data-cy="desktop-pagination-buttons"
        className={classNames('d-none align-items-center justify-content-between', {
          'd-sm-flex': appStore.ltiMode,
          'd-md-flex': !appStore.ltiMode,
        })}
      >
        {isLoading ? (
          <Skeleton
            width={48}
            height={24}
          />
        ) : (
          <div
            data-cy="total-records"
            className="gray-900"
          >
            {totalRecords} total
          </div>
        )}
        <div className="d-flex align-items-center gap-2">
          {isLoading ? (
            <Skeleton
              width={320}
              height={24}
            />
          ) : (
            <>
              <button
                className="btn-pagination"
                onClick={() => setPageIndex(0)}
                disabled={!canPreviousPage}
              >
                <ChevronsLeft className={classNames({ 'gray-300': !canPreviousPage })} />
              </button>
              <button
                className="btn-pagination"
                data-cy="btn-previous-page"
                onClick={onPreviousPage}
                disabled={!canPreviousPage}
              >
                <ChevronLeft className={classNames({ 'gray-300': !canPreviousPage })} />
              </button>
              <ReactPaginate
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                pageLinkClassName="btn-pagination"
                previousClassName="d-none"
                nextClassName="d-none"
                breakLabel="..."
                breakLinkClassName="btn-pagination"
                containerClassName="d-flex align-items-center gap-2 mb-0 ps-0 list-unstyled"
                activeLinkClassName="btn-pagination active"
                renderOnZeroPageCount={null}
                onPageChange={page => setPageIndex(page.selected)}
                forcePage={currentPageIndex}
              />
              <button
                className="btn-pagination"
                data-cy="btn-next-page"
                onClick={onNextPage}
                disabled={!canNextPage}
              >
                <ChevronRight className={classNames({ 'gray-300': !canNextPage })} />
              </button>
              <button
                className="btn-pagination"
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={!canNextPage}
              >
                <ChevronsRight className={classNames({ 'gray-300': !canNextPage })} />
              </button>
            </>
          )}
        </div>
      </div>

      {/* Mobile devices */}
      <div
        data-cy="mobile-pagination-buttons"
        className="d-flex d-md-none align-items-center justify-content-between"
      >
        {isLoading ? (
          <Skeleton
            width={60}
            height={40}
          />
        ) : (
          <button
            className="btn-pagination mobile"
            data-cy="btn-previous-page-mobile"
            onClick={onPreviousPage}
            disabled={!canPreviousPage}
          >
            <ChevronLeft
              size={16}
              className={classNames({ 'gray-300': !canPreviousPage })}
            />
          </button>
        )}

        {isLoading ? (
          <Skeleton
            width={60}
            height={40}
          />
        ) : (
          <div className="gray-900">{totalRecords} total</div>
        )}

        {isLoading ? (
          <Skeleton
            width={60}
            height={40}
          />
        ) : (
          <button
            className="btn-pagination mobile"
            data-cy="btn-next-page-mobile"
            onClick={onNextPage}
            disabled={!canNextPage}
          >
            <ChevronRight
              size={16}
              className={classNames({ 'gray-300': !canNextPage })}
            />
          </button>
        )}
      </div>
    </>
  );
};
