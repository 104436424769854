import {
  ColumnDef,
  ExpandedState,
  Row,
  TableOptions,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AppPagination, ErrorEmptyBlock } from 'components';
import { AppTable } from 'components/AppTable';
import { useAppStore } from 'hooks/useAppStore';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { LearnerContentReport, LearnerReport } from 'types/student-report';
import { LearnerReportListViewData, LearnerReportsList } from '../LearnerReportsList';
import { useParams } from 'react-router-dom';

type IProps = {
  data: LearnerReport[];
  isLoading: boolean;
  searchTerm?: any;
  defaultColumns: any[];
  tableOptionsData?: Partial<TableOptions<any>>;
  onRowClick?: (row: Row<any>) => void;
};

export const LearnerReportsTable = observer((props: IProps) => {
  const { data, isLoading, searchTerm, defaultColumns, tableOptionsData = {}, onRowClick } = props;
  const { uiStore } = useAppStore();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { userId } = useParams();

  const columns: any = useMemo<ColumnDef<LearnerReport>[]>(
    () =>
      isLoading
        ? defaultColumns.map(column => {
            return {
              ...column,
              cell: () => <Skeleton />,
            };
          })
        : defaultColumns,
    [isLoading],
  );

  const defaultPageSize = useMediaScreen().isMdDown ? 25 : 10;

  const learnerReportsData = useMemo(() => {
    return isLoading ? Array(defaultPageSize).fill({}) : data;
  }, [isLoading, data]);

  const table = useReactTable({
    ...tableOptionsData,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    data: learnerReportsData,
    columns,
    initialState: {
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    uiStore.scrollToTop();
  }, [table?.getState().pagination.pageIndex]);

  useEffect(() => {
    const pageIndex = table.getState().pagination.pageIndex;
    if (table.getRowModel().rows.length === 0 && pageIndex > 0) table.setPageIndex(pageIndex - 1);
  }, [table.getRowModel().rows.length]);

  useEffect(() => {
    table.setPageIndex(0);
    table.toggleAllRowsExpanded(false);
  }, [searchTerm]);

  const reportsData = useMemo<LearnerReportListViewData[]>(() => {
    if (!userId)
      return (learnerReportsData as LearnerReport[]).map(report => ({
        id: report.id,
        email: report.email,
        totalAttempts: report.totalAttempts,
        fullName: report.fullName,
        platformId: report.platformId,
      }));

    return (learnerReportsData as LearnerContentReport[]).map(report => ({
      contentTitle: report?.content?.title,
      library: report?.content?.library,
      totalAttempts: report.totalAttempt,
      platformId: report.platformId,
      attempts: report.attempts,
      firstAttemptScore: report.firstAttemptScore,
      firstAttemptPercent: report.firstAttemptPercent,
      lastAttemptScore: report.lastAttemptScore,
      lastAttemptPercent: report.lastAttemptPercent,
      bestScore: report.bestScore,
      bestScorePercent: report.bestScorePercent,
    }));
  }, [learnerReportsData, userId]);

  if (!learnerReportsData?.length) return <ErrorEmptyBlock />;

  return (
    <div
      data-cy="learner-reports-view"
      className="h-100 w-100 d-flex flex-column justify-content-between"
    >
      <div
        data-cy="learner-reports-view-desktop"
        className="d-none d-md-block px-4"
      >
        <AppTable
          table={table}
          onRowClick={onRowClick}
        />
      </div>

      <div
        data-cy="learner-reports-view-mobile"
        className="d-block d-md-none"
      >
        <LearnerReportsList
          data={reportsData}
          isLoading={isLoading}
          onRowClick={row => (onRowClick ? onRowClick(row) : null)}
        />
      </div>

      <div className="mt-4 px-0 px-md-4">
        <AppPagination
          isLoading={isLoading}
          totalRecords={data?.length}
          pageCount={table.getPageCount()}
          currentPageIndex={table.getState().pagination.pageIndex}
          setPageIndex={index => table.setPageIndex(index)}
          canNextPage={table.getCanNextPage()}
          canPreviousPage={table.getCanPreviousPage()}
          onNextPage={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
          onPreviousPage={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
        />
      </div>
    </div>
  );
});
