import { updateChat } from 'apis/AiChat/AiChat';
import { useMutation } from 'react-query';
import { AiChatHistory, ErrorResponse } from 'types';

type Props = {
  onSuccess?: (data: AiChatHistory, variables: { chatId: string; name: string }) => void;
};

export const useUpdateChat = ({ onSuccess }: Props) => {
  return useMutation<AiChatHistory, ErrorResponse, { chatId: string; name: string }>(updateChat, {
    onSuccess,
  });
};
