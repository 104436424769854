import React from 'react';
import { useForm } from 'react-hook-form';
import TextField from 'components/AppForm/TextField';
import useModal from 'hooks/useModal';
import { X } from 'react-feather';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateConnection } from 'types';
import { useRegisterConnection } from 'hooks/lti/useRegisterConnection';
import { TextAreaField } from 'components/AppForm/TextAreaField';
import { useUpdateConnection } from 'hooks';
import { URL_REGEXP } from 'constants/regexp';

type Props = {
  data?: CreateConnection;
};

export const AddConnectionSettings = ({ data }: Props) => {
  const { closeModal } = useModal();

  const schema = yup
    .object()
    .shape({
      platformName: yup.string().trim().required('Connection name is required.'),
      clientId: yup.string().trim().required('Client ID is required.'),
      platformUrl: yup.string().trim().required('Issuer is required.'),
      authEndpoint: yup
        .string()
        .trim()
        .required('Login URL is required.')
        .matches(URL_REGEXP, 'The login URL format is invalid'),
      accesstokenEndpoint: yup
        .string()
        .trim()
        .required('Token URL is required.')
        .matches(URL_REGEXP, 'The token URL format is invalid '),
      authConfig: yup.object().shape({
        key: yup
          .string()
          .trim()
          .required('Key Set URL is required.')
          .matches(URL_REGEXP, 'The key set URL format is invalid'),
      }),
      isUpdateUserName: yup.boolean(),
      isUpdateUserEmail: yup.boolean(),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateConnection>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: { ...data },
  });

  const { mutateAsync: registerConnection, isLoading: registerLoading } = useRegisterConnection();
  const { mutateAsync: updateConnection, isLoading: updateLoading } = useUpdateConnection();

  const onSubmit = handleSubmit(async (data: CreateConnection) => {
    data?.kid ? await updateConnection(data) : await registerConnection(data);
    closeModal();
    reset({});
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div
          className="d-flex flex-column p-3 modal-wrapper"
          data-cy="add-lms-connection"
        >
          <div className="d-flex justify-content-between align-item-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                className="gray-900 fw-semibold font-size-18"
                data-cy="header-title"
              >
                Add registration data
              </div>
              <X
                onClick={() => {
                  closeModal();
                  reset({});
                }}
                className="close-icon me-0 gray-400 cursor-pointer d-block"
                size={24}
                data-cy="close-icon"
              />
            </div>
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('platformName')}
              label="Connection name"
              style={{
                height: 40,
              }}
            />
            {errors?.platformName && <p className="feedback-invalid">{errors?.platformName?.message as string}</p>}
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('clientId')}
              label="Client ID"
              style={{
                height: 40,
              }}
            />
            {errors?.clientId && <p className="feedback-invalid">{errors?.clientId?.message as string}</p>}
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('platformUrl')}
              label="Issuer"
              style={{
                height: 40,
              }}
            />
            {errors?.platformUrl && <p className="feedback-invalid">{errors?.platformUrl?.message as string}</p>}
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('authEndpoint')}
              label="Login URL"
              style={{
                height: 40,
              }}
            />
            {errors?.authEndpoint && <p className="feedback-invalid">{errors?.authEndpoint?.message as string}</p>}
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('accesstokenEndpoint')}
              label="Token URL"
              style={{
                height: 40,
              }}
            />
            {errors?.accesstokenEndpoint && (
              <p className="feedback-invalid">{errors?.accesstokenEndpoint?.message as string}</p>
            )}
          </div>

          <div className="form-group mt-3">
            <TextField
              register={register('authConfig.key')}
              label="Key Set URL"
              style={{
                height: 40,
              }}
            />

            {errors?.authConfig?.key && (
              <p className="feedback-invalid">{errors?.authConfig?.key?.message as string}</p>
            )}
          </div>
          <div className="form-group mt-3">
            <TextAreaField
              register={register('authConfig.secret')}
              label="Private key (optional)"
              rows={3}
            />

            {errors?.authConfig?.secret && (
              <p className="feedback-invalid">{errors?.authConfig?.secret?.message as string}</p>
            )}
          </div>

          <button
            className="btn btn-primary ms-auto w-fit px-3 py-2 text-white mt-2"
            type="submit"
            disabled={registerLoading || updateLoading}
            data-cy="btn-submit"
          >
            Save
            {(registerLoading || updateLoading) && (
              <span
                className="ms-3 spinner-border spinner-border-sm text-light"
                role="status"
              ></span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};
