import { PageHeader } from 'components/PageHeader';
import React, { useEffect, useMemo, useState } from 'react';
import {
  NavLink,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { ContentPermission, MichiAssistantBtn } from 'components';
import { useAppStore } from 'hooks/useAppStore';
import { ContentStatus } from 'models/Content';
import { useGetContentRole } from 'hooks';
import { ContentRole, Role } from '@spiderbox/common';
import { observer } from 'mobx-react-lite';
import { UserPermission } from 'components/UserPermission';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import Skeleton from 'react-loading-skeleton';
import { ContentActionStatus } from 'types';
import { useGetContentDefaultSetting } from 'hooks/setting';
import { SaveButton } from './components/SaveButton';
import { SaveAndInsertButton } from './components/SaveAndInsertButton';
import { PublishButton } from './components/PublishButton';
import { SaveAsDraftButton } from './components/SaveAsDraftButton';
import { useUserPermission } from 'hooks/useUserPermission';
import featureFlagService from 'services/feature-flag.service';

export const ContentEditorPage = observer(() => {
  const { id: contentId } = useParams();
  const navigate = useNavigate();
  const {
    uiStore,
    ltiMode,
    contentStore,
    subscriptionStore: { currentSubscription },
    userInfo: { roles },
  } = useAppStore();
  const { isSaving, isContentLoaded, pageTitle, contentStatus, isDirty, totalUserAttempts } = contentStore;
  const [searchParams] = useSearchParams();
  const library = searchParams.get('library');
  const { state } = useLocation();
  const { contentRole } = useGetContentRole(contentId);
  const { isNavbarCollapsed } = uiStore;
  const contentDefaultSetting = useGetContentDefaultSetting();
  const hasEditPublishedContentPermission = useUserPermission(['content.editPublished']) || roles.includes(Role.ADMIN);

  const backToPlayer = useMemo(() => {
    return searchParams?.get('backToPlayer') === 'true';
  }, [searchParams]);

  const urlBackTo = ltiMode ? `${state?.from}?edit_btn_margin=${state?.editBtnMargin || ''}` : state?.from;

  useEffect(() => {
    const folderId = searchParams.get('folderId');
    contentStore.setFolderId(folderId);
  }, [searchParams]);

  useEffect(() => {
    contentStore.loadContentAsync(contentId).then();
    return () => {
      contentStore.clear();
    };
  }, [contentId]);

  useEffect(() => {
    contentStore.setSettings(contentDefaultSetting);
  }, [contentId, library]);

  useEffect(() => {
    if (!contentId && state?.shouldRefresh) {
      // Load new content state
      contentStore.loadContentAsync('new-content').then(() => (state.shouldRefresh = false));
    }
  }, [contentId, state]);

  useEffect(() => {
    if (!isNavbarCollapsed) {
      uiStore.collapseNav();
    }
  }, []);

  usePrompt(isDirty);

  useEffect(() => {
    if (contentRole === ContentRole.NONE) {
      navigate('/dashboard');
      toast('You do not have permission to access this content.', { type: 'error' });
    }

    if (contentRole === ContentRole.VIEWER) {
      navigate(
        {
          pathname: 'preview',
        },
        {
          state: state,
        },
      );
    }
  }, [contentRole]);

  const [isAIEnabled, setIsAIEnabled] = useState(false);

  useEffect(() => {
    const aiFeature = featureFlagService.isFeatureEnabled('ai_assistant');
    setIsAIEnabled(aiFeature);
  }, []);

  const searchParamsUrl = useMemo(() => {
    return createSearchParams(searchParams).toString();
  }, [searchParams]);

  const CTAButtons = useMemo(() => {
    if (!isContentLoaded)
      return (
        <Skeleton
          height={40}
          width={100}
        />
      );

    if (ltiMode) {
      if (
        [ContentStatus.Published, ContentStatus.Inactive].includes(contentStatus) &&
        !hasEditPublishedContentPermission
      )
        return <></>;

      return (
        <>
          <ContentPermission
            role={contentRole}
            action="content.update"
          >
            <SaveButton
              status={!contentStatus && ContentActionStatus.PUBLISH}
              className="btn-outline-primary"
            />
          </ContentPermission>
          {!backToPlayer && <SaveAndInsertButton />}
        </>
      );
    }

    const Buttons = () => {
      if (!contentStatus || contentStatus === ContentStatus.Draft)
        return (
          <>
            <SaveAsDraftButton />
            <PublishButton />
          </>
        );

      if (contentStatus === ContentStatus.Inactive) {
        if (!hasEditPublishedContentPermission) return <></>;

        return (
          <>
            <SaveButton className="btn-outline-primary" />
            <PublishButton />
          </>
        );
      }

      if (contentStatus === ContentStatus.Published) {
        if (!hasEditPublishedContentPermission) return <></>;

        return (
          <SaveButton
            status={ContentActionStatus.PUBLISH}
            className="btn-primary"
          />
        );
      }
    };

    return (
      <>
        {isAIEnabled && (
          <div className="me-3">
            <MichiAssistantBtn />
          </div>
        )}
        <ContentPermission
          role={contentRole}
          action="content.update"
        >
          <Buttons />
        </ContentPermission>
      </>
    );
  }, [ltiMode, contentStatus, contentRole, isContentLoaded, isSaving, hasEditPublishedContentPermission]);

  return (
    <div>
      {isContentLoaded && (
        <PageHeader
          title={pageTitle}
          rightElement={CTAButtons}
          subHeader={
            <Nav
              tabs
              className="bg-white px-5"
            >
              <ContentPermission
                role={contentRole}
                action="content.update"
              >
                <>
                  <NavItem>
                    <NavLink
                      className={({ isActive }) => [isActive ? 'active' : '', 'nav-link'].join(' ')}
                      to={`edit?${searchParamsUrl}`}
                      replace={true}
                      state={state}
                    >
                      Content
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={({ isActive }) => [isActive ? 'active' : '', 'nav-link'].join(' ')}
                      to={`settings?${searchParamsUrl}`}
                      replace={true}
                      state={state}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                </>
              </ContentPermission>
              <NavItem>
                <NavLink
                  className={({ isActive }) => [isActive ? 'active' : '', 'nav-link'].join(' ')}
                  to={`preview?${searchParamsUrl}`}
                  replace={true}
                  state={state}
                >
                  Preview
                </NavLink>
              </NavItem>
              {!!currentSubscription?.studentReportItem && !ltiMode && !!totalUserAttempts && (
                <UserPermission actions={['report']}>
                  <NavItem>
                    <NavLink
                      className={({ isActive }) => [isActive ? 'active' : '', 'nav-link'].join(' ')}
                      to={`reports?${searchParamsUrl}`}
                      state={state}
                      replace={true}
                    >
                      Reports
                    </NavLink>
                  </NavItem>
                </UserPermission>
              )}
            </Nav>
          }
          hasBackButton={true}
          backTo={urlBackTo}
        ></PageHeader>
      )}
      <div className="py-md-4 px-md-5 py-2 px-3">
        <Outlet context={{ ...state }} />
      </div>
    </div>
  );
});
