import { TableOptions, createColumnHelper, getExpandedRowModel } from '@tanstack/react-table';
import {
  attemptsColumn,
  bestScoreColumn,
  firstScoreColumn,
  lastScoreColumn,
} from 'pages/Content/ContentUserDataReports/components/Columns';
import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LearnerContentReport } from 'types/student-report';
import { LearnerReportLayout } from '../LearnerReportLayout';
import { fetchLearnerReport } from 'apis/LearnerReport/LearnerReport';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useDownloadLearnerReport, useGetOptionLibraries, useGetUserDetail } from 'hooks';
import Skeleton from 'react-loading-skeleton';

export const LearnerReportDetailPage = () => {
  const { userId } = useParams();
  const [searchParam] = useSearchParams();
  const platformId = searchParam.get('platform');
  const { isLoading: isDownloadLoading, mutate: downloadLearnerReportCsv } = useDownloadLearnerReport();

  const { data: userInfo } = useGetUserDetail(userId);

  const crumbs = useMemo(() => {
    if (!userInfo) return;

    return [
      { path: `/learner-reports?${searchParam}&platform=&user=&platformName=`, title: 'All Learners' },
      { path: '', title: userInfo.fullName, unclickable: true },
    ];
  }, [userId, userInfo, searchParam]);

  const queryKey = ['learner-reports', userId, platformId];

  const columnHelper = createColumnHelper<LearnerContentReport>();

  const { data: libraries, isLoading } = useGetOptionLibraries();

  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };

  const defaultColumns = [
    columnHelper.display({
      id: 'expand-report',
      size: 50,
      cell: ({ row }) => {
        return (
          <div>
            {row.getIsExpanded() ? (
              <ChevronUp
                size={24}
                className="text-neutral-600 cursor-pointer ms-2"
              />
            ) : (
              <ChevronDown
                size={24}
                className="text-neutral-600 cursor-pointer ms-2"
              />
            )}
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'content-info',
      header: 'Content Name',
      meta: {
        className: 'w-25',
      },
      cell: ({ row }) => {
        const content = row.original.content;

        return (
          <div className="pe-2 d-flex align-items-center">
            <div className="flex-fill w-1px">
              <div className="text-truncate text-neutral-700 fw-semibold">{content.title}</div>
              {isLoading ? (
                <Skeleton
                  width={100}
                  height={10}
                />
              ) : (
                <div className="text-truncate text-neutral-700">{mappingTitle(content.library)}</div>
              )}
            </div>
          </div>
        );
      },
    }),
    attemptsColumn,
    firstScoreColumn,
    lastScoreColumn,
    bestScoreColumn,
  ];

  const tableOptionsData: Partial<TableOptions<any>> = {
    enableExpanding: true,
    getExpandedRowModel: getExpandedRowModel(),
  };

  return (
    <LearnerReportLayout
      queryKey={queryKey}
      userId={userId}
      fetchReports={(params, userId) => fetchLearnerReport({ ...params, platformId }, userId)}
      downloadCsv={searchTerm => downloadLearnerReportCsv({ params: { ...searchTerm, platformId }, userId })}
      pageTitle="Learner Report"
      isDownloadLoading={isDownloadLoading}
      defaultColumns={defaultColumns}
      tableOptionsData={tableOptionsData}
      searchInputPlaceHolder="Search by content name"
      canFilterByContentType
      crumbs={crumbs}
      isLoading={isLoading}
      onRowClick={row => row.toggleExpanded()}
    />
  );
};
