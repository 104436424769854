import React from 'react';
import { useEmptyTrash } from 'hooks';
import useModal from 'hooks/useModal';
import { Trash2 } from 'react-feather';
import { ConfirmModalProps } from '../Confirm';

export const useEmptyTrashModal = () => {
  const { openModal, closeModal } = useModal();
  const { mutate: emptyTrash } = useEmptyTrash();

  const openEmptyTrashModal = () =>
    openModal('warning', {
      title: 'Permanently delete?',
      content: "All items in the trash will be permanently deleted and you won't be able to restore them.",
      titleIcon: (
        <Trash2
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: 'Delete',
      onAccept: () => {
        emptyTrash();
        closeModal();
      },
      onCancel: closeModal,
    } as ConfirmModalProps);

  return { openEmptyTrashModal };
};
