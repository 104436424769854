import { useAppStore } from 'hooks/useAppStore';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { WorkspaceInfo } from 'types';
import { SubscriptionControlWorkspace } from '../SubscriptionControlWorkspace';
import { SubscriptionMenuWorkspace } from '../SubscriptionMenuWorkspace';
import { ERSColor } from 'components/ModalManager/ModalInviteUsers/common/RSInviteUsers';

export const SubscriptionSelectWorkspace = observer(() => {
  const {
    workspaceStore: { workspaces = [], currentWorkspaceId },
  } = useAppStore();
  const navigate = useNavigate();

  const switchWorkspaces = useLocalObservable(() => ({
    isShow: false,
    toggleMenu() {
      this.isShow = !this.isShow;
    },
    setIsShow(value: boolean) {
      this.isShow = value;
    },
  }));

  const selectedWorkspace = useMemo(
    () => workspaces.find(option => option.id === currentWorkspaceId),
    [currentWorkspaceId, workspaces],
  );

  const switchWorkspace = (data: WorkspaceInfo) => {
    navigate('/workspace/switch', { state: { data } });
  };

  const IndicatorSeparator = () => null;

  return (
    <ReactSelect
      value={selectedWorkspace}
      options={workspaces}
      components={{ IndicatorSeparator, Control: SubscriptionControlWorkspace, Menu: SubscriptionMenuWorkspace }}
      isSearchable={false}
      placeholder=""
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          border: `1px solid ${ERSColor.NEUTRAL_200}`,
          borderRadius: '6px',
          boxShadow: 'none',
          '&:hover': {
            border: `1px solid ${ERSColor.PRIMARY}`,
          },
        }),
        menu: baseStyles => ({
          ...baseStyles,
          border: `1px solid ${ERSColor.NEUTRAL_200}`,
          borderRadius: '6px',
          maxHeight: '200px',
          overflow: 'scroll',
          '@media only screen and (max-width: 1200px)': {
            maxHeight: '150px',
          },
        }),
      }}
      menuIsOpen={switchWorkspaces.isShow}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switchWorkspaces={switchWorkspaces}
      handleClickSwitchWorkspace={switchWorkspace}
    />
  );
});
