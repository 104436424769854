import React from 'react';
import useModal from 'hooks/useModal';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import './SidebarMenuBottom.scss';
import { SidebarProfile } from './components/SidebarProfile';
import { RedirectMasterAdminDashboard } from './components/RedirectMasterAdminDashboard';

export const SidebarMenuBottom = observer(() => {
  const [open, toggleOpen] = useToggle(false);
  const sidebarMenuBottomRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useModal();

  useClickAway(sidebarMenuBottomRef, () => {
    if (isOpen) return;
    toggleOpen(false);
  });

  return (
    <div
      ref={sidebarMenuBottomRef}
      className="position-absolute bottom-0 start-0 bg-white sidebar-menu-bottom"
      data-cy="sidebar-menu-bottom"
    >
      <RedirectMasterAdminDashboard />
      <SidebarProfile
        open={open}
        toggleOpen={toggleOpen}
      />
    </div>
  );
});
