import React from 'react';
import { AiChatHistory } from 'types';
import { useToggle } from 'react-use';
import EditNameForm from './EditNameForm';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useUpdateChat } from 'hooks/ai-chat';
import ChatCardContent from './ChatCardContent';
import './ChatCard.scss';

type ChatCardProps = {
  chat: AiChatHistory;
  onDelete?: () => void;
  onSelect?: () => void;
};

const ChatCard = ({ chat, onDelete, onSelect }: ChatCardProps) => {
  const [isEditMode, toggleEditMode] = useToggle(false);
  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateChat({
    onSuccess: (data, variables) => {
      queryClient.setQueryData<AiChatHistory[]>('chat-history', (chatsList: AiChatHistory[] = []) => {
        return chatsList.map(chat => {
          if (chat.id === variables.chatId) {
            return { ...chat, name: data.name };
          }

          return chat;
        });
      });
      toast.success('Chat name has been updated.');
    },
  });

  const handleRenameChat = async ({ name }: { name: string }) => {
    await mutateAsync({ chatId: chat.id, name });
    toggleEditMode();
  };

  return (
    <div
      className="gap-3 my-1 chat-card d-flex justify-content-between align-items-center"
      onClick={onSelect}
    >
      {isEditMode ? (
        <EditNameForm
          value={chat.name}
          onCancel={toggleEditMode}
          onSubmit={handleRenameChat}
        />
      ) : (
        <ChatCardContent
          chat={chat}
          onEdit={toggleEditMode}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default ChatCard;
