import apiClient from '../../ApiClient';
import { MasterAdminLibrary, MasterAdminLibraryPayload, MasterAdminLibraryQuery } from 'types/master-admin-library';

const END_POINT = 'master-admin/libraries';

export async function getMasterAdminLibraries(payload: MasterAdminLibraryQuery): Promise<MasterAdminLibrary[]> {
  return await apiClient.get(END_POINT, { params: payload }).then(response => {
    return response.data;
  });
}

export async function updateAdditionalMetadata({
  libraries,
  duplicateLibs,
}: {
  libraries: MasterAdminLibrary[];
  duplicateLibs: string[];
}): Promise<MasterAdminLibrary[]> {
  const payload: MasterAdminLibraryPayload[] = [];

  libraries.forEach(item => {
    const items = duplicateLibs.includes(item.metadata.machineName)
      ? libraries?.filter(lib => lib.metadata.machineName === item.metadata.machineName)
      : [item];

    items.forEach(duplicateItem => {
      payload.push({
        library: {
          machineName: duplicateItem.metadata.machineName,
          majorVersion: duplicateItem.metadata.majorVersion,
          minorVersion: duplicateItem.metadata.minorVersion,
        },
        additionalMetadata: item.additionalMetadata,
      });
    });
  });
  return await apiClient.put(`${END_POINT}/additional-metadata`, payload).then(response => {
    return response.data;
  });
}
