import React, { useEffect } from 'react';
import useModal from 'hooks/useModal';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { SubscriptionStatus } from '@spiderbox/common';

export const ExpiredSubscriptionLayout = observer(({ children }: { children?: JSX.Element }) => {
  const {
    workspaceStore: { isWorkspaceOwner },
    subscriptionStore: { currentSubscription },
  } = useAppStore();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (isWorkspaceOwner) {
      if (currentSubscription?.status === SubscriptionStatus.UNPAID) {
        openModal('payment-retry', { size: 'md' });
      } else closeModal();
    } else {
      if ([SubscriptionStatus.UNPAID, SubscriptionStatus.CANCELED].includes(currentSubscription?.status)) {
        openModal('expired-plan', {
          size: 'lg',
          className: 'h-100 mt-3 mx-md-auto my-md-0 px-1 px-md-3 justify-content-center align-items-center',
          hiddenModalClassName: true,
        });
      } else closeModal();
    }
  }, [currentSubscription?.status]);

  return children ? children : <Outlet />;
});
