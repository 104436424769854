import { SubscriptionInfo } from '@spiderbox/common';
import { makeAutoObservable } from 'mobx';
import { Plan } from 'types/subscriptions';

export class SubscriptionStore {
  currentSubscription: SubscriptionInfo = null;
  plans: { [key: string]: Plan } = {};

  constructor() {
    makeAutoObservable(this);
  }

  updateCurrentSubscription = (subscriptionData: SubscriptionInfo) => {
    if (!subscriptionData) {
      this.currentSubscription = null;
      return;
    }

    this.currentSubscription = {
      ...this.currentSubscription,
      ...subscriptionData,
    };
  };

  setPlans = (data: Plan[]) => {
    this.plans = [...data]
      .map(plan => ({
        name: plan.name,
        id: plan?.id,
        price: plan.unit_amount / 100.0,
        lookupKey: plan.lookup_key,
      }))
      .reduce((plans, plan) => ({ ...plans, [plan.lookupKey]: plan }), {});
  };

  clear() {
    this.currentSubscription = null;
  }
}
