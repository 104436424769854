import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { FC } from 'react';
import './ContentResultAnswer.scss';

interface Props {
  answer: string;
  correct: boolean;
  learner: boolean;
  choice?: boolean;
}

export const ContentResultAnswer: FC<Props> = ({ answer, correct, learner, choice }) => {
  return (
    <div
      className={classNames(
        'answer',
        { 'd-flex flex-column w-100': !choice },
        { 'd-inline-flex': choice },
        { 'incorrect-answer': !correct },
        { 'learner-correct-answer': correct && learner },
        { 'correct-answer': correct && !learner },
      )}
    >
      <div className={classNames({ 'd-flex justify-content-start': !choice }, { 'd-flex': choice })}>
        {correct ? <CheckIcon /> : <XMarkIcon />}
      </div>
      {!choice && (
        <div
          className="algin-items-center"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        ></div>
      )}
    </div>
  );
};
