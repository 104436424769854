import { useAppStore } from 'hooks/useAppStore';
import React from 'react';
import './UserTextPrompt.scss';
import { AIMessageActionType, AIMessage } from 'types';

type Props = {
  message: AIMessage;
};

const UserTextPrompt = ({ message }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();

  const { actionType, content, metadata } = message;

  const renderLabel = () => {
    switch (actionType) {
      case AIMessageActionType.RefineContext:
        return 'Refine Context';
      case AIMessageActionType.GenerateContent:
        return 'Select Content Type';
      case AIMessageActionType.RefineContent:
        return 'Refine Content';
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (actionType) {
      case AIMessageActionType.RefineContext:
      case AIMessageActionType.RefineContent:
      case AIMessageActionType.Chat:
        return <div className="font-size-14 fw-normal text-neutral-900">{content}</div>;
      case AIMessageActionType.GenerateContent:
        return (
          <>
            <div className="font-size-14 fw-normal text-neutral-900">
              Generate content for <span className="fw-bold">{metadata?.contentType}</span>
            </div>
            {content && (
              <>
                <div className="font-size-14 fw-medium text-neutral-900 mb-2 mt-3">Additional Information</div>
                <div className="font-size-14 fw-normal text-neutral-900">{content}</div>
              </>
            )}
          </>
        );
      case AIMessageActionType.GenerateContext:
        return <div className="font-size-14 fw-normal text-neutral-900">Finish Brainstorm & Analyse Content</div>;
      default:
        return null;
    }
  };

  return (
    <div className="user-text-prompt">
      <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
      <div className="prompt p-2">
        {renderLabel() && <div className="font-size-14 fw-medium text-neutral-900 mb-2">{renderLabel()}</div>}
        {renderContent()}
      </div>
    </div>
  );
};

export default UserTextPrompt;
