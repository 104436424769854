import React from 'react';
import { observer } from 'mobx-react-lite';

// Import scss
import './assets/scss/theme.scss';
import { Routers } from 'routes/root';

const App = () => {
  return <Routers />;
};

export default observer(App);
