import { PageHeader } from 'components/PageHeader';
import React from 'react';
import { PlanInformation } from './components/PlanInformation';
import { PlanOptions } from './components/PlanOptions';
import { useAppStore } from 'hooks/useAppStore';
import moment from 'moment';

export const SubscriptionPage = () => {
  const {
    workspaceStore: { isWorkspaceOwner },
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  const renderCancelledBanner = () => {
    if (!currentSubscription?.cancelAtPeriodEnd) return <></>;

    return (
      <div className="bg-secondary-500 d-flex flex-column gap-2 px-4 py-2">
        <div className="gray-900 font-size-14 fw-medium">Your plan cancellation request has been received.</div>
        <div className="gray-900 font-size-14">
          Your current plan will remain active until the end of the current period on{' '}
          <span className="fw-medium">{moment(currentSubscription.currentPeriodEnd).format('DD/MM/YYYY')}</span>. Simply
          click on the <span className="fw-medium">Resume Plan</span> button to resume your current plan.
        </div>
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title="Subscription"
        subHeader={isWorkspaceOwner && renderCancelledBanner()}
      />

      <div className="d-flex flex-column p-3 p-md-4 gap-4">
        <PlanInformation />

        <div className="rounded-4 bg-white">
          <div className="border-bottom border-gray-200 text-neutral-900 fw-semibold font-size-16 px-4 pt-4 pb-3">
            Monthly Plan
          </div>
          <div className="px-4 py-3">
            <PlanOptions />
          </div>
        </div>
      </div>
    </>
  );
};
