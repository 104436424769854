import { ContentRole } from '@spiderbox/common';
import { duplicate } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useDuplicate = () => {
  const { queryKey } = useAppStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isMdDown } = useMediaScreen();

  return useMutation(duplicate, {
    onSuccess: (data: any) => {
      const documents = queryClient.getQueryData<Document[]>(queryKey);
      queryClient.setQueryData(queryKey, [{ ...data, contentRole: ContentRole.OWNER }, ...documents]);
      toast.success('Content has been duplicated.');
      !isMdDown && navigate(`/contents/${data.id}`);
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
