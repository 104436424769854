import { fetchOptionSettings } from 'apis/Options/Options';
import { me } from 'apis/Profile/Profile';
import { getWorkspaces } from 'apis/Workspace/Workspace';
import { makeAutoObservable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import QueryString from 'qs';
import React from 'react';
import { UserDetail } from 'types';
import { FolderStore } from './FolderStore';
import { WorkspaceStore } from './WorkspaceStore';
import { UIStore } from './uiStore';
import { LtiStore } from './LtiStore';
import { ContentStore } from './ContentStore';
import { SubscriptionStore } from './SubscriptionStore';
import { fetchAllPlans, getCurrentSubscription } from 'apis/Payment/Subscription';

export class AppStore {
  accessToken: string = '';
  refreshToken: string = '';
  userInfo: UserDetail = null;
  workspaceStore: WorkspaceStore;
  uiStore: UIStore;
  queryKey: (string | QueryString.ParsedQs)[] = [];
  currentContentPageCrumb: { title: string; path: string } = { title: 'All Content', path: '/contents/all' };
  folderStore: FolderStore;
  ltiStore: LtiStore;
  contentStore: ContentStore;
  subscriptionStore: SubscriptionStore;
  initializedLayout = false;

  constructor() {
    this.workspaceStore = new WorkspaceStore();
    this.uiStore = new UIStore();
    this.folderStore = new FolderStore();
    this.ltiStore = new LtiStore();
    this.contentStore = new ContentStore();
    this.subscriptionStore = new SubscriptionStore();

    makeAutoObservable(this);

    makePersistable(
      this,
      {
        name: 'AppStore',
        properties: ['accessToken', 'userInfo', 'refreshToken'],
        storage: window.localStorage,
        stringify: true,
        debugMode: false,
      },
      { delay: 200 },
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get authenticated() {
    return !!this.accessToken;
  }

  get ltiMode() {
    return !!this.ltiStore.ltiKey;
  }

  updateAccessToken(token: string) {
    this.accessToken = token;
  }

  updateRefreshToken(token: string) {
    this.refreshToken = token;
  }

  updateUserInfo(userInfo: UserDetail) {
    this.userInfo = { ...this.userInfo, ...userInfo };
  }

  setQueryKey = (query: (string | QueryString.ParsedQs)[]) => {
    this.queryKey = [...query];
  };

  setCurrentPageCrumb = ({ title, path }: { title: string; path: string }) => {
    this.currentContentPageCrumb = { title, path };
  };

  clearUser() {
    this.userInfo = null;
    this.accessToken = '';
    this.refreshToken = '';
    this.workspaceStore.clear();
    this.uiStore.clear();
    this.subscriptionStore.clear();
    this.queryKey = [];
    this.setInitializedLayout(false);
  }

  async initLayout() {
    const workspaces = await getWorkspaces();

    runInAction(() => {
      this.workspaceStore.updateWorkspaces(workspaces);

      if (this.ltiMode) {
        const currentWorkspace = workspaces.find(wp => wp.id === this.ltiStore.workspaceId);
        this.workspaceStore.updateCurrentWorkspace(currentWorkspace);
      } else if (!workspaces.some(wp => wp.id === this.workspaceStore.currentWorkspaceId)) {
        {
          this.workspaceStore.updateCurrentWorkspace(workspaces[0]);
        }
      }
    });

    if (workspaces.length) {
      if (!this.subscriptionStore.plans?.length) {
        const subscriptionPlans = await fetchAllPlans();
        runInAction(() => {
          this.subscriptionStore.setPlans(subscriptionPlans);
        });
      }

      const subscription = await getCurrentSubscription();
      runInAction(() => {
        this.subscriptionStore.updateCurrentSubscription(subscription);
      });
    }

    const user = await me();
    const settings = await fetchOptionSettings();

    runInAction(() => {
      this.updateUserInfo(user);
      this.workspaceStore.updateSetting(settings);
      this.initializedLayout = true;
    });
  }

  setInitializedLayout(value: boolean) {
    this.initializedLayout = value;
  }
}

export const appStore = new AppStore();

export const AppStoreContext = React.createContext(appStore);
