import { SubscriptionInfo, SubscriptionStatus } from '@spiderbox/common';
import { syncCurrentSubscription } from 'apis/Payment/Subscription';
import { Loading } from 'components';
import React from 'react';
import { useMutation } from 'react-query';
import { Navigate, useSearchParams } from 'react-router-dom';

export const SyncSubscriptionPage = () => {
  const [params] = useSearchParams();
  const { mutate, data: subscription } = useMutation<SubscriptionInfo>(syncCurrentSubscription);

  if (params.get('session_id') && params.get('success') === 'true') {
    mutate();

    if (subscription?.status === SubscriptionStatus.ACTIVE || subscription?.status === SubscriptionStatus.TRIALING) {
      return <Navigate to="/dashboard" />;
    }
  }

  return <Loading />;
};
