import { useMemo } from 'react';
import { MasterAdminLibraryQuery } from 'types/master-admin-library';
import { URLSearchParams } from 'url';

export const useSearchTermMasterAdmin = (
  searchParams: URLSearchParams,
  setKeyword: React.Dispatch<React.SetStateAction<string>>,
) => {
  return useMemo(() => {
    const queries = Object.fromEntries(searchParams.entries()) as MasterAdminLibraryQuery;
    setKeyword(queries.search?.trim() || '');
    return queries;
  }, [searchParams]);
};
