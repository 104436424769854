import React from 'react';
import { findTrash } from 'apis/Folder/Folder';
import { ContentListLayout } from '../components/ContentListLayout';

export const TrashPage = () => {
  const tableProps = {
    emptyDataTitle: 'Trash is empty',
    titleColumnClassName: 'w-75',
    allowEditMode: false,
    showIconStatusMobile: false,
    allowRedirectToContent: false,
  };

  return (
    <ContentListLayout
      pageTitle="Trash"
      queryKey={['folders', 'trash']}
      findContents={findTrash}
      showEmptyTrashButton
      tableProps={tableProps}
      crumbRootData={{ title: '', path: '' }}
    />
  );
};
