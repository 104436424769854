import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { omitBy, isNil } from 'lodash';
import { TSelectOption } from 'models/Option';
import { Option } from 'react-multi-select-component';

export const useContentFilterForm = (value, allLibraries, onAccept, onClear) => {
  const { control, watch, reset, setValue, getValues } = useForm({
    defaultValues: {
      kind: value?.kind,
      status: value?.status,
      libraries: [],
      starred: value?.starred === 'true',
      lastAttemptFrom: value?.lastAttemptFrom,
      lastAttemptTo: value?.lastAttemptTo,
    },
  });

  const statusWatch = watch('status');
  const kindWatch = watch('kind');
  const librariesWatch = watch('libraries');

  const onApplyFilter = () => {
    const data = getValues();

    data.libraries =
      data?.libraries?.length === allLibraries?.length
        ? []
        : (data.libraries = data?.libraries?.map(item => item.value));
    onAccept?.(omitBy(data, isNil));
  };

  const clearAll = () => {
    reset({
      kind: '',
      status: '',
      libraries: [],
      starred: false,
      lastAttemptFrom: null,
      lastAttemptTo: null,
    });
    onClear?.();
  };

  const selectTypeFilter = (value: string) => {
    if (value === 'Folder') {
      setValue('status', '');
      setValue('libraries', []);
    }
  };

  const removeLibraryItem = (item: TSelectOption) => {
    const itemIndex = librariesWatch.findIndex(library => library.value === item.value);
    const newLibraryFilterParams = [...librariesWatch];
    newLibraryFilterParams.splice(itemIndex, 1);
    setValue('libraries', newLibraryFilterParams);
  };

  const valueContainer = (selectedOptions: Option[], totalOptions: number) => {
    if (selectedOptions?.length === 1) return selectedOptions[0]?.label;

    return selectedOptions?.length > 0 && selectedOptions?.length < totalOptions
      ? 'Mixed Content Type'
      : 'All Content Type';
  };

  const libraryOptions: TSelectOption[] = useMemo(() => {
    return (
      allLibraries?.map(library => {
        return { value: library?.metadata.machineName, label: library?.title };
      }) || []
    );
  }, [allLibraries]);

  useEffect(() => {
    if (allLibraries) {
      if (value?.libraries) {
        const selectedLibraries = Object.values(value.libraries);
        const defaultLibraries = allLibraries.filter(
          library => selectedLibraries?.includes(library.metadata.machineName),
        );
        setValue(
          'libraries',
          defaultLibraries.map(item => ({
            value: item.metadata.machineName,
            label: item.title,
          })),
        );
      }
    }
  }, [allLibraries]);

  return {
    control,
    onApplyFilter,
    clearAll,
    selectTypeFilter,
    removeLibraryItem,
    valueContainer,
    libraryOptions,
    statusWatch,
    kindWatch,
    librariesWatch,
    setValue,
  };
};
