import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { useAppStore } from 'hooks/useAppStore';
import { Loading } from 'components/Loading';

// Good place to call APIs for data that require for the whole app.
export const AuthLayout = observer(({ children }: { children?: JSX.Element | any }) => {
  const appStore = useAppStore();

  useEffect(() => {
    if (!appStore.initializedLayout) {
      appStore.initLayout();
    }
  }, [appStore.initializedLayout]);

  if (appStore.initializedLayout) {
    return children ? children : <Outlet />;
  }

  return <Loading />;
});
