import React from 'react';
import LightBulbIcon from 'assets/images/ai-assistant/help-tips/light-bulb.svg';
import { ChevronDown, ChevronUp } from 'react-feather';
import { ReactSVG } from 'react-svg';
import { useHelpTipsContext } from 'components/AIOffset/components/HelpTips/HelpTipsProvider';

const HelpTipIndicator = () => {
  const { isOpen, toggleOpen } = useHelpTipsContext();

  const ChevronIcon = isOpen ? ChevronDown : ChevronUp;

  return (
    <div className="flex-shrink-0 d-flex justify-content-end">
      <a
        className="gap-2 mb-1 cursor-pointer d-flex align-items-center justify-content-end"
        onClick={toggleOpen}
      >
        <ReactSVG
          src={LightBulbIcon}
          width={16}
          height={16}
          className="flex-shrink-0 mb-1 text-neutral-400 d-flex"
          strokeWidth={1.5}
        />

        <div className="font-size-14 fw-medium text-neutral-400">{isOpen ? 'Hide tips' : 'What should I pick?'}</div>

        <ChevronIcon
          strokeWidth={1.5}
          width={20}
          height={20}
          className="flex-shrink-0 text-neutral-400 d-flex"
        />
      </a>
    </div>
  );
};

export default HelpTipIndicator;
