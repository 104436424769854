import { RowData, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'components/AppTable';
import React from 'react';
import { adminContentTypeColumns } from './Column';
import './AdminContentTypeTable.scss';
import { MasterAdminLibrary } from 'types/master-admin-library';
import { QueryClient, useQueryClient } from 'react-query';

/* eslint-disable @typescript-eslint/no-unused-vars */
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    queryClient: QueryClient;
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

interface Props {
  dataTable: MasterAdminLibrary[];
}

export const AdminContentTypeTable = ({ dataTable }: Props) => {
  const columns = React.useMemo(() => adminContentTypeColumns, []);
  const queryClient = useQueryClient();

  const table = useReactTable({
    data: dataTable,
    columns: columns,
    initialState: {
      pagination: {
        pageSize: 1000,
      },
    },
    meta: {
      queryClient,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="admin-content-type-table">
      <AppTable table={table} />
    </div>
  );
};
