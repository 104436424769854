import { ContentStatus } from 'models/Content';
import React from 'react';
import { Globe, FileMinus, Lock, Minus } from 'react-feather';

type IProps = {
  contentStatus: ContentStatus | '';
};

export const ContentStatusIcon = ({ contentStatus }: IProps) => {
  return contentStatus === 'Published' ? (
    <Globe
      size={20}
      className="gray-500"
    />
  ) : contentStatus === 'Inactive' ? (
    <Lock
      size={20}
      className="gray-500"
    />
  ) : contentStatus === 'Draft' ? (
    <FileMinus
      size={20}
      className="gray-500"
    />
  ) : (
    <Minus
      size={20}
      className="gray-500"
    />
  );
};
