import { MasterAdminLibrary } from 'types/master-admin-library';

export const filterLatestVersions = (libraries: MasterAdminLibrary[]): MasterAdminLibrary[] => {
  const latestVersions: { [key: string]: MasterAdminLibrary } = {};

  for (const library of libraries) {
    const key = library.metadata['machineName'];
    if (!latestVersions[key] || compareVersions(library.metadata, latestVersions[key].metadata) > 0) {
      latestVersions[key] = library;
    }
  }

  return Object.values(latestVersions);
};

export const compareVersions = (v1, v2) => {
  if (v1.majorVersion !== v2.majorVersion) {
    return v1.majorVersion - v2.majorVersion;
  }
  return v1.minorVersion - v2.minorVersion;
};

export const findDuplicateLibsByMachineName = (libs: MasterAdminLibrary[]): string[] => {
  const machineNameSet = new Set();
  const duplicateLibsByMachineName = new Set();

  (libs || []).forEach(lib => {
    const machineName = lib.metadata.machineName;
    if (machineNameSet.has(machineName)) {
      duplicateLibsByMachineName.add(machineName);
    } else {
      machineNameSet.add(machineName);
    }
  });

  return Array.from(duplicateLibsByMachineName) as string[];
};
