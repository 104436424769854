import React from 'react';
import './UserUploadPrompt.scss';
import { useAppStore } from 'hooks/useAppStore';
import { AnalyzeContentInputForm } from 'types';
import { removeUUIDFromFilename } from 'utils';
import { formatFileSize, formatFileType } from 'components/AIOffset/utils';

interface Props {
  chatId: string;
  importedDocuments: AnalyzeContentInputForm;
}

const UserUploadPrompt = ({ importedDocuments }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();

  const { fileUrls, webUrls, text } = importedDocuments;

  const handleExternalLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="user-upload-wrapper">
      <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
      <div className="user-upload-prompt d-flex flex-column gap-3 p-3 bg-primary-50">
        {text && <div className="font-size-14 fw-normal text-neutral-900">{text}</div>}
        {webUrls.length > 0 && (
          <div className="d-flex flex-column gap-2">
            {webUrls.map((url, index) => (
              <div key={index}>
                <div
                  className="file-name text-neutral-900 font-size-14 fw-normal text-decoration-underline cursor-pointer"
                  onClick={() => handleExternalLinkClick(url)}
                >
                  {url}
                </div>
              </div>
            ))}
          </div>
        )}
        {fileUrls.length > 0 && (
          <div className="text-neutral-900">
            <div className="d-flex flex-column gap-2">
              {fileUrls.map((file, index) => (
                <div key={index}>
                  <div className="list-file">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="file-name font-size-14 fw-normal cursor-pointer">
                        {removeUUIDFromFilename(file.name)}
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-row gap-2 font-size-12 fw-normal text-neutral-500">
                      <div>{formatFileType(file.contentType)}</div>
                      <div className="file-upload-divider"></div>
                      <div>{formatFileSize(file.fileSize)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserUploadPrompt;
