import React from 'react';
import { useHelpTipsContext } from 'components/AIOffset/components/HelpTips/HelpTipsProvider';
import { THelpTip } from 'models/HelpTip';
import HelpTipContent from './HelpTipContent';

type HelpTipItemProps = {
  helpTip: THelpTip;
  onNext: () => void;
  onPrev: () => void;
};

const HelpTipItem = ({ helpTip, onNext, onPrev }: HelpTipItemProps) => {
  const { isOpen } = useHelpTipsContext();

  if (!isOpen) return null;

  return (
    <HelpTipContent
      helpTip={helpTip}
      onNext={onNext}
      onPrev={onPrev}
    />
  );
};

export default HelpTipItem;
