import { useEffect, useState } from 'react';

export const useInterval = (callback: () => void, delay: number, stopCondition: boolean) => {
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (!isRunning) {
      return; // Stop the interval if it's not running
    }

    const intervalId = setInterval(() => {
      callback();

      if (stopCondition) {
        clearInterval(intervalId);
        setIsRunning(false);
      }
    }, delay);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount or stop
  }, [callback, delay, isRunning, stopCondition]);

  return { start: () => setIsRunning(true), stop: () => setIsRunning(false) };
};
