import React, { useEffect, useRef, useState } from 'react';
import './PaymentMethods.scss';
import { Plus } from 'react-feather';
import { useMutation, useQuery } from 'react-query';
import { createSetupIntent, getAllPaymentMethods } from 'apis/Payment/PaymentMethod';
import { Loading } from 'components';
import { PaymentMethodItem } from './PaymentMethodItem';
import useModal from 'hooks/useModal';
import { useMediaScreen } from 'hooks/useMediaScreen';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { SetupIntent } from '@stripe/stripe-js';
import { PaymentMethodData } from 'types/payment';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export const PaymentMethods = observer(() => {
  const {
    workspaceStore: { isWorkspaceOwner, currentWorkspaceId },
  } = useAppStore();
  const { openModal, closeModal } = useModal();
  const { isMdDown } = useMediaScreen();
  const { data = [], isLoading } = useQuery(
    ['workspace', currentWorkspaceId, 'cards'],
    async () => await getAllPaymentMethods(),
    {
      staleTime: 300000,
    },
  );
  const [selectedCard, setSelectedCard] = useState<PaymentMethodData & { index: number }>(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const paymentMethodsRef = useRef(null);
  const { mutateAsync: setupIntentPayment, isLoading: setupIntentLoading } =
    useMutation<SetupIntent>(createSetupIntent);

  const openAddPaymentMethodModal = async () => {
    const paymentIntent = await setupIntentPayment();

    openModal('add-payment-method', {
      onAccept: () => null,
      onCancel: () => {
        closeModal();
      },
      paymentIntent,
    });
  };

  const onSelectCard = (cardId: string) => {
    const cardIndex = paymentMethods?.findIndex(card => card?.id === cardId);

    const newSelectedCard = { ...paymentMethods[cardIndex] };

    setSelectedCard(newSelectedCard?.id === selectedCard?.id ? null : newSelectedCard);
  };

  useClickAway(paymentMethodsRef, () => {
    setSelectedCard(null);
  });

  useEffect(() => {
    setPaymentMethods(data?.map((x, index) => ({ ...x, index })) || []);
  }, [data]);

  if (!paymentMethods?.length && !isWorkspaceOwner) return <></>;

  return (
    <div className="d-flex flex-column flex-md-row flex-md-row-reverse align-items-center align-items-md-stretch payment-methods-wrapper">
      <div
        ref={paymentMethodsRef}
        className="d-flex flex-column flex-column-reverse flex-md-row flex-fill justify-content-center justify-content-md-start align-items-center card-element-list pb-3"
        data-cy="cards-list"
      >
        {isLoading ? (
          <Loading />
        ) : (
          paymentMethods?.map((card, index) => {
            return (
              <div
                className={classNames('card-element-wrapper', {
                  'not-selected':
                    isMdDown && selectedCard?.id !== card?.id && selectedCard !== null && index < selectedCard?.index,
                })}
                style={{
                  zIndex: paymentMethods?.length - index,
                }}
                key={index}
                onClick={() => onSelectCard(card?.id)}
              >
                <PaymentMethodItem
                  paymentMethodData={card}
                  selectedId={selectedCard?.id || null}
                  onSelectCard={onSelectCard}
                />
              </div>
            );
          })
        )}
      </div>

      {isWorkspaceOwner && (
        <div
          className="d-flex flex-row flex-md-column align-items-center justify-content-center add-card p-2 p-md-4 mb-3"
          style={{ cursor: setupIntentLoading ? 'wait' : 'pointer' }}
          onClick={() => openAddPaymentMethodModal()}
        >
          <div
            className="add-card-btn d-flex align-items-center justify-content-center"
            data-cy="add-card-btn"
          >
            <Plus
              size={16}
              className="primary-500 flex-fill"
            />
          </div>

          <div className="mt-2 mt-md-3 ms-2 ms-md-0 h5 font-size-12 font-size-md-14 gray-500">Add Payment Method</div>
        </div>
      )}
    </div>
  );
});
