import { ErrorResponse } from 'types';

export function fetchServerError(errorResponse: ErrorResponse) {
  if (errorResponse && errorResponse.errors) {
    const errorField = Object.keys(errorResponse.errors)[0];

    return errorResponse?.errors[errorField][0];
  }

  return errorResponse?.message || 'Something went wrong. Please try again later.';
}
