import React, { useState } from 'react';
import { ModalBody } from 'reactstrap';
import { ContentPermissionsInput, User } from 'types';
import { Footer } from '../Footer';
import { X } from 'react-feather';
import { useUpdatePermissions } from 'hooks';
import { SharingView } from '../SharingView';
import { useAppStore } from 'hooks/useAppStore';

type Props = {
  contentId: string;
  owner: User;
  title?: string;
  labelCancel?: string;
  labelOK?: string;
  onCancel?: () => void;
  onOK?: () => void;
  isShareMultipleFile?: boolean;
};

export const ShareWith = (props: Props) => {
  const {
    workspaceStore: { currentWorkspaceId: workspaceId },
  } = useAppStore();
  const { onOK, onCancel, labelCancel = 'Cancel', labelOK = 'Done', title, contentId, owner } = props;
  const [formValues, setFormValues] = useState<ContentPermissionsInput>();
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [shouldSendInvitation, setShouldSendInvitation] = useState<boolean>(false);
  const { mutateAsync, isLoading } = useUpdatePermissions();

  return (
    <div className="p-3">
      <ModalBody
        className="shareWith__body"
        data-cy="share-with-modal"
      >
        <div className="d-flex justify-content-between align-items-center w-100 gap-2 mb-3">
          <div className="d-flex gray-900 fw-semibold font-size-18 overflow-hidden">
            Share
            <div className="text-truncate">&nbsp; &quot;{title}</div>
            &quot;
          </div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>
        <SharingView
          onChange={setFormValues}
          onDirty={setIsDirtyForm}
          onShouldSendInvitation={setShouldSendInvitation}
          contentId={contentId}
          owner={owner}
        />
      </ModalBody>
      <div className="h-100 mt-3">
        <Footer
          labelCancel={labelCancel}
          labelOK={shouldSendInvitation ? 'Send' : labelOK}
          onOK={async () => {
            if (isDirtyForm) {
              await mutateAsync({ value: formValues, contentId, workspaceId, shouldSendInvitation });
            }
            onOK?.();
          }}
          onCancel={() => onCancel?.()}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
