import { updateWorkspace } from 'apis/Workspace/Workspace';
import { useAppStore } from 'hooks/useAppStore';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse, WorkspaceInfo } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateWorkspace = (setError?: UseFormSetError<WorkspaceInfo>) => {
  const { workspaceStore } = useAppStore();
  const queryClient = useQueryClient();

  return useMutation<WorkspaceInfo, ErrorResponse, WorkspaceInfo>({
    mutationFn: updateWorkspace,
    retry: false,
    onSuccess: (data: WorkspaceInfo) => {
      toast('Your changes have been saved.', { type: 'success' });
      workspaceStore.updateCurrentWorkspace(data);
      queryClient.invalidateQueries('workspaces');
    },
    onError: (error: ErrorResponse) => {
      Object.entries(error.errors).forEach(([key, value]) => {
        setError?.(key as any, { type: 'serverError', message: value[0] });
      });
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
