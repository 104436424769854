import { PageHeader } from 'components/PageHeader';
import React, { Fragment, useEffect } from 'react';
import { ConnectLMS } from './main/ConnectLMS';
import { ListConnectionName } from './main/ListConnectionName';
import useModal from 'hooks/useModal';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { useGetConnections } from 'hooks';
import { CreateConnection } from 'types';

export const IntegrationsPage = () => {
  const { openModal, closeModal } = useModal();

  const { isMdDown } = useMediaScreen();

  const { data, isLoading } = useGetConnections({});

  useEffect(() => {
    if (isMdDown) {
      openModal('warning', {
        title: 'Integration is not supported on mobile devices.',
        content: 'Please switch to desktop to create integration.',
        hasFooter: false,
        onCancel: () => closeModal(),
      });
    }
  }, [isMdDown]);

  const hasNewConnection = data?.some((connection: Partial<CreateConnection>) => connection.lms);

  usePrompt(
    !isLoading && hasNewConnection,
    'Are you sure you want to leave the integration progress?',
    'All data from the newly added connection will be lost unless you complete the integration process.',
  );

  return (
    <Fragment>
      <PageHeader
        title="Integrations"
        backTo="/organisation/users"
      />

      {isMdDown ? (
        <></>
      ) : (
        <div className="d-flex flex-column p-2 p-md-4 pt-0 pt-md-0">
          <div className="flex-fill d-flex flex-column py-4">
            <ConnectLMS />
            <ListConnectionName />
          </div>
        </div>
      )}
    </Fragment>
  );
};
