import React from 'react';
import { ChevronsRight } from 'react-feather';
import './SidebarCollapsedBtn.scss';
import { observer } from 'mobx-react-lite';
import { appStore } from 'stores/AppStore';

export const SidebarCollapsedBtn = observer(() => {
  const { uiStore } = appStore;

  const handleOnClick = () => {
    uiStore.toggleNav();
  };

  return (
    <button
      type="button"
      className="btn-sm p-1 mx-2 sidebar-collapsed-btn border-0 rounded-3"
      onClick={handleOnClick}
      data-cy="sidebar-collapsed-btn"
    >
      <ChevronsRight
        size={22}
        data-cy="sidebar-collapsed-icon"
      />
    </button>
  );
});
