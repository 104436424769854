import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronRight,
  Copy,
  Edit2,
  Folder,
  Globe,
  Lock,
  Radio,
  RefreshCw,
  Share2,
  Star,
  Trash2,
  Users,
  X,
} from 'react-feather';
import { ModalBody } from 'reactstrap';
import './ContentActions.scss';
import { ContentActions as ContentActionsType, ContentStatus, Document, TDocumentType } from 'models/Content';
import useModal from 'hooks/useModal';
import { RenameModalProps } from '../Rename/Rename';
import {
  useAddOrRemoveStarContent,
  useDuplicate,
  useChangeStatus,
  useMoveToTrash,
  useRestore,
  useDeletePermanently,
} from 'hooks';
import { ContentPermission } from 'components/ContentPermission';
import { EditPublishedContentPermission } from 'components/AIOffset/components/EditPublishedContentPermission';

export type ContentActionsModalProps = {
  content: Document;
  onCancel?: () => void;
};

export const ContentActions = (props: ContentActionsModalProps) => {
  const { content, onCancel } = props;
  const [statusOptionsExpanded, toggleStatusOptions] = useState(false);
  const { openModal, closeModal } = useModal();
  const { mutate: duplicate } = useDuplicate();

  const isTrashed = !!content.trashedAt;
  const hasSharing = !!content?.hasSharing;

  const openMoveFolderModal = () => {
    openModal('move-folder', {
      onAccept: () => closeModal(),
      onCancel: onBackToTopModal,
      content,
      openFromPopup: false,
    });
  };

  const statusOptions: ContentActionsType[] = [
    {
      icon: (
        <Globe
          size={20}
          className="gray-600"
        />
      ),
      label: 'Published',
      action: 'publish',
    },
    {
      icon: (
        <Lock
          size={20}
          className="gray-600"
        />
      ),
      label: 'Inactive',
      action: 'deactivate',
    },
  ];

  const { mutate: addOrRemoveStarContent } = useAddOrRemoveStarContent();
  const { mutate: changeStatus } = useChangeStatus(content);
  const { mutate: restore } = useRestore();
  const { mutate: moveToTrash } = useMoveToTrash();
  const { mutate: deletePermanently } = useDeletePermanently();

  const confirmAction = (action: string) => {
    openModal('warning', {
      title: `Are you sure you want to ${action} this content?`,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      titleIcon: (
        <Radio
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: () => {
        changeStatus({
          id: content.id,
          status: action === 'publish' ? ContentStatus.Published : ContentStatus.Inactive,
        });
        closeModal();
      },
      onCancel: onBackToTopModal,
    });
  };

  const duplicateAction = (contentId: string) => {
    openModal('duplicate-content', {
      title: `Do you want to duplicate this content?`,
      labelOK: 'Yes',
      labelCancel: 'Cancel',
      titleIcon: (
        <Copy
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: async () => {
        await duplicate(contentId);
        closeModal();
      },
      onCancel: () => onBackToTopModal(),
    });
  };

  const onRenameContent = () => {
    openModal('rename-content', {
      defaultValue: content.title,
      currentId: content.id,
      onCancel: () => onBackToTopModal(),
      onOk: () => closeModal(),
    } as RenameModalProps);
  };

  const openModalMoveFolderToTrash = () => {
    openModal('warning', {
      title: 'Are you sure you want to move this folder to Trash?',
      content: 'All items contained in this folder will be also moved to Trash.',
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      titleIcon: (
        <Trash2
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: () => {
        moveToTrash(content?.id);
        onBackToTopModal();
        closeModal();
      },
      onCancel: () => closeModal(),
    });
  };

  const onBackToTopModal = () => {
    closeModal();
    openModal('content-actions', {
      content,
      onCancel: () => closeModal(),
    });
  };

  const shareWithAction = () => {
    openModal('share-with', {
      title: content.title,
      owner: content.user,
      contentId: content.id,
      isShareMultipleFile: false,
      onCancel: onBackToTopModal,
      onOK: () => closeModal(),
    });
  };

  const onStarredContent = () => {
    addOrRemoveStarContent(content);
    closeModal();
  };

  const onMoveToTrash = () => {
    if (content.kind === 'Folder') {
      openModalMoveFolderToTrash();
    } else {
      moveToTrash(content?.id);
      closeModal();
    }
  };

  const onRestoreContent = () => {
    restore(content.id);
    closeModal();
  };

  const onDeletePermanently = () => {
    openModal('warning', {
      title: 'Permanently delete?',
      content: "This item will be permanently deleted and you won't be able to restore it.",
      titleIcon: (
        <Trash2
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: 'Delete',
      onCancel: () => onBackToTopModal(),
      onAccept: () => {
        deletePermanently(content.id);
        closeModal();
      },
    });
  };

  return (
    <div className="d-flex flex-column pt-2 pb-4 content-action-modal modal-wrapper">
      <ModalBody className="d-flex flex-column">
        <div className="modal-header d-flex justify-content-between align-items-center w-100">
          <div className="text-truncate w-100 d-flex gap-3 align-items-center">
            <div
              data-cy="content-title"
              className="gray-900 fw-semibold font-size-18 text-truncate overflow-hidden"
            >
              {content?.title}
            </div>
            {hasSharing && (
              <Users
                size={20}
                className="fw-semibold flex-shrink-0"
                color="#374151"
                data-cy="content-share-icon"
              />
            )}
          </div>

          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="d-flex flex-column justify-content-center px-3">
          {!isTrashed && (
            <>
              <ContentPermission
                role={content.contentRole}
                action="content.duplicate"
              >
                {content.kind === TDocumentType.Content && (
                  <EditPublishedContentPermission content={content}>
                    <div
                      data-cy="duplicate-content"
                      className="action-item d-none align-items-center"
                      onClick={() => duplicateAction(content.id)}
                    >
                      <Copy
                        size={20}
                        className="gray-600"
                      />
                      <span className="gray-600 ms-2">Duplicate</span>
                    </div>
                  </EditPublishedContentPermission>
                )}
              </ContentPermission>
              <ContentPermission
                role={content.contentRole}
                action="content.update"
              >
                <EditPublishedContentPermission content={content}>
                  <div
                    data-cy="rename-content"
                    className="action-item d-flex align-items-center"
                    onClick={onRenameContent}
                  >
                    <Edit2
                      size={20}
                      className="gray-600"
                    />
                    <span className="gray-600 ms-2">Rename</span>
                  </div>
                </EditPublishedContentPermission>
              </ContentPermission>
              <ContentPermission
                role={content.contentRole}
                action="content.addStar"
              >
                <div
                  data-cy="bookmark-content"
                  className="action-item d-flex align-items-center"
                  onClick={onStarredContent}
                >
                  <Star
                    size={20}
                    className="gray-600"
                  />
                  <span className="gray-600 ms-2">{content?.starred ? 'Remove star' : 'Add star'}</span>
                </div>
              </ContentPermission>

              <ContentPermission
                role={content.contentRole}
                action="content.moveToFolder"
              >
                <EditPublishedContentPermission content={content}>
                  <div
                    data-cy="move-content-to-folder"
                    className="action-item d-flex align-items-center justify-content-between"
                    onClick={() => openMoveFolderModal()}
                  >
                    <div className="d-flex align-items-center">
                      <Folder
                        size={20}
                        className="gray-600"
                      />
                      <span className="gray-600 ms-2">Move to folder</span>
                    </div>

                    <ChevronRight
                      size={20}
                      className="gray-600 me-0"
                    />
                  </div>
                </EditPublishedContentPermission>
              </ContentPermission>
              <ContentPermission
                role={content.contentRole}
                action="content.updatePermissions"
              >
                <EditPublishedContentPermission content={content}>
                  <div
                    data-cy="share-content"
                    className="action-item d-flex align-items-center justify-content-between"
                    onClick={shareWithAction}
                  >
                    <div className="d-flex align-items-center">
                      <Share2
                        size={20}
                        className="gray-600"
                      />
                      <span className="gray-600 ms-2">Share with</span>
                    </div>

                    <ChevronRight
                      size={20}
                      className="gray-600 me-0"
                    />
                  </div>
                </EditPublishedContentPermission>
              </ContentPermission>
              {content.kind === TDocumentType.Content && (
                <ContentPermission
                  role={content.contentRole}
                  action="content.update"
                >
                  <EditPublishedContentPermission content={content}>
                    <div
                      data-cy="change-content-status"
                      className="action-item d-flex align-items-center justify-content-between"
                      onClick={() => toggleStatusOptions(!statusOptionsExpanded)}
                    >
                      <div className="d-flex align-items-center">
                        <Radio
                          size={20}
                          className="gray-600"
                        />
                        <span className="gray-600 ms-2">Change status</span>
                      </div>

                      {statusOptionsExpanded ? (
                        <ChevronDown
                          size={20}
                          className="gray-600 me-0"
                        />
                      ) : (
                        <ChevronRight
                          size={20}
                          className="gray-600 me-0"
                        />
                      )}
                    </div>
                  </EditPublishedContentPermission>
                </ContentPermission>
              )}

              {statusOptionsExpanded && (
                <>
                  {statusOptions.map((item, key) => {
                    const isDisabled = () =>
                      (content.status === ContentStatus.Draft && item.action === 'deactivate') ||
                      (content.status === ContentStatus.Published && item.action === 'publish') ||
                      (content.status === ContentStatus.Inactive && item.action === 'deactivate');

                    return (
                      <div
                        key={key}
                        className={`action-item sub d-flex align-items-center item-status ${
                          isDisabled() ? 'disabled' : ''
                        }`}
                        onClick={!isDisabled() ? () => confirmAction(item.action) : null}
                      >
                        <span className="icon">{item.icon}</span>
                        <span className="gray-600 ms-2 label">{item.label}</span>
                      </div>
                    );
                  })}
                </>
              )}

              <ContentPermission
                role={content.contentRole}
                action="content.moveToTrash"
              >
                <EditPublishedContentPermission content={content}>
                  <div
                    data-cy="remove-content"
                    className="action-item d-flex align-items-center"
                    onClick={onMoveToTrash}
                  >
                    <Trash2
                      size={20}
                      className="gray-600"
                    />
                    <span className="ms-2">Move to trash</span>
                  </div>
                </EditPublishedContentPermission>
              </ContentPermission>
            </>
          )}

          {isTrashed && (
            <>
              <ContentPermission
                role={content.contentRole}
                action="content.restore"
              >
                <div
                  data-cy="restore-content"
                  className="action-item d-flex align-items-center"
                  onClick={onRestoreContent}
                >
                  <RefreshCw
                    size={20}
                    className="gray-600"
                  />
                  <span className="ms-2">Restore</span>
                </div>
              </ContentPermission>
              <ContentPermission
                role={content.contentRole}
                action="content.delete"
              >
                <div
                  data-cy="delete-content"
                  className="action-item d-flex align-items-center"
                  onClick={() => onDeletePermanently()}
                >
                  <Trash2
                    size={20}
                    className="gray-600"
                  />
                  <span className="ms-2">Delete</span>
                </div>
              </ContentPermission>
            </>
          )}
        </div>
      </ModalBody>
    </div>
  );
};
