import { removePendingUser } from 'apis/Workspace/Workspace';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useRemovePendingUser = () => {
  const queryClient = useQueryClient();

  return useMutation(removePendingUser, {
    onSuccess: () => {
      toast('Invitation has been cancelled.', { type: 'success' });
      queryClient.invalidateQueries();
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
