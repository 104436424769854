import classNames from 'classnames';
import { useContentEditor } from 'hooks/content/useContentEditor';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Save } from 'react-feather';
import { ContentActionStatus } from 'types';

export const SaveAsDraftButton = observer(() => {
  const { contentStore } = useAppStore();
  const { isSaving } = contentStore;
  const { saveAsync } = useContentEditor();

  return (
    <button
      className={classNames('btn btn-outline-primary d-flex justify-center me-3')}
      onClick={() => saveAsync(ContentActionStatus.DRAFT)}
      disabled={isSaving}
    >
      <Save size={20} />
      <span className="ms-2">Save Draft</span>
    </button>
  );
});
