import React from 'react';
import './LtiSettings.scss';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { Controller, useForm } from 'react-hook-form';
import { LtiSetting } from 'types/setting';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { useUpdateSetting } from 'hooks/setting';
import { useUserPermission } from 'hooks/useUserPermission';
import { Collapse } from 'reactstrap';
import { AppDropDown } from 'components/AppDropDown';
import { ContentAttemptSetting, LtiSettingOptions } from '@spiderbox/common';

const ltiSettingOptions: { value: LtiSettingOptions; label: string }[] = [
  { value: LtiSettingOptions.ALWAYS, label: 'Always' },
  { value: LtiSettingOptions.NEVER, label: 'Never' },
  { value: LtiSettingOptions.PER_CONTENT_ON_DEFAULT, label: 'Set per content - on by default' },
  { value: LtiSettingOptions.PER_CONTENT_OFF_DEFAULT, label: 'Set per content - off by default' },
];

const sendScoreForOptions: { value: ContentAttemptSetting; label: string }[] = [
  { value: ContentAttemptSetting.FIRST_ATTEMPT, label: 'First attempt' },
  { value: ContentAttemptSetting.LAST_ATTEMPT, label: 'Last attempt' },
  { value: ContentAttemptSetting.BEST_ATTEMPT, label: 'Best attempt' },
];

export const LtiSettings = observer(() => {
  const { workspaceStore } = useAppStore();

  const { setting } = workspaceStore;

  const { ltiSetting } = setting || {};

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<LtiSetting>({
    defaultValues: ltiSetting,
  });

  usePrompt(isDirty);

  const sendScoreToLms = watch('ltiSendScoreToLms');

  const { mutate, isLoading: updateSettingLoading } = useUpdateSetting();

  const hasPermission = useUserPermission(['organisation.editLTISetting']);

  const onSubmit = async (values: LtiSetting) => {
    mutate({
      ltiSetting: {
        ...values,
      },
    });

    reset({}, { keepValues: true });
  };

  return (
    <SettingsLayout
      handleSubmit={handleSubmit(onSubmit)}
      isLoading={updateSettingLoading}
    >
      <div className="lti-setting-layout bg-white">
        <div className="lti-setting-title d-flex flex-column px-3 px-md-4 py-3 gap-2">
          <div className="gray-900 font-size-16 fw-semibold">LTI Settings</div>
          <div className="gray-500">LTI behavioural settings</div>
        </div>

        <div className="d-flex flex-column gap-3 px-2 px-md-4 py-3">
          <div className="lti-setting-item py-3">
            <div className="px-3 px-md-4">
              <div className="gray-900 fw-medium h5 font-size-14">Send score to LMS</div>
              <div className="gray-500">
                Setting this to &apos;Never&apos; will affect content that has already been inserted into the LMS.
              </div>
              <div className="setting-dropdown mt-3">
                <Controller
                  control={control}
                  name="ltiSendScoreToLms"
                  render={({ field }) => (
                    <AppDropDown
                      disabled={!hasPermission}
                      items={ltiSettingOptions}
                      selected={field.value}
                      selectItemFunc={(item: any) => field.onChange(item)}
                      dataCy="send-score-to-lms-setting"
                    />
                  )}
                />
              </div>
            </div>

            <Collapse
              isOpen={[LtiSettingOptions.ALWAYS, LtiSettingOptions.PER_CONTENT_ON_DEFAULT].includes(sendScoreToLms)}
            >
              <div className="lti-setting-item__option px-3 px-md-4 pt-3 mt-3">
                <div className="gray-900 fw-medium h5 font-size-14">Send score for</div>
                <div className="gray-500">
                  <span className="fw-semibold">Important:</span> It is possible for computer savvy learners to cheat in
                  Evokio and always get full score. Evokio should not be used for exams. We are working on an exam mode.
                </div>
                <div className="setting-dropdown mt-3">
                  <Controller
                    control={control}
                    name="ltiSendScoreFor"
                    render={({ field }) => (
                      <AppDropDown
                        disabled={!hasPermission}
                        items={sendScoreForOptions}
                        selected={field.value}
                        selectItemFunc={(item: any) => field.onChange(item)}
                        dataCy="send-score-for-setting"
                      />
                    )}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
});
