import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  useLocation,
  UNSAFE_DataRouterStateContext as DataRouterStateContext,
  UNSAFE_NavigationContext as NavigationContext,
  LinkProps,
  useResolvedPath,
  Link,
  NavLink,
} from 'react-router-dom';
import { SubMenu } from '../SubMenu';
import { useClickAway, useMedia } from 'react-use';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { SubMenuType } from 'types/sidebar';

export interface MenuItemProps extends Omit<LinkProps, 'className' | 'style' | 'children'> {
  children?:
    | React.ReactNode
    | ((props: {
        isActive: boolean;
        isPending: boolean;
        setActive: (value: boolean) => void;
        isShowingSubMenu: boolean;
        setShowingSubMenu: (value: boolean) => void;
      }) => React.ReactNode);
  caseSensitive?: boolean;
  className?:
    | string
    | ((props: { isActive: boolean; isPending: boolean; isShowingSubMenu: boolean }) => string | undefined);
  end?: boolean;
  style?: React.CSSProperties | ((props: { isActive: boolean; isPending: boolean }) => React.CSSProperties | undefined);
  subMenu?: SubMenuType[];
  icon?: React.ReactNode;
  depth?: number;
}

export const MenuItem = observer(
  ({
    to,
    end,
    caseSensitive,
    style: styleProp,
    className: classNameProp,
    subMenu,
    icon,
    title,
    depth = 0,
  }: MenuItemProps) => {
    const path = useResolvedPath(to, { relative: 'path' });
    const location = useLocation();
    const routerState = React.useContext(DataRouterStateContext);
    const { navigator } = React.useContext(NavigationContext);
    const [isActive, setActive] = useState<boolean>(false);
    const [isShowingSubMenu, setShowingSubMenu] = useState<boolean>(false);
    const { uiStore } = useAppStore();
    const isLgDown = useMedia('(max-width: 992px)');

    const ref = useRef<HTMLLIElement>(null);

    // Close sub menu when click outside
    useClickAway(ref, () => {
      if (uiStore.isNavbarCollapsed) {
        setShowingSubMenu(false);
      }
    });

    // Close sub menu when collapse navbar or change route
    useEffect(() => {
      if (uiStore.isNavbarCollapsed) {
        setShowingSubMenu(false);
      }
    }, [uiStore.isNavbarCollapsed, location]);

    let toPathname = navigator.encodeLocation ? navigator.encodeLocation(path).pathname : path.pathname;
    let locationPathname = location.pathname;
    let nextLocationPathname =
      routerState && routerState.navigation && routerState.navigation.location
        ? routerState.navigation.location.pathname
        : null;

    if (!caseSensitive) {
      locationPathname = locationPathname.toLowerCase();
      nextLocationPathname = nextLocationPathname ? nextLocationPathname.toLowerCase() : null;
      toPathname = toPathname.toLowerCase();
    }

    useEffect(() => {
      setActive(
        locationPathname === toPathname ||
          (!end && locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/'),
      );
    }, [locationPathname, toPathname]);

    const isPending =
      nextLocationPathname != null &&
      (nextLocationPathname === toPathname ||
        (!end &&
          nextLocationPathname.startsWith(toPathname) &&
          nextLocationPathname.charAt(toPathname.length) === '/'));

    let className: string | undefined;
    if (typeof classNameProp === 'function') {
      className = classNameProp({ isActive, isPending, isShowingSubMenu });
    } else {
      className = classNames(
        classNameProp,
        { 'mm-active': isActive },
        { pending: isPending },
        { 'sub-menu-expanded': isShowingSubMenu },
      );
    }

    const style = typeof styleProp === 'function' ? styleProp({ isActive, isPending }) : styleProp;

    const hasSubMenu = subMenu?.length > 0;
    depth = depth + 1;

    const closeSidebarMenuIfNeeded = () => {
      if (isLgDown) {
        uiStore.toggleNav();
      }
    };

    return (
      <li
        className={className}
        style={style}
        ref={ref}
        data-cy="sidebar-menu-item"
      >
        {depth <= 1 && (
          <Link
            to={hasSubMenu ? null : to}
            className={classNames('waves-effect py-3 ps-3', { 'has-arrow': hasSubMenu })}
            onClick={e => {
              if (hasSubMenu) {
                e.preventDefault();
                setShowingSubMenu(!isShowingSubMenu);
              } else {
                closeSidebarMenuIfNeeded();
              }
            }}
          >
            {icon}
            <span className={uiStore.isNavbarCollapsed ? 'ms-4' : 'ms-2'}>{title}</span>
          </Link>
        )}
        {depth > 1 && (
          <NavLink
            to={to}
            onClick={() => {
              setShowingSubMenu(false);
              closeSidebarMenuIfNeeded();
            }}
          >
            <span className="mm-disc me-3 mb-1"></span> {title}
          </NavLink>
        )}

        {hasSubMenu && (
          <SubMenu
            subMenu={subMenu}
            isShowing={isShowingSubMenu}
            depth={depth}
          />
        )}
      </li>
    );
  },
);
