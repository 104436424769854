import React from 'react';
import { Edit2, Star, Users } from 'react-feather';
import classNames from 'classnames';
import { CellContext } from '@tanstack/react-table';
import { Document } from 'models/Content';
import './TitleCell.scss';
import Skeleton from 'react-loading-skeleton';
import { useAddOrRemoveStarContent, useGetOptionLibraries } from 'hooks';
import { ContentPermission } from 'components';
import { EditPublishedContentPermission } from 'components/AIOffset/components/EditPublishedContentPermission';

export type TitleCellProps = {
  allowEditMode?: boolean;
  onRenameContent?: (contentId: string, value: string) => void;
};

export const TitleCell = (props: TitleCellProps & CellContext<Document, Document>) => {
  const { row, allowEditMode = true, onRenameContent } = props;

  const { data: libraries, isLoading } = useGetOptionLibraries();

  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };

  const { mutate: addOrRemoveStarContent } = useAddOrRemoveStarContent();

  const onStarredContent = () => {
    addOrRemoveStarContent(row.original);
  };

  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="flex-fill w-1px d-flex justify-content-between">
        <div className="d-flex flex-fill overflow-hidden">
          <div className={classNames('overflow-hidden me-3 w-auto')}>
            <div className="shadow-none bg-transparent p-0 me-1 d-block text-truncate gray-700 fw-bold user-select-all">
              {row.original?.title}
            </div>
            {row.original?.library &&
              (isLoading ? (
                <Skeleton
                  width={100}
                  height={10}
                />
              ) : (
                <div className="gray-500 text-truncate title-original">{mappingTitle(row.original?.library)}</div>
              ))}
          </div>

          {allowEditMode && (
            <div
              className="title-cell--actions align-items-center flex-shrink-0"
              style={{ minWidth: 50 }}
            >
              {!!row.original?.hasSharing && (
                <Users
                  size={20}
                  color="#374151"
                  className="me-2"
                />
              )}
              <ContentPermission
                role={row.original.contentRole}
                action="content.update"
              >
                <EditPublishedContentPermission content={row.original}>
                  <Edit2
                    className="gray-500 me-2"
                    onClick={e => {
                      e.stopPropagation();
                      onRenameContent(row.original?.id, row.original?.title);
                    }}
                    size={20}
                  />
                </EditPublishedContentPermission>
              </ContentPermission>
              <ContentPermission
                role={row.original.contentRole}
                action="content.addStar"
              >
                <Star
                  className={classNames(row.original?.starred ? 'text-secondary-500' : 'gray-500')}
                  fill={row.original?.starred ? '#F7CE46' : 'none'}
                  size={20}
                  onClick={e => {
                    e.stopPropagation();
                    onStarredContent();
                  }}
                />
              </ContentPermission>
            </div>
          )}

          {(!!row.original?.starred || !!row.original?.hasSharing) && (
            <div
              className="title-cell--starred align-items-center gap-2 flex-shrink-0"
              style={{ minWidth: 25 }}
            >
              {!!row.original?.hasSharing && (
                <Users
                  size={20}
                  color="#374151"
                />
              )}

              {!!row.original?.starred && (
                <Star
                  className="text-secondary-500"
                  fill="#F7CE46"
                  size={20}
                />
              )}
            </div>
          )}

          {!allowEditMode && (!!row.original?.starred || !!row.original?.hasSharing) && (
            <div
              className="title-cell--actions align-items-center gap-2 flex-shrink-0"
              style={{ minWidth: 25 }}
            >
              {!!row.original?.hasSharing && (
                <Users
                  size={20}
                  color="#374151"
                />
              )}

              {!!row.original?.starred && (
                <Star
                  className="text-secondary-500"
                  fill="#F7CE46"
                  size={20}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
