import { Role, getActionsUserPermissionsByRole } from '@spiderbox/common';
import { createWorkspace } from 'apis/Workspace/Workspace';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { appStore } from 'stores/AppStore';
import { ErrorResponse, WorkspaceInfo } from 'types';
import { fetchServerError } from 'utils';

export const useCreateWorkspace = (onSuccess?: (data: WorkspaceInfo) => void, onError?: () => void) => {
  return useMutation(createWorkspace, {
    retry: false,
    onSuccess: async (data: WorkspaceInfo) => {
      appStore.workspaceStore.addWorkspace({ ...data, isOwner: true });
      appStore.updateUserInfo({
        ...appStore.userInfo,
        roles: [Role.ADMIN],
        rolesPermissions: getActionsUserPermissionsByRole([Role.ADMIN]),
      });
      toast('New workspace has been created successfully.', { type: 'success' });
      onSuccess?.(data);
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
      onError?.();
    },
  });
};
