import React, { useEffect, useMemo } from 'react';
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable, Row } from '@tanstack/react-table';
import { AppPagination, ErrorEmptyBlock } from 'components';
import { AppTable } from 'components/AppTable';
import { Document, TDocumentType } from 'models/Content';
import { AllContentsList } from '../AllContentsList';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { useQuery, useQueryClient } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import {
  actionColumn,
  insertContentColumn,
  lastModifiedColumn,
  ownerColumn,
  statusColumn,
  titleColumn,
  typeColumn,
} from 'pages/Content/components/Columns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { RenameModalProps } from 'components/ModalManager/Rename/Rename';
import { Trash2 } from 'react-feather';
import { useRestore } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import { appStore } from 'stores';
import classNames from 'classnames';

type IProps = {
  isLoading?: boolean;
  data: Document[];
  hideOwnerColumn?: boolean;
  onRename?: (value: string) => void;
  customColumns?: ColumnDef<Document>[];
  emptyDataTitle?: string;
  titleColumnClassName?: string;
  allowEditMode?: boolean;
  showIconStatusMobile?: boolean;
  allowRedirectToContent?: boolean;
  searchTerm?: any;
};

export const AllContentsTable = ({
  data,
  isLoading,
  hideOwnerColumn,
  customColumns = [],
  emptyDataTitle,
  titleColumnClassName = '',
  allowEditMode,
  showIconStatusMobile,
  allowRedirectToContent = true,
  searchTerm,
}: IProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { uiStore, ltiMode } = useAppStore();
  const { folderId } = useParams();
  const ltiColumns = ltiMode ? [insertContentColumn()] : [];
  const defaultCustomColumns: ColumnDef<Document>[] = [
    ownerColumn,
    lastModifiedColumn,
    statusColumn,
    ...ltiColumns,
    actionColumn(),
  ];
  const location = useLocation();

  customColumns = customColumns.length === 0 ? defaultCustomColumns : customColumns;

  const queryClient = useQueryClient();

  const { data: shouldDeselectContent } = useQuery<boolean>(['contents', 'de-select'], {
    cacheTime: 0,
    staleTime: 300000,
  });

  const onRenameContent = (contentId: string, value: string) => {
    openModal('rename-content', {
      defaultValue: value,
      currentId: contentId,
      onCancel: () => closeModal(),
      onOk: () => closeModal(),
    } as RenameModalProps);
  };

  const handleNotEditContent = () => {
    openModal('warning', {
      title: 'Edit Content is not supported on mobile devices',
      content: 'Please switch to desktop to edit content',
      hasFooter: false,
      onCancel: () => closeModal(),
    });
  };

  const defaultColumns = useMemo(() => {
    const columns = [
      typeColumn,
      titleColumn({ allowEditMode, className: titleColumnClassName, onRenameContent }),
      ...customColumns,
    ];

    const filters = [];
    if (hideOwnerColumn) filters.push('owner');
    if (ltiMode) filters.push('last_modified');

    return columns.filter(col => !filters.includes(col.id));
  }, [hideOwnerColumn, customColumns, allowEditMode, titleColumnClassName, ltiMode]);

  const columns: any = useMemo<ColumnDef<Document>[]>(
    () =>
      isLoading
        ? defaultColumns.map(column => {
            return {
              ...column,
              cell: () => <Skeleton />,
            };
          })
        : defaultColumns,
    [isLoading],
  );

  const defaultPageSize = useMediaScreen().isMdDown ? 25 : 10;

  const contentData = useMemo(() => {
    return isLoading ? Array(defaultPageSize).fill({}) : data;
  }, [isLoading, data]);

  const table = useReactTable({
    data: contentData,
    columns,
    initialState: {
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { mutate: restore } = useRestore();

  const openModalRestore = (document: Document) => {
    openModal('restore', {
      title: (
        <div>
          <b>{`${document.title}`}</b> is in Trash
        </div>
      ),
      content: 'To access this item, please restore it.',
      labelOK: 'Restore',
      labelCancel: 'Keep in Trash',
      titleIcon: (
        <Trash2
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: async () => {
        await restore(document.id);
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  useEffect(() => {
    const contents = table.getSelectedRowModel().flatRows.map(x => x.original);
    if (contents.length > 0) queryClient.setQueryData(['contents', 'de-select'], false);
    queryClient.setQueryData(
      ['contents', 'selected', 'ids'],
      contents.map(x => x.id),
    );
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    if (shouldDeselectContent) table.toggleAllRowsSelected(false);
  }, [shouldDeselectContent]);

  const handleRowClick = async (document: Document) => {
    if (isLoading) return;

    if (allowRedirectToContent) {
      document.kind === TDocumentType.Folder ? navigate(`/folders/${document.id}`) : handleNotEditContent();
    } else openModalRestore(document);
  };

  const onRowClick = async (row: Row<any>) => {
    if (isLoading) return;

    if (allowRedirectToContent) {
      let path = row.original.kind === TDocumentType.Content ? 'contents' : 'folders';

      if (!ltiMode) {
        path = '/' + path;
      }

      const params = [];
      const state: any = {};
      if (row.original.kind === TDocumentType.Content && folderId) {
        params.push(`folderId=${folderId}`);
      }
      if (row.original.kind === TDocumentType.Content) {
        state.from = location.pathname;
      }

      if (folderId) {
        if (row.original.kind === TDocumentType.Folder) {
          navigate(`../${row.original.id}`, {
            relative: 'path',
            state,
          });
        } else {
          navigate(`../../contents/${row.original.id}${!!params?.length ? `?${params.join('&')}` : ''}`, {
            relative: 'path',
            state,
          });
        }
        return;
      }

      navigate(`${path}/${row.original.id}${!!params?.length ? `?${params.join('&')}` : ''}`, {
        state,
      });
    } else {
      openModalRestore(row.original);
    }
  };

  useEffect(() => {
    uiStore.scrollToTop();
  }, [table?.getState().pagination.pageIndex]);

  useEffect(() => {
    const pageIndex = table.getState().pagination.pageIndex;
    if (table.getRowModel().rows.length === 0 && pageIndex > 0) table.setPageIndex(pageIndex - 1);
  }, [table.getRowModel().rows.length]);

  useEffect(() => {
    table.setPageIndex(0);
  }, [searchTerm]);

  return contentData?.length ? (
    <div
      data-cy="content-view"
      className="h-100 w-100 d-flex flex-column justify-content-between"
    >
      <div
        data-cy="content-view-desktop"
        className={classNames('d-none', { 'd-md-block': !ltiMode, 'd-sm-block': ltiMode })}
      >
        <AppTable
          onRowClick={onRowClick}
          table={table}
        />
      </div>

      {!appStore.ltiMode && (
        <div
          data-cy="content-view-mobile"
          className={classNames('d-block ', {
            'd-md-none': !ltiMode,
            'd-sm-none': ltiMode,
          })}
        >
          <AllContentsList
            isLoading={isLoading}
            data={table.getRowModel().rows.map(x => x.original)}
            showIconStatusMobile={showIconStatusMobile}
            onRowClick={handleRowClick}
          />
        </div>
      )}

      <div className="mt-4">
        <AppPagination
          isLoading={isLoading}
          totalRecords={data?.length}
          pageCount={table.getPageCount()}
          currentPageIndex={table.getState().pagination.pageIndex}
          setPageIndex={index => table.setPageIndex(index)}
          canNextPage={table.getCanNextPage()}
          canPreviousPage={table.getCanPreviousPage()}
          onNextPage={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
          onPreviousPage={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
        />
      </div>
    </div>
  ) : (
    <ErrorEmptyBlock title={emptyDataTitle} />
  );
};
