import { AiChatHistory } from 'types';
import moment from 'moment';

interface GroupedChats {
  [date: string]: AiChatHistory[];
}

export const groupChatsByDate = (chats: AiChatHistory[]): GroupedChats => {
  if (!chats) return {};

  return chats.reduce((groups: GroupedChats, chat) => {
    const date = moment(chat.createdAt).format('YYYY-MM-DD');

    if (!groups[date]) {
      groups[date] = [];
    }

    if (chat.name) {
      groups[date].push(chat);
    }

    return groups;
  }, {});
};
