import React from 'react';
import { ReactSVG } from 'react-svg';
import Svg from 'assets/images/payment/unionpay.svg';

type Props = {
  className?: string;
};

export const Unionpay = ({ className }: Props) => {
  return (
    <ReactSVG
      src={Svg}
      className={className}
    />
  );
};
