import { FieldErrors } from 'react-hook-form';
import { ErrorResponse } from 'types';

export function fetchControlError(
  formErrors: FieldErrors,
  serverError: ErrorResponse,
  control: string,
  isDirty: boolean,
): string {
  if (formErrors[control]?.message) return formErrors[control]?.message as string;
  return serverError?.errors && serverError?.errors[control] && !isDirty ? serverError?.errors[control][0] : '';
}
