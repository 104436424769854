import { getPreview } from 'apis/Content/Content';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation } from 'react-query';

export const useGetContentPreview = () => {
  const {
    contentStore: { loadContentPreviewAsync },
  } = useAppStore();

  const { isLoading } = useMutation({
    mutationFn: getPreview,
  });

  return {
    loadContentPreviewAsync,
    isLoading,
  };
};
