import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AppPagination, ErrorEmptyBlock, SortIcon, useUserModal } from 'components';
import { AppTable } from 'components/AppTable';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { Trash2 } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { User, UserRole, UserSortType, UserStatus } from 'types/user';
import { UserRoleCell } from '../Cell/UserRoleCell';
import { UserStatusBadge } from '../UserStatusBadge';
import { UsersList } from '../UsersList';
import { useUserPermission } from 'hooks/useUserPermission';
import { UserPermission } from 'components/UserPermission';

type IProps = {
  data: User[];
  isLoading: boolean;
  orderBy: (filter: UserSortType | '') => void;
  searchTerm?: any;
};

export const UsersTable = observer(({ data, isLoading, orderBy, searchTerm }: IProps) => {
  const { uiStore, userInfo } = useAppStore();
  const columnHelper = createColumnHelper<User>();
  const { openModalDeleteUser, openModalRemovePendingUser, openModalResendInvitation } = useUserModal();

  const defaultColumns = [
    columnHelper.display({
      id: 'user-info',
      header: () => (
        <div className="d-flex gap-1">
          <div className="align-items-center">Name</div>
          <div
            className="cursor-pointer"
            onClick={() => orderBy('fullName')}
          >
            <SortIcon />
          </div>
        </div>
      ),
      size: 375,
      meta: {
        className: 'ps-5',
      },
      cell: ({ row }) => {
        const fullName = row.original?.fullName;
        return (
          <div className="ps-4 d-flex align-items-center">
            <AvatarProfile
              width={32}
              height={32}
              imgSrc={row.original.profileImageUrl}
              userFullName={fullName || row.original.email}
            />

            <div className="ms-2 flex-fill w-1px">
              {fullName && <div className="text-truncate gray-900 fw-semibold text-capitalize">{fullName}</div>}
              <div className="text-truncate gray-900">{row.original.email}</div>
            </div>
          </div>
        );
      },
    }),

    columnHelper.accessor('roles', {
      id: 'roles',
      header: 'Roles',
      meta: {
        className: 'w-40',
      },
      cell: (info: CellContext<User, UserRole>) => {
        const user = info.row.original;
        return (
          <UserRoleCell
            user={user}
            disabled={user?.id === userInfo?.id}
          />
        );
      },
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      size: 100,
      cell: (info: CellContext<User, UserStatus>) => {
        return (
          <div className="d-flex">
            <UserStatusBadge status={info.getValue() || 'Pending'} />
          </div>
        );
      },
    }),

    columnHelper.display({
      id: 'status-actions',
      size: 175,
      cell: ({ row }) => {
        const user = row.original;

        if (user.status === 'Pending') {
          return (
            <div
              className="cursor-pointer gray-500 h6 font-size-14 mb-0"
              onClick={() => openModalResendInvitation(row.original)}
            >
              Resend Invitation
            </div>
          );
        }

        return <></>;
      },
    }),

    columnHelper.display({
      id: 'remove-user',
      size: 75,
      cell: ({ row }) => {
        const user = row.original;

        if (user.isOwner || user?.id === userInfo?.id) return <></>;

        return (
          <UserPermission actions={['user.remove']}>
            <div data-cy="btn-remove-user">
              <Trash2
                onClick={e => {
                  e.stopPropagation();
                  row.original?.id ? openModalDeleteUser(row.original) : openModalRemovePendingUser(row.original);
                }}
                size={24}
                className="gray-500 cursor-pointer"
              />
            </div>
          </UserPermission>
        );
      },
    }),
  ];

  const columns: any = useMemo<ColumnDef<User>[]>(
    () =>
      isLoading
        ? defaultColumns.map(column => {
            return {
              ...column,
              cell: () => <Skeleton />,
            };
          })
        : defaultColumns,
    [isLoading],
  );

  const defaultPageSize = useMediaScreen().isMdDown ? 25 : 10;

  const usersData = useMemo(() => {
    return isLoading ? Array(defaultPageSize).fill({}) : data;
  }, [isLoading, data]);

  const table = useReactTable({
    data: usersData,
    columns,
    initialState: {
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const navigate = useNavigate();

  const hasPermission = useUserPermission(['user.manageRolePermission']);

  useEffect(() => {
    uiStore.scrollToTop();
  }, [table?.getState().pagination.pageIndex]);

  useEffect(() => {
    const pageIndex = table.getState().pagination.pageIndex;
    if (table.getRowModel().rows.length === 0 && pageIndex > 0) table.setPageIndex(pageIndex - 1);
  }, [table.getRowModel().rows.length]);

  useEffect(() => {
    table.setPageIndex(0);
  }, [searchTerm]);

  if (!usersData?.length) return <ErrorEmptyBlock />;

  return (
    <div
      data-cy="users-view"
      className="h-100 w-100 d-flex flex-column justify-content-between"
    >
      <div
        data-cy="users-view-desktop"
        className="d-none d-md-block"
      >
        <AppTable
          table={table}
          onRowClick={row => {
            if (row.original.status === 'Pending' || row.original.isOwner || !hasPermission) return;
            navigate(row.original.id);
          }}
          trClassName={row =>
            (row.original as unknown as User).isOwner ||
            (row.original as unknown as User).status === 'Pending' ||
            !hasPermission
              ? 'bg-transparent cursor-default'
              : ''
          }
        />
      </div>

      <div
        data-cy="users-view-mobile"
        className="d-block d-md-none"
      >
        <UsersList
          isLoading={isLoading}
          data={table.getRowModel().rows.map(x => x.original)}
        />
      </div>

      <div className="mt-4">
        <AppPagination
          isLoading={isLoading}
          totalRecords={data?.length}
          pageCount={table.getPageCount()}
          currentPageIndex={table.getState().pagination.pageIndex}
          setPageIndex={index => table.setPageIndex(index)}
          canNextPage={table.getCanNextPage()}
          canPreviousPage={table.getCanPreviousPage()}
          onNextPage={() => table.setPageIndex(table.getState().pagination.pageIndex + 1)}
          onPreviousPage={() => table.setPageIndex(table.getState().pagination.pageIndex - 1)}
        />
      </div>
    </div>
  );
});
