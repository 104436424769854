import { useUserPermission } from 'hooks/useUserPermission';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  actions: string[];
  children: JSX.Element;
  renderHasNotPermission?: JSX.Element;
}

export const UserPermission: React.FC<Props> = observer(({ actions, children, renderHasNotPermission = <></> }) => {
  const hasPermission = useUserPermission(actions);

  return hasPermission ? children : renderHasNotPermission;
});
