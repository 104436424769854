import { getCheckoutSession } from 'apis/Payment/Subscription';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useSubscriptionCheckout = () => {
  return useMutation(getCheckoutSession, {
    onSuccess: (data: any) => {
      window.location.assign(data?.url);
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
