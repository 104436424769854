import { SidebarCollapsedBtn } from 'components/VerticalLayout/SidebarCollapsedBtn';
import React from 'react';
import './PageHeader.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PastDueSubscriptionBanner } from 'components/PastDueSubscriptionBanner';
import IconLogo from 'assets/icons/IconLogo';

interface Props {
  title: string;
  rightElement?: JSX.Element | JSX.Element[];
  subHeader?: JSX.Element | JSX.Element[];
  hasBackButton?: boolean;
  customTitle?: JSX.Element | string;
  backTo?: string;
}

export const PageHeader: React.FC<Props> = observer(
  ({ title, rightElement = null, subHeader = null, hasBackButton = false, customTitle, backTo = '' }) => {
    const navigate = useNavigate();
    const { isMdDown } = useMediaScreen();
    const { ltiMode } = useAppStore();

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div
          className="page-header sticky-top"
          data-cy="page-header"
        >
          <div className="d-flex justify-content-between border-bottom border-gray-100 bg-white">
            {!ltiMode && (
              <Link
                className="d-block d-lg-none d-flex justify-content-center align-items-center page-header__logo"
                to="/dashboard"
              >
                <div className="navbar-brand-box__header border-end border-gray-100 py-3 d-flex justify-content-center">
                  <IconLogo />
                </div>
              </Link>
            )}
            <div className="flex-fill d-flex justify-content-between align-items-center flex-grow-1 p-2 p-lg-3 page-header__main">
              <div
                className={classNames(
                  'flex-fill d-flex flex-wrap justify-content-start align-items-center text-truncate',
                )}
              >
                {!ltiMode && <SidebarCollapsedBtn />}
                {hasBackButton && !isMdDown && (
                  <>
                    <button
                      onClick={() => (backTo ? navigate(backTo) : navigate(-1))}
                      type="button"
                      className="btn btn-link  page-header__back"
                    >
                      <ArrowLeft
                        size={20}
                        className="me-1 align-top"
                      />
                      Back
                    </button>
                    <div className="mx-2 page-header__divider"></div>
                  </>
                )}

                {title && (
                  <div
                    className="flex-fill w-1px fw-bolder text-truncate ms-2 pe-3 d-block page-header__title"
                    data-cy="page-header__title"
                  >
                    {customTitle ? customTitle : title}
                  </div>
                )}
              </div>

              {rightElement && (
                <div
                  className="d-flex justify-content-end align-items-center page-header__right-element"
                  data-cy="page-header__right-element"
                >
                  {rightElement}
                </div>
              )}
            </div>
          </div>
          <PastDueSubscriptionBanner />
          {subHeader && <div className="sub-header">{subHeader}</div>}
        </div>
      </>
    );
  },
);
