import React, { useMemo, useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Edit3, Folder, Trash2 } from 'react-feather';
import { Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { Document } from 'models/Content';
import { parse } from 'qs';
import { calculateTotalFilterParams } from 'utils/calculateTotalFilterParams';
import { useAppStore } from 'hooks/useAppStore';
import { useContentSearchParams, useGetBreadcrumb, useGetContentRole, useRestore } from 'hooks';
import {
  useContentModal,
  useFolderModal,
  useFilterModal,
  ContentPermission,
  useEmptyTrashModal,
  MichiAssistantBtn,
} from 'components';
import { AllContentsTable } from 'pages/Content/AllContentsPage/AllContentsTable';
import { SubHeader } from '../SubHeader';
import { permanentlyDeleteColumn, removedAtColumn, restoreColumn } from '../Columns';
import classNames from 'classnames';
import featureFlagService from 'services/feature-flag.service';
import AIOffset from 'components/AIOffset/AIOffset';

type ContentListLayoutProps = {
  pageTitle?: string;
  queryKey: string[];
  findContents: (searchTerm: any, folderId?: string) => Promise<Document[]>;
  crumbRootData?: { title: string; path: string };
  hideOwnerColumn?: boolean;
  showCreateContentButton?: boolean;
  showEmptyTrashButton?: boolean;
  folderId?: string;
  tableProps?: object;
};
export const ContentListLayout = (props: ContentListLayoutProps) => {
  const {
    pageTitle,
    queryKey,
    findContents,
    crumbRootData,
    hideOwnerColumn = false,
    showCreateContentButton = false,
    showEmptyTrashButton = false,
    folderId = null,
    tableProps = {},
  } = props;
  const { setQueryKey, ltiMode } = useAppStore();
  const { filters, openFilterModal } = useFilterModal();
  const { openNewFolderModal } = useFolderModal();
  const { openNewContentModal } = useContentModal();
  const { searchParams, search, setSearch } = useContentSearchParams({ filters });
  const { openEmptyTrashModal } = useEmptyTrashModal();
  const { mutate: restore } = useRestore();
  const { openPermanentlyDeleteContentModal } = useContentModal();

  const searchTerm = useMemo(() => {
    const search = parse(searchParams.toString());
    setQueryKey([...queryKey, search]);
    return search;
  }, [searchParams, folderId]);

  const filterCount = useMemo(() => calculateTotalFilterParams(searchTerm), [searchTerm]);

  const { crumbs } = useGetBreadcrumb({
    folderId,
    root: crumbRootData,
    isSearch: filterCount > 0 || !!search,
  });

  const { contentRole } = useGetContentRole(folderId);

  const { data: documents, isFetching } = useQuery<Document[]>(
    [...queryKey, searchTerm],
    async () => await findContents(searchTerm, folderId),
    {
      cacheTime: 0,
      staleTime: 300000,
    },
  );

  const trashPageColumns = [
    removedAtColumn,
    restoreColumn({ onRestore: restore }),
    permanentlyDeleteColumn({ onDelete: openPermanentlyDeleteContentModal }),
  ];

  const contentTableProps = {
    ...tableProps,
    customColumns: showEmptyTrashButton ? trashPageColumns : [],
  };

  const renderContentsData = () => {
    return (
      <AllContentsTable
        isLoading={isFetching}
        data={documents}
        hideOwnerColumn={hideOwnerColumn}
        {...contentTableProps}
        searchTerm={searchTerm}
      />
    );
  };

  const [isAIEnabled, setIsAIEnabled] = useState(false);

  useEffect(() => {
    const aiFeature = featureFlagService.isFeatureEnabled('ai_assistant');
    setIsAIEnabled(aiFeature);
  }, []);

  return (
    <>
      <PageHeader
        title={showEmptyTrashButton ? 'Trash' : crumbs[crumbs.length - 1].title}
        customTitle={pageTitle}
        rightElement={
          <div className="d-flex align-items-center">
            {!showEmptyTrashButton && isAIEnabled && (
              <div className="me-3">
                <MichiAssistantBtn />
              </div>
            )}
            {showCreateContentButton && (
              <ContentPermission
                role={contentRole}
                action="content.create"
              >
                <div className="d-flex align-items-center">
                  <Button
                    className="d-flex align-items-center me-2"
                    outline
                    color="primary"
                    onClick={() => openNewFolderModal(folderId)}
                  >
                    <Folder
                      className="d-none d-sm-block me-2"
                      strokeWidth={1.5}
                      size={20}
                      type="outline"
                    />
                    <span>New Folder</span>
                  </Button>

                  <Button
                    className={classNames('d-none align-items-center', {
                      'd-md-flex': !ltiMode,
                      'd-sm-flex': ltiMode,
                    })}
                    color="primary"
                    onClick={openNewContentModal}
                  >
                    <Edit3
                      className="d-none d-sm-block me-2"
                      strokeWidth={1.5}
                      size={20}
                      type="outline"
                    />
                    <span>New Content</span>
                  </Button>
                </div>
              </ContentPermission>
            )}
            {showEmptyTrashButton && (
              <div className="d-flex align-items-center">
                <Button
                  className="d-flex align-items-center me-2"
                  outline
                  color="primary"
                  onClick={openEmptyTrashModal}
                >
                  <Trash2
                    className="d-none d-sm-block me-2"
                    strokeWidth={1.5}
                    size={20}
                    type="outline"
                  />
                  <span>Empty Trash</span>
                </Button>
              </div>
            )}
          </div>
        }
        subHeader={
          <SubHeader
            filterCount={filterCount}
            crumbs={!showEmptyTrashButton && crumbs}
            onOpenFilterModal={() => openFilterModal(searchTerm)}
            search={search}
            onSearch={setSearch}
            crumbTitle={showEmptyTrashButton && 'You can always restore your folders and contents from trash.'}
          />
        }
      />

      <div
        className={classNames('flex-fill d-flex flex-column p-3 mt-3', {
          'p-sm-4 pt-sm-0': ltiMode,
          'p-md-4 pt-md-0': !ltiMode,
        })}
      >
        <div className="flex-fill">{renderContentsData()}</div>
        {ltiMode && <AIOffset />}
      </div>
    </>
  );
};
