import { MachineName } from './machine-content.enum';

export const ContentSupportedList = [
  MachineName.H5P_Blanks,
  MachineName.H5P_DragText,
  MachineName.H5P_DragQuestion,
  MachineName.H5P_MarkTheWords,
  MachineName.H5P_TrueFalse,
  MachineName.H5P_Crossword,
  MachineName.H5P_MultiMediaChoice,
  MachineName.H5P_MultiChoice,
  MachineName.H5P_Summary,
  MachineName.H5P_SingleChoiceSet,
  MachineName.H5P_SortParagraphs,
];

export const ContentSetSupportedList = [
  MachineName.H5P_QuestionSet,
  MachineName.H5P_Column,
  MachineName.H5P_CoursePresentation,
  MachineName.H5P_InteractiveVideo,
  MachineName.H5P_InteractiveBook,
];
