import classNames from 'classnames';
import { TViewMode } from 'models/Content';
import React from 'react';
import './LibraryViewsMode.scss';

interface Props {
  viewMode: TViewMode;
  handleViewsModeChange: (mode: TViewMode) => void;
}

export const LibraryViewMode: React.FC<Props> = ({ viewMode, handleViewsModeChange }) => {
  return (
    <div
      className="library__view-modes"
      data-cy="view-mode-buttons"
    >
      <button
        onClick={() => handleViewsModeChange('gallery')}
        className={classNames('btn rounded-pill border-0 library__view-modes__btn', { active: viewMode === 'gallery' })}
        data-cy="library__view-modes__gallery"
      >
        <span>Gallery</span>
      </button>
      <button
        color="primary"
        onClick={() => handleViewsModeChange('list')}
        className={classNames('btn rounded-pill border-0 library__view-modes__btn', { active: viewMode === 'list' })}
        data-cy="library__view-modes__list"
      >
        <span>List</span>
      </button>
    </div>
  );
};
