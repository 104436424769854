import React from 'react';
import './PlanInformation.scss';
import { SubscriptionCard } from './components/SubscriptionCard';

export const PlanInformation = () => {
  return (
    <div className="plan-information-layout bg-white">
      <div className="plan-information-title px-3 px-md-4 py-3">
        <div className="gray-900 font-size-16 fw-semibold">Plan Details</div>
      </div>

      <div className="plan-information-item px-3 px-md-4 py-3">
        <SubscriptionCard />
      </div>
    </div>
  );
};
