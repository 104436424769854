import React from 'react';
import { User } from 'types';
import { useUserModal } from '../hooks';
import { ModalBody } from 'reactstrap';
import { Copy, Trash2, X } from 'react-feather';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import './UserActions.scss';
import { UserPermission } from 'components/UserPermission';
import { observer } from 'mobx-react-lite';

export type UserActionsModalProps = {
  user: User;
  onCancel?: () => void;
};

export const UserActions = observer((props: UserActionsModalProps) => {
  const { user, onCancel } = props;
  const { openModalDeleteUser, openModalRemovePendingUser, openModalResendInvitation } = useUserModal();

  return (
    <div className="d-flex flex-column py-4 user-action-modal modal-wrapper">
      <ModalBody className="d-flex flex-column">
        <div className="modal-header d-flex justify-content-between align-items-start w-100 pt-0">
          <div className="d-flex align-items-center gap-2 overflow-hidden">
            <AvatarProfile
              userFullName={user?.fullName || user?.email}
              imgSrc={user?.profileImageUrl}
              width={40}
              height={40}
              avatarClassNames="flex-shrink-0"
            />
            <div className="d-flex flex-column overflow-hidden">
              <div
                className="fw-bold gray-900 overflow-hidden text-truncate"
                data-cy="user-name"
              >
                {user?.fullName}
              </div>
              <div
                className="fw-normal gray-900 overflow-hidden text-truncate"
                data-cy="user-email"
              >
                {user?.email}
              </div>
            </div>
          </div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>
        <div className="d-flex flex-column justify-content-center px-3">
          {user.status === 'Pending' ? (
            <>
              <div
                className="action-item d-flex align-items-center"
                onClick={() => openModalResendInvitation(user)}
              >
                <Copy
                  data-cy="resend-invitation"
                  size={20}
                  className="gray-600"
                />
                <span className="ms-2 gray-600"> Resend Invitation</span>
              </div>
              <UserPermission actions={['user.remove']}>
                <div
                  className="action-item d-flex align-items-center"
                  onClick={() => openModalRemovePendingUser(user)}
                >
                  <Trash2
                    data-cy="remove-user"
                    size={20}
                    className="gray-600"
                  />
                  <span className="ms-2 gray-600">Remove from workspace</span>
                </div>
              </UserPermission>
            </>
          ) : (
            <UserPermission actions={['user.remove']}>
              <div
                className="action-item d-flex align-items-center"
                onClick={() => openModalDeleteUser(user)}
              >
                <Trash2
                  data-cy="remove-user"
                  size={20}
                  className="gray-600"
                />
                <span className="ms-2 gray-600">Remove from workspace</span>
              </div>
            </UserPermission>
          )}
        </div>
      </ModalBody>
    </div>
  );
});
