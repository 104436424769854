import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from 'components/AppForm/TextField';
import { DragDropUpload } from 'components/DragDropUpload';
import { PageHeader } from 'components/PageHeader';
import { useUpdateWorkspace } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import { Media } from 'models/Media';
import { useState } from 'react';
import { Save } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorResponse, WorkspaceInfo } from 'types';
import { fetchServerError } from 'utils';
import * as yup from 'yup';
import './WorkspaceSettingsPage.scss';
import { usePrompt } from 'hooks/usePrompt/usePrompt';
import { useUserPermission } from 'hooks/useUserPermission';
import { observer } from 'mobx-react-lite';
import { UserPermission } from 'components/UserPermission';

export const WorkspaceSettingsPage = observer(() => {
  const { workspaceStore } = useAppStore();
  const [uploadFileLoading, setUploadFileLoading] = useState<boolean>(false);
  const { currentWorkspace } = workspaceStore;
  const { id, name, imageUrl } = currentWorkspace || {};

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
    setError,
  } = useForm<WorkspaceInfo>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({ name: yup.string().trim().required('Workspace name is required.') }) as any,
    ),
    defaultValues: {
      id,
      name,
      imageUrl,
    },
  });

  const hasEditPermission = useUserPermission(['organisation.update']);

  const { mutate, isLoading } = useUpdateWorkspace(setError);

  const onSubmit = async (values: WorkspaceInfo) => {
    if (!hasEditPermission) return;

    mutate(values);

    reset({}, { keepValues: true });
  };

  const onError = async () => {
    toast(
      'Apologies, but there seems to be a validation error in your form submission. Please review and correct the necessary fields.',
      { type: 'warning' },
    );
  };

  const onUploadImgSuccess = (data: Media) => {
    setValue('imageUrl', data.url);
    setValue('imageId', data.id, { shouldDirty: true, shouldTouch: true });
  };

  const onUploadImgError = (error: ErrorResponse) => {
    Object.entries(error.errors).forEach(([key, value]) => {
      setError(key as any, { type: 'serverError', message: value[0] });
    });
    toast(fetchServerError(error), { type: 'error' });
  };

  usePrompt(isDirty);

  return (
    <form
      className="d-flex flex-column vh-100"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Helmet>
        <title>Workspace Settings</title>
      </Helmet>
      <PageHeader
        title="Workspace Settings"
        customTitle={
          <>
            Workspace <span className="d-none d-md-inline-block">Settings</span>
          </>
        }
        rightElement={
          <UserPermission actions={['organisation.update']}>
            <button
              className="btn btn-primary p-2 d-flex align-items-center"
              type="submit"
              data-cy="btn-save-changes"
              disabled={isLoading || uploadFileLoading}
            >
              <Save
                className="d-none d-md-block me-2"
                color="white"
                strokeWidth={1.5}
                height={20}
                width={20}
              />
              Save Changes
            </button>
          </UserPermission>
        }
      />
      <div className="flex-fill d-flex flex-column">
        <div className="flex-fill p-0 p-md-4">
          <div className="h-100 d-flex flex-column bg-white px-4 py-3 gap-5 workspace-settings-wrapper">
            <header
              className="text-medium fw-bold gray-900"
              data-cy="title"
            >
              Workspace Information
            </header>
            <div className="row">
              <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
                <span
                  className="gray-900 font-weight-medium"
                  data-cy="workspace-avatar-label"
                >
                  Workspace Avatar
                </span>
              </label>

              <DragDropUpload
                imageUrl={imageUrl}
                onUploadSuccess={data => onUploadImgSuccess(data)}
                onUploadError={error => onUploadImgError(error)}
                userFullName={name}
                setUploadLoading={loading => setUploadFileLoading(loading)}
                isPublic
                hasEditPermission={hasEditPermission}
              />
            </div>

            <div className="row">
              <label className="d-block col-12 col-md-4 mb-md-0 fw-bold">
                <span
                  className="gray-900 font-weight-medium"
                  data-cy="workspace-name-label"
                >
                  Workspace Name*
                </span>
              </label>

              <div className="col-12 col-md-8 col-xl-6">
                <div className="form-group mt-0 input-field">
                  <TextField
                    register={register('name')}
                    id="name"
                    placeholder="Workspace name"
                    error={!!errors.name}
                    dataCy="workspace-name-input"
                    disabled={!hasEditPermission}
                  />
                  <p
                    className="feedback-invalid mb-0"
                    data-cy="workspace-name-invalid"
                  >
                    {errors?.name?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
