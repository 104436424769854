import classNames from 'classnames';
import { useContentEditor } from 'hooks/content/useContentEditor';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Save } from 'react-feather';
import { ContentActionStatus } from 'types';

export const SaveButton = observer(({ className, status }: { className: string; status?: ContentActionStatus }) => {
  const { contentStore } = useAppStore();
  const { saveAsync } = useContentEditor();
  const { isSaving } = contentStore;

  return (
    <button
      className={classNames('btn d-flex justify-center me-3', className)}
      onClick={() => {
        saveAsync(status);
      }}
      disabled={isSaving}
    >
      <Save size={20} />
      <span className="ms-2">Save</span>
    </button>
  );
});
