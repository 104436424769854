import React, { useEffect, useState } from 'react';
import './NewContentBtn.scss';
import { Plus } from 'react-feather';
import { appStore } from 'stores/AppStore';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import useModal from 'hooks/useModal';
import { NewContentModalProps } from 'components/ModalManager/NewContent';
import { useParams } from 'react-router-dom';
import { hasContentPermission } from '@spiderbox/common';
import { findContentPermissions } from 'apis/Folder/Folder';

interface Props {
  isMobile?: boolean;
}

export const NewContentBtn: React.FC<Props> = observer(({ isMobile = false }) => {
  const { uiStore, ltiMode } = appStore;
  const { isNavbarCollapsed } = uiStore;
  const { openModal, closeModal } = useModal();
  const { folderId } = useParams();
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  useEffect(() => {
    if (!folderId) {
      setHasPermission(true);
    } else {
      findContentPermissions(folderId).then(({ permissions }) => {
        setHasPermission(hasContentPermission(permissions, 'content.create'));
      });
    }
  }, [folderId]);

  const handleNotCreateContent = () => {
    openModal('warning', {
      title: 'Create New Content is not supported on mobile devices',
      content: 'Please switch to desktop to create content',
      hasFooter: false,
      onCancel: () => closeModal(),
    });
  };

  const openNewContentModal = () => {
    if (isMobile) return;

    if (!hasPermission) {
      return;
    }

    openModal('new-content', {
      size: 'xl',
      className: classNames('modal-fullscreen-lg-down custom-new-content-modal', { 'modal-fullscreen': ltiMode }),
      scrollable: true,
      onCancel: closeModal,
    } as NewContentModalProps);
  };

  return (
    <>
      <button
        disabled={!hasPermission}
        onClick={openNewContentModal}
        className={classNames(
          'd-none d-sm-block btn btn-primary text-white d-flex justify-content-center align-items-center text-nowrap',
          isNavbarCollapsed ? 'btn-plus rounded-circle p-0 m-auto mb-2' : 'py-2 px-3 rounded text-center w-100',
        )}
        data-cy={`${isNavbarCollapsed ? 'btn-plus' : 'btn-new'}`}
      >
        {isNavbarCollapsed ? <Plus size={20} /> : 'New Content'}
      </button>
      <button
        className={classNames(
          'btn btn-primary btn-plus text-white rounded-circle d-flex justify-content-center align-items-center p-0 m-auto mb-2 d-block d-sm-none',
        )}
        disabled={!hasPermission}
        onClick={handleNotCreateContent}
      >
        <Plus size={20} />
      </button>
    </>
  );
});
