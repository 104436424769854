import { ContentQueryParams } from 'models/Content';

export function calculateTotalFilterParams(queryParams: ContentQueryParams): number {
  let total = 0;

  const excludedKeys = [
    'search',
    'timestamp',
    'from',
    'ltik',
    'lastAttemptFrom',
    'lastAttemptTo',
    'platform',
    'platformName',
    'user',
  ];

  Object.keys(queryParams).forEach(key => {
    if (excludedKeys.includes(key)) return;

    if (key === 'starred' && queryParams[key]?.toString() === 'true') total++;

    if (key !== 'starred' && Object.values(queryParams[key])?.length > 0) total++;
  });

  return total;
}
