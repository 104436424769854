import { updateAdditionalMetadata } from 'apis/MasterAdmin/Library/Library';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useUpdateAdditionalMetadata = () => {
  return useMutation(updateAdditionalMetadata, {
    onSuccess: () => {
      toast.success('Changes have been saved.');
    },
    onError: (error: ErrorResponse) => {
      toast.error(fetchServerError(error));
    },
  });
};
