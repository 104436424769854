import classNames from 'classnames';
import React, { Fragment, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  addonIcon?: JSX.Element;
  error?: FieldError | boolean;
  value?: any;
  ref?: any;
  register?: UseFormRegisterReturn<any>;
  dataCy?: string;
  isRequired?: boolean;
}

// eslint-disable-next-line react/display-name
const TextField: React.FC<Props> = forwardRef(
  (
    {
      label,
      error,
      value,
      maxLength,
      register,
      dataCy = 'text-field',
      isRequired = false,
      addonIcon = null,
      ...props
    }: Props,
    ref,
  ) => {
    const classesInput = classNames('form-control', {
      invalid: error,
    });

    return (
      <Fragment>
        {label && (
          <label className="mb-2 d-block gray-900">
            {label} {isRequired && <span>*</span>}
          </label>
        )}
        <div className="d-flex align-items-center position-relative w-100">
          <input
            ref={ref}
            className={classesInput}
            value={value}
            maxLength={maxLength && maxLength + 1}
            autoComplete="off"
            type="text"
            style={{ boxShadow: 'none' }}
            {...register}
            {...props}
            data-cy={dataCy}
          />

          {addonIcon && (
            <div className="position-absolute end-0 bottom-0 top-0 d-flex align-items-center">{addonIcon}</div>
          )}
        </div>
      </Fragment>
    );
  },
);

export default TextField;
