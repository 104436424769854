import React from 'react';
import './ContentDocumentationTool.scss';
import classNames from 'classnames';
import { ResultContent } from 'models/Content';
import { ContentDocumentationToolHelper } from './components/ContentDocumentationToolHelper';
import RateALot from 'assets/images/contents/Rate-Alot.svg';
import RateSomething from 'assets/images/contents/Rate-Something.svg';
import RateLittle from 'assets/images/contents/Rate-Little.svg';

type Props = {
  results: ResultContent[];
};

export const ContentDocumentationTool = ({ results }: Props) => {
  const GoalTable = (props: ResultContent) => {
    return (
      <>
        <div className="content-document-tool-table">
          <div
            className="mb-3 fw-bold"
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></div>
          <table className="w-100 mb-3">
            <thead>
              <tr>
                <th className="label-answer">Answer</th>
                <th className="label-learner-answer text-center">Learner&apos;s Answer</th>
              </tr>
            </thead>
            <tbody>
              {props?.indexes?.map((answer, index) => (
                <tr key={index}>
                  <td>{answer}</td>
                  <td className="text-center">
                    <div className={classNames('answer', `answer-${props?.learnerAnswers[index]}`)}>
                      {props?.learnerAnswers[index] + '' === '2' && (
                        <img
                          src={RateALot}
                          alt="Learned a lot"
                        />
                      )}
                      {props?.learnerAnswers[index] + '' === '1' && (
                        <img
                          src={RateSomething}
                          alt="Learned something"
                        />
                      )}
                      {props?.learnerAnswers[index] + '' === '0' && (
                        <img
                          src={RateLittle}
                          alt="Learned little"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ContentDocumentationToolHelper helpTexts={props.helpTexts} />
      </>
    );
  };

  const Label = (props: ResultContent) => {
    return (
      <div className="content-document-tool-table">
        <div
          className="mb-3 fw-bold"
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        ></div>
        <div className="mb-4">{props?.learnerAnswers}</div>
      </div>
    );
  };

  return (
    <div className="w-100 content-documentation-tool p-md-0 p-3">
      {results.map((result, index) => (
        <div
          key={'' + index}
          className={classNames('border bg-white border-neutral-200 rounded-3 p-3', {
            'mb-3': index !== results.length - 1,
          })}
        >
          {result.interactionType === 'choice' ? <GoalTable {...result} /> : <Label {...result} />}
        </div>
      ))}
    </div>
  );
};
