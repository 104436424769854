import { SignUpRequest } from 'models/SignUp';
import apiClient from '../ApiClient';
import { ErrorResponse, SignInFormInput, LoginResponse } from 'types';
import { AxiosResponse } from 'axios';

export async function login(payload: SignInFormInput): Promise<LoginResponse> {
  return await apiClient.post('auth/login', payload).then(response => {
    return response.data;
  });
}

export async function signUp(payload: SignUpRequest): Promise<LoginResponse | ErrorResponse> {
  return await apiClient.post('auth/signup', payload).then(response => {
    return response.data;
  });
}

export async function logout(fromAll: boolean = false): Promise<any | ErrorResponse> {
  return await apiClient.post('auth/logout', { fromAll });
}

export async function refresh(refreshToken: string): Promise<AxiosResponse> {
  return await apiClient.post('auth/refresh', { refreshToken });
}

export async function acceptInvitation(payload: SignUpRequest): Promise<LoginResponse | ErrorResponse> {
  return await apiClient.post('auth/invitation/accept', payload).then(response => {
    return response.data;
  });
}
