import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { User } from 'types/user';
import React from 'react';
import { UserStatusBadge } from '../UserStatusBadge';
import { MoreVertical } from 'react-feather';
import './UsersList.scss';
import { UserRoleCell } from '../Cell/UserRoleCell';
import Skeleton from 'react-loading-skeleton';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { useUserModal } from 'components';
import { useNavigate } from 'react-router-dom';
import { useUserPermission } from 'hooks/useUserPermission';

type IProps = {
  data: User[];
  isLoading: boolean;
};

export const UsersList = observer(({ data, isLoading }: IProps) => {
  const { userInfo } = useAppStore();

  const navigate = useNavigate();
  const { openUserActionsModal } = useUserModal();

  const hasPermission = useUserPermission(['user.manageRolePermission']);

  return (
    <div
      className="users-page bg-white"
      data-cy="users-list"
    >
      {data?.map((user, key) => {
        return (
          <div
            className="users-list__item gap-3 bg-white"
            key={key}
            data-cy={'user-item-' + key}
            onClick={() => {
              if (user.status === 'Pending' || user.isOwner || !hasPermission) return;
              navigate(user.id);
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="flex-fill d-flex align-items-center">
                {isLoading ? (
                  <Skeleton
                    height={32}
                    width={32}
                    circle
                  />
                ) : (
                  <AvatarProfile
                    width={32}
                    height={32}
                    imgSrc={user.profileImageUrl}
                    userFullName={user.fullName || user.email}
                  />
                )}

                <div className="ms-2 flex-fill w-1px">
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    user.fullName && (
                      <div className="text-truncate gray-900 fw-semibold text-capitalize">{user.fullName}</div>
                    )
                  )}

                  {isLoading ? <Skeleton /> : <div className="text-truncate gray-900">{user.email}</div>}
                </div>
              </div>

              {!isLoading && (
                <div className="d-flex me-0">
                  <div style={{ marginRight: user.isOwner || user?.id === userInfo?.id ? 28 : 8 }}>
                    <UserStatusBadge status={user.status} />
                  </div>

                  {user.isOwner || user?.id === userInfo?.id ? (
                    <></>
                  ) : (
                    <MoreVertical
                      size={20}
                      className="cursor-pointer gray-900"
                      onClick={e => {
                        e.stopPropagation();
                        openUserActionsModal(user);
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="mt-3">
              {isLoading ? (
                <Skeleton />
              ) : (
                <UserRoleCell
                  user={user}
                  disabled={user.id === userInfo?.id}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
});
