import React from 'react';
import { findMine } from 'apis/Folder/Folder';
import { useAppStore } from 'hooks/useAppStore';
import { ContentListLayout } from '../components/ContentListLayout';

export const MyContentPage = () => {
  const { setCurrentPageCrumb } = useAppStore();
  const crumbData = { title: 'My Content', path: '/contents/mine' };
  setCurrentPageCrumb(crumbData);

  return (
    <ContentListLayout
      pageTitle="My Content"
      queryKey={['folders', 'mine']}
      hideOwnerColumn
      findContents={findMine}
      crumbRootData={crumbData}
      showCreateContentButton
    />
  );
};
