import { Option } from 'components/ModalManager/ModalInviteUsers/common/RSInviteUsers';
import { MediaMinimal } from 'models/Media';
import { QueryParams } from 'types/common';
import { WorkspaceUser } from './workspace';
import { Role } from '@spiderbox/common';

export type User = {
  id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profileImageUrl?: string;
  status?: UserStatus;
  profileImage?: MediaMinimal;
  fullName?: string;
  roles?: UserRole;
  isOwner?: boolean;
  isMasterAdmin?: boolean;
};

export type ContentPermissionAccess = 'owner' | 'editor' | 'viewer' | string;

export type UserQueryParams = QueryParams & {
  status?: '' | UserStatus;
  sortBy?: UserSortType;
  sortOrder?: 'asc' | 'desc';
};

export type UserRole = Role[];

export type UserStatus = 'Active' | 'Pending';

export type TInviteUsers = {
  email: Option[];
  roles: Option[];
};

export type UserSortType = 'fullName' | 'email' | 'role' | 'status' | 'createdAt';

export const RoleTypeMapping = {
  [Role.ADMIN]: 'Admin',
  [Role.MEMBER]: 'Member',
};

export type UpdateUserRolesParams = {
  roles: UserRole;
  email: string;
  status: UserStatus;
};

export type UserDetail = User & WorkspaceUser;

export type RolePermission = {
  action: string;
  helper?: string;
  label: string;
  group?: boolean;
};

export type RolePermissionMapping = RolePermission & {
  active: boolean;
  disabled?: boolean;
};
