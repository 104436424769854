import { AppBreadCrumb } from 'components/AppBreadCrumb';
import useModal from 'hooks/useModal';
import React from 'react';
import { Copy, Folder, RefreshCcw, Star, Trash2, X } from 'react-feather';
import { useQuery, useQueryClient } from 'react-query';
import './MultipleSelectActions.scss';

export const MultipleSelectActions = ({
  crumbs,
  crumbTitle,
}: {
  crumbs?: { title: string; path: string }[];
  crumbTitle?: string;
}) => {
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();
  const { data: selected } = useQuery<string[]>(['contents', 'selected', 'ids'], {
    cacheTime: 300000,
    staleTime: 300000,
  });

  const openMoveFolderModal = () => {
    openModal('move-folder', {
      onAccept: () => {
        console.log('accept');
      },
      onCancel: () => closeModal(),
    });
  };

  const onDeselectContents = () => {
    queryClient.setQueryData(['contents', 'de-select'], true);
  };

  return (
    <div
      data-cy="selected-contents-actions"
      className="d-flex justify-content-between align-items-center multi-actions-wrapper"
    >
      {crumbTitle && !selected?.length && <div className="gray-900 fw-bold font-size-16">{crumbTitle}</div>}
      {selected?.length > 0 ? (
        <div className="d-flex align-items-center gap-4">
          <X
            data-cy="deselect-all-icon"
            onClick={onDeselectContents}
            className="cursor-pointer gray-500"
            size={24}
          />

          <div
            data-cy="total-selected"
            className="gray-900 fw-bold font-size-18"
          >
            {selected?.length} selected
          </div>

          <Copy
            data-cy="duplicate-button"
            className="cursor-pointer gray-500"
            size={24}
          />

          <Star
            data-cy="starred-button"
            className="cursor-pointer gray-500"
            size={24}
          />

          <Folder
            data-cy="move-to-folder-button"
            onClick={openMoveFolderModal}
            className="cursor-pointer gray-500"
            size={24}
          />

          <RefreshCcw
            data-cy="restore-button"
            className="cursor-pointer gray-500"
            size={24}
          />

          <Trash2
            data-cy="delete-button"
            className="cursor-pointer gray-500"
            size={24}
          />
        </div>
      ) : (
        <AppBreadCrumb crumbs={crumbs} />
      )}
    </div>
  );
};
