import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './Welcome.scss';
import { useAppStore } from 'hooks/useAppStore';
import { useCreateWorkspace } from 'hooks/workspace';
import { useNavigate } from 'react-router-dom';
import IconLogo from 'assets/icons/IconLogo';

export const WelcomePage = observer(() => {
  const [workSpace, setWorkSpace] = useState('');
  const { userInfo } = useAppStore();
  const navigate = useNavigate();

  const { isLoading, mutate } = useCreateWorkspace(() => {
    navigate('/subscription-plans', { replace: true });
  });

  return (
    <div className="welcome-wrapper vh-100 d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      {userInfo ? (
        <div className="container p-3 flex-fill w-1px d-flex flex-column justify-content-center align-items-center">
          <IconLogo
            isVariant={true}
            className="mb-3"
          />
          <div className="welcome__title mt-4 text-white mw-100 text-truncate">Welcome {userInfo.fullName}!</div>
          <div className="welcome__description mt-4 text-white">Please name your workspace to get started.</div>
          <div className="mt-4 welcome__input">
            <input
              className="bg-primary-500 text-white w-100"
              placeholder={`${userInfo.fullName}'s workspace`}
              type="text"
              name="workspace"
              autoComplete="off"
              autoFocus={true}
              onChange={event => setWorkSpace(event.target.value)}
              required
            />
          </div>
          <button
            className="btn btn-submit mt-4 bg-white"
            type="button"
            onClick={() => mutate(workSpace)}
            disabled={!workSpace || isLoading}
          >
            Go to Evokio
          </button>
        </div>
      ) : (
        <></>
      )}
      ;
    </div>
  );
});
