import classNames from 'classnames';
import React from 'react';
import { LogOut, User } from 'react-feather';
import { NavLink, Link } from 'react-router-dom';
import { useLogout } from 'hooks';

interface Props {
  toggleOpen: () => void;
}

export const WorkspaceMenu = ({ toggleOpen }: Props) => {
  const { mutate } = useLogout();

  const onLogout = () => {
    mutate(false);
  };

  return (
    <div className="mt-2 sidebar-profile__list">
      <ul className="metismenu list-unstyled">
        <li
          className="waves-effect d-inline-block px-2"
          data-cy="sidebar-profile__my-profile"
        >
          <NavLink
            className={({ isActive }) => {
              return classNames('d-flex align-items-center w-100 p-3 pointer-cursor rounded-3', {
                active: isActive,
              });
            }}
            to="/profile"
            onClick={() => toggleOpen()}
          >
            <User
              size={20}
              strokeWidth={2}
            />
            <span className="ms-2">My profile</span>
          </NavLink>
        </li>

        <li
          className="waves-effect d-inline-block px-2"
          data-cy="sidebar-profile__logout"
        >
          <Link
            className="d-flex align-items-center w-100 p-3 pointer-cursor rounded-3"
            to={null}
            onClick={() => onLogout()}
          >
            <LogOut
              size={20}
              strokeWidth={2}
            />
            <span className="ms-2">Log Out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
