import React from 'react';
import './UserRoleBadge.scss';
import classNames from 'classnames';
import { ChevronDown } from 'react-feather';

type IProps = {
  isOwner?: boolean;
  label: string | JSX.Element;
  isShowChevron?: boolean;
};
export const UserRoleBadge = ({ isOwner = false, label, isShowChevron = false }: IProps) => {
  return (
    <div
      className={classNames('user-role-badge w-auto d-flex align-items-center justify-content-between cursor-pointer', {
        isOwner,
        'bg-transparent cursor-default': !isShowChevron,
      })}
      data-cy="user-role-badge"
    >
      <div
        className="text-truncate"
        data-cy="user-role-label"
      >
        {label}
      </div>

      {isShowChevron && (
        <ChevronDown
          className="cursor-pointer gray-900 ms-2"
          strokeWidth={2}
          width={16}
          height={20}
          data-cy="chevron-down-icon"
        />
      )}
    </div>
  );
};
