import React, { useEffect } from 'react';
import { FolderPlus, X } from 'react-feather';
import { ModalBody } from 'reactstrap';
import { Footer } from '../Footer';
import { FolderView } from '../FolderView';
import { Document } from 'models/Content';
import { useMoveFolder } from 'hooks/folder/useMoveFolder';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { rootFolder } from 'stores/FolderStore';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
  title?: string;
  labelCancel?: string;
  labelOK?: string;
  onCancel?: () => void;
  onAccept?: () => void;
  content: Document;
  openFromPopup?: boolean;
};

export const MoveFolder = observer((props: Props) => {
  const { content, onCancel, onAccept } = props;
  const { folderStore } = useAppStore();
  const { isFolderCreating, folderSelected } = folderStore;
  const { addNewFolder, cancelNewFolder, moveToFolder, moveFolderLoading } = useMoveFolder(folderStore);
  const { folderId } = useParams();

  useEffect(() => {
    if (!folderSelected?.id) {
      folderStore.selectFolder(rootFolder);
    }
  }, []);

  return (
    <div
      className="d-flex flex-column p-3 move-folder-wrapper gap-3"
      data-cy="move-folder-modal"
    >
      <ModalBody className="d-flex flex-column gap-3">
        <div className="position-relative d-flex justify-content-start w-100">
          <div
            className="d-flex gray-900 fw-semibold font-size-18 overflow-hidden pe-5"
            data-cy="title-modal"
          >
            <span>Move</span>
            <div className="text-truncate">&nbsp; &quot;{content?.title}</div>
            <span className="flex-shrink-0">&quot; to folder</span>
          </div>
          <X
            onClick={() => {
              if (isFolderCreating) {
                cancelNewFolder();
              }
              onCancel?.();
            }}
            className="close-icon position-absolute top-0 end-0 gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>
      </ModalBody>
      <FolderView
        {...props}
        folderStore={folderStore}
      />
      <div className="d-flex flex-fill align-items-center justify-content-between">
        <button
          disabled={isFolderCreating || !folderSelected?.id}
          className="d-flex btn align-items-center justify-content-center move-folder-wrapper__icon p-2 border rounded-circle"
          data-cy="add-new-icon"
          onClick={() => addNewFolder()}
        >
          <FolderPlus
            size={20}
            className="primary-300"
            strokeWidth={1.5}
          />
        </button>

        {!isFolderCreating && (
          <Footer
            labelCancel="Cancel"
            labelOK="Move"
            isLoading={moveFolderLoading}
            disabledOK={!folderSelected?.id}
            onOK={() => {
              if (folderId && folderSelected?.id && folderSelected?.id === folderId) {
                toast(
                  <div>
                    Item has been moved to folder <span className="fw-bold">{folderSelected?.title}</span>
                  </div>,
                  { type: 'success' },
                );
                onAccept?.();
                return;
              }

              moveToFolder(
                { id: content.id, targetId: folderSelected.id },
                {
                  onSuccess: () => {
                    folderStore.selectFolder(null);
                    onAccept?.();
                  },
                },
              );
            }}
            onCancel={onCancel}
          />
        )}
      </div>
    </div>
  );
});
