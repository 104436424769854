import { ColumnDef, Row, Table, createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';
import { Info } from 'react-feather';
import { QueryClient } from 'react-query';
import { UncontrolledTooltip } from 'reactstrap';
import { MasterAdminLibrary } from 'types/master-admin-library';

const columnHelper = createColumnHelper<MasterAdminLibrary>();

export const contentTypeColumn = columnHelper.accessor('title', {
  header: () => <div className="text-start ps-2 text-neutral-900 fw-bold text-break">Content Type</div>,
  size: 80,
  enableResizing: false,
  cell: ({ row }) => <div className="ps-2 text-neutral-700 fw-bold text-break">{row.original.title}</div>,
});

export const allowColumn = columnHelper.display({
  id: 'allow',
  header: () => (
    <>
      <div className="d-flex justify-content-center align-items-center text-neutral-900 fw-bold flex-wrap">
        Allow{' '}
        <Info
          id="allow-tooltip"
          className="ms-1 d-none d-lg-block"
          size={16}
        />
      </div>
      <UncontrolledTooltip
        placement="auto"
        container="body"
        target="allow-tooltip"
      >
        Selected content types will be available for authors when creating new content.
      </UncontrolledTooltip>
    </>
  ),
  size: 40,
  enableResizing: false,
  cell: ({ table, row }) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      const { queryClient } = table.options.meta;

      const newData = getNewDataTable(table, row, checked, queryClient, 'allow');
      updateNewQueryData(queryClient, newData);
    };

    return (
      <div className="d-flex justify-content-center">
        <div className="form-group app-table-checkbox m-0">
          <input
            className="form-check-input primary-500 fs-lg cursor-pointer"
            type="checkbox"
            checked={!row.original.additionalMetadata?.restricted}
            onChange={handleOnChange}
          />
        </div>
      </div>
    );
  },
});

export const insideColumn = columnHelper.display({
  id: 'inside',
  header: () => (
    <>
      <div className="d-flex justify-content-center align-items-center text-neutral-900 fw-bold flex-wrap">
        Allow inside other types{' '}
        <Info
          id="inside-tooltip"
          className="ms-1 d-none d-lg-block"
          size={16}
        />{' '}
      </div>
      <UncontrolledTooltip
        placement="auto"
        container="body"
        target="inside-tooltip"
      >
        Selected content types will be available only as a part of another content type.
      </UncontrolledTooltip>
    </>
  ),
  size: 40,
  enableResizing: false,
  cell: ({ table, row }) => {
    const preventClick = !row.original.additionalMetadata?.restricted;

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (preventClick) {
        return;
      }

      const { checked } = e.target;
      const { queryClient } = table.options.meta;

      const newData = getNewDataTable(table, row, checked, queryClient, 'inside');
      updateNewQueryData(queryClient, newData);
    };

    return (
      <div className="d-flex justify-content-center">
        <div className="form-group app-table-checkbox m-0">
          <input
            className={classNames('form-check-input primary-500 fs-lg', {
              'cursor-pointer': !preventClick,
              'cursor-not-allowed': preventClick,
            })}
            type="checkbox"
            checked={!Boolean(row.original.additionalMetadata?.restrictedInOthers)}
            onChange={handleOnChange}
          />
        </div>
      </div>
    );
  },
});

const importColumn = columnHelper.display({
  id: 'import',
  header: () => (
    <>
      <div className="d-flex flex-wrap justify-content-center align-items-center text-neutral-900 fw-bold">
        Allow import{' '}
        <Info
          id="import-tooltip"
          className="ms-1 d-none d-lg-block"
          size={16}
        />
      </div>
      <UncontrolledTooltip
        placement="auto"
        container="body"
        target="import-tooltip"
      >
        Selected content types will be available only through upload option.
      </UncontrolledTooltip>
    </>
  ),
  size: 40,
  enableResizing: false,
  cell: ({ table, row }) => {
    const preventClick = !row.original.additionalMetadata?.restricted;

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (preventClick) {
        return;
      }

      const { checked } = e.target;
      const { queryClient } = table.options.meta;

      const newData = getNewDataTable(table, row, checked, queryClient, 'import');
      updateNewQueryData(queryClient, newData);
    };

    return (
      <div className="d-flex justify-content-center">
        <div className="form-group app-table-checkbox m-0">
          <input
            className={classNames('form-check-input primary-500 fs-lg', {
              'cursor-pointer': !preventClick,
              'cursor-not-allowed': preventClick,
            })}
            type="checkbox"
            checked={!Boolean(row.original.additionalMetadata?.restrictedImport)}
            onChange={handleOnChange}
          />
        </div>
      </div>
    );
  },
});

export const adminContentTypeColumns: ColumnDef<MasterAdminLibrary, string | unknown>[] = [
  contentTypeColumn,
  allowColumn,
  insideColumn,
  importColumn,
];

const updateAllow = (item: MasterAdminLibrary, checked: boolean) => {
  const newAdditionalMetadata = {
    restricted: !checked,
    restrictedInOthers: !checked ? Boolean(item.additionalMetadata?.restrictedInOthers) : false,
    restrictedImport: !checked ? Boolean(item.additionalMetadata?.restrictedImport) : false,
  };

  return {
    ...item,
    additionalMetadata: {
      ...item.additionalMetadata,
      ...newAdditionalMetadata,
    },
  };
};

const updateInside = (item: MasterAdminLibrary, checked: boolean) => {
  return {
    ...item,
    additionalMetadata: {
      ...item.additionalMetadata,
      restrictedInOthers: !checked,
    },
  };
};

const updateImport = (item: MasterAdminLibrary, checked: boolean) => {
  return {
    ...item,
    additionalMetadata: {
      ...item.additionalMetadata,
      restrictedImport: !checked,
    },
  };
};

const getNewDataTable = (
  table: Table<MasterAdminLibrary>,
  row: Row<MasterAdminLibrary>,
  checked: boolean,
  queryClient: QueryClient,
  type: 'allow' | 'inside' | 'import',
) => {
  const newDataTable = table.options.data.map(item => {
    if (item.id !== row.original.id) {
      return item;
    }

    let updateItem: MasterAdminLibrary = null;

    switch (type) {
      case 'allow':
        updateItem = updateAllow(item, checked);
        break;
      case 'inside':
        updateItem = updateInside(item, checked);
        break;
      case 'import':
        updateItem = updateImport(item, checked);
        break;
      default:
        break;
    }

    queryClient.setQueryData<MasterAdminLibrary[]>(['master-admin-libraries', 'restricted'], (oldData = []) => {
      if (oldData.length === 0 || !oldData.some((oldItem: MasterAdminLibrary) => oldItem.id === item.id)) {
        return [...oldData, updateItem];
      }

      return oldData.map((oldItem: MasterAdminLibrary) => (oldItem.id === item.id ? updateItem : oldItem));
    });

    return updateItem;
  });

  return newDataTable;
};
const updateNewQueryData = (queryClient: QueryClient, newData: MasterAdminLibrary[]) => {
  queryClient.setQueryData(['master-admin-libraries'], newData);
  queryClient.invalidateQueries(['master-admin-libraries', ['master-admin-libraries', 'restricted']]);
};
