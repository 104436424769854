export const REFINE_CONTEXT_BADGE = [
  'Delve deeper into each aspects',
  'Elaborate on the key points within the topic',
  'Shorter the context into a few bullet points',
  'Use academy language',
];

export const SELECT_CONTENT_BADGE = [
  'Use Academy language',
  'Create content for kids',
  'Create content for adults',
  'Question should assess both technical skills and soft skill',
  'Question should be challenging but still fun',
];
