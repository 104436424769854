import { IContentMetadata, IEditorModel, IPlayerModel } from '@lumieducation/h5p-server';
import { ContentStatus, H5PContent } from 'models/Content';
import apiClient from '../ApiClient';
import { ContentDetail, ContentSetting } from '@spiderbox/common';
import { ContentPermissions, ContentPlay } from 'types';
import { appStore } from 'stores';

const endPoint = '/contents';

export const save = async (payload: {
  contentId: string;
  requestBody: { library: string; params: any; metadata?: IContentMetadata };
  parentId?: string;
  setting?: ContentSetting;
  status?: ContentStatus;
  permissions?: ContentPermissions;
}): Promise<H5PContent> => {
  const { contentId, requestBody, parentId, setting, status, permissions } = payload;
  delete setting?.contentId;
  const body = JSON.stringify({ ...requestBody, parentId, setting, permissions, status });
  return contentId
    ? (await apiClient.patch(`${endPoint}/${contentId}`, body)).data
    : (await apiClient.post(`${endPoint}`, body)).data;
};

export const remove = async (contentId: string): Promise<void> => {
  await apiClient.delete(`${endPoint}/${contentId}`);
};

export const getEdit = async (
  contentId: string,
  aiMessageId?: string,
): Promise<
  IEditorModel & {
    library?: string;
    metadata?: IContentMetadata;
    params?: any;
  }
> => {
  contentId = contentId === 'new-content' ? undefined : contentId;
  const { data } = await apiClient.get<
    IEditorModel & {
      library?: string;
      metadata?: IContentMetadata;
      params?: any;
    }
  >(`${endPoint}/${contentId}/editor?aiMessageId=${aiMessageId}`);
  return data;
};

export const getContent = async (contentId: string): Promise<ContentDetail> => {
  const { data } = await apiClient.get(`${endPoint}/${contentId}`);
  return data;
};

export const getPlay = async ({
  contentId,
  contextId,
  asUserId,
  readOnlyState,
}: ContentPlay): Promise<IPlayerModel> => {
  contentId = contentId === 'new-content' ? undefined : contentId;
  const { data } = await apiClient.get(
    `${endPoint}/${contentId}/play?contextId=${contextId}&asUserId=${asUserId}&readOnlyState=${readOnlyState}`,
  );
  return data;
};

export const getEmbed = async (contentId: string) => {
  const { data } = await apiClient.get(`${endPoint}/${contentId}/embed`);
  return data;
};

export const getPreview = async ({
  contentId,
  contextId,
  asUserId,
  readOnlyState,
  library,
  parameters,
  metadata,
}: Partial<ContentPlay>): Promise<IPlayerModel> => {
  const settings = appStore.contentStore.settings;
  contentId = contentId === null ? 'new-content' : contentId;

  const { data } = await apiClient.post(
    `${endPoint}/${contentId}/preview?contextId=${contextId}&asUserId=${asUserId}&readOnlyState=${readOnlyState}`,
    {
      library,
      parameters,
      metadata,
      settings,
    },
  );

  return data;
};

export const generateDownloadLink = (contentId: string): string => {
  return `/api/${endPoint}/download/${contentId}`;
};

export const getContentContextId = async (contentId: string, userId: string, context: string = ''): Promise<string> => {
  const { data } = await apiClient.get(`${endPoint}/${contentId}/contextId/${userId}?context=${context}`);
  return data;
};

export const uploadContent = async (formData: FormData): Promise<H5PContent> => {
  return (await apiClient.post(`${endPoint}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }))
    .data;
};
