import { Role } from '@spiderbox/common';
import { UserPermission } from 'components/UserPermission';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { ContentStatus, Document, TDocumentType } from 'models/Content';
import React from 'react';

type Props = {
  children: JSX.Element;
  content: Document;
};
export const EditPublishedContentPermission = observer((props: Props) => {
  const { content, children } = props;
  const {
    userInfo: { roles },
  } = useAppStore();

  if (content.kind === TDocumentType.Folder || content.status === ContentStatus.Draft || roles.includes(Role.ADMIN))
    return children;

  return <UserPermission actions={['content.editPublished']}>{children}</UserPermission>;
});
