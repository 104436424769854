import React from 'react';
import SelectContentIcon from 'assets/images/ai-assistant/help-tips/select-content.svg';
import RefineContextIcon from 'assets/images/ai-assistant/help-tips/refine.svg';
import SelectOtherIcon from 'assets/images/ai-assistant/help-tips/select-other.svg';
import ImportCreateIcon from 'assets/images/ai-assistant/help-tips/import-create.svg';
import ApplyCreateIcon from 'assets/images/ai-assistant/help-tips/apply-create.svg';
import { ReactSVG } from 'react-svg';
import { THelpTip } from 'models/HelpTip';
import { ChevronLeft, ChevronRight } from 'react-feather';

type HelpTipContentProps = {
  helpTip: THelpTip;
  onNext: () => void;
  onPrev: () => void;
};

const HelpTipContent = ({ helpTip, onNext, onPrev }: HelpTipContentProps) => {
  const mapIcon = (type: THelpTip['type']) => {
    switch (type) {
      case 'import-create':
        return ImportCreateIcon;
      case 'brainstorm':
      case 'apply-create':
        return ApplyCreateIcon;
      case 'select-content':
        return SelectContentIcon;
      case 'refine-context':
      case 'refine-content':
        return RefineContextIcon;
      case 'select-other':
        return SelectOtherIcon;
      default:
        return null;
    }
  };
  return (
    <div
      className="gap-3 help-tip-item d-flex align-items-center"
      style={{ padding: '16px 54px' }}
    >
      <ChevronLeft
        strokeWidth={1.5}
        width={24}
        height={24}
        className="flex-shrink-0 cursor-pointer text-neutral-500 bg-neutral-100 arrow-icon arrow-icon-left"
        onClick={onPrev}
      />
      <div className="flex-shrink-0 tip-icon d-flex align-items-center justify-content-center">
        <ReactSVG src={mapIcon(helpTip.type)} />
      </div>
      <div className="flex-fill font-size-14">
        <span className="fw-bold text-neutral-500">{helpTip.title}</span>:&nbsp;
        <span className="text-neutral-500 fw-normal">{helpTip.description}</span>
      </div>
      <ChevronRight
        strokeWidth={1.5}
        width={24}
        height={24}
        className="flex-shrink-0 cursor-pointer text-neutral-500 bg-neutral-100 arrow-icon arrow-icon-right"
        onClick={onNext}
      />
    </div>
  );
};

export default HelpTipContent;
