import React from 'react';
import { ReactSVG } from 'react-svg';
import { AIMessageActionType } from 'types';
import refine from 'assets/images/ai-assistant/message-actions/refine-context.svg';
import selectOtherContent from 'assets/images/ai-assistant/message-actions/select-other-content.svg';

interface ActionButtonsProps {
  lastMessage: {
    actionType: AIMessageActionType;
    messageContentType: string;
  };
  isApplyLoading: boolean;
  isRefineLoading: boolean;
  isGenerateLoading: boolean;
  isAnalyzing: boolean;
  handleAction: (message: any) => void;
  handleRefineClick: (actionType: AIMessageActionType, messageContentType: string) => void;
  handleOpenSelectContentModal: () => void;
  renderIcon: (actionType: AIMessageActionType) => string;
  actionLabel: (actionType: AIMessageActionType) => string;
  getRefineLabel: (actionType: AIMessageActionType) => string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  lastMessage,
  isApplyLoading,
  isRefineLoading,
  isGenerateLoading,
  isAnalyzing,
  handleAction,
  handleRefineClick,
  handleOpenSelectContentModal,
  renderIcon,
  actionLabel,
  getRefineLabel,
}) => {
  const disabled = isApplyLoading || isRefineLoading || isGenerateLoading || isAnalyzing;

  return (
    <div className="flex-row gap-3 d-flex align-items-center">
      <button
        className="gap-2 btn btn-primary-hover d-flex align-items-center"
        onClick={() => handleAction(lastMessage)}
        disabled={disabled}
      >
        {isApplyLoading ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <div className="flex-shrink-0 p-1 d-flex align-items-center justify-content-center">
            <ReactSVG src={renderIcon(lastMessage.actionType)} />
          </div>
        )}
        {actionLabel(lastMessage.actionType)}
      </button>

      <button
        className="gap-2 btn btn-secondary-hover d-flex align-items-center"
        onClick={() => handleRefineClick(lastMessage.actionType, lastMessage.messageContentType)}
        disabled={disabled}
      >
        <div className="flex-shrink-0 p-1 d-flex align-items-center justify-content-center">
          <ReactSVG src={refine} />
        </div>
        {getRefineLabel(lastMessage.actionType)}
      </button>

      {(lastMessage.actionType === AIMessageActionType.GenerateContent ||
        lastMessage.actionType === AIMessageActionType.RefineContent) && (
        <button
          className="gap-2 btn btn-secondary-hover d-flex align-items-center"
          onClick={handleOpenSelectContentModal}
          disabled={disabled}
        >
          <div className="flex-shrink-0 p-1 d-flex align-items-center justify-content-center">
            <ReactSVG src={selectOtherContent} />
          </div>
          Select Other Content
        </button>
      )}
    </div>
  );
};

export default ActionButtons;
