import { PaymentIntent, PaymentMethodData } from 'types/payment';
import apiClient from 'apis/ApiClient';

const endPoint = '/payment';

export async function getAllPaymentMethods(): Promise<PaymentMethodData[]> {
  const response = await apiClient.get(`${endPoint}/payment-methods`);

  return response.data;
}

export async function createSetupIntent() {
  const response = await apiClient.post(`${endPoint}/setup-intent`);

  return response.data;
}

export async function removePaymentMethod(paymentMethodId: string): Promise<PaymentMethodData[]> {
  const response = await apiClient.delete(`${endPoint}/payment-methods/${paymentMethodId}`);

  return response.data;
}

export async function makePaymentMethodDefault(paymentMethodId: string): Promise<PaymentMethodData[]> {
  const response = await apiClient.put(`${endPoint}/payment-methods/${paymentMethodId}/default`);

  return response.data;
}

export async function syncPaymentMethod(paymentMethodId: string): Promise<PaymentMethodData> {
  const response = await apiClient.get(`${endPoint}/payment-methods/${paymentMethodId}/sync`);

  return response.data;
}

export const getLatestPaymentIntent = async (): Promise<PaymentIntent> => {
  const response = await apiClient.get(`${endPoint}/latest-payment-intent`);

  return response.data;
};
