import { uploadContent } from 'apis/Content/Content';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';

export const useUploadContent = () => {
  return useMutation({
    mutationFn: uploadContent,
    onSuccess: () => {
      toast('New content has been created successfully.', { type: 'success' });
    },
    onError: (error: ErrorResponse) => {
      toast.error(error.message || 'This file is invalid.');
    },
  });
};
