import React from 'react';
import './ContentDocumentationToolHelper.scss';
import RateALot from 'assets/images/contents/Rate-Alot.svg';
import RateSomething from 'assets/images/contents/Rate-Something.svg';
import RateLittle from 'assets/images/contents/Rate-Little.svg';

type Props = {
  helpTexts?: string[];
};
export const ContentDocumentationToolHelper = ({ helpTexts }: Props) => {
  return (
    <div className="d-flex gap-2 gap-md-0 p-md-0 flex-column flex-md-row">
      <div className="helper">
        <div className="helper-icon answer-0">
          <img
            src={RateLittle}
            alt="Learned little"
          />
        </div>
        <span>{helpTexts?.[0] || 'Learned little'}</span>
      </div>

      <div className="helper ms-md-4">
        <div className="helper-icon answer-1">
          <img
            src={RateSomething}
            alt="Learned something"
          />
        </div>
        <span>{helpTexts?.[1] || 'Learned something'}</span>
      </div>

      <div className="helper ms-md-4">
        <div className="helper-icon answer-2">
          <img
            src={RateALot}
            alt="Learned a lot"
          />
        </div>
        <span>{helpTexts?.[2] || 'Learned a lot'}</span>
      </div>
    </div>
  );
};
