import React, { useMemo } from 'react';
import { Loading } from 'components';
import { useAppStore } from 'hooks/useAppStore';
import { startCase } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Briefcase, CreditCard, Users } from 'react-feather';
import './SubscriptionCard.scss';
import { useGetWorkspaceUsageRecord } from 'hooks/workspace/useGetWorkspaceUsageRecord';
import { formatCurrency } from 'utils';
import { pluralize } from 'utils/helpers';

export const SubscriptionCard = observer(() => {
  const {
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  const { data: usageRecord, isLoading } = useGetWorkspaceUsageRecord();

  const subscriptionEndsAt = useMemo(() => {
    return moment(currentSubscription?.currentPeriodEnd).format('LL');
  }, [currentSubscription]);

  return (
    <div className="subscription-card d-flex flex-column">
      {currentSubscription && !isLoading ? (
        <div className="d-flex flex-column flex-lg-row gap-0 gap-lg-4">
          <div className="flex-fill subscription-card-period d-flex flex-column">
            <div className="d-flex align-items-start gap-2">
              <div className="text-neutral-900 fw-semibold">CURRENT PERIOD</div>
              <span className={`badge rounded-pill badge-${currentSubscription?.status}`}>
                {startCase(currentSubscription?.status)}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1 mt-2">
              <div className="text-neutral-600 font-size-16">Next Payment</div>
              <div
                className="text-primary-400 font-size-16"
                data-cy="subscription-period-end"
              >
                on {subscriptionEndsAt}
              </div>
            </div>
          </div>

          <div className="flex-fill subscription-card-info">
            <div className="d-flex flex-column">
              <div className="text-neutral-900 fw-semibold text-nowrap">ACTIVE USERS</div>

              <div className="text-neutral-900 fw-semibold fs-5 mt-2 d-flex flex-wrap align-items-center">
                <Users
                  size={32}
                  strokeWidth={1}
                  className="text-primary me-2"
                />{' '}
                <span
                  className="text-nowrap"
                  data-cy="total-active-users"
                >
                  {pluralize(usageRecord?.activeUsers, 'user')}
                </span>
              </div>
            </div>
          </div>

          {currentSubscription?.studentReportItem && (
            <div className="flex-fill subscription-card-info">
              <div className="d-flex flex-column">
                <div className="text-neutral-900 fw-semibold text-nowrap">ACTIVE LEARNERS</div>

                <div className="text-neutral-900 fw-semibold fs-5 mt-2 d-flex flex-wrap align-items-center">
                  <Briefcase
                    size={32}
                    strokeWidth={1}
                    className="text-primary me-2"
                  />{' '}
                  <span
                    className="text-nowrap"
                    data-cy="total-active-students"
                  >
                    {pluralize(usageRecord?.activeStudents, 'learner')}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="flex-fill subscription-card-info pb-0">
            <div className="d-flex flex-column">
              <div className="text-neutral-900 fw-semibold">UPCOMING INVOICE (ESTIMATED)</div>

              <div className="text-neutral-900 fw-semibold fs-5 mt-2 d-flex flex-wrap align-items-center">
                <CreditCard
                  size={32}
                  strokeWidth={1}
                  className="text-primary me-2"
                />{' '}
                <span data-cy="estimated-price">{formatCurrency(usageRecord?.estimatedPrice)}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
});
