import React from 'react';
import { cancelSubscription, resumeSubscription } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import { useMediaScreen } from 'hooks/useMediaScreen';
import useModal from 'hooks/useModal';
import moment from 'moment';
import { FileText } from 'react-feather';
import { toast } from 'react-toastify';
import { useActivateStudentReport, useDeactivateStudentReport } from 'hooks';
import { useQueryClient } from 'react-query';

export const useSubscriptionModal = () => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const {
    subscriptionStore: { currentSubscription, updateCurrentSubscription },
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const { isLgDown } = useMediaScreen();
  const { mutateAsync: activateStudentReport } = useActivateStudentReport();
  const { mutateAsync: deactivateStudentReport } = useDeactivateStudentReport();

  const subscriptionEndsAt = moment(currentSubscription?.currentPeriodEnd).format('LL');

  const openCancelPlanModal = (cancelAtPeriodEnd: boolean) => {
    const title = `Are you sure you want to ${cancelAtPeriodEnd ? 'resume' : 'cancel'} plan?`;
    const content = cancelAtPeriodEnd
      ? `This plan will no longer be canceled. It will renew on ${subscriptionEndsAt}`
      : `Your plan will be canceled, but is still available until the end of your billing period on ${subscriptionEndsAt}. If you change your mind, you can resume your subscription.`;
    const updateAction = cancelAtPeriodEnd ? resumeSubscription : cancelSubscription;

    openModal('warning', {
      title,
      content,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: () => {
        closeModal();
      },
      onAccept: async (done: () => void) => {
        await updateAction();
        updateCurrentSubscription({ ...currentSubscription, cancelAtPeriodEnd: !cancelAtPeriodEnd });
        queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
        done();
        toast(`Subscription has been ${cancelAtPeriodEnd ? 'resumed' : 'canceled'}.`);
        closeModal();
      },
    });
  };

  const openActivateStudentReportModal = (onSuccess: () => void) => {
    openModal('warning', {
      title: 'Activate Learner Report?',
      content:
        'Activating Learner Report feature will allow users to gain valuable learner performance information and send scores to LMS.',
      titleIcon: (
        <FileText
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: 'Yes, Activate',
      onAccept: async (done: () => void) => {
        try {
          const data = await activateStudentReport();

          updateCurrentSubscription({ ...currentSubscription, studentReportItem: data.studentReportItem });
          queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
          done();
          onSuccess();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  const openDeactivateStudentReportModal = (onSuccess: () => void) => {
    openModal('deactivate-student-report', {
      size: 'lg',
      hiddenModalClassName: true,
      className: isLgDown && 'align-items-center mw-100 h-100 mx-2 my-0',
      onAccept: async (done: () => void) => {
        try {
          const data = await deactivateStudentReport();

          updateCurrentSubscription({ ...currentSubscription, ...data });
          queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
          queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'invoices']);
          done();
          onSuccess();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  return {
    openCancelPlanModal,
    openDeactivateStudentReportModal,
    openActivateStudentReportModal,
  };
};
