export const SELECT_CONTENT_TIP = {
  type: 'select-content' as const,
  title: 'Select Content',
  description:
    "Perfect when you're satisfied with your context. Choose this to select your content type and let Michi handle the rest.",
} as const;

export const REFINE_CONTEXT_TIP = {
  type: 'refine-context' as const,
  title: 'Refine Context',
  description:
    'Ideal for tweaking existing context to perfection. Use this when you want to change context length, adjust language, or modify some details.',
} as const;

export const APPLY_CREATE_TIP = {
  type: 'apply-create' as const,
  title: 'Apply & Create',
  description:
    'Ready to turn your ideas into finished learning material? Use this step to generate a cohesive piece that brings your content to life.',
} as const;

export const REFINE_CONTENT_TIP = {
  type: 'refine-content' as const,
  title: 'Refine Content',
  description:
    'Enhance your existing content by changing the number of questions, adding or removing items, or adjusting for relevant age groups or language levels.',
} as const;

export const SELECT_OTHER_TIP = {
  type: 'select-other' as const,
  title: 'Select Other Content',
  description:
    'Unsatisfied with your initial choice? Start fresh by selecting a new content type tailored to your existing learning context.',
} as const;

export const BRAINSTORM_TIP = {
  type: 'brainstorm' as const,
  title: 'Brainstorm with Michi',
  description:
    "Don't know where to start? Use Michi's insights to quickly brainstorm and enhance your learning content ideas.",
} as const;

export const IMPORT_CREATE_TIP = {
  type: 'import-create' as const,
  title: 'Import & Create',
  description:
    "Already has content to upload or insert? Effortlessly import and create engaging learning materials in just minutes with Michi's streamlined process.",
} as const;
